<template>
  <div>
    <strong> <p>Fill this form to publish your upcoming event</p> </strong>
    <p>For users to request the publication of their events on the site.</p>
    <p v-show="successMessage" class="alert alert-success rounded">
      Thank you for your enquiry. We will keep you informed about the latest updates to the
      Platform.
    </p>
    <p v-show="errorMessage" class="alert alert-danger rounded">
      An error occured, please try again later.
    </p>
    <p v-show="noFields" class="alert alert-warning">Please fill in all fields</p>

    <base-input type="text" v-model="eventFormFields.organization" label="Name/Organization" />

    <base-input type="number" v-model="eventFormFields.phone_number" label="Phone Number" />

    <base-input type="email" v-model="eventFormFields.email" label="Email" />

    <base-input type="text" v-model="eventFormFields.event_name" label="Name of event" />

    <base-textarea type="text" v-model="eventFormFields.event_desc" label="Description of event" />

    <base-select
      type="text"
      v-model="eventFormFields.event_type"
      width="200px"
      :items="event_type"
      label="Select Event Type"
    >
    </base-select>

    <br />

    <div class="newGrid grid">
      <span class="secondGrid grid">
        <base-input
          type="number"
          v-model="startMonth"
          label="Commencement Date"
          width="70px"
          placeholder="MM"
        />

        <base-input
          type="number"
          v-model="startDay"
          width="62px"
          placeholder="DD"
          class="mt-5 pt-2"
        />

        <base-select
          class="mt-5 adjust"
          :items="generateArrayOfYears()"
          selected="YYYY"
          rules
          disabled
          v-model="startYear"
          width="90px"
          name="Start Year"
        />
      </span>

      <span class="secondGrid grid">
        <!-- <div class="space"> -->

        <base-input
          type="number"
          v-model="startHour"
          width="62px"
          placeholder="HH"
          class="mt-5 pt-2"
        />

        <base-input
          type="number"
          v-model="startMinute"
          width="70px"
          placeholder="MM"
          class="mt-5 pt-2"
        />

        <base-select
          class="mt-5 pt-2"
          type="text"
          v-model="startMeridem"
          width="75px"
          selected
          disabled
          :items="event_time"
        >
        </base-select>
      </span>
    </div>

    <br />

    <div class="newGrid grid">
      <span class="secondGrid grid">
        <base-input
          type="number"
          v-model="endMonth"
          label="Event Ends"
          width="80px"
          placeholder="MM"
        />

        <base-input
          type="number"
          v-model="endDay"
          width="62px"
          placeholder="DD"
          class="mt-4 pt-2"
        />

        <base-select
          :items="generateArrayOfYears()"
          selected="YYYY"
          rules
          disabled
          v-model="endYear"
          width="90px"
          name="End Year"
          class="mt-4 adjust"
        />
      </span>

      <span class="secondGrid grid">
        <base-input
          type="number"
          v-model="endHour"
          width="62px"
          placeholder="HH"
          class="mt-4 pt-2"
        />

        <base-input
          type="number"
          v-model="endMinute"
          width="70px"
          placeholder="MM"
          class="mt-4 pt-2"
        />

        <base-select
          type="text"
          v-model="endMeridem"
          width="75px"
          selected
          disabled
          :items="event_time"
          class="mt-4 pt-2"
        >
        </base-select>
      </span>
    </div>

    <button @click="conformSend" class="send send--close red--text w-25">SUBMIT</button>
  </div>
</template>

<script>
import moment from 'moment';
import messageMixin from '../../mixins/messages';
import { getEventTypes as EVENT, postEvent, constants as Constants } from '../../services/service';

export default {
  mixins: [messageMixin],
  data() {
    return {
      startDay: '',
      startYear: '',
      startMonth: '',
      endDay: '',
      endMonth: '',
      endYear: '',

      startHour: '',
      startMinute: '',
      startMeridem: '',
      endHour: '',
      endMinute: '',
      endMeridem: '',

      eventFormFields: {
        organization: '',
        event_name: '',
        event_desc: '',
        phone_number: '',
        email: '',
        event_type: '',
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: '',
      },
      event_type: null,
      event_time: ['AM', 'PM'],
    };
  },
  methods: {
    dateTime() {
      const startDate = new Date(
        this.startYear,
        this.startMonth,
        this.startDay,
        this.startHour,
        this.startMinute
      );
      const StartDate = moment(String(startDate)).format('YYYY-MM-DD');
      const StartTime = moment(String(startDate)).format('LT');
      const endDate = new Date(
        this.endYear,
        this.endMonth,
        this.endDay,
        this.endHour,
        this.endMinute
      );
      const EndDate = moment(String(endDate)).format('YYYY-MM-DD');
      const EndTime = moment(String(EndDate)).format('LT');
      this.eventFormFields.start_date = StartDate;
      this.eventFormFields.start_time = StartTime;
      this.eventFormFields.end_date = EndDate;
      this.eventFormFields.end_time = EndTime;
      this.eventFormFields.event_type = this.eventFormFields.event_type.id;
    },
    emptyFields() {
      this.eventFormFields.organization = '';
      this.eventFormFields.event_name = '';
      this.eventFormFields.event_desc = '';
      this.eventFormFields.phone_number = '';
      this.eventFormFields.email = '';
      this.eventFormFields.event_type = '';
      this.startMonth = '';
      this.startYear = '';
      this.startDay = '';
      this.startHour = '';
      this.startMinute = '';
      this.startMeridem = '';
      this.endMinute = '';
      this.endHour = '';
      this.endYear = '';
      this.endMonth = '';
      this.endMeridem = '';
      this.endDay = '';
      console.log('cleared form');
    },
    output() {
      console.log('ouput working');
    },

    conformSend() {
      this.output();
      this.dateTime();

      if (
        this.eventFormFields.organization &&
        this.eventFormFields.event_name &&
        this.eventFormFields.event_desc &&
        this.eventFormFields.email &&
        this.eventFormFields.phone_number
      ) {
        this.errorMessage = false;
        console.log('getting ready to post');
        console.log(this.eventFormFields);

        const payload = this.eventFormFields;

        postEvent(payload)
          .then((response) => {
            console.log(`have posted succesfully${response}`);
            // give successs message and empty all input fields
            if (response.status === 201) {
              this.toast();
              console.log('submitted');
              this.emptyFields();
            } else {
              this.errorMessage = true;
            }
          })
          .catch((e) => {
            console.log(e);
            this.errorMessage = true;
          });
      } else {
        console.log('something went wrong');
        this.noFields = true;
      }
    },
    generateArrayOfYears() {
      const max = new Date().getFullYear();
      const min = max - 80;
      const years = [];
      for (let i = max; i >= min; i -= 1) {
        years.push(i);
      }
      return years;
    },
  },

  mounted() {
    console.log('events mounted');
    Constants().then((response) => {
      this.event_type = response.data.EventTypes;
      console.log('the types are ');
      console.log(this.event_type);
    });
  },
};
</script>

<style src="./feedback.css" scoped></style>
