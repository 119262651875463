<template>
  <div>
    <strong><p>Please send us a feedback. We would love to hear from you.</p></strong>
    <p v-show="successMessage" class="alert alert-success rounded">
      Thank you for your feedback. Your opinions are important to us.
    </p>
    <p v-show="errorMessage" class="alert alert-danger rounded">
      An error occured, please try again later.
    </p>
    <p v-show="noFields" class="alert alert-warning">Please fill in all fields</p>

    <base-input
      type="text"
      label="Feedback Title"
      rules="required"
      name="Feedback Title"
      v-model="feedbackFormFields.title"
    />

    <base-input
      label="Email"
      type="email"
      rules="required|email"
      name="Email"
      v-model="feedbackFormFields.email"
    />

    <base-select
      type="text"
      rules="required"
      name="Feedback Type"
      v-model="feedbackFormFields.feedback_type"
      :items="item"
      label="Feedback Type"
    >
    </base-select> 

    <base-textarea
      label="Feedback"
      v-model="feedbackFormFields.content"
      rules="required"
      name="Feedback"
      :rows="5"
    />

    <!-- <label class="text-left font-weight-bold text-dark mb-1 mt-4"> Captcha</label> -->

    <button @click="conformSend" class="send send--close red--text w-25">
      SUBMIT
    </button>
  </div>
</template>

<script>
import messageMixin from '../../mixins/messages';
// import { getFeedbackTypes as FEEDBACK, postFeedback as ADD_FEEDBACK } from '../../services/service';

import { getFeedType as Feedback, postFeed as addFeed, constants as Constants } from '../../services/requests';

export default {
  mixins: [messageMixin],
  data() {
    return {
      feedbackFormFields: {
        feedback: '',
        title: '',
        email: '',
        content: '',
        feedback_type: '',
      },
      item: null,
    };
  },
  methods: {
    conformSend() {
      // this.feedbackFormFields.feedback_type = this.feedbackFormFields.feedback_type.id;
      this.feedbackFormFields.feedback = this.feedbackFormFields.content

      if (
        this.feedbackFormFields.title &&
        this.feedbackFormFields.email &&
        this.feedbackFormFields.content
      ) {
        this.errorMessage = false;
        console.log('fields arent empty');
        const payload = this.feedbackFormFields;
        addFeed(payload)
          .then((response) => {
            console.log(`have posted succesfully${response}`);
            // give successs message and empty all input fields
            if (response.status === 201) {
              this.toast();
              console.log('submitted');
              this.successMessage = true;
              this.feedbackFormFields.title = '';
              this.feedbackFormFields.email = '';
              this.feedbackFormFields.content = '';
              this.feedbackFormFields.feedback_type = '';
            } else {
              this.errorMessage = true;
            }
          })
          .catch((e) => {
            console.log(e);
            this.errorMessage = true;
          });
      } else {
        console.log('something went wrong');
        this.noFields = true;
      }
    },
  },

  mounted() {
    Constants().then((response) => {
      console.log('constants', response)
      this.item = response.data.FeedBackTypes;
      console.log('items are');
      console.log(this.item);
    });
  },
};
</script>

<style src="./feedback.css" scoped></style>
