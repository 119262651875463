<template>
  <div>
    <b-link :to="to" class="text-decoration-none">
      <b-card
        shadow
        footer-bg-variant="white"
        style="max-width: auto;"
        footer-class="text-left pt-3 pb-3 text-black-50"
        class="shadow mb-5 bg-white border-0 text-decoration-none"
      >
        <main>
          <slot name="body"></slot>
        </main>

        <template #footer>
          <b-row align-h="between">
            <b-col cols="6" sm="4">
              <span class="d-none d-sm-block">
                Uploaded {{ $dataset.formatDate(date, 'DD/MM/YYYY') }}</span
              >
              <span class="text-truncate d-sm-none">
                Uploaded {{ $dataset.formatDate(date, 'DD/MM/YYYY') }}</span
              >
            </b-col>
            <b-col cols="6" sm="4" class="text-right" v-if="seemorelink">
              <b-link class="ml-1 text-primary text-decoration-none" :to="to"
                >See more...</b-link
              ></b-col
            >
          </b-row>
        </template>
      </b-card>
    </b-link>
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      default: '',
    },
    seemorelink: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
