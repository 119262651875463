<template>
  <div :class="[showsideborder ? 'border-left' : '', 'text-left p-3 h-100']" style="width: 14rem">
    <div>
      <div>
        <div class="filter-button mt-n2" style="margin-bottom: 3rem">
          <base-button
            text="Apply Filter"
            @click="applyFilter"
            :loading="loading"
            class="base-button"
          />
        </div>
        <p class="font-weight-bolder h6 text-black-50 mb-4 mt-n2">FILTERS</p>
      </div>
      <p class="font-weight-bolder pb-2 h6 text-black-50">PROGRAM AREAS</p>
      <div
        v-if="programsAreaToShowComputed.length > 0"
        :class="programsAreaToShowComputed.length > 3 ? 'states' : ''"
      >
        <base-checkbox
          v-for="(program, index) in programsAreaToShowComputed"
          :key="index"
          class="pl-2 mt-n5"
          v-model="progamAreasSelected"
          :checkboxItem="program.name"
          :item="program"
        />
      </div>
      <p v-else style="font-size: 15px; color: #888888">No program area data found</p>
      <b-link
        v-if="programsAreaToShowComputed.length < 4 && !programsAreaToShowComputed.length < 1"
        class="text-decoration-none"
        @click="showMoreLessProgramArea(true)"
        >See {{ getProgramsArea.results.length - 3 }} more</b-link
      >
      <b-link
        v-if="programsAreaToShowComputed.length > 4 && !programsAreaToShowComputed.length < 1"
        class="text-decoration-none"
        @click="showMoreLessProgramArea(false)"
        >See less</b-link
      >
    </div>
    <!--
    <div>
      <p class="font-weight-bolder regular mb-n4 mt-4 pb-2 h6 text-black-50">STATES</p>
      <base-input
        @input="searchStates"
        v-model="stateToSearch"
        prepend="fa fa-search"
        height="1.8rem"
        prependclass="mt-n1"
        :filled="true"
        class="pb-2"
        placeholder="Search"
        :width="statesSearchWidth ? statesSearchWidth : '90%'"
      />
      <div
        v-if="statesToShowComputed.length > 0"
        :class="statesToShowComputed.length > 4 ? 'states' : ''"
      >
        <base-checkbox
          v-for="(state, index) in statesToShowComputed"
          :key="index"
          class="pl-2 mt-n5"
          :checkboxItem="state.name"
          :item="state"
          v-model="stateSelected"
        />
      </div>
      <p
        v-else
        style="
        font-size: 15px; color: #888888"
      >
        No State found for your search "{{ stateToSearch }}"
      </p>
      <b-link
        v-if="statesToShowComputed.length < 4 && !statesToShowComputed.length < 1"
        class="text-decoration-none"
        @click="showMoreLessStates(true)"
        >See {{ states.length - 4 }} more</b-link
      >
      <b-link
        v-if="statesToShowComputed.length > 4 && !statesToShowComputed.length < 1"
        class="text-decoration-none"
        @click="showMoreLessStates(false)"
        >See less</b-link
      >
    </div> -->

    <div class="w-100">
      <p class="font-weight-bolder mt-4 pb-2 h6 text-black-50">STATUS OF IMPLEMENTATION</p>
      <base-checkbox
        v-for="(status, index) in ['Completed', 'Ongoing', 'Abadoned']"
        :key="index"
        class="pl-2 mt-n5"
        :checkboxItem="status"
        :item="status"
        v-model="statusSelected"
      />
    </div>

    <div>
      <p class="font-weight-bolder mt-4 pb-2 h6 text-black-50">HEALTH SYSTEM AREA</p>
      <div
        v-if="healthSystemAreaToShowComputed.length > 0"
        :class="healthSystemAreaToShowComputed.length > 4 ? 'states' : ''"
      >
        <base-checkbox
          v-for="(healthsystem, index) in healthSystemAreaToShowComputed"
          :key="index"
          class="pl-2 mt-n5"
          :checkboxItem="healthsystem"
          :item="healthsystem"
          v-model="healthSystemAreasSelected"
        />
      </div>
      <p v-else style="font-size: 15px; color: #888888">No health system data found</p>
      <b-link
        v-if="
          healthSystemAreaToShowComputed.length < 4 && !healthSystemAreaToShowComputed.length < 1
        "
        class="text-decoration-none"
        @click="showMoreLessHealthSysytem(true)"
        >See {{ healthSystemAreaFormatted.length - 3 }} more</b-link
      >
      <b-link
        v-if="
          healthSystemAreaToShowComputed.length > 4 && !healthSystemAreaToShowComputed.length < 1
        "
        class="text-decoration-none"
        @click="showMoreLessHealthSysytem(false)"
        >See less</b-link
      >
    </div>

    <div v-if="accessLevel">
      <p class="font-weight-bolder mt-4 pb-2 h6 text-black-50">ACCESS LEVEL</p>
      <base-checkbox
        class="pl-2 mt-n5"
        v-for="(access, index) in sensitivity"
        :key="index"
        :checkboxItem="access.name"
        :item="access"
        v-model="accessLevelSelected"
      />
    </div>
  </div>
</template>

<script type="js">
import { mapGetters, mapActions, mapMutations } from 'vuex';
import baseButton from '../../../../components/FormInputs/_base-button.vue';
import statesProj from './State';

export default {
  name: 'ProjectFilters',
  components: { baseButton },
  props: {
    showsideborder: {
      type: Boolean,
      default: true,
    },
    statesSearchWidth: {
      type: String,
      default: null,
    },
    filterButtonAlign: {
      type: String,
      default: '7rem',
    },
    accessLevel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      allProjData: [],
      statesProj: [],
      newStatesProj: [],
      loading: false,
      stateToSearch: '',
      statesToShow: [],
      progamAreasSelected: [],
      programsAreaSelectedToSend: [],
      stateSelected: [],
      stateToSend: [],
      accessLevelSelected: [],
      accessLevelToSend: [],
      statusSelected: [],
      statusToSend: [],
      healthSystemAreasSelected: [],
      healthSystemAreasToSend: [],
      sensitivity: [],
      states: [],
      healthSystemAreaToShow: [],
      healthSystemAreaFormatted: [],
      programsAreaToShow: [],
      programsAreaFormatted: [],
    };
  },
  methods: {
    ...mapActions('DATASET_STORE', ['SETPROGRAMAREAS', 'SETSYSTEMAREA', 'SETSTATEGEOCOVERAGE', 'SETPOPULATING', 'SETFILTERDATA']),
    ...mapMutations('DATASET_STORE', [
      'setProgramsArea',
      'setHealthSystemArea',
      'setStakeholderBranches',
      'setStakeholderCategories',
      'setGeoCoverage',
    ]),

    async applyFilter() {
      this.loading = true;
     this.SETPOPULATING(true);
    this.statesProj = statesProj;
    this.allProjData = await this.$dataset.getAllProjects();

    // for each status selected, filter the program set
    if(this.statusToSend.length > 0){
         let newArrStatus = [];
     await this.statusToSend.forEach((status) => {
       newArrStatus = newArrStatus.concat(this.allProjData.data.results.filter((value) => value.status === status.toLowerCase()));
    })
    this.allProjData.data.results = newArrStatus;

    }
    console.log(this.programsAreaSelectedToSend)

   // for each program area selected, filter the program set
      if(this.programsAreaSelectedToSend.length > 0){
        let newArrProg = [];
      await this.programsAreaSelectedToSend.forEach((programArea) => {
       newArrProg = newArrProg.concat(this.allProjData.data.results.filter((value) => value.program_area.some(el => el.id === programArea)));
    })
      this.allProjData.data.results = newArrProg;
    }

     // for each health system area selected, filter the program set
          if(this.healthSystemAreasToSend.length > 0){
        let newHealthSysProg = [];
      await this.healthSystemAreasToSend.forEach((healthSystemArea) => {
       newHealthSysProg = newHealthSysProg.concat(this.allProjData.data.results.filter((value) => value.health_system_area.some(el => el.id === healthSystemArea)));
    })
      this.allProjData.data.results = newHealthSysProg;
    }


    await this.statesProj.forEach((state) => {
           const num = this.allProjData.data.results.filter((value) => value.geo_coverage.some(el => el.name === state.name))
        .length;
      this.statesProj[state.id - 1].numProj = num;
      this.newStatesProj = this.statesProj;
    });

    const data = {
        status: await this.statusToSend,
        program_areas: await this.programsAreaSelectedToSend,
        health_system: await this.healthSystemAreasToSend
       }

       console.log('data', data)

    // resetting the states data
    await this.SETSTATEGEOCOVERAGE(this.newStatesProj);

 this.SETPOPULATING(false);
 this.loading = false
 this.SETFILTERDATA(data);

    },
    searchStates() {
      this.statesToShow = this.states.filter((item) => {
        return item.name.toLowerCase().includes(this.stateToSearch.toLowerCase());
      });
    },
    showMoreLessHealthSysytem(show) {
      if (show) {
        this.healthSystemAreaToShow = this.getHealthSystemArea.results;
        return;
      }
      this.healthSystemAreaToShow = this.healthSystemAreaFormatted.slice(0, 3);
    },
    showMoreLessProgramArea(show) {
      if (show) {
        this.programsAreaToShow = this.getProgramsArea.results;
        return;
      }

      this.programsAreaToShow = this.programsAreaFormatted.slice(0, 3);
    },
    showMoreLessStates(show) {
      if (show) {
        this.statesToShow = this.states;
        return;
      }
      this.statesToShow = this.states.slice(0, 3);
    },
  },
  watch: {
    getHealthSystemArea(val) {
      this.healthSystemAreaFormatted = val;
      this.healthSystemAreaToShow = val.slice(0, 3);
    },
    getProgramsArea(val) {
      this.programsAreaFormatted = val;
      this.programsAreaToShow = val.slice(0, 3);
    },

    progamAreasSelected(val) {
      const indexOfItem = this.programsAreaSelectedToSend.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.programsAreaSelectedToSend.splice(indexOfItem, 1);
        return;
      }
      this.programsAreaSelectedToSend.push(Number(val.item.id));

      console.log(this.programsAreaSelectedToSend);
    },
    stateSelected(val) {
      const indexOfItem = this.stateToSend.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.stateToSend.splice(indexOfItem, 1);
        return;
      }
      this.stateToSend.push(Number(val.item.id));
    },
    accessLevelSelected(val) {
      const indexOfItem = this.accessLevelToSend.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.accessLevelToSend.splice(indexOfItem, 1);
        return;
      }
      this.accessLevelToSend.push(Number(val.item.id));
    },
    statusSelected(val) {
      const indexOfItem = this.statusToSend.indexOf(val.item);
      if (indexOfItem >= 0) {
        this.statusToSend.splice(indexOfItem, 1);
        return;
      }
      this.statusToSend.push(val.item);
      console.log(this.statusToSend)
    },
    healthSystemAreasSelected(val) {
      const indexOfItem = this.healthSystemAreasToSend.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.healthSystemAreasToSend.splice(indexOfItem, 1);
        return;
      }
      this.healthSystemAreasToSend.push(Number(val.item.id));
      console.log(this.healthSystemAreasToSend)
    },
  },
  async mounted() {
    this.SETPOPULATING(true);
    this.statesProj = statesProj;
    const data = await this.$dataset.getAllProjects();
    console.log('data', data)
    this.statesProj.forEach((state) => {
      // const num = data.data.results.filter((value) => value.geo_coverage[0].name === state.name)
      //   .length;
      const num = data.data.results.filter((value) => value.geocoverages.some(el => el.name === state.name))
        .length;
      this.statesProj[state.id - 1].numProj = num;
    });

    console.log('stateProj', this.statesProj)
    // setting the geo coverage
    await this.SETSTATEGEOCOVERAGE(this.statesProj);
    // getting the state geo from vuex
   const programsAreaData = await this.$dataset.getAllProgramAreas();
   console.log('programs', programsAreaData)
      const healthAreas = await this.$dataset.getAllHealthAreas();
    // end of the new code that is added
    if (this.getProgramsArea.length < 1 || this.getHealthSystemArea.length < 1) {


      await this.SETPROGRAMAREAS(programsAreaData.data);
      await this.SETSYSTEMAREA(healthAreas.data);
    }
    const statesData = await this.$dataset.getStates();
    // const constants = await this.$dataset.getConstants();
    // console.log('new constants', constants)
    const sensitivityData = await this.$dataset.getAllSensitivity();
    this.sensitivity = sensitivityData;
    this.states = statesData.data.results;
    this.statesToShow = statesData.data.results.slice(0, 3);
    console.log('healthAreas', healthAreas)
    this.healthSystemAreaFormatted = healthAreas;
    this.healthSystemAreaToShow = healthAreas;
    this.programsAreaFormatted = programsAreaData.data.results;
    this.programsAreaToShow = programsAreaData.data.results;
    this.SETPOPULATING(false);
  },
  computed: {
    ...mapGetters('DATASET_STORE', [
      'getProgramsArea',
      'getHealthSystemArea',
      'getStateGeoCoverage',
      'getPopulating'
    ]),
    statesToShowComputed() {
      return this.statesToShow;
    },
    healthSystemAreaToShowComputed() {
      return this.healthSystemAreaToShow;
    },
    programsAreaToShowComputed() {
      return this.programsAreaToShow;
    },
  },
};
</script>

<style scoped>
.states {
  height: 10rem;
  overflow: auto;
}

.base-button:hover {
  opacity: 0.9;
}
</style>
