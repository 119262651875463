<template>
  <b-modal id="modal-center" centered hide-footer hide-header size="lg" ref="modal">
    <div class="container">
      <div class="d-flex justify-content-between my-3">
        <h3 class="font-weight-bold text-primary">{{ data.name }}</h3>
        <div class="row">
          <div class="col-md-6">
            <button
              class="btn btn-sm bg-primary text-white d-inline rounded-0"
              @click="edit(data.id)"
            >
              <i class="fa fa-pen mx-1"></i>Edit
            </button>
          </div>
          <div class="col-md-6">
            <select class="form-control d-inline">
              <option value="">{{ data.details.status ? 'Active' : 'Suspended' }}</option>
            </select>
          </div>
        </div>
        <h6 class="text-primary" type="button" @click="closeModal()">close</h6>
      </div>
      <div class="row row-cols-2 mt-4">
        <div class="col-md-6">
          <h6 class="font-weight-bold">NAME OF NGO</h6>
          <p>{{ data.name }}</p>
          <h6 class="font-weight-bold">YEAR OF REGISTRATION</h6>
          <p>{{ data.details.year }}</p>
          <h6 class="font-weight-bold">NAME OF CONTACT PERSON</h6>
          <p>first name: {{ data.details.contact_person_first_name }}</p>
          <p>last name: {{ data.details.contact_person_last_name }}</p>
        </div>
        <div class="col-md-6">
          <h6 class="font-weight-bold">PHONE ON. OF CONTACT PERSON</h6>
          <p>{{ data.details.contact_person_phone }}</p>
          <h6 class="font-weight-bold">EMAIL</h6>
          <p>{{ data.email }}</p>
          <h6 class="font-weight-bold">STATE</h6>
          <p>{{ data.details.state }}</p>
          <h6 class="font-weight-bold">GEOGRAPHICAL ZONE</h6>
          <p>{{ data.details.geo_zone }}</p>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ng-admin-modal',
  props: ['data'],
  methods: {
    edit(id) {
      this.$router.push({ path: `update-ngo/${id}` });
    },
    closeModal() {
      this.$refs.modal.hide();
    },
  },
};
</script>
<style scoped>
* {
  font-family: 'Open Sans';
}

h6 {
  color: rgb(0, 0, 0);
}

p {
  color: #696868;
  font-weight: bold;
}
</style>
