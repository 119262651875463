<template>
  <div>
    <b-row class="mb-5 ml-0" align-h="between">
      <h4 class="text-left text-primary">{{ projectDetails.name }}</h4>
    </b-row>
    <b-row class="p-0" no-gutters align-h="start">
      <b-col cols="12" md="3" lg="2" class="mr-0">
        <p class="text-left font-weight-bold text-black-50">Organization/owner</p>
      </b-col>
      <b-col cols="12" md="8" lg="10" class="ml-0">
        <p class="text-left text-primary">
          {{ organization }}
        </p>
      </b-col>
    </b-row>
    <b-row class="p-0" no-gutters align-h="start">
      <b-col cols="12" md="3" lg="2" class="mr-0">
        <p class="text-left font-weight-bold text-black-50">Data format</p>
      </b-col>
      <b-col cols="12" md="9" lg="10" class="ml-0">
        <p class="text-left text-primary">File, File PDF</p>
      </b-col>
    </b-row>
    <b-row class="p-0" no-gutters align-h="start">
      <b-col cols="12" md="3" lg="2" class="mr-0">
        <p class="text-left font-weight-bold text-black-50">Category</p>
      </b-col>
      <b-col cols="12" md="9" lg="10" class="ml-0">
        <p class="text-left text-primary">N/A</p>
      </b-col>
      <b-col cols="12" md="9" lg="10" class="ml-0">
        <p class="text-left text-primary">
          <span
            v-for="(program, index) in projectDetails.program_area"
            :key="index"
            class="ml-1 text-primary text-decoration-none"
            ><span v-if="index !== projectDetails.program_area.length - 1">{{ program }},</span>
            <span v-else>{{ program.name }}</span></span
          >
        </p>
      </b-col>
    </b-row>
    <b-row class="p-0" no-gutters align-h="start">
      <b-col cols="12" md="3" lg="2" class="mr-0">
        <p class="text-left font-weight-bold text-black-50">Tags</p>
      </b-col>
      <b-col cols="12" md="9" lg="10" class="ml-0">
        <p class="text-left text-primary">
          Abia, Adamawa, Akwa Ibom, Anambra, Bauchi, Bayelsa, Benue, Borno, Cross River, Delta,
          Ebonyi, Edo, Ekiti, Enugu, Gombe, Imo, Jigawa, Kaduna, Kano, Katsina, Kebbi, Kogi, Kwara,
          Lagos, Nasarawa, Niger, Ogun, Ondo, Osun, Oyo, Plateau, Rivers, Sokoto, Taraba, Yobe,
          Zamfara
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      organization: 'Organization not found',
    };
  },

  methods: {
    async getOrganizationName(orgId) {
      try {
        console.log(orgId, 'orgId');
        if (orgId !== undefined) {
          const organization = await this.$dataset.getSpecificOrganization(orgId);
          console.log(organization);
          const { name } = organization.data;
          this.organization = name;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },

  async mounted() {
    await this.getOrganizationName(this.projectDetails?.organization);
  },
};
</script>
