<template>
  <div>
    <b-container>
      <b-row class="mb-4 ml-1">
        <h4 class="text-left text-primary ml-n1">
          {{ projectDetails.activity }}
        </h4>
      </b-row>
      <div class="w-100 desc p-4">
        <p class="text-left font-weight-bold text-black-50">Stakeholder</p>
        <p class="text-left mt-n2">{{ projectDetails.stake_holder.name }}</p>

        <p class="text-left font-weight-bold text-black-50 mt-4">Division</p>
        <p class="text-left mt-n2">
          {{ projectDetails.stake_holder_branch.stake_holder_division.name }}
        </p>

        <p class="text-left font-weight-bold text-black-50 mt-4">Branch</p>
        <p class="text-left mt-n2">{{ projectDetails.stake_holder_branch.name }}</p>

        <p class="text-left font-weight-bold text-black-50 mt-4">Category</p>
        <p class="text-left mt-n2">{{ projectDetails.stake_holder_category.name }}</p>

        <p class="text-left font-weight-bold text-black-50 mt-4">Activity</p>
        <p class="text-left mt-n2">{{ projectDetails.activity }}</p>

        <p class="text-left font-weight-bold text-black-50 mt-4">Roles</p>
        <p class="text-left mt-n2">{{ projectDetails.role }}</p>
      </div>
      <div class="text-left ml-1 mt-5">
        <base-button text="Back" @click="$router.go(-1)" width="9em" />
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectDetails: {},
    };
  },
  async mounted() {
    const { data } = await this.$dataset.getAllStakeholderActivityDetailsById(
      this.$route.params.id
    );
    this.projectDetails = data;
    console.log(data);
  },
  methods: {},
};
</script>

<style scoped>
.desc {
  background-color: #f7f7f7;
}
</style>
