import './toast.css';

class Toast {
  constructor() {
    this.PRIMARY = 'bg-primary';
    this.SECONDARY = 'bg-secondary';
    this.WARNING = 'bg-warning';
    this.SUCCESS = 'bg-primary';
    this.ERROR = 'bg-danger';
    this.INFO = 'bg-info';
    this.TYPE = 'ERROR';
    this.showClass = 'show';

    const newDiv = document.createElement('div');
    newDiv.setAttribute('id', 'snackbar2367840');
    document.body.appendChild(newDiv);
    this.ToastID = document.getElementById('snackbar2367840');
  }

  getClass(type) {
    let colorClass = this.PRIMARY;
    switch (type) {
      case 'ERROR':
        colorClass = this.ERROR;
        break;
      case 'WARN':
        colorClass = this.WARNING;
        break;
      case 'INFO':
        colorClass = this.INFO;
        break;
      case 'SUCCESS':
        colorClass = this.SUCCESS;
        break;
      default:
        colorClass = this.SUCCESS;
        break;
    }
    return colorClass;
  }

  initializeToastBackground(TYPE) {
    const backgroundClass = this.getClass(TYPE);
    this.ToastID.classList.add(backgroundClass);
  }

  initializeToastMessage(message) {
    this.ToastID.innerHTML = message;
  }

  removeToastBackground(TYPE) {
    const backgroundClass = this.getClass(TYPE);
    this.ToastID.classList.remove(backgroundClass);
  }

  removeToastMessage() {
    this.ToastID.innerHTML = '';
  }

  // Method
  show(options) {
    this.initializeToastBackground(options.type);
    this.initializeToastMessage(options.message);
    this.ToastID.classList.add(this.showClass);
    setTimeout(() => {
      this.ToastID.classList.remove(this.showClass);
      this.removeToastBackground(options.type);
      this.removeToastMessage();
    }, 3000);
  }
}

const T = new Toast();

export default T;
