export default [
  {
    name: 'Tuberculosis',
    content:
      'Projects targeted at reducing tuberculosis prevalence and incidence rates in the country. Projects that also aim at improving treatment rates, living conditions of those living with HIV-TB co-morbidity, etc.',
  },
  {
    name: 'Malaria',
    content:
      'Includes projects targeted at reducing malaria prevalence in the country. Also includes those aimed at increasing access to preventive measures and improving treatment rates.',
  },
  {
    name: 'Maternal and Child health',
    content:
      'This includes projects that aim to increase access to quality maternal and reproductive health services, e.g. projects related to family planning services, PMTCT services, nutrition during pregnancy, utilization of maternal and reproductive health services. Projects also aimed at reducing under 5 mortality, Under-five morbidity (diarrhoea, malaria, pneumonia, malnutrition, HIV/AIDS), violence against children, children living with disabilities, etc.',
  },
  {
    name: 'Sexual and Reproductive health',
    content:
      'This consists of activities involving collection of data on topics related to access and quality of reproductive health services, reproductive tract diseases, STIs, sexual behaviour, gender based violence, etc.',
  },
  {
    name: 'Water, Sanitation and Hygiene',
    content:
      'This includes projects revolving around improving water, sanitation and hygiene practices in the country.',
  },
  {
    name: 'HIV/AIDS-STDs/STIs',
    content:
      'This includes projects aimed at improving knowledge and attitude to HIV/AIDS or STIs/STDs, increasing access to HIV/AIDS or STIs/STDs screening, treatment and rehabilitation services, improving quality of HIV/AIDS or STIs/STDs services. It also includes projects aimed at improving access to and quality of PMTCT services.',
  },
  {
    name: 'Mental health',
    content:
      'This includes activities aimed at mental health interventions used to modify behavior, emotional state, or feelings. Also includes programs targeted at treatment of mental disorders.',
  },
  {
    name: 'NCDs',
    content:
      'This program area includes all interventions and/or activities targeted at the treatment and reduction in the prevalence of Non-Communicable Diseases',
  },
  {
    name: 'Tobacco and Drug Use',
    content:
      'This includes activities that addresses all forms of drug abuse, alone or in combination, including the underage use of legal drugs (e.g., tobacco or alcohol); the use of illegal drugs (e.g., marijuana or heroin); and the inappropriate use of legally obtained substances (e.g., inhalants), prescription medications, or over-the-counter drugs.',
  },
  {
    name: 'NHMIS/DHIS2',
    content:
      'This includes projects aimed at improving DHIS2 capacity among individuals. It also includes providing technical support to FMOH on improving DHIS2, as well as providing analytical support on data from NHMIS using DHIS2.',
  },
  {
    name: 'Adolescent health',
    content:
      'This includes projects, activities and interventions targeted at improving access to health care for adolescents.',
  },
  {
    name: 'Cancer',
    content:
      'This presents interventions and activities to reduce the impact of cancer through prevention and treatment mechanisms and solutions.',
  },
  {
    name: 'NTDs',
    content:
      'Projects and activities revolving around neglected tropical diseases, which are a diverse group of tropical infections caused by a variety of pathogens such as viruses, bacteria, protozoa and parasitic worms (helminths)',
  },
  {
    name: 'SDGs',
    content:
      'This includes all projects and interventions tailored towards achieving one or more of the Sustainable Development Goals (SDG)',
  },
  {
    name: 'Health promotion',
    content:
      'This category includes programs, interventions or strategies designed to produce behavior changes or improve health status among individuals or an entire population. Interventions may include educational programs, new or stronger policies, improvements in the environment, or a health campaign.',
  },
  {
    name: 'Immunization',
    content:
      'This includes projects that aim to improve immunization rates among women and children in the country.',
  },
  {
    name: 'Eye Health',
    content:
      'This includes projects, programs and interventions aimed at improving eye health. It presents interventions to promote rehabilitation and improve quality of life for people with vision impairment and eye disorders.',
  },
  {
    name: 'Oral Health',
    content:
      'This includes interventions and activities to improve access to dental services and improve oral health of children and adults also; the implemention and evaluation of activities that have an impact on oral health.',
  },
  {
    name: 'Logistics, Drugs and Commodities',
    content:
      'Interventions and activities that promote and improve supply chain management systems to ensure drug and commodity availability for any population ',
  },
  {
    name: 'Digital health',
    content:
      'Projects, program and activities that aim to improve health services, and outcomes through the use of digital technology',
  },
  {
    name: 'Disease surveillance',
    content:
      'Projects and interventions aimed at improving disease surveillance for enhanced response and reduction in case fatality rates during disease outbreaks',
  },
];
