<template>
  <div class="container">
    <p class="h4 my-3">Project-Program Areas</p>
    <div class="card border-right border-left-0 border-bottom-0 border-top-0">
      <div class="card-body">
        <div class="d-flex" v-for="(data, index) in programareas" :key="index">
          <div>
            <i class="fa fa-play mr-2" style="font-size: 0.7rem; color: rgb(146, 146, 146)"></i>
          </div>
          <div class="d-flex flex-column">
            <p class="my-0 text-primary">{{ data.name }}</p>
            <p class="text-dark">{{ data.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import programareas from './program_areas';

export default {
  data() {
    return {
      programareas,
    };
  },
};
</script>

<style>
.content-before::before {
  content: '';
  color: red;
}
</style>
