<template>
  <div class="project">
    <!-- projects pangination -->
    <div class="admin-nav">
      <h2>Recent Activities</h2>

      <b-list-group>
        <b-list-group-item v-for="item in getActivities.data.results" :key="item._id" class="card">
          <div>
            <strong> Date/ Time:</strong>
            {{ item.created_at | format_date }}
          </div>
          <br />
          <div>
            <strong> User type: </strong>
            {{ item.user_type }}
          </div>
          <br />
          <div>
            <strong>Activity: </strong>
            {{ item.message }}
          </div>
        </b-list-group-item>
        <br />
        <br />
      </b-list-group>
    </div>

    <br />
  </div>
</template>

<style lang="scss" scoped>
.project {
  padding: 30px 0px 0px 20px;
}
.admin-nav {
  background: #fff;
  top: 0;
  left: 0;
  z-index: 1030;
  position: sticky;
  width: 100%;
}

.card {
  margin: 15px;
}
</style>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import DatasetCard from '../components/Shared/DatasetCard.vue';
import Pagination from '../components/Shared/Pagination.vue';
// import FilterSideBar from '../components/Shared/FilterSideBar.vue';

export default {
  components: {
    DatasetCard,
    Pagination,
  },

  filters: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY hh:mm');
      }
    },
  },

  data() {
    return {
      isAll: false,
      isApproved: false,
      isPending: true,
      currentPage: 1,
      loading: false,
      datasets: [],
      isDataset: true,
    };
  },

  methods: {
    ...mapActions('admin', ['GET_ACTIVITIES']),
  },
  async mounted() {
    this.GET_ACTIVITIES();
  },

  computed: {
    ...mapGetters('admin', ['getActivities']),
  },
};
</script>
