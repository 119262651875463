<template>
  <div class="addFaq">
    <h1>ADD NEW FAQ</h1>
    <h5>Select the inventory category in respect to the FAQ</h5>
    <form>
      <div class="form-group">
        <select name="Category" class="form-control" id="category" style="width: 242px">
          <option value="category">Category</option>
          <option value="All">All</option>
          <option value="Project">Project Inventory</option>
          <option value="Data">Data Inventory</option>
          <option value="ngo">Non-Government Organisation</option>
          <option value="stakeholder">Stakeholder Inventory</option>
        </select>
      </div>
      <div class="form-group">
        <label>Provide Question here</label>
        <input type="text" class="form-control" />
      </div>
      <div class="form-group">
        <label>Provide Answer here</label>
        <input type="text" class="form-control" />
      </div>
      <div class="faq-link" style="padding-bottom: 20px">
        <button type="button" class="btn btn-primary" style="width: 200px; height: 50px">
          Publish
        </button>
        <router-link to="/admin/faq" style="padding-left: 50px">Back</router-link>
      </div>
    </form>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.addFaq {
  margin: 30px 0px 0px 20px;
}
h1 {
  color: #30ab4c;
}
label {
  text-align: left;
  font: normal normal bold 16px Roboto;
  letter-spacing: 0px;
  color: #090909;
  opacity: 1;
}
form {
  width: 900px;
}
.form-group {
  padding: 20px 0px;
}
</style>
