<template>
  <header>
    <nav class="container-fluid navbar navbar-expand-lg navbar-light bg-primary">
      <div class="row">
        <div class="col-9">
          <a href="/" width="100%" class="nav__logo h5">
            <img
              :src="require('@/assets/inventory-img/img/fmoh_logo.png')"
              alt=""
              style="width: 15%; height: inherit"
              srcset=""
            />
            <span class="text-white">INVENTORY DASHBOARD SYSTEM</span>
          </a>
        </div>
        <div class="col-3">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <a href="" class="px-2 text-white">Slide Mode</a>
              </li>
              <li class="nav-item">
                <router-link class="px-2 text-white" to="/inventory-dashboard/about"
                  >About</router-link
                >
                <!-- <a href="about.html" class="px-2 text-white">About</a> -->
              </li>
              <li class="nav-item active">
                <a href="faq.html" class="px-2 text-white">FAQs</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
