<template>
  <div >
    <div class="row-wrap d-flex align-items-center justify-content-between" v-for="info in infos" :key='info.id'>

        <router-link to='/admin/stakeholder-lists' class="p-3 text-dark"> <span>{{info.id}}. {{info.title}}</span></router-link>
        <div class="icons">
               <router-link to='/admin/edit-stakeholder'><i class="fa fa-edit"></i></router-link>
              <i class="fa fa-trash" aria-hidden="true"></i>
        </div>

    </div>
  </div>
</template>

<script>
export default {
    name:'stakeholferRows',
    props:{
        infos: {
         type: Object,
        },
    }
}
</script>

<style scoped>
    .row-wrap{
        border-bottom: 1px solid #eee;
    }
   .fa {
  padding: 0px 20px 0px 0px;
  cursor: pointer;
}
.fa-edit {
  color: #2da047;
}
.fa-trash {
  color: red;
}
</style>
