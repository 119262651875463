<template>
  <div class="mb-4">
    <div class="text-center" v-if="loader">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div class="container d-flex justify-content-between align-items-center mt-4">
        <h2 class="text-primary mt-4">{{ $route.params.name }} Reports</h2>
        <base-input
          prepend="fa fa-search"
          prependclass="mt-1"
          :filled="true"
          placeholder="Search"
          width="30%"
          v-model="search"
        />
      </div>
      <div
        class="alert text-primary text-center text-uppercase"
        v-if="projects.length === 0 && loader === false"
      >
        no report found
      </div>
      <div class="container my-5" v-for="(projects, index) in filteredList" :key="index">
        <div class="card shadow" @click="review(projects.id)">
          <div class="card-body">
            <p class="font-weight-bold my-0">{{ projects.name }}</p>
            <p class="mt-2 mb-4">
              {{ projects.details !== null ? projects.details.summary : 'Unknown' }}
            </p>
            <p class="my-0">
              <span class="font-weight-bold">Name of organisation: </span>
              {{ projects.organization !== null ? projects.organization.name : 'Unknown' }}
            </p>
          </div>
          <div class="card-footer bg-transparent d-flex justify-content-between">
            <p>Updated: {{ projects.updated_at | formatDate }}</p>
            <p>comments</p>
          </div>
        </div>
      </div>

      <div class="container my-4">
        <div class="d-flex justify-content-end align-items-center">
          <p class="mt-1 mx-2">Row per page</p>
          <select id="zone" style="width: 50px; height: 40px;" v-model="perPage">
            <option value="5"> 5 </option>
            <option value="10"> 10 </option>
          </select>
          <p class="mx-2 mt-2">{{ currentPage }} - {{ perPage }} of {{ total }}</p>
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrganizationProjects as GET_PROJECTS } from '../../config/api-modules/api.projects';

export default {
  data() {
    return {
      param: this.$route.params.id,
      projects: [],
      search: '',
      loader: false,
      total: 0,
      perPage: 2,
      currentPage: 1,
    };
  },
  computed: {
    filteredList() {
      return this.projects
        .filter((project) => {
          return project.name.toLowerCase().match(this.search.toLowerCase());
        })
        .slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
  },
  methods: {
    fetchProjects() {
      this.loader = true;
      GET_PROJECTS(this.param).then((rez) => {
        this.loader = false;
        this.projects = rez.data.results;
        this.total = this.projects.length;
      });
    },
    review(id) {
      this.$router.push({ path: `/ngo/project/${id}` });
    },
  },
  mounted() {
    this.fetchProjects();
  },
};
</script>

<style scoped>
* {
  font-family: 'Roboto';
}

.card:hover {
  cursor: pointer;
}
</style>
