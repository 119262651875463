import { render, staticRenderFns } from "./feedback-breadcrumbs.vue?vue&type=template&id=2bae8158&scoped=true&"
import script from "./feedback-breadcrumbs.vue?vue&type=script&lang=js&"
export * from "./feedback-breadcrumbs.vue?vue&type=script&lang=js&"
import style0 from "./feedback-breadcrumbs.vue?vue&type=style&index=0&id=2bae8158&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bae8158",
  null
  
)

export default component.exports