<template>
  <div class="m5">
    <ul class="nav pb-4">
      <li>
        <a href="/">Home ></a>
      </li>
      <li>
        <a href="/admin/stakeholder">Stakeholder Inventory ></a>
      </li>
      <li>
        <a href="/admin/research">Research And Knowledge ></a>
      </li>
      <li>
        <a href="/admin/edit-stakeholder" class="active">Edit Activity</a>
      </li>
    </ul>
    <h3 style="color: #30ab4c">EDIT ACTIVITY</h3>
    <p>
      Please complete the following questions by selecting the answer(s) of your choice or writing
      in the space provided.
    </p>

    <div class="my-5 wrap">
      <span class="option">Stakeholder Name</span>
      <span class="ps-2">World Health Organisation</span>
    </div>
    <form>
      <div class="my-5 wrap">
        <label class="option">Division</label>
        <p>Branch and Category change may change depending on the selected Division</p>
        <input class="value" placeholder="Research Knowledge and Management" />
      </div>
      <div class="my-5 wrap">
        <label class="option">Branch</label>
        <select class="form-select" aria-label="Default select example">
          <option selected>Open this select menu</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
      </div>
      <div class="my-5 wrap">
        <label class="option">Category</label>
        <select class="form-select" aria-label="Default select example">
          <option selected>Open this select menu</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
      </div>
      <div class="my-5 wrap">
        <label class="option2">Ativity</label>
        <div class="d-flex align-items-center">
          <div class="value2">
            <input class="value-input" placeholder="Research Knowledge and Management" />
            <i class="absolute fa fa-pencil" aria-hidden="true"></i>
          </div>
          <div class="icons d-flex ms-5">
            <Plus />
            <i class="fa fa-plus-square-o" aria-hidden="true"></i>
            <i class="fa fa-edit"></i>
            <i class="fa fa-trash" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div class="my-5 wrap">
        <label class="option2">Roles</label>
        <div class="d-flex align-items-center">
          <div class="value2">
            <input class="value-input" placeholder="Research Knowledge and Management" />
            <i class="absolute fa fa-pencil" aria-hidden="true"></i>
          </div>
          <div class="icons d-flex ms-5">
            <i class="fa fa-plus-square-o" aria-hidden="true"></i>
            <i class="fa fa-edit"></i>
            <i class="fa fa-trash" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div class="my-5 d-flex">
        <button class="update" type="submit">Update</button>
        <button class="back">Back</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'EditStakeholder',
};
</script>

<style scoped>
.wrap {
  max-width: 683px;
  display: flex;
  flex-direction: column;
}
.option {
  font-weight: 600;
  margin-bottom: 10px;
}
.value {
  width: 90%;
  padding: 15px 15px 15px 18px;
  border: 1px solid #eee;
}
.btn {
  background-color: white;
  border: none;
}
.form-select {
  width: 90%;
  padding: 15px 15px 15px 18px;
  border: 1px solid#eee;
}
.option2 {
  font-weight: 600;
  margin-bottom: 10px;
}
.value2 {
  position: relative;
  width: 70%;
  padding: 15px 15px 15px 18px;
  border: 1px solid #eee;
}
.value-input {
  border: none;
  margin: auto;
  padding: 5px;
  text-align: center;
  width: 95%;
  box-shadow: 0px 7px 5px #2da04747;
  border-radius: 8px;
}

.icons {
  margin-left: 35px;
}

.fa-edit {
  color: #2da047;
  font-size: 25px;
  margin-left: 15px;
  font-weight: 400;
}
.fa-plus-square-o {
  color: #2da047 !important;
  font-size: 25px !important;
  font-weight: 400 !important;
}
.absolute {
  position: absolute;
  top: 15%;
  right: 7%;
  color: #30ab4c;
  font-size: 15px;
  font-weight: 800;
}
.fa-trash {
  color: red;
  font-size: 25px;
  margin-left: 15px;
  font-weight: 400;
}
.update {
  padding: 10px;
  background: #30ab4c 0% 0% no-repeat padding-box;
  width: 20%;
  color: #f4f4f4;
  margin-right: 10px;
}
.back {
  padding: 10px;
  color: #30ab4c;
  width: 20%;
  background: #f4f4f4;
  margin-left: 10px;
}
</style>
