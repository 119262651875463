<template>
  <div class="edit-faq">
    <ul class="nav">
      <li>
        <a href="#">Home ></a>
      </li>
      <li>
        <a href="#">FAQ ></a>
      </li>
      <li>
        <a class="active" href="#">Add New Faq</a>
      </li>
    </ul>
    <h1>EDIT FAQ</h1>
    <form>
      <div class="form-group">
        <label>Question</label>
        <input type="text" class="form-control" placeholder="How do I upload projects?" />
      </div>
      <div class="form-group">
        <label>Answer:</label>
        <input
          type="text"
          class="form-control"
          placeholder="To upload projects, click the health projects tab on the menu bar, click add project, and fill the form provided"
        />
      </div>
      <div style="padding-bottom: 20px">
        <button type="button" class="btn btn-primary" style="width: 200px; height: 50px">
          Update
        </button>
        <router-link to="/admin/faq" style="padding-left: 50px">Back</router-link>
      </div>
    </form>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.edit-faq {
  margin: 30px 0px 0px 20px;
}
.active {
  color: black;
}
</style>
