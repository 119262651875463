<template>
  <div>
    <BaseLogoBar
      style="
        position: fixed;
        top: 0;
        z-index: 3500;
        background-color: #fff;
        width: 100%;
        height: 100px;
      "
    />
    <BaseNavbar
      style="position: fixed; top: 100px; height: 70px; z-index: 3000"
      :isToggle="isToggle"
      @handleToggle="handleToggle"
    />
    <!-- <Sidebar /> -->
    <div class="main">
      <div class="large">
        <div class="main-left">
          <div class="user" style="padding-top: 10px">
            <img src="@/assets/user-icon.svg" /><br />
            <h5>
              <!-- <b>{{ user.user_type }}</b> -->
              <b>NGO</b>
            </h5>
            <p style="color: #707070">{{ user.email }}</p>
          </div>

          <span class="title ms-5 mt-5 ps-5">REPORTS</span>
          <span class="link ms-5 ps-5"
            ><router-link to="/organizations">Annual Reports</router-link></span
          >
          <span class="link ms-5 ps-5"
            ><router-link to="/submit_yearly_report">Submit Yearly Reports</router-link></span
          >
          <span class="link button ms-5 mb-5 pb-5" @click="logoutButton()"
            ><div>SIGN OUT</div></span
          >
        </div>
      </div>
      <!--Menu for smaller screen-->
      <div :class="{ responsive: isToggle }" v-if="isToggle === true">
        <div class="main-left">
          <div class="user" style="padding-top: 10px">
            <!-- <pre>
              {{ user.user_type }}
            </pre> -->
            <img src="@/assets/user-icon.svg" /><br />
            <h5>
              <!-- <b>{{ user.user_type }}</b> -->
              <b>NGO</b>
            </h5>
            <p style="color: #707070">{{ user.email }}</p>
          </div>

          <span class="title ms-5 mt-5 ps-5">REPORTS</span>
          <span class="link ms-5 ps-5"
            ><router-link to="/organizations" @click="handleToggle"
              >Annual Reports</router-link
            ></span
          >
          <span class="link ms-5 ps-5"
            ><router-link to="/submit_yearly_report" @click="handleToggle"
              >Submit Yearly Reports</router-link
            ></span
          >
          <span class="link button ms-5 mb-5 pb-5" @click="logoutButton()"
            ><div>SIGN OUT</div></span
          >
        </div>
      </div>
    </div>
    <!--footer-->
    <div class="main-right" @click="handleToggle">
      <router-view class="main-right-view" />
      <BaseFooter />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      isToggle: false,
    };
  },

  methods: {
    ...mapActions('auth', ['LOGOUT']),

    async logoutButton() {
      await this.LOGOUT();
      this.$router.reload();
      this.$gates.setRoles([]);
      this.$forceUpdate();
      this.$router.push('/');
    },
    handleToggle() {
      this.isToggle = !this.isToggle;
    },
  },

  async mounted() {
    // this.GET_USER();
    // console.log(await this.user);
  },

  // making the organization dashboard only accessible to organisations
  beforeMount() {
    if (this.user.user_type !== 'ngo') {
      this.$router.push('/');
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },
};
</script>
<style lang="scss" scoped>
.index {
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;
}

.large {
  padding: 20px;
  background: transparent
    linear-gradient(22deg, #30ab4c52 0%, #2fa94b51 0%, #2ea74a50 6%, #1856261c 100%) 0% 0% no-repeat
    padding-box;
  height: 100vh;
  width: 25.4%;
  // flex:1;
  opacity: 1;
  padding: 50px 20px 20px 20px;
  position: fixed;
  top: 135px;
  left: 0;
  // overflow: auto;
  z-index: 1000;
}
.main-left {
  display: flex;
  // margin-top:200px;
  flex-direction: column;
  padding: 0px 0px 0px 10px;
  justify-content: flex-start;
}
.main-left span {
  font: normal normal bold 14px Roboto;
  margin: 20px 0px 0px 60px;
}
.main-left a {
  color: #0a0a0a;
  margin: 0px;
  padding: 0px;
}
.main-right-view {
  padding: 20px;
  width: 100%;
}
.main-right {
  // flex:3;
  width: 75%;
  margin-left: 25%;
  margin-top: 150px;
}
a:hover {
  border-bottom: 4px solid #2da047;
}
a.router-link-active {
  border-bottom: 4px solid #2da047;
}
.user {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-self: flex-start;
}

.logout {
  cursor: pointer;
}

.logout:hover {
  border-bottom: 4px solid #2da047;
  width: 100px;
}
.toggle {
  display: none;
}
.responsive {
  display: none;
}
.button {
  cursor: pointer;
}
// .base {
//   display: none;
// }
@media (max-width: 900px) {
  .toggle {
    display: inline-block;
  }
  .responsive {
    display: flex;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .responsive .main-left {
    position: fixed;
    flex-direction: column;
    width: 70%;
    height: auto;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #d0e0d4;
    z-index: 1090;
  }
  .main-right-view {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-right {
    // display: flex;
    width: 100%;
    margin-left: 0;
    margin-top: 150px;
  }
  .main-left {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 10px;
    justify-content: center;
    margin: 100px 0 0 auto;
    position: fixed;
    top: 0px;
    bottom: 0px;
    overflow-x: hidden;
    z-index: 1000;
  }

  .tag {
    margin: 5px;
  }
  .main {
    position: relative;
    display: flex;
    /* flex-direction: column; */
    transition: 2s all ease-in;
  }
  .large {
    display: none;
  }
}
</style>
