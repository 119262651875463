<template>
  <div class="addUser">
    <h1>EDIT PERMISSION</h1>
    <form>
      <div class="form-group">
        <label>Organization/Owner</label>
        <input type="text" class="form-control" v-model="owner" />
      </div>
      <div class="form-group">
        <label>Position</label>
        <input type="text" class="form-control" v-model="position" />
      </div>
      <div class="form-group">
        <label>Username</label>
        <input type="text" class="form-control" v-model="username" />
      </div>
      <div class="form-group">
        <label>Email</label>
        <input type="text" class="form-control" v-model="email" />
      </div>
      <div class="form-group">
        <label>Password</label>
        <input type="text" class="form-control" v-model="password" />
      </div>
      <div class="form-group">
        <select class="form-control" style="width: 242px">
          <option value="choose" selected>Choose Admin</option>
          <option value="root">Root Admin</option>
          <option value="international">International Cooperation Admin</option>
          <option value="management">Management and Evaluation Admin</option>
          <option value="ngo">Non-Governmental Organisation Admin</option>
          <option value="organisation">Organisation Admin</option>
        </select>
      </div>
      <label>Permissions to:</label>
      <p>Read from:</p>
      <p>Write to:</p>
      <div style="padding-bottom:20px">
        <button type="button" class="btn btn-primary" style="width:200px; height:50px">
          Update
        </button>
        <router-link to="/admin/user" style="padding-left:50px">Back</router-link>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selected: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.addUser {
  margin: 30px 0px 0px 20px;
}
h1 {
  color: #30ab4c;
}
label {
  text-align: left;
  font: normal normal bold 16px Roboto;
  letter-spacing: 0px;
  color: #090909;
  opacity: 1;
}
form {
  width: 900px;
}
.form-group {
  padding: 20px 0px;
}
.check {
  display: flex;
  justify-content: space-around;
  left: 0;
}
</style>
