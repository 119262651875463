<template>
  <div :style="`width: ${width}`">
    <ValidationProvider
      tag="div"
      :rules="rules"
      :name="name"
      :vid="vid"
      v-slot="{ errors, ariaInput, ariaMsg }"
    >
      <b-form-group
        :id="name"
        :label="label"
        :label-for="name"
        class="text-left font-weight-bold text-black mb-1 mt-4"
      >
        <p class="text-black-50 font-weight-lighter mb-1 mt-n1">{{ description }}</p>
        <b-form-textarea
          :id="name"
          v-model="currentValue"
          no-resize
          :rows="rows"
          v-bind="ariaInput"
        >
        </b-form-textarea>
      </b-form-group>
      <p class="text-danger text-left mt-n1 font-weight-light" v-bind="ariaMsg" v-if="errors[0]">
        <small> {{ errors[0] }} </small>
      </p>
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'BaseTextarea',
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '98%',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    vid: {
      type: String,
      default: undefined,
    },
    rows: {
      type: Number,
      default: 5,
    },
  },
  data: () => ({}),
  // watch: {
  //   /**
  //    * allows us to use v-model on our input
  //    * @param {*} val
  //    */
  //   currentValue(val) {
  //     this.$emit('input', val);
  //   },
  // },
  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style scoped>
textarea,
select.form-control {
  background: transparent;
  border: none;
  border: 1px solid #707070;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

textarea:focus,
select.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #707070;
}
</style>
