<template>
  <div id="ngo-admin-add">
    <section class="container mt-2 mb-5">
      <h4 class="text-primary text-uppercase font-weight-normal">Register new NGO</h4>
      <p>
        Please complete the following questions by selecting the answers(s) of your choice or
        writing in the space provided
      </p>
      <ValidationObserver v-slot="{ passes }">
        <form @submit.prevent="passes(submit(form))">
          <div class="form-row">
            <div class="col-md-4">
              <base-input
                height="3rem"
                placeholder="Name of Organization"
                width="100%"
                rule="required"
                v-model="form.name"
                label="Name of Organization"
              />
            </div>
            <div class="col-md-4">
              <base-input
                height="3rem"
                placeholder="Year of Establishment"
                width="100%"
                rule="required"
                v-model="form.establishment_year"
                label="Year of Establishment"
                maxlength="4"
                type="number"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-4">
              <base-select
                height="3rem"
                placeholder="Is it an umbrella organization"
                width="100%"
                rule="required"
                :items="polar"
                v-model="form.is_umbrella_organization"
                label="Is it an umbrella organization?"
              />
            </div>

            <!-- <div class="col-md-4 col-mrg pxt-8 upload-sec">
              <p>Upload information</p>
              <input
                type="file"
                class="ml-1 mt-0"
                accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                @change="fileSelected4($event.target.files)"
              />
            </div> -->
            <!-- <div class="col-md-4 col-mrg mt-8">
                  <p>
           If Yes, kindly furnish the records
          </p>
          <input
            type="file"
            class="ml-1 mt-0"
            accept="image/*"
            @change="fileSelected3($event.target.files)"
          />
            </div> -->
          </div>

          <br /><br />
          <div class="sub-head">CAC</div>

          <div class="form-row">
            <div class="col-md-4">
              <base-input
                height="3rem"
                placeholder="Year of registration"
                width="100%"
                rule="required"
                v-model="form.cac_registration_year"
                label="CAC Year of registration"
                type="number"
              />
            </div>
            <div class="col-md-4">
              <base-input
                height="3rem"
                placeholder="Registration no"
                width="100%"
                rule="required"
                v-model="form.cac_registration_number"
                label="CAC Registration no"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-4">
              <base-select
                height="3rem"
                placeholder="Type"
                width="100%"
                rule="required"
                :items="type"
                v-model="form.cac_type"
                label="CAC Type"
              />
            </div>
            <div class="col-md-4 col-mrg upload-sec">
              <p>CAC registration certificate</p>

              <input
                type="file"
                accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
                @change="fileSelected1($event.target.files)"
              />
            </div>
          </div>

          <div class="col-md-8">
            <base-input
              height="3rem"
              placeholder="Postal Address"
              width="100%"
              rule="required"
              v-model="form.cac_postal_address"
              label="Postal Address"
            />
          </div>

          <br />
          <div class="sub-head">Contact Person</div>

          <div class="form-row">
            <div class="col-md-4">
              <base-input
                height="3rem"
                placeholder="Name of contact person"
                width="100%"
                rule="required"
                v-model="form.contact_name"
                label="Name of contact person"
              />
            </div>
            <div class="col-md-4">
              <base-input
                height="3rem"
                placeholder="Full address"
                width="100%"
                rule="required"
                v-model="form.contact_address"
                label="Full address"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-4">
              <base-input
                height="3rem"
                placeholder="Position"
                width="100%"
                rule="required"
                v-model="form.contact_position"
                label="Position"
              />
            </div>
            <div class="col-md-4">
              <base-input
                height="3rem"
                placeholder="Email"
                width="100%"
                rule="required"
                v-model="form.contact_email"
                label="Email"
                type="email"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-4">
              <base-input
                height="3rem"
                placeholder="Telephone"
                width="100%"
                rule="required"
                v-model="form.contact_phone"
                label="Telephone"
              />
            </div>

            <div class="col-md-4 col-mrg pxt-8 upload-sec">
              <p>CV of regular contact person</p>
              <input
                type="file"
                class="ml-1 mt-0"
                accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
                @change="fileSelected2($event.target.files)"
              />
            </div>
          </div>

          <br />
          <br />
          <div class="sub-head">Organization</div>

          <base-textarea
            height="3rem"
            placeholder="Focus of Organization programmes in health"
            width="100%"
            rules="required"
            v-model="form.organization_focus"
            label="Focus of Organization programmes in health"
            name="Focus of Organization programmes in health"
          />

          <div class="form-row">
            <div class="col-md-4">
              <base-input
                height="3rem"
                placeholder="No of Staff"
                width="100%"
                rule="required"
                v-model="form.number_of_staffs"
                label="No of Staff"
                type="number"
              />
            </div>
            <div class="col-md-4">
              <base-input
                height="3rem"
                placeholder="Physical Facilites"
                width="100%"
                rule="required"
                v-model="form.physical_facilities"
                label="Physical Facilites with no (e.g Offices x1, ..)"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-4">
              <p style="color: black; font-weight: bold;">Letter of Introduction</p>
              <input
                type="file"
                class="ml-1 mt-0"
                accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
                @change="fileSelected5($event.target.files)"
              />
            </div>
          </div>

          <base-input
            height="3rem"
            placeholder="Banker1"
            width="100%"
            rules="required"
            v-model="banker1"
            label="Bankers and their full addresses"
            name="Bankers and their full addresses"
          />

          <base-input
            height="3rem"
            placeholder="Banker 2 (if available)"
            width="100%"
            rules="required"
            v-model="banker2"
            name="Bankers and their full addresses"
          />

          <base-input
            height="3rem"
            placeholder="Banker 3 (if available)"
            width="100%"
            rules="required"
            v-model="banker3"
            name="Bankers and their full addresses"
          />

          <base-textarea
            height="3rem"
            placeholder="Other assets"
            width="100%"
            rules="required"
            v-model="form.other_assets"
            label="Other assets"
            name="Other assets"
          />

          <base-input
            height="3rem"
            placeholder="Source 1"
            width="100%"
            rules="required"
            v-model="form.source1"
            label="Sources of funding"
            name="Sources of funding"
          />

          <base-input
            height="3rem"
            placeholder="Source 2 (if applicable)"
            width="100%"
            rules="required"
            v-model="source2"
            name="Source 2"
          />

          <base-input
            height="3rem"
            placeholder="Source 3 (if applicable)"
            width="100%"
            rules="required"
            v-model="source3"
            name="Source 3"
          />

          <base-textarea
            height="3rem"
            placeholder="Scope of Operation"
            width="100%"
            rules="required"
            v-model="form.scope"
            label="Scope of Operation"
            name="Scope of Operation"
          />

          <base-textarea
            height="3rem"
            placeholder="Local Affiliation"
            width="100%"
            rules="required"
            v-model="form.local_affiliation"
            label="Local Affiliation"
            name="Local Affiliation"
          />

          <base-textarea
            height="3rem"
            placeholder="international Affiliation"
            width="100%"
            rules="required"
            v-model="form.international_affiliation"
            label="International Affiliation"
            name="International Affiliation"
          />

          <base-textarea
            height="3rem"
            placeholder="Past projects in the last 5 years"
            width="100%"
            rules="required"
            v-model="form.past_projects"
            label="Past projects in the last 5 years "
            name="Past projects in the last 5 years"
          />

          <base-select
            height="3rem"
            placeholder="Is your organization working with others"
            width="100%"
            rules="required"
            v-model="form.is_organization_working_others"
            :items="polar"
            label="Is your organization working with others"
            name="Is your organization working with others"
          />

          <base-textarea
            height="3rem"
            placeholder="List of organizations"
            width="100%"
            rules="required"
            v-model="form.list_of_organizations"
            label="List of Organizations"
            name="List of Organizations"
          />

          <div class="form-row">
            <div class="col-md-6">
              <base-textarea
                height="3rem"
                placeholder="List of past publications/ reports of your organization"
                width="100%"
                rule="required"
                v-model="form.list_past_publications"
                label="List of past publications/ reports of your organization"
              />
            </div>

            <div class="col-md-4 col-mrg pxt-8 upload-sec">
              <p>Upload Sample</p>
              <input
                type="file"
                class="ml-1 mt-0"
                accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
                @change="fileSelected3($event.target.files)"
              />
            </div>
          </div>

          <base-textarea
            height="3rem"
            placeholder="Regular resource persons"
            width="100%"
            rules="required"
            v-model="form.resource_persons"
            label="Regular resource persons"
            name="Regular resource persons"
          />

          <br /><br /><br />

          <button class="text-white bg-primary p-2 custom-button mt-4" :disabled="loader">
            <div class="spinner-border" role="status" v-if="loader">
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else>Submit</span>
          </button>
        </form>
      </ValidationObserver>
    </section>
  </div>
</template>

<script>
// import _baseSelect from '../../../../components/FormInputs/_base-select.vue';
// import _baseTextarea from '../../../../components/FormInputs/_base-textarea.vue';
import {
  getProgramArea as GETPROGRAMS,
  addOrganization as ADD_ORGANIZATION,
  // patchOrganization as UPDATAE_NGO,
} from '../../config/api-modules/api.organization';
import zones from '../../config/states_and_zones';

export default {
  // components: { _baseSelect, _baseTextarea },
  name: 'ngo-admin-add',
  data() {
    return {
      form: {
        name: '',
        type: 'Non governmental Organization',
        program_area: null,
        email: '',
        address: '',
        establishment_year: '',
        state: '',
        geo_zone: '',
        name_of_organization: '',
        is_umbrella_organization: '',
        cac_registration_year: '',
        cac_registration_number: '',
        cac_type: '',
        cac_postal_address: '',
        contact_address: '',
        contact_position: '',
        contact_email: '',
        contact_name: '',
        contact_phone: '',
        // focus_organisation_programmes: '',
        organization_focus: '',
        number_of_staffs: '',
        source_of_funding: null,
        scope: '',
        bankers: null,
        physical_facilities: '',
        other_assets: '',
        local_affiliation: '',
        international_affiliation: '',
        past_projects: '',
        list_past_publications: '',
        list_of_organizations: '',
        is_organization_working_others: '',
        contact_person_first_name: '',
        contact_person_last_name: '',
        contact_person_phone: '',
        contact_person_email: '',
        contact_person_position: '',
        umbrella_org: '',
        cac_registration_certificate: null,
        cv_of_regular_contact_person: null,
        activities_documentation: null,
        resource_persons: '',
        // umbrella_org_file: null,
        regular_resource_persons: '',
        status: false,
      },
      polar: [true, false],
      type: ['For Profit', 'Non Profit'],
      zones: [
        'North Central',
        'North East',
        'North West',
        'South East',
        'South South',
        'South West',
      ],
      states: [
        'Niger',
        'Kogi',
        'Benue',
        'Plateau',
        'Nasarawa',
        'Kwara',
        'FCT',
        'Bauchi',
        'Borno',
        'Taraba',
        'Adamawa',
        'Gombe',
        'Yobe',
        'Zamfara',
        'Sokoto',
        'Kaduna',
        'Kebbi',
        'Katsina',
        'Kano',
        'Jigawa',
        'Enugu',
        'Imo',
        'Ebonyi',
        'Abia',
        'Anambra',
        'Bayelsa',
        'Akwa Ibom',
        'Edo',
        'Rivers',
        'Cross River',
        'Delta',
        'Oyo',
        'Ekiti',
        'Osun',
        'Ondo',
        'Lagos',
        'Ogun',
      ],
      source1: '',
      source2: '',
      source3: '',
      banker1: '',
      banker2: '',
      banker3: '',
      cac_registration_certificate: null,
      cv_of_regular_contact_person: null,
      activities_documentation: null,
      letter_of_introduction: null,
      umbrella_org_file: null,
      loader: false,
      programs: [],
      zone: zones,
      program_area_loader: false,
      total: null,
    };
  },
  methods: {
    fileSelected1(file) {
      // eslint-disable-next-line prefer-destructuring
      this.cac_registration_certificate = file[0];
    },

    fileSelected2(file) {
      // eslint-disable-next-line prefer-destructuring
      this.cv_of_regular_contact_person = file[0];
    },

    fileSelected3(file) {
      // eslint-disable-next-line prefer-destructuring
      this.activities_documentation = file[0];
    },

    fileSelected4(file) {
      // eslint-disable-next-line prefer-destructuring
      this.umbrella_org_file = file[0];
    },
    fileSelected5(file) {
      // eslint-disable-next-line prefer-destructuring
      this.letter_of_introduction = file[0];
    },

    generateYear() {
      let startYear = 1940;
      const currentYear = new Date().getFullYear();
      const years = [];
      startYear = startYear || 1980;
      while (startYear <= currentYear) {
        // eslint-disable-next-line no-plusplus
        years.push(startYear++);
      }
      return years;
    },
    async submit(payload) {
      // appending all the formData
      const formNew = new FormData();
      // run a loop to append every field in the form object the the formNew formData
      for (const key in this.form) {
        formNew.append(key, this.form[key]);
      }
      // manually append the files to the formNew formData
      formNew.append('cac', this.cac_registration_certificate);
      formNew.append('cv', this.cv_of_regular_contact_person);
      formNew.append('activities', this.activities_documentation);
      formNew.append('letter_of_introduction', this.letter_of_introduction);

      // console.log('file', this.cac_registration_certificate);
      // resolving the form data:
      this.form.cac_registration_certificate = new FormData();
      this.form.cac_registration_certificate.append('cac', this.cac_registration_certificate);
      this.form.umbrella_org = 'None';
      this.form.cv_of_regular_contact_person = new FormData();
      this.form.cv_of_regular_contact_person.append('cv', this.cv_of_regular_contact_person);
      this.form.activities_documentation = new FormData();
      await this.form.activities_documentation.append('activities', this.activities_documentation);

      // this.form.umbrella_org_file = new FormData();
      // await this.form.umbrella_org_file.append('umbrella_org', this.umbrella_org_file);

      // source of funding
      this.form.source_of_funding = '';
      this.form.source_of_funding += this.source1;
      if (this.source2) {
        this.form.source_of_funding = this.source1.concat(this.source2);
      }

      if (this.source3) {
        this.form.source_of_funding = this.source1.concat(this.source2, this.source3);
      }

      // bankers
      this.form.bankers = '';
      this.form.bankers += this.banker1;
      if (this.banker2) {
        this.form.bankers = this.banker1.concat(this.banker2);
      }

      if (this.banker3) {
        this.form.bankers = this.banker1.concat(this.banker2, this.banker3);
      }

      this.form.email = this.form.contact_person_email;
      this.form.name_of_organization = this.form.name;

      // debugger;
      this.loader = true;
      ADD_ORGANIZATION(formNew)
        .then((e) => {
          console.log(e);
          this.$toast.show({
            type: 'SUCCESS',
            message: `${payload.name} has been added`,
          });
          // this.$router.push('/ngo/admin');
          this.$router.push('/ngo/landing');
          /**
           *
           */
          // const newJec = Object.assign(e.data.details, { roles: [3] });
          // console.log(newJec);
          // debugger;
          // UPDATAE_NGO(e.data.id, { roles: [3] })
          //   .then((et) => {
          //     console.log(et);
          //     this.$toast.show({
          //       type: 'SUCCESS',
          //       message: `${payload.name} has been added`,
          //     });
          //     this.$router.push('/ngo/admin');
          //   })
          //   .catch((el) => {
          //     console.log(el);
          //   });
          // this.$toast.show({
          //   type: 'SUCCESS',
          //   message: `${payload.name} has been added`,
          // });
        })
        .catch(() => {
          this.$toast.show({
            type: 'ERROR',
            message: `Unable to add ${payload.name}`,
          });
        })
        .finally(() => {
          this.loader = false;
        });
    },

    getPrograms(count) {
      this.program_area_loader = true;
      GETPROGRAMS(count)
        .then((data) => {
          this.total = data.data.count;
          this.programs = data.data.results;
        })
        .catch(() => {
          this.programs = [];
        })
        .finally(() => {
          this.program_area_loader = false;
        });
    },
  },
  mounted() {
    this.getPrograms();
  },
};
</script>

<style lang="scss" scoped>
.sub-head {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.custom-button {
  width: 150px;
}
label {
  font-size: 0.9375rem;
}

* {
  font-family: 'Open Sans';
}
label {
  font-size: 0.813rem !important;
}
.form-check-input {
  font-family: 'Open Sans' !important;
}

.col-mrg {
  margin-left: 40px;
}

.upload-sec {
  margin-top: 35px;
}
</style>
