<template>
  <div id="ngo-dashboard">
    <div class="row w-100">
      <div class="col-md-2 bg-light">
        <div class="mt-2 p-3">
          <div class="text-center">
            <i class="fas fa-user-circle"></i>
            <p class="my-0 font-weight-bold">{{ user.name }}</p>
            <p class="font-weight-normal">{{ user.email }}</p>
          </div>

          <div class="text-wrap my-2">
            <nav class="nav flex-column">
              <a class="nav-link font-weight-bold">REPORTS</a>
              <router-link to="/ngo/portal">
                <a class="nav-link">Annual Reports</a>
              </router-link>
              <router-link to="portal/submit-report">
                <a class="nav-link ">Submit Annual Report</a>
              </router-link>
            </nav>
          </div>
        </div>
      </div>
      <div class="col-md-10">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ngo-dashboard',
  computed: {
    ...mapGetters('auth', ['user']),
  },
};
</script>
<style lang="scss" scoped>
* {
  font-family: 'Roboto';
}

.nav {
  .nav-link {
    cursor: pointer;
    color: #707070;
    letter-spacing: 0px;
    font-size: 12.5px;
  }
}
.nav .nav-link:active {
  color: #30ab4c;
}

p {
  color: #707070;
  font-size: 1rem;
}
a {
  font-size: 1.1rem;
}
.fas {
  font-size: 100px;
}
</style>
