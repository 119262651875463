<template>
  <div>
    <b-link class="text-decoration-none">
      <b-card
      @click="getProjectById()"
        shadow
        footer-bg-variant="transparent"
        style="max-width: auto;"
        footer-class="text-left pt-3 pb-3 text-black-50"
        class="shadow mb-5 bg-white border-0 text-decoration-none"
      >
        <main>
          <slot name="body"></slot>
        </main>

        <template #footer>
          <b-row align-h="between">
            <!-- <b-col cols="6" sm="4">
              <span class="d-none d-sm-block">
                Uploaded {{ $dataset.formatDate(date, 'DD/MM/YYYY') }}</span
              >
              <span class="text-truncate d-sm-none">
                Uploaded {{ $dataset.formatDate(date, 'DD/MM/YYYY') }}</span
              >
            </b-col> -->
            <b-col cols="6" sm="4" class="text-right" v-if="seemorelink">
              <!-- <b-link class="ml-1 text-primary text-decoration-no     ne" :to="to"
                >See details...</b-link
              > -->

              <div>
                <span v-if="status === 'Approved'">Approved</span>
                <span v-if="status === 'Pending'">Pending</span>
              </div>

              <button
                @click="getProjectById()"
                class="text-white bg-primary p-2 custom-button mt-4"
                :disabled="loader"
              >
                <div class="spinner-border" role="status" v-if="loader">
                  <span class="sr-only">Loading...</span>
                </div>
                <span v-else>View Project</span>
              </button>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-link>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      loader: false,
    };
  },

  props: {
    to: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      default: '',
    },
    seemorelink: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
  },

  methods: {
    ...mapActions('DATASET_STORE', ['SETPROJECT']),

    async getProjectById() {
      this.loader = true;

      const data = await this.$dataset.getAllProjects();

      const projectData = data.data.results;

      const singleProject = projectData.filter((project) => project.id === this.id)[0];
      console.log('singleProject', singleProject);

      this.SETPROJECT(singleProject);

      this.$router.push('/admin/project/sub-project');

      console.log('getting', await this.getProject);
    },
  },
};
</script>

<style scoped>
.card-footer {
  display: none;
}
</style>
