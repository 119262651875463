import AuthServices from './UserService';
import router from '../../router';

export default {
  namespaced: true,
  state: {
    error: {
      message: '',
      show: false,
    },
    user: null,
    type: null,
    // token: localStorage.getItem('access_token') || '',
  },
  mutations: {
    show(state, payload) {
      state.error.message = payload.message;
      state.error.show = true;
    },
    noShow(state) {
      state.error.message = '';
      state.error.show = false;
    },
    setUser(state, payload) {
      state.user = payload;
      state.type = payload.user_type;
    },
    setRole(state, payload) {
      state.role = payload;
    },
  },
  actions: {
    async LOGIN({ commit }, payload) {
      const response = await AuthServices.Login(payload);
      const { status, data } = response;
      if (status === 200) {
        if (data) {
          commit('setUser', data);
          // if(data.user_type === 'org'){
          //   router.push('/admin/projects')
          // }
        }
      }
      return Promise.resolve(response);
    },
    async REGISTRATION({ commit }, payload) {
      const response = await AuthServices.Registration(payload);
      const { status, data } = response;
      if (status === 200) {
        if (data.entity) {
          commit('setUser', data);

          console.log('user data', data);
        }
      }
      return Promise.resolve(response);
    },
    async LOGOUT({ commit, state }) {
      await AuthServices.Logout();
      /* 
      this prevents the user state 
      object from throwing an 
      "entity is null" error 
      after logging out
      */
      //  redirecting to the home route
      router.push('/');
      window.location.reload();
      state.user = null;
      const data = null;
      commit('setUser', data);
      commit('setRole', data);
    },
    // async GET_USER({ commit }) {
    //   const data = await AuthServices.getUserInfo();
    //   console.log(data);
    //   console.log('user data', data)
    //   commit('setUser', data);
    //   return Promise.resolve(data);
    // },
    async SET_ROLE({ commit }, data) {
      commit('setRole', data);
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.user,
    isAdmin: (state) => state.type === 'admin',
    user: (state) => (state.user ? state.user : null),
    role: (state) => state.role,
  },
};
