<template>
  <div>
    <!-- <Header /> -->
    <div class="hero bg-light">
      <div class="container-fluid">
        <div class="row">
          <div class="col vh-100">
            <iframe
              style="position: absolute; height: 86%; width: 100%; border: none"
              src="https://app.powerbi.com/view?r=eyJrIjoiYTUwMDczNzgtODgzNy00OTVkLTgxMmMtNDMzMzkwZjE2ODQ0IiwidCI6ImQyMWIyNDkyLTFkZGMtNGZlOC05ZDcxLTM1MjcyMzU1NWYyZCIsImMiOjl9&pageName=ReportSection"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
// import Header from '../components/Header.vue';
// import Footer from '../components/Footer.vue';

export default {};
</script>

<style lang="scss" scoped>
.vh-50 {
  height: 50vh !important;
}
</style>
