<template>
  <div>
    <h1>
      {{ getProject.name }}
    </h1>
    <!-- projects pangination -->
    <form @submit.prevent="passes(submitProject)">
      <!-- <form @submit.prevent="submitProject()"> -->
      <base-input
        v-model="name"
        name="Support Name"
        rules="required"
        label="Name of support or intervention project"
      />
      <base-input name="Organization Name" rules="required" label="Name of organization" />

      <p class="text-left font-weight-bold text-dark mb-1 mt-4">Collaborating partners</p>
      <div style="width: 98%">
        <multiselect
          v-model="collaboratingPartner"
          :options="organizations"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Choose Collaborating Partners"
          label="name"
          track-by="id"
          :preselect-first="true"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length &amp;&amp; !isOpen" class="multiselect__single"
              >{{ collaboratingPartner.length }} collaborating partner selected</span
            >
          </template>
        </multiselect>
        <div class="input-error1" v-if="collaboratingPartnerEmpty">
          The Collaborating partners field is required
        </div>
      </div>

      <p class="text-left font-weight-bold text-dark mb-1 mt-4">Funder(s)</p>
      <p class="text-left text-black-50 font-weight-medium mb-1 mt-n0">
        Please provide information on any organizations or agencies that funded this intervention
      </p>
      <div style="width: 98%">
        <multiselect
          v-model="funders"
          :options="organizations"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Choose Funders for this Project"
          label="name"
          track-by="id"
          :preselect-first="true"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length &amp;&amp; !isOpen" class="multiselect__single"
              >{{ funders.length }} funder selected</span
            >
          </template>
        </multiselect>
        <div class="input-error1" v-if="fundersEmpty">The Funders field is required</div>
      </div>

      <p class="text-left font-weight-bold text-dark mb-1 mt-4">Details of the project cost</p>
      <p class="text-left text-black-50 font-weight-medium mb-n2 mt-n0">
        Enter project cost with currency of project
      </p>
      <b-col cols="12" md="4" class="ml-n3">
        <base-select
          v-model="currency"
          :items="['Euros', 'Naira', 'Pounds', 'US Dollars', 'Yen', 'Yuan']"
          label="Select Currency"
          name="Currency"
          rules="required"
        />
      </b-col>
      <base-input
        v-model="project_cost"
        type="number"
        name="Project cost"
        rules="required"
        label="Project cost"
        description="Figures Only"
      />
      <base-input
        v-model="project_type_support"
        name="Type of support"
        rules="required"
        label="Type of support or intervention"
        description="Please provide information on
            type of support or intervention eg Training ,Analytical
            support, Logistics, Data collection, HMIS/DHIS, Review of data collection tools etc"
      />
      <div class="d-flex">
        <div style="width: 100%">
          <base-input
            v-model="pdObjectives"
            name="Project development objectives"
            rules="required"
            width="100%"
            label="Project development objectives"
          />
        </div>
        <!-- <div style="width: 2%" class="mt-5">
              <b-icon icon="plus-circle" class="mt-3 ml-1" @click="addpdObjective"></b-icon>
            </div> -->
      </div>
      <p class="text-left font-weight-bold text-dark mb-1 mt-4">Program areas</p>
      <div class="input-error1" v-if="programSelectedEmpty">
        The Program areas field is required
      </div>
      <p class="text-left text-black-50 font-weight-medium mb-5 mt-n0">Tick all that apply</p>
      <b-row class="pl-2">
        <b-col
          v-for="(program, index) in getProgramsArea.results"
          :key="index"
          cols="12"
          lg="3"
          class="mt-n4"
        >
          <base-checkbox
            v-model="programSelected"
            :checkbox-item="program.name"
            class="mt-n5"
            :item="program"
          />
          <!-- @change="addEditProgramsArea(program)" -->
        </b-col>
      </b-row>
      <base-input
        name="Other program areas"
        rules="required"
        label="Other program areas - Please specify"
      />
      <p class="text-left font-weight-bold text-dark mb-1 mt-4">Health system area</p>
      <div class="input-error1" v-if="healthSystemSeletedEmpty">
        The Health System field is required
      </div>
      <p class="text-left text-black-50 font-weight-medium mb-1 mt-n0 mb-5">Tick all that apply</p>
      <b-row class="pl-2">
        <b-col
          v-for="(health, index) in getHealthSystemArea.results"
          :key="index"
          cols="12"
          lg="3"
          class="mt-n4"
        >
          <base-checkbox
            v-model="healthSystemSeleted"
            :checkbox-item="health.name"
            class="mt-n5"
            :item="health"
          />
          <!-- @change="addEditHealthArea(health)" -->
        </b-col>
      </b-row>
      <base-input
        name="Other health system areas"
        rules="required"
        label="Other health system areas - Please specify"
      />
      <b-row class="mb-n2">
        <b-col cols="12" md="4">
          <base-select
            v-model="projectOwnerType"
            label="Project type"
            :items="ownertype"
            name="Project type"
            track="name"
            rules="required"
          />
        </b-col>
        <b-col cols="12" md="4" offset-md="2">
          <base-select
            v-model="projectSensitivitySelected"
            label="Sensitivity of project"
            name="Sensitivity of project"
            :items="sensitivity"
            track="name"
          />
        </b-col>
      </b-row>
      <p class="text-left font-weight-bold text-black-50 mb-0 mt-4">Period(s) of implementation</p>
      <b-row class="mb-n2">
        <b-col cols="12" md="3">
          <base-select
            v-model="startMonth"
            :items="months"
            track="name"
            label="Start Month"
            width="92%"
            name="Start Month"
            rules="required"
          />
        </b-col>
        <b-col cols="12" md="3">
          <base-select
            v-model="startYear"
            :items="generateArrayOfYears()"
            label="Start Year"
            width="92%"
            name="Start Year"
            rules="required"
          />
        </b-col>
        <b-col cols="12" md="3">
          <base-select
            v-model="endMonth"
            :items="months"
            track="name"
            label="End month"
            width="92%"
            name="End month"
            rules="required"
          />
        </b-col>
        <b-col cols="12" md="3">
          <base-select
            v-model="endYear"
            :items="generateArrayOfYears()"
            label="End Year"
            width="92%"
            name="End Year"
            rules="required"
          />
        </b-col>
      </b-row>
      <b-row class="mb-n2 mt-4">
        <b-col cols="12" md="5">
          <base-select
            v-model="status"
            label="Status of implementation"
            :items="['Completed', 'Ongoing', 'Abandonded']"
            name="Status of implementation"
            description="What is the current status of the implementation?"
            rules="required"
          />
        </b-col>
      </b-row>
      <b-row v-if="ongoing" class="mb-n2 mt-2">
        <b-col cols="12" md="6">
          <base-select
            v-model="percentage_completion"
            label="Percentage Completion of the Ongoing Project (%)"
            :items="[10, 20, 30, 40, 50, 60, 70, 80, 90]"
            name="Status of implementation"
            description="
Select the approximate Percentage Completion of the ongoing project"
            rules="required"
          />
        </b-col>
      </b-row>
      <b-row v-if="abandoned" class="mb-n2 mt-2">
        <b-col cols="12" md="6">
          <base-select
            v-model="percentage_completion"
            label="Percentage Completion of the Ongoing Project (%)"
            :items="[10, 20, 30, 40, 50, 60, 70, 80, 90]"
            name="Status of implementation"
            description="
Select the approximate Percentage Completion of the ongoing project"
            rules="required"
          />
        </b-col>
        <b-col cols="12" md="6">
          <base-textarea
            v-model="abandon_reason"
            label="Reason for Abandoning Project"
            name="Reasons"
            description="Please state the reasons for abandoning the project"
            rules="required"
          />
        </b-col>
      </b-row>
      <base-textarea
        v-model="target_group"
        label="Population of Interest/ target group"
        name="Population of Interest/ target group"
        description="Please provide information on target population of intervention"
        rules="required"
      />
      <p class="text-left font-weight-bold text-black pb-3 mt-4">Geographical coverage</p>
      <div class="input-error1" v-if="geoCoverageBaseLevelSelectedEmpty">
        The Geo coverage field is required
      </div>
      <base-checkbox
        v-for="(level, index) in geoCoverageBaseLevels"
        :key="index"
        v-model="geoCoverageBaseLevelSelected"
        :item="level"
        class="pl-2 mt-n5"
        :checkbox-item="level.name"
      />
      <div v-if="showGeoCoverageForFederal">
        <p class="text-left font-weight-bold text-black-50 pb-3 mt-4">Federal</p>
        <base-checkbox
          v-for="(federalLevel, index) in getGeoCoverageFederalLevel"
          :key="index"
          v-model="geoCoverageSelected"
          :item="federalLevel"
          class="pl-2 mt-n5"
          :checkbox-item="federalLevel.name"
        />
      </div>

      <div v-if="showGeoCoverageForState">
        <p class="text-left font-weight-bold text-black-50 pb-4 mt-4">State Level</p>
        <b-row class="pl-2">
          <b-col
            v-for="(statelLevel, index) in getGeoCoverageStateLevel"
            :key="index"
            cols="12"
            lg="3"
            class="mt-n4"
          >
            <base-checkbox
              :key="index"
              v-model="geoCoverageSelected"
              :item="statelLevel"
              class="pl-0 mt-n5"
              :checkbox-item="statelLevel.name"
            />
          </b-col>
        </b-row>
      </div>

      <div v-if="showGeoCoverageForLga">
        <p class="text-left font-weight-bold text-black-50 pb-0 mt-4">LGA's Level</p>
        <p class="text-left text-black-50 font-weight-medium mb-4 pb-1 mt-n3">
          Select a State to get It's LGA's
        </p>
        <div class="states">
          <base-checkbox
            v-for="(statelLevel, index) in getGeoCoverageStateLevel"
            :key="index"
            v-model="geoCoverageLgaSelected"
            :item="statelLevel"
            class="pl-2 mt-n5"
            :checkbox-item="statelLevel.name"
          />
        </div>
      </div>

      <div v-if="lgasToShow.length > 0">
        <div v-for="(lgaData, index) in lgasToShow" :key="index">
          <p class="text-left font-weight-bold text-black-50 pb-3 mt-4">
            {{ lgaData.state.name }} State LGA's
          </p>
          <b-row>
            <b-col
              v-for="(lgaData, index) in lgaData.lga"
              :key="index"
              cols="12"
              lg="3"
              class="mt-n4"
            >
              <base-checkbox
                v-model="geoCoverageSelected"
                :item="lgaData"
                class="pl-2 mt-n5"
                :checkbox-item="lgaData.name"
              />
            </b-col>
          </b-row>
        </div>
      </div>

      <p class="text-left font-weight-bold text-black-50 mb-0 mt-4">Name of contact person</p>
      <b-row class="mb-n2">
        <b-col cols="6">
          <base-input
            v-model="contact_person_first_name"
            width="98%"
            name="First Name"
            rules="required"
            label="First Name"
          />
        </b-col>
        <b-col cols="6">
          <base-input
            v-model="contact_person_last_name"
            name="Last Name"
            width="96%"
            rules="required"
            label="Last Name"
          />
        </b-col>
      </b-row>
      <base-input
        v-model="contact_person_phone"
        name="Contact Person Phone number"
        rules="required"
        label="Phone number of contact person"
      />
      <base-input
        v-model="contact_person_email"
        name="Contact Person email"
        rules="required|email"
        label="Email of contact person"
      />
      <p class="text-left font-weight-bold text-black mb-0 mt-4 pb-1 border-bottom">
        The submission was authorized by
      </p>
      <b-row align-h="between" class="mb-n2">
        <b-col>
          <base-input
            v-model="submission_authority_first_name"
            name="First Name"
            width="98%"
            rules="required"
            label="First Name"
          />
        </b-col>
        <b-col>
          <base-input
            v-model="submission_authority_last_name"
            name="Last Name"
            width="96%"
            rules="required"
            label="Last Name"
          />
        </b-col>
      </b-row>
      <base-input
        v-model="submission_authority_phone"
        name="Contact Person Phone number"
        rules="required"
        label="Phone number of contact person"
      />
      <base-input
        v-model="submission_authority_email"
        name="Contact Person email"
        rules="required"
        label="Email of contact person"
      />
      <p class="text-left font-weight-bold text-black mb-0 mt-4 pb-1 border-bottom">
        Other project information
      </p>
      <div class="d-flex">
        <div style="width: 100%">
          <base-input
            v-model="outcomes"
            name="Expected outcomes"
            width="100%"
            rules="required"
            label="Expected outcomes"
          />
        </div>
        <!-- <div style="width: 2%" class="mt-5">
              <b-icon icon="plus-circle" class="mt-3 ml-1" @click="addExpectedOutcomes"></b-icon>
            </div> -->
      </div>
      <div class="d-flex">
        <div style="width: 100%">
          <base-input
            v-model="achievements"
            name="Achievements"
            width="100%"
            rules="required"
            label="Achievements"
          />
        </div>
      </div>
      <div class="d-flex">
        <div style="width: 100%">
          <base-input
            v-model="challenges"
            name="Challenges"
            width="100%"
            rules="required"
            label="Challenges"
          />
        </div>
      </div>
      <base-textarea v-model="comments" label="Comments" name="Comments" rules="required" />
      <p class="text-left font-weight-bold text-black-50 mb-0 mt-4 pb-1 ml-1 mt-5">
        Upload project [select file pdf. docx.]
      </p>
      <br />
      <input
        type="file"
        class="ml-1 mt-0"
        accept="image/*"
        @change="fileSelected($event.target.files)"
      />
      <b-row class="ml-1 mt-5">
        <button type="submit" class="sharp ml-n1" @click="errorCheck1">
          <b-spinner v-if="loading" small /> <span v-else>Approve</span>
        </button>
        <button type="button" class="save ml-0 ml-sm-n1">Save and Continue Later</button>
      </b-row>

      <div class="grid">
        <div><button @click="approve()" class="approve_btn">Approve</button></div>
        <div><button @click="reject()" class="reject_btn">Suspend</button></div>
      </div>
    </form>
  </div>
</template>

<script>
import { toast } from '@/plugin/customToast';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {},
  data() {
    return {
      loadingData: false,
      showGeoCoverageForState: false,
      showGeoCoverageForFederal: false,
      showGeoCoverageForLga: false,
      loading: false,
      items: ['foo', 'bar', 'fizz', 'buzz'],
      values: [],
      startMonth: '',
      endMonth: '',
      startYear: '',
      endYear: '',
      start_date: '',
      end_date: '',
      geoCoverage: [],
      programsArea: [],
      healthSystemArea: [],
      healthSystemAreaSelectedToSend: [],
      healthSystemSeleted: [],
      programSelected: [],
      programsAreaSelectedToSend: [],
      name: 'Testing the default name to show',
      status: '',
      target_group: '',
      comments: '',
      currency: null,
      project_type_support: '',
      contact_person_first_name: '',
      contact_person_last_name: '',
      contact_person_phone: '',
      contact_person_email: '',
      submission_authority_first_name: '',
      submission_authority_last_name: '',
      submission_authority_phone: '',
      submission_authority_email: '',
      project_cost: null,
      collaboratingPartner: [],
      funders: [],
      project_objectives: [],
      pdObjectives: '',
      expected_outcomes: [],
      outcomes: '',
      achievementsSelected: [],
      achievements: '',
      challengesSelected: [],
      challenges: '',
      abandon_reason: '',
      percentage_completion: null,
      document: null,
      months: [
        {
          no: 1,
          name: 'January',
        },
        {
          no: 2,
          name: 'February',
        },
        {
          no: 3,
          name: 'March',
        },
        {
          no: 4,
          name: 'April',
        },
        {
          no: 5,
          name: 'May',
        },
        {
          no: 6,
          name: 'June',
        },
        {
          no: 7,
          name: 'July',
        },
        {
          no: 8,
          name: 'August',
        },
        {
          no: 9,
          name: 'September',
        },
        {
          no: 10,
          name: 'October',
        },
        {
          no: 11,
          name: 'January',
        },
        {
          no: 12,
          name: 'December',
        },
      ],
      value: [],
      organizations: [],

      // misc
      ongoing: false,
      abandoned: false,
      geoCoverageBaseLevelSelected: {},
      geoCoverageFederalSelected: {},
      geoCoverageStateSelected: {},
      geoCoverageLgaSelected: {},
      geoCoverageStateLgsSelected: {},
      geoCoverageSelected: {},
      lgasToShow: [],
      geoCoverageBaseLevels: [
        {
          name: 'Federal Level',
          parent: null,
          level: '',
        },
        {
          name: 'State Level',
          parent: '',
          level: 'State',
        },
        {
          name: "LGA's Level",
          parent: '',
          level: 'Lga',
        },
      ],
      sensitivity: [],
      ownertype: [],
      projectSensitivitySelected: {},
      projectOwnerType: {},
      completedProject: false,
      // error checks not done with the vue-multiselct library
      collaboratingPartnerEmpty: false,
      fundersEmpty: false,
      programSelectedEmpty: false,
      healthSystemSeletedEmpty: false,
      geoCoverageBaseLevelSelectedEmpty: false,
      newError: false,
    };
  },
  watch: {
    pdObjectives(val) {
      this.project_objectives = val.map((item) => {
        return item.objective;
      });
      console.log('project_objectives', this.project_objectives);
    },
    outcomes(val) {
      this.expected_outcomes = val.map((item) => {
        return item.outcome;
      });
      console.log('project_objectives', this.expected_outcomes);
    },
    achievements(val) {
      this.achievementsSelected = val.map((item) => {
        return item.achievement;
      });
      console.log('project_objectives', this.achievementsSelected);
    },
    challenges(val) {
      this.challengesSelected = val.map((item) => {
        return item.challenge;
      });
      console.log('project_objectives', this.challengesSelected);
    },
    projectSensitivitySelected(val) {
      console.log('projectSensitivitySelected', val);
    },
    projectOwnerType(val) {
      console.log('projectOwnerType', val);
    },
    programSelected(val) {
      const indexOfItem = this.programsAreaSelectedToSend.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.programsAreaSelectedToSend.splice(indexOfItem, 1);
        // console.log('programsAreaSelectedToSend', this.programsAreaSelectedToSend);
        return;
      }
      this.programsAreaSelectedToSend.push(Number(val.item.id));
      // console.log('programsAreaSelectedToSend', this.programsAreaSelectedToSend);
    },
    healthSystemSeleted(val) {
      const indexOfItem = this.healthSystemAreaSelectedToSend.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.healthSystemAreaSelectedToSend.splice(indexOfItem, 1);
        // console.log('healthSystemAreaSelectedToSend', this.healthSystemAreaSelectedToSend);
        return;
      }
      this.healthSystemAreaSelectedToSend.push(Number(val.item.id));
      // console.log('healthSystemAreaSelectedToSend', this.healthSystemAreaSelectedToSend);
    },
    geoCoverageSelected(val) {
      const indexOfItem = this.geoCoverage.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.geoCoverage.splice(indexOfItem, 1);
        return;
      }
      this.geoCoverage.push(Number(val.item.id));
    },
    geoCoverageLgaSelected(val) {
      const indexOfItem = this.lgasToShow
        .map((item) => {
          return item.state.id;
        })
        .indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.lgasToShow.splice(indexOfItem, 1);
        return;
      }
      const lgas = this.getGeoCoverageLgaLevel(val.item.id);
      const data = {
        state: val.item,
        lga: lgas,
      };
      this.lgasToShow.push(data);
    },
    geoCoverageBaseLevelSelected(val) {
      if (val.item.name === 'Federal Level' && val.item.parent === null && val.selected) {
        this.showGeoCoverageForFederal = true;
        return;
      }
      if (val.item.name === 'Federal Level' && val.item.parent === null && !val.selected) {
        this.showGeoCoverageForFederal = false;
        return;
      }
      if (val.item.level === 'State' && val.item.parent === '' && val.selected) {
        this.showGeoCoverageForState = true;
        return;
      }
      if (val.item.level === 'State' && val.item.parent === '' && !val.selected) {
        this.showGeoCoverageForState = false;
        return;
      }
      if (val.item.level === 'Lga' && val.item.parent === '' && val.selected) {
        this.showGeoCoverageForLga = true;
        return;
      }
      if (val.item.level === 'Lga' && val.item.parent === '' && !val.selected) {
        this.showGeoCoverageForLga = false;
        return;
      }
      console.log(val);
    },
    status(val) {
      if (val === 'Ongoing') {
        this.abandoned = false;
        this.ongoing = true;
        return;
      }
      if (val === 'Abandonded') {
        this.ongoing = false;
        this.abandoned = true;
        return;
      }
      if (val === 'Completed') {
        this.completedProject = true;
        return;
      }
      this.abandoned = false;
      this.ongoing = false;
    },
    startMonth(val) {
      this.start_date = `${this.startYear}-${val.no}-01`;
      console.log(this.start_date);
    },
    startYear(val) {
      this.start_date = `${val}-${this.startMonth.no}-01`;
      console.log(this.start_date);
    },
    endMonth(val) {
      this.end_date = `${this.endYear}-${val.no}-01`;
      console.log(this.end_date);
    },
    endYear(val) {
      this.end_date = `${val}-${this.endMonth.no}-01`;
      console.log(this.end_date);
    },
    user(val) {
      console.log(val);
    },
  },
  async mounted() {
    // pre-populating the data
    this.name = this.getProject.name;

    this.startMonth = this.getProject.startMonth;
    this.endMonth = this.getProject.endMonth;
    this.startYear = this.getProject.endMonth;
    this.endYear = this.getProject.endYear;
    this.start_date = this.getProject.start_date;
    this.end_date = this.getProject.end_date;
    this.geoCoverage = this.getProject.geoCoverage;
    this.programsArea = this.getProject.programsArea;
    // healthSystemArea: [],
    // healthSystemAreaSelectedToSend: [],
    // healthSystemSeleted: [],
    // programSelected: [],
    // programsAreaSelectedToSend: [],
    // name: 'Testing the default name to show',
    // status: '',
    // target_group: '',
    // comments: '',
    // currency: null,
    // project_type_support: '',
    this.contact_person_first_name = this.getProject.contact_person_first_name;
    this.contact_person_last_name = this.getProject.contact_person_last_name;
    this.contact_person_phone = this.getProject.contact_person_phone;
    this.contact_person_email = this.getProject.contact_person_email;
    // submission_authority_first_name: '',
    // submission_authority_last_name: '',
    // submission_authority_phone: '',
    // submission_authority_email: '',
    // project_cost: null,
    // collaboratingPartner: [],
    // funders: [],
    // project_objectives: [],
    // pdObjectives: '',
    // expected_outcomes: [],
    // outcomes: '',
    // achievementsSelected: [],
    // achievements: '',
    // challengesSelected: [],
    // challenges: '',
    // abandon_reason: '',
    // percentage_completion: null,
    // document: null,

    try {
      this.loadingData = true;

      if (
        this.getProgramsArea.results < 1 ||
        this.getHealthSystemArea.results < 1 ||
        this.getGeoCoverage < 1 ||
        this.getGeoCoverageFederalLevel < 1 ||
        this.getGeoCoverageStateLevel < 1 ||
        this.getGeoCoverageLgaLevel < 1
      ) {
        const programsAreaData = await this.$dataset.getAllProgramAreas();
        const healthAreas = await this.$dataset.getAllHealthAreas();
        const categories = await this.$dataset.getAllStakeholdersCategory();
        const branches = await this.$dataset.getAllStakeholdersBranch();
        const geoCoverage = await this.$dataset.getAllGeoCoverage();
        console.log('programsAreaData.data', programsAreaData.data);
        await this.SETPROGRAMAREAS(programsAreaData.data);
        await this.SETSYSTEMAREA(healthAreas.data);
        await this.SETSTAKEHOLDERSBRANCHES(branches.data);
        await this.SETSTAKEHOLDERCATEGORIES(categories.data);
        await this.SETGEOCOVERAGE(geoCoverage.data.results);
      }

      const { data } = await this.$dataset.getAllOrganization();
      const sensitivity = await this.$dataset.getAllSensitivity();
      const ownertype = await this.$dataset.getOwnerType();
      this.organizations = data.results.sort(this.compare());
      this.sensitivity = sensitivity.data.results;
      this.ownertype = ownertype.data.results;
      this.loadingData = false;
    } catch (error) {
      this.loadingData = false;
    }
    // console.log('sensitivity', this.sensitivity);
    console.log('funders', this.funders);
  },
  methods: {
    // function to sort the organizations array by name
    compare(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },

    errorCheck1() {
      if (this.collaboratingPartner.length === 0) {
        this.collaboratingPartnerEmpty = true;
        this.newError = true;
      }
      if (this.funders.length === 0) {
        this.fundersEmpty = true;
        this.newError = true;
      }
      if (this.programSelected.length === 0) {
        this.programSelectedEmpty = true;
        this.newError = true;
      }
      if (this.healthSystemSeleted.length === 0) {
        this.programSelectedEmpty = true;
        this.newError = true;
      }
      if (this.geoCoverageBaseLevelSelected.length === 0) {
        this.geoCoverageBaseLevelSelectedEmpty = true;
        this.newError = true;
      }
      if (
        !this.collaboratingPartnerEmpty &&
        !this.fundersEmpty &&
        !this.programSelectedEmpty &&
        !this.programSelectedEmpty &&
        !this.geoCoverageBaseLevelSelectedEmpty
      ) {
        this.newError = false;
      }
    },
    ...mapActions('DATASET_STORE', [
      'SETPROGRAMAREAS',
      'SETSYSTEMAREA',
      'SETSTAKEHOLDERSBRANCHES',
      'SETSTAKEHOLDERCATEGORIES',
      'SETGEOCOVERAGE',
    ]),

    ...mapActions('admin', [
      'APPROVE_PROJECT',
      'REJECT_PROJECT', // -> this.LOGIN()
    ]),
    approve() {
      console.log('to approve');

      this.APPROVE_PROJECT(this.getProject.id)
        .then(() => {
          this.$swal('Project approved!');
          this.$router.go(-1);
        })
        .catch(() => {
          this.$swal('Project not approved');
        });
    },
    reject() {
      this.REJECT_PROJECT(this.getProject.id)
        .then(() => {
          this.$swal('Project rejected!');
          this.$router.go(-1);
        })
        .catch(() => {
          this.$swal('Project not rejected');
        });
    },

    fileSelected(file) {
      // eslint-disable-next-line prefer-destructuring
      this.document = file[0];
    },
    changeGeoCoverage(event, b) {
      console.log(this.$event, b);
    },
    generateArrayOfYears() {
      const max = new Date().getFullYear();
      const min = max - 80;
      const years = [];
      // eslint-disable-next-line no-plusplus
      for (let i = max; i >= min; i--) {
        years.push(i);
      }
      return years;
    },
    async submitProject() {
      this.loading = true;
      // if statement is subject to review
      if (!this.newError) {
        try {
          const dataToSubmit = {
            name: this.name,
            currency: this.currency,
            project_cost: Number(this.project_cost),
            project_type_support: this.project_type_support,
            project_objectives: JSON.stringify(this.project_objectives),
            start_date: this.start_date,
            end_date: this.end_date,
            status: this.status.toLowerCase(),
            percentage_completion: this.percentage_completion,
            abandon_reason: this.abandon_reason,
            target_group: this.target_group,
            contact_person_first_name: this.contact_person_first_name,
            contact_person_last_name: this.contact_person_last_name,
            contact_person_phone: this.contact_person_phone,
            contact_person_email: this.contact_person_email,
            submission_authority_first_name: this.submission_authority_first_name,
            submission_authority_last_name: this.submission_authority_last_name,
            submission_authority_phone: this.submission_authority_phone,
            submission_authority_email: this.submission_authority_email,
            expected_outcomes: JSON.stringify(this.expected_outcomes),
            achievements: JSON.stringify(this.achievementsSelected),
            challenges: JSON.stringify(this.challengesSelected),
            comments: JSON.stringify(this.comments),
            completed: this.completedProject,
            organization: this.user.id,
            project_ownership: this.projectOwnerType.id,
            project_sensitivity: this.projectSensitivitySelected.id,
            program_area: this.programsAreaSelectedToSend,
            health_system_area: this.healthSystemAreaSelectedToSend,
            geo_coverage: this.geoCoverage,
          };
          const { data } = await this.$dataset.submitProject(dataToSubmit);

          const associateData = {
            project: data.id,
            organizations_roles: [],
          };
          this.funders.forEach((item) => {
            const funderData = { organization: item.id, role: 1 };
            associateData.organizations_roles.push(funderData);
          });
          this.collaboratingPartner.forEach((item) => {
            const collaborData = { organization: item.id, role: 2 };
            associateData.organizations_roles.push(collaborData);
          });
          // associate a project organizations with roles
          await this.$dataset.associateProjectOrganizations(associateData);
          // add project file if it exist
          if (this.document) {
            const projectDocument = new FormData();
            projectDocument.append('document', this.document);
            await this.$dataset.editProject(data.id, projectDocument);
            // console.log('editedProject', editedProject);
          }
          this.$router.push('/inventory/dataset-inventory');
          toast.show({
            type: 'SUCCESS',
            message: 'Project Submitted Sucessfully!',
          });
          this.loading = false;
        } catch (error) {
          this.loading = false;
          // console.log(error);
        }
      }
    },
    addpdObjective() {
      this.pdObjectives.push({ objective: '' });
    },
    addExpectedOutcomes() {
      this.outcomes.push({ outcome: '' });
    },
    addAchievement() {
      this.achievements.push({ achievement: '' });
    },
    addChallenges() {
      this.challenges.push({ challenge: '' });
    },
  },
  computed: {
    ...mapGetters('DATASET_STORE', [
      'getProgramsArea',
      'getHealthSystemArea',
      'getGeoCoverage',
      'getGeoCoverageFederalLevel',
      'getGeoCoverageStateLevel',
      'getGeoCoverageLgaLevel',
      'getProject',
    ]),
    ...mapGetters('auth', ['user']),
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  justify-content: center;
  align-items: center;
}
.grid {
  display: grid;
  grid-template-columns: 50% 50%;
}

.approve_btn {
  background-color: green;
  margin: 20px;
  width: 150px;
  color: white;
  border-radius: 5px;
  opacity: 1;
}

.approve_btn:hover {
  background-color: green;
  opacity: 0.8;
}

.reject_btn {
  background-color: green;
  margin: 20px;
  width: 150px;
  color: white;
  border-radius: 5px;
}

.reject_btn:hover {
  background-color: green;
  opacity: 0.8;
}
</style>
