import { Roles, allRoles } from '@/modules/auth/Role';
import index from './views/index.vue';
import viewsLanding from './views/main-page.vue';
import NgoLanding from './views/pages/ngo-landing-section.vue';
import NgoPortal from './views/dashboard/dashboard-index.vue';
import NgoPortalVeiwProject from './views/dashboard/view-projects.vue';
import NgoPortalSubmitReport from './views/dashboard/submit-report.vue';
import NgoPortalViewSingleProject from './views/dashboard/view-single-project-details.vue';
import NgoRegistrationSteps from './views/pages/ngo-registration-steps.vue';
import NgoRegistrationForm from './views/pages/ngo-registration-form.vue';
import NgoRegistered from './views/pages/ngo-registered-ngos.vue';
import NgoForum from './views/pages/ngo-forum.vue';
import NgoAbout from './views/pages/ngo-about.vue';
import NgoFaq from './views/pages/ngo-faq.vue';
import ContactUs from './views/pages/contact-us.vue';

import NgoAdmin from './views/admin-pages/ngo-admin.vue';
import NgoAdminAdd from './views/admin-pages/ngo-admin-add.vue';
import NgoAdminEdit from './views/admin-pages/ngo-admin-update.vue';
import NgoAdminProjectList from './views/admin-pages/ngo-admin-project-list.vue';

import PROGRAM_AREA_ROUTES from '../programareas/router';

export default [
  {
    path: '/ngo',
    alias: '/',
    component: index,
    children: [
      ...PROGRAM_AREA_ROUTES,
      {
        path: '',
        component: viewsLanding,
        name: 'Home',
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'landing',
        component: NgoLanding,
        name: 'ngo registration',
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'about',
        component: NgoAbout,
        name: 'About',
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'faq',
        component: NgoFaq,
        name: 'Faq',
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'forum',
        component: NgoForum,
        name: 'Forum',
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'contact-us',
        component: ContactUs,
        name: 'Contact us',
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'registration-steps',
        component: NgoRegistrationSteps,
        name: 'ngo registration steps',
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'registration-form',
        component: NgoRegistrationForm,
        name: 'ngo registration form',
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'registered',
        component: NgoRegistered,
        name: 'registered ngos',
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'project/:id',
        component: NgoPortalViewSingleProject,
        name: 'Report',
      },
      {
        path: 'portal',
        component: NgoPortal,
        children: [
          {
            path: '',
            component: NgoPortalVeiwProject,
            name: 'ngo portal',
            meta: {
              requiresAuth: true,
              roles: allRoles,
            },
          },
          {
            path: 'submit-report',
            component: NgoPortalSubmitReport,
            name: 'submit report',
            meta: {
              requiresAuth: true,
              roles: [Roles.Organization, Roles.ngo],
            },
          },
        ],
      },

      {
        path: 'admin',
        component: NgoAdmin,
        name: 'ngo admin',
        meta: {
          requiresAuth: true,
          roles: [Roles.admin],
        },
      },
      {
        path: 'add-new-ngo',
        component: NgoAdminAdd,
        name: 'Add new ngo',
        meta: {
          requiresAuth: true,
          roles: [Roles.admin],
        },
      },
      {
        path: 'project-list/:id/:name',
        component: NgoAdminProjectList,
        name: 'Projects Listing',
        meta: {
          requiresAuth: true,
          roles: [Roles.admin],
        },
      },
      {
        path: 'update-ngo/:id',
        component: NgoAdminEdit,
        name: 'Update ngo',
        meta: {
          requiresAuth: true,
          roles: [Roles.admin],
        },
      },
    ],
  },
];
