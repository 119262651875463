<template>
  <div class="font-weight-light m-5 text-center">
    <h1>Whoops Something Went Wrong</h1>
    <router-link class="btn btn-outline-primary rounded-0" to="/">Go Home</router-link>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
