<template>
  <div class="w-100 desc p-4">
    <p class="text-left font-weight-bold text-black-50">Name of contact person</p>
    <p class="text-left mt-n2">
      <span>{{ projectDetails.contact_person_first_name }}</span>
      <span class="ml-2">{{ projectDetails.contact_person_last_name }}</span>
    </p>

    <p class="text-left font-weight-bold text-black-50">
      Email Address of Contact Person/Organisation
    </p>
    <a :href="`mailto:${projectDetails.contact_person_email}`" class="text-left mt-n2">{{
      projectDetails.contact_person_email
    }}</a>
  </div>
</template>

<script>
export default {
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.desc {
  background-color: #f7f7f7;
}
</style>
