<template>
  <b-link :to="to" @click.prevent="$emit('click')" class="text-decoration-none">
    <button
      :type="type"
      :disabled="loading"
      :style="`min-width:${width}; max-width:${width}; height:${height}; border-radius:${radius}`"
      class="sharp ml-n1"
      @click="$emit('click')"
    >
      <b-spinner v-if="loading" small></b-spinner>
      <span v-else>{{ text }}</span>
    </button>
  </b-link>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    text: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    radius: {
      type: String,
      default: '',
    },
    to: {
      type: String,
    },
    type: {
      type: String,
      default: 'button',
    },
  },
};
</script>

<style scoped>
button {
  display: inline-block;
  padding: 10px 29px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  outline: none;
  min-width: 100%;
}

.sharp {
  /* width: auto; */
  color: #fff;
  background-color: #30ab4c;
  border-color: #0b7022;
}

.save {
  background-color: #fff;
  color: #30ab4c;
  border-color: #0b7022;
  border-radius: 0;
}

.save:hover,
.save:focus,
.save:active,
.save.active,
.open .dropdown-toggle.save {
  background-color: #ffffff;
  color: #30ab4c;
  outline: none;
  border-color: #30ab4c;
}

.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open .dropdown-toggle.sharp {
  border-radius: 0;
  color: #fff;
  background-color: #30ab4c;
  outline: none;
  border-color: #30ab4c;
}

/***********************
  SHARP BUTTONS
************************/
.sharp {
  outline: none;
  border-radius: 0;
}

/***********************
  CUSTON BTN VALUES
************************/

button:focus,
button:active:focus,
button.active:focus {
  outline: 0 none;
}

.sharp {
  background: #30ab4c;
  color: #ffffff;
}
.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open > .dropdown-toggle.sharp {
  background: #30ab4c;
}
.sharp:active,
.sharp.active {
  background: #45cc64;
  box-shadow: none;
}
.save:active,
.save.active {
  background: #d8f7e0;
  color: #30ab4c;
  box-shadow: none;
}
</style>
