<template>
  <div>
    <h4>PROJECT SECTION FAQ’s</h4>
    <div class="bg-light p-0 rounded-0">
      <div class="accordion p-0 my-4" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-1
              class="text-left btn-light font-weight-bold rounded-0"
              ><i class="fas fa-caret-down mr-2"></i>How do I upload Projects?</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >To upload projects, click the “submit projects” tab on the menu bar and fill the
                form provided.
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-2
              class="text-left btn-light font-weight-bold rounded-0"
              ><i class="fas fa-caret-down mr-2"></i>How many submissions am I allowed to
              make?</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >You can make as many submissions as possible, as long as information provided is
                about health projects that have been carried out by your organization since
                2015.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-3
              class="text-left btn-light font-weight-bold rounded-0"
              ><i class="fas fa-caret-down mr-2"></i>Who can I contact if I need help?</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                Our contact information is available on the “About page” of this website.
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-4
              class="text-left btn-light font-weight-bold rounded-0"
              ><i class="fas fa-caret-down mr-2"></i>I can't find the health project I'm looking
              for. What do I do?</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                You will see a search icon at the top of the page where you can type in the name of
                the project you are looking for. You can also use the various filters available to
                narrow your search. If you still cannot find the project you are looking for, then
                it has not been published on the website. However, this inventory will be updated
                periodically. You can check back regularly to find the project you want.
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-5
              class="text-left btn-light font-weight-bold rounded-0"
              ><i class="fas fa-caret-down mr-2"></i>How do I know if my submission has been
              accepted?</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-5" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Generally, it takes one month for submitted projects to be verified and published.
                Check the website regularly to know if the project you submitted has been
                published.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-6
              class="text-left btn-light font-weight-bold rounded-0"
              ><i class="fas fa-caret-down mr-2"></i>I know of some surveys by other organizations,
              and I can't find them here.</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-6" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >If you know of projects that are not available on this website, please let us know
                the name of the organization and project on our feedback page.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-7
              class="text-left btn-light font-weight-bold rounded-0"
              ><i class="fas fa-caret-down mr-2"></i>How do I contact an organization that submitted
              a project?</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-7" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >If you know of projects that are not available on this website, please let us know
                the name of the organization and project on our feedback page.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-8
              class="text-left btn-light font-weight-bold rounded-0"
              ><i class="fas fa-caret-down mr-2"></i>How do I contact an organization that submitted
              a project?</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-8" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >The contact details of the implementing organization are available for each
                project. Select the project of interest, and then click the Contact details
                tab.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-9
              class="text-left btn-light font-weight-bold rounded-0"
              ><i class="fas fa-caret-down mr-2"></i>What if my question is not addressed in the
              FAQ's?</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-9" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Please email us, and we will get back to you shortly. Contact details can be found
                in the “contact us” page.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
