<template>
  <div class="faq-page">
    <div class="faq-sticky">
      <ul class="nav">
        <li>
          <a href="#">Home ></a>
        </li>
        <li>
          <a href="#">FAQ ></a>
        </li>
        <li>
          <a class="active" href="#">Project Inventory</a>
        </li>
      </ul>
      <h1>FAQs</h1>
      <!-- <div class="btn-group addFaq" role="group">
        <router-link to="/admin/add-faq"
          ><button type="button" class="btn btn-primary rounded-0">
            + ADD NEW QUESTIONS
          </button></router-link
        >
      </div> -->
    </div>
    <div class="faqs">
      <h5 style="color: #707070">Created FAQs</h5>
      <div class="faq-btn">
        <div class="faq-row">
          <b-btn v-b-toggle.myCollapse class="text-left btn-light font-weight-bold rounded-0">
            <span class="when-closed"> <i class="fa fa-caret-down" aria-hidden="true"></i></span>
            <span class="when-opened"> <i class="fa fa-caret-up" aria-hidden="true"></i></span>
            How do I upload projects?
            <div class="icons">
              <router-link to="/admin/edit-faq"><i class="fa fa-edit"></i></router-link>
              <i class="fa fa-trash" aria-hidden="true"></i>
            </div>
          </b-btn>
        </div>
        <b-collapse id="myCollapse">
          To upload projects, click the health projects tab on the menu bar, click add project, and
          fill the form provided
        </b-collapse>
      </div>
      <div class="faq-btn">
        <div class="faq-row">
          <b-btn v-b-toggle.myCollapse2 class="text-left btn-light font-weight-bold rounded-0">
            <span> <i class="fa fa-caret-down" aria-hidden="true"></i></span>
            <!-- <span> <i class="fa fa-caret-up" aria-hidden="true"></i></span> -->
            How many submissions am I allowed to make?
            <div class="icons">
              <router-link to="/admin/edit-faq"><i class="fa fa-edit"></i></router-link>
              <i class="fa fa-trash" aria-hidden="true"></i>
            </div>
          </b-btn>
        </div>
        <b-collapse id="myCollapse2"> </b-collapse>
      </div>
      <div class="faq-btn">
        <div class="faq-row">
          <b-btn v-b-toggle.myCollapse3 class="text-left btn-light font-weight-bold rounded-0">
            <span> <i class="fa fa-caret-down" aria-hidden="true"></i></span>
            <!-- <span> <i class="fa fa-caret-up" aria-hidden="true"></i></span> -->
            Who can I contact if I need help?
            <div class="icons">
              <i class="fa fa-edit"></i>
              <i class="fa fa-trash" aria-hidden="true"></i>
            </div>
          </b-btn>
        </div>
        <b-collapse id="myCollapse3"> </b-collapse>
      </div>
      <div class="faq-btn">
        <div class="faq-row">
          <b-btn v-b-toggle.myCollapse4 class="text-left btn-light font-weight-bold rounded-0">
            <span> <i class="fa fa-caret-down" aria-hidden="true"></i></span>
            <!-- <span> <i class="fa fa-caret-up" aria-hidden="true"></i></span> -->
            I can’t find the health project I’m looking for. What do I do?
            <div class="icons">
              <i class="fa fa-edit"></i>
              <i class="fa fa-trash" aria-hidden="true"></i>
            </div>
          </b-btn>
        </div>
        <b-collapse id="myCollapse4"> </b-collapse>
      </div>
      <div class="faq-btn">
        <div class="faq-row">
          <b-btn v-b-toggle.myCollapse5 class="text-left btn-light font-weight-bold rounded-0">
            <span> <i class="fa fa-caret-down" aria-hidden="true"></i></span>
            <!-- <span> <i class="fa fa-caret-up" aria-hidden="true"></i></span> -->
            How do I know if my submission has been accepted?
            <div class="icons">
              <i class="fa fa-edit"></i>
              <i class="fa fa-trash" aria-hidden="true"></i>
            </div>
          </b-btn>
        </div>
        <b-collapse id="myCollapse5"> </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.faq-page {
  margin: 30px 0px 0px 20px;
}
.faq-sticky {
  top: 0;
  background: #fff;
  position: sticky;
  z-index: 1030;
}
.active {
  color: black;
}
h1 {
  color: #30ab4c;
}
.tab-btn button {
  border-radius: 10px 12px 1px 0px;
  border: 1px solid#2DA047;
  background: white;
  color: #2da047;
  width: 300px;
  font-size: 15px;
  // background: #2da047 0% 0% no-repeat padding-box;
}
.tab-btn {
  display: flex;
  padding-bottom: 20px;
}
.addFaq {
  width: 313px;
  height: 60px;
}
.tab-btn .btn-active {
  background: #2da047 0% 0% no-repeat padding-box;
  color: #fff;
}
.faqs {
  padding: 40px 0px 0px 0px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.btn-light {
  background: #fff;
  border-color: #fff;
}
.faq-btn {
  width: 900px;
  padding-bottom: 50px;
}
.faq-row {
  display: flex;
  justify-content: space-around;
}
.btn-light:hover {
  background: #fff;
  border-color: #fff;
}
.fa {
  padding: 0px 20px 0px 0px;
  cursor: pointer;

}
.fa-edit {
  color: #2da047;
  font-size: 24px;
}
.fa-trash {
  color: red;
  font-size: 24px;
}
.icons {
  float: right;
}
</style>
