<template>
  <div>
    <div class="sticky-top">
      <BaseLogoBar />
      <BaseNavbar />
      <div>
        <BreadCrumb />
      </div>
    </div>
    <div class="d-flex" id="wrapper">
      <div id="page-content-wrapper" class="">
        <div class="">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <BaseFooter />
  </div>
</template>

<script>
import BreadCrumb from './components/feedback-breadcrumbs.vue';

export default {
  name: 'feedback-index',
  components: {
    BreadCrumb,
  },

  computed: {},
};
</script>

<style scoped>
#wrapper {
  overflow-x: hidden;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
.sticky-top {
  /* background-color: white;
  margin-top: 10rem; */
}
</style>
