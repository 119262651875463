const statesProj = [
  {
    name: 'Abia',
    id: 1,
    numProj: 0,
  },

  {
    name: 'Adamawa',
    id: 2,
    numProj: 0,
  },

  {
    name: 'Akwa Ibom',
    id: 3,
    numProj: 0,
  },

  {
    name: 'Anambra',
    id: 4,
    numProj: 0,
  },

  {
    name: 'Bauchi',
    id: 5,
    numProj: 0,
  },

  {
    name: 'Bayelsa',
    id: 6,
    numProj: 0,
  },

  {
    name: 'Benue',
    id: 7,
    numProj: 0,
  },

  {
    name: 'Borno',
    id: 8,
    numProj: 0,
  },

  {
    name: 'Cross River',
    id: 9,
    numProj: 0,
  },

  {
    name: 'Delta',
    id: 10,
    numProj: 0,
  },

  {
    name: 'Ebonyi',
    id: 11,
    numProj: 0,
  },

  {
    name: 'Edo',
    id: 12,
    numProj: 0,
  },

  {
    name: 'Ekiti',
    id: 13,
    numProj: 0,
  },

  {
    name: 'Enugu',
    id: 14,
    numProj: 0,
  },

  {
    name: 'FCT',
    id: 15,
    numProj: 0,
  },

  {
    name: 'Gombe',
    id: 16,
    numProj: 0,
  },

  {
    name: 'Imo',
    id: 17,
    numProj: 0,
  },

  {
    name: 'Jigawa',
    id: 18,
    numProj: 0,
  },

  {
    name: 'Kaduna',
    id: 19,
    numProj: 0,
  },

  {
    name: 'Kano',
    id: 20,
    numProj: 0,
  },

  {
    name: 'Katsina',
    id: 21,
    numProj: 0,
  },

  {
    name: 'Kebbi',
    id: 22,
    numProj: 0,
  },

  {
    name: 'Kogi',
    id: 23,
    numProj: 0,
  },

  {
    name: 'Kwara',
    id: 24,
    numProj: 0,
  },

  {
    name: 'Lagos',
    id: 25,
    numProj: 0,
  },

  {
    name: 'Nasarawa',
    id: 26,
    numProj: 0,
  },

  {
    name: 'Niger',
    id: 27,
    numProj: 0,
  },

  {
    name: 'Ogun',
    id: 28,
    numProj: 0,
  },

  {
    name: 'Ondo',
    id: 29,
    numProj: 0,
  },

  {
    name: 'Osun',
    id: 30,
    numProj: 0,
  },

  {
    name: 'Oyo',
    id: 31,
    numProj: 0,
  },

  {
    name: 'Plateau',
    id: 32,
    numProj: 0,
  },

  {
    name: 'Rivers',
    id: 33,
    numProj: 0,
  },

  {
    name: 'Sokoto',
    id: 34,
    numProj: 0,
  },

  {
    name: 'Taraba',
    id: 35,
    numProj: 0,
  },

  {
    name: 'Yobe',
    id: 36,
    numProj: 0,
  },

  {
    name: 'Zamfara',
    id: 37,
    numProj: 0,
  },
];

export default statesProj;
