<template>
  <div class="project">
    <!-- projects pangination -->
    <div class="admin-nav">
      <ul class="nav">
        <li>
          <a href="#">Home ></a>
        </li>
        <li>
          <a href="#">Project Inventory ></a>
        </li>
        <li>
          <a class="active" href="#">All Projects</a>
        </li>
      </ul>
      <div class="tab-btn">
        <!-- <button @click="changeAll()" :class="[isAll ? 'btn-active' : '']">All Datasets</button> -->
        <button @click="changePend()" :class="[isPending ? 'btn-active' : '']">
          Pending Datarequests
        </button>
        <button @click="changeApprov()" :class="[isApproved ? 'btn-active' : '']">
          Approved Datarequests
        </button>
        <!-- <button @click="changeDataReq()" :class="[isDatarequest ? 'btn-active' : '']">
          Data Request Entries
        </button> -->
      </div>
    </div>
    <pre>{{ datarequest }}</pre>
    <br />
    <div>
      <b-row class="p-0" no-gutters align-h="between">
        <b-col cols="12" sm="9">
          <div v-if="loading" class="text-center loading-icon">
            <b-spinner v-if="loading" x-large></b-spinner>
          </div>
          <div v-else>
            <!-- DataRequest card -->

            <div>
              <DataRequestCard
                v-for="(datarequest, index) in datarequests"
                :key="index"
                :to="`/inventory/dataset-view/${datarequest.id}`"
                :seemorelink="true"
                :date="datarequest.created_at"
                :id="datarequest.id"
              >
                <template slot="body" v-if="datarequest.user_details">
                  <b-card-title class="text-left font-weight-bold text-black-50 h5">
                    {{ datarequest.title }}
                  </b-card-title>
                  <b-card-text class="text-left text-black-50">
                    {{ datarequest.description }}
                  </b-card-text>
                  <div class="text-left mt-4">
                    <h3 class="font-weight-bolder text-black-50">{{ datarequest.datasetTitle }}</h3>
                  </div>
                  <div class="text-left mt-4">
                    <span class="font-weight-bolder text-black-50">Project Name:</span>
                    <span class="ml-1 text-black-50">
                      {{ datarequest.projectTitle }}
                    </span>
                  </div>
                  <div class="text-left mt-4">
                    <!-- <span class="font-weight-bolder text-black-50">Description:</span> -->
                    <span class="ml-1 text-black-50">
                      {{ datarequest.projectDescription }}
                    </span>
                  </div>
                  <div class="text-left mt-4">
                    <span class="font-weight-bolder text-black-50">Name:</span>
                    <span class="ml-1 text-black-50">
                      {{ datarequest.user_details.first_name }}
                      {{ datarequest.user_details.last_name }}
                    </span>
                  </div>
                  <div class="text-left mt-4">
                    <span class="font-weight-bolder text-black-50">email:</span>
                    <span class="ml-1 text-black-50">
                      {{ datarequest.user_details.username }}
                    </span>
                  </div>
                </template>
              </DataRequestCard>
              <div v-if="datarequests.length === 0">
                No datarequest available
                <img src="../../../assets/no-data.svg" alt="" width="500px" />
              </div>
            </div>
          </div>
        </b-col>
        <!-- <b-col cols="3" class="pl-5 d-none d-sm-block">
          <FilterSideBar />
        </b-col> -->
      </b-row>
      <div class="mx-auto text-center">
        <Pagination
          :totalPages="datarequests.count"
          :itemCount="datarequests.length"
          @change-page="changePage($event)"
          :currentPage="currentPage"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.project {
  padding: 30px 0px 0px 20px;
}
.admin-nav {
  background: #fff;
  top: 0;
  left: 0;
  z-index: 1030;
  position: sticky;
  width: 100%;
}
h4 {
  color: #707070;
}
.paragraph {
  color: #707070;
  padding-top: 20px;
}
.card-section {
  padding-top: 20px;
}
.card {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  width: 1000px;
  height: 300px;
  padding: 20px 30px 0px 20px;
  margin: 0 auto;
}
.card-section {
  margin-bottom: 25px;
}
.card-bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}
button {
  border-radius: 10px 12px 1px 0px;
  border: 1px solid#2DA047;
  background: white;
  color: #2da047;
  width: 230px;
  font-size: 15px;
  // background: #2da047 0% 0% no-repeat padding-box;
}
.tab-btn {
  display: flex;
  padding-top: 25px;
}
.btn-active {
  background: #2da047 0% 0% no-repeat padding-box;
  color: #fff;
}
.overflow-auto {
  display: flex;
  justify-content: center;
}
</style>

<script>
import moment from 'moment';
import instance from '@/plugin/axios';
import { mapActions } from 'vuex';
// import DatasetCard from '../components/Shared/DatasetCard.vue';
import DataRequestCard from '../components/Shared/DataRequestCard.vue';
import Pagination from '../components/Shared/Pagination.vue';
// import getProjectForSpecificUser from '../../dataset/services/dataset.service.js';
// import FilterSideBar from '../components/Shared/FilterSideBar.vue';

export default {
  components: {
    // DatasetCard,
    Pagination,
    // FilterSideBar,
    DataRequestCard,
  },
  data() {
    return {
      isAll: false,
      isApproved: false,
      isPending: true,
      currentPage: 1,
      loading: false,
      datarequests: [],
      isDatarequest: true,
    };
  },
  methods: {
    ...mapActions('admin', ['GET_DATAREQUEST']),
    async fetchData() {
      this.loading = true;
      const newArray = [];
      try {
        const datarequest = await this.GET_DATAREQUEST();
        console.log(datarequest, 'datarequest');
        await Promise.all(
          datarequest.data.results.map(async (el) => {
            // const userProject = await this.$dataset.getProjectForSpecificUser();
            const userProject = await instance.get(`/user_projects/${el?.project}/`);
            const dataset = await instance.get(`/datasets/${el?.dataset}/`);
            // console.log(userProject, dataset, 'ud');
            newArray.push({
              datasetTitle: dataset?.data?.name,
              projectTitle: userProject?.data?.title,
              projectDescription: userProject?.data?.description,
              ...el,
            });
          })
        );
        this.datarequests = newArray;
        this.loading = false;
        // console.log(this.datarequests, 'datarequests')
        // return this.datarequests
      } catch (error) {
        this.loading = false;
        this.datarequests = [];
      }
    },

    async changeApprov() {
      this.isDatarequest = true;
      this.loading = true;
      this.isAll = false;
      this.isApproved = true;
      this.isPending = false;
      const response = await this.GET_DATAREQUEST();
      const item = response.data?.results;
      const items = await item.filter((datarequest) => datarequest?.status === 'Approved');
      this.datarequests = items;
      this.loading = false;
    },

    async changePend() {
      this.isDatarequest = true;
      // console.log('check the pending');
      this.loading = true;
      this.isAll = false;
      this.isApproved = false;
      this.isPending = true;
      // console.log(this.datarequests, 'data1');
      const response = await this.GET_DATAREQUEST();
      const item = response.data?.results;
      const items = await item.filter((datarequest) => {
        // console.log('datarequest', datarequest);
        return datarequest?.status === 'Pending';
      });
      this.datarequests = items;
      // console.log(this.datarequests, 'data2');
      this.loading = false;
    },
    async changePage(e) {
      this.loading = true;
      try {
        const datarequests = await this.GET_DATAREQUEST(e);
        this.datarequests = datarequests?.data;
        this.currentPage = e;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    getYear(date) {
      return moment(date).format('YYYY');
    },
  },
  async mounted() {
    await this.fetchData();
    console.log('datasets', this.datarequests);
  },
};
</script>
