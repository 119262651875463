<template>
<div>
   <highcharts :constructor-type="'mapChart'" :options="mapOptions" class="map"></highcharts>
   </div>
 
</template>

<script>
export default {
  props: {
    chartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      mapOptions: {
        chart: {
          map: 'myMapName',
        },
        title: {
          text: 'Project distribution across Nigerian states',
        },
        subtitle: {
          text: '',
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: 'spacingBox',
          },
        },
        colorAxis: {
          min: 0,
        },
        series: [
          {
            name: 'Random data',
            states: {
              hover: {
                color: '#BADA55',
              },
            },
            dataLabels: {
              enabled: true,
              format: '{point.name}',
            },
            allAreas: false,
            data: [
              ['ng-ri', 10],
              ['ng-kt', 11],
              ['ng-so', 12],
              ['ng-za', 13],
              ['ng-yo', 14],
              ['ng-ke', 15],
              ['ng-ad', 16],
              ['ng-bo', 17],
              ['ng-ak', 18],
              ['ng-ab', 19],
              ['ng-im', 20],
              ['ng-by', 21],
              ['ng-be', 22],
              ['ng-cr', 23],
              ['ng-ta', 24],
              ['ng-kw', 25],
              ['ng-la', 26],
              ['ng-ni', 27],
              ['ng-fc', 28],
              ['ng-og', 29],
              ['ng-on', 30],
              ['ng-ek', 31],
              ['ng-os', 32],
              ['ng-oy', 33],
              ['ng-an', 34],
              ['ng-ba', 35],
              ['ng-go', 36],
              ['ng-de', 37],
              ['ng-ed', 38],
              ['ng-en', 39],
              ['ng-eb', 40],
              ['ng-kd', 41],
              ['ng-ko', 42],
              ['ng-pl', 43],
              ['ng-na', 44],
              ['ng-ji', 45],
              ['ng-kn', 46],
            ],
          },
        ],
      },
    };
  },
    watch: {
      chartData(newVal) {
        this.mapOptions.series[0].data =  newVal
      },
      immediate: true,
      deep: true,
  },
  async mounted(){
    this.mapOptions.series[0].data = await this.chartData
  }
};
</script>
<style scoped>
.map {
  min-height: 500px;
}
</style>
