<template>
  <div>
    <footer class="fixed-bottom">
      <div class="container-fluid">
        <div class="row bg-primary">
          <div class="col text-white m-1">
            Built with
            <span class="text-danger"><i class="fas fa-heart"></i></span> by eHealth4everyone
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
