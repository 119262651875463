<template>
  <div
    :class="[currentTab === tab.tab ? 'selected' : '', 'tab']"
    @click.stop="changeTabs(currentTab, tab)"
  >
    <!-- tab.tab === 'Microdata' ? $emit('showMicroDataModal') : $emit('changetab', tab.tab) -->
    {{ tab.name }}
  </div>
</template>

<script>
export default {
  props: {
    tab: {
      type: Object,
      required: true,
    },
    currentTab: {
      type: String,
    },
  },
  data() {
    return {
      class: 'tab',
    };
  },
  methods: {
    changeTabs(current, tab) {
      return this.$emit('changetab', tab.tab);
    },
  },
};
</script>

<style scoped>
.tab {
  background-color: #00000026;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  color: #707070;
  min-width: 9vw;
  max-width: 20vw;
  padding: 0.5rem 1.1rem 0.5rem 1.1rem;
  padding-bottom: 0.5rem;
  border: 1px solid #707070;
  min-height: 2.5rem;
  cursor: pointer;
}
.selected {
  background-color: #ffffff;
  color: #707070;
  border-left: 1px solid #707070;
  border-right: 1px solid #707070;
  border-top: 1px solid #707070;
}
</style>
