<template>
  <div class="m-5 p-3">
    <div v-for="(info, index) in resultQuery" :key="index">
      <b-card>
        <b-card-text>
          {{ info.orgName }}
        </b-card-text>
        <b-card-text>
          {{ info.orgInfo.username }}
        </b-card-text>
        <b-card-text>
          {{ info.orgInfo.email }}
        </b-card-text>

        <b-card-text class="mb-5"> {{ info.description }}. </b-card-text>
        <hr />

        <div class="w-100 d-flex align-items-center justify-content-between">
          <a href="#" class="card-link">Year {{ info.year }}</a>
          <b-card-text class="card-link"> Status: {{ info.status.value }} </b-card-text>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnnualReportsList',
  props: {
    infos: {
      type: Array,
    },
    resultQuery: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.card {
  margin-bottom: 30px;
  width: 95%;
  height: auto;
  border: none;
  box-shadow: 0px 12px 10px #3e3d3d1a;
}
.card-link {
  color: #2da047;
}
.card-text {
  margin: 20px !important;
}
.card-text span {
  font-weight: 600 !important;
}
.card-text:nth-child(1) {
  font-weight: 800 !important;
}
.sub-title {
  margin-left: 20px !important;
}

.card-link:nth-child(1) {
  margin-left: 20px;
}
</style>
