<template>
  <div class="w-100 desc p-4">
    <p class="text-left font-weight-bold text-black-50">Type of data collection activity</p>
    <p class="text-left mt-n2">{{ projectDetails.survey_type }}</p>

    <p class="text-left font-weight-bold text-black-50">Sample size</p>
    <!-- <p class="text-left mt-n2">{{ projectDetails.name }}</p> -->

    <p class="text-left font-weight-bold text-black-50">Sampling procedure</p>
    <p class="text-left mt-n2">{{ projectDetails.sample_procedure }}</p>

    <p class="text-left font-weight-bold text-black-50">Data collection period</p>
    <p class="text-left mt-n2">
      <span>{{ convertDate(projectDetails.start_date) }}</span>
      <span class="ml-1 mr-1">-</span>
      <span>{{ convertDate(projectDetails.end_date) }}</span>
    </p>

    <p class="text-left font-weight-bold text-black-50">Data collection tool</p>
    <p class="text-left mt-n2">{{ projectDetails.collection_tool }}</p>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  methods: {
    convertDate(date) {
      return moment(date).format('MMMM YYYY');
    },
  },
};
</script>

<style scoped>
.desc {
  background-color: #f7f7f7;
}
</style>
