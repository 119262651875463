const routes = {
  organization: '/organizations/',
  comments: '/project_comments/',
  geocoverage: '/geocoverages/',
  news: '/news/',
  program_areas: '/program_areas/',
  projects: '/projects/',
  auth: {
    reset_password: '/password/',
    verify_token: '/password/confirm/',
  },
};

export default routes;
