<template>
  <div>
    <Header />
    <div class="container my-3">
      <div class="row">
        <div class="col">
          <h2 class="text-center">About the Inventory Dashboard</h2>
        </div>
      </div>
    </div>
    <div class="container my-5">
      <div class="row">
        <div class="col">
          <img :src="require('@/assets/inventory-img/img/inventory-mac.png')" alt="" />
        </div>
        <div class="col">
          <p>
            The inventory dashboard was developed by the Federal Ministry of Health (FMoH) to
            analyse and visualise all data submitted by health partners (international partners and
            non-governmental organisations) on the Health Partner's Project and Data Mapping (HPPDM)
            platform. Similar to the HPPDM platform, the inventory dashboard also consists of two
            sections; the Health Projects Inventory and the Health Data Inventory. Through analysis
            of the inventory data, key decision-makers can easily assess the performance of
            activities and identify/ gaps in health programs, interventions, information and data.
            This will help to improve coordination and monitoring of health activities at the
            national level; ensure alignment of said activities with the country's agenda, and to
            schedule priorities for future planning.
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped></style>
