<template>
  <b-container>
    <div>
      <HeroBanner />
      <br />
      <p class="text-justify">
        Note that stakeholders do not solely refer to organizations or partners but every entity
        that is involved in carrying out an activity or set of activities within each branch of a
        division within the DHPRS.
      </p>

      <p>The objectives are:</p>
      <ul class="text-justify ml-5">
        <li>
          To provide the DHPRS with a continuously updated database of the FMoH’s stakeholders and
          their areas of support.
        </li>
        <li>
          To ensure all stakeholder activities are aligned towards the Ministry’s strategic goals.
        </li>
        <li>
          To ensure the DHPRS effectively and efficiently engages all stakeholders using the
          appropriate platforms.
        </li>
      </ul>
      <div class="mt-5 mx-auto">
        <h5 class="text-center mb-3">Sections:</h5>
        <div v-if="loading" class="text-center loading-icon">
          <b-spinner v-if="loading" x-large></b-spinner>
        </div>

        <div
          v-else
          class="pt-4 d-flex flex-column flex-sm-row bd-highlight justify-content-between mx-auto"
        >
          <SectionCard v-for="(section, index) in sections" :section="section" :key="index" />
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { sortBy } from 'lodash';
import HeroBanner from '../components/homepage/HeroBanner.vue';
import SectionCard from '../components/homepage/SectionCard.vue';

export default {
  components: { HeroBanner, SectionCard },
  data() {
    return {
      loading: false,
      sections: [],
      newDesc: [
        {
          description:
            'Coordinate health policy and plan formulation, review and priority settings',
        },
        {
          description:
            'Coordinate and promote all health research for efficient health system development and knowledge sharing',
        },
        {
          description:
            'Coordinate international cooperation on health and collaborations with development partners, NGOs and other health actors',
        },
        {
          description: 'Plan for human resource for health development',
        },
        {
          description:
            'Coordinate monitoring and evaluation of the health system and its performance',
        },
      ],
      sn: [2, 1, 3, 4, 5],
    };
  },
  async mounted() {
    this.loading = true;
    try {
      const data = await this.$dataset.getAllStakeholdersDivision();
      this.sections = data.map((sec, i) => ({
        name: sec,
        description: this.newDesc[i].description,
        sn: this.sn[i],
      }));
      this.sections = sortBy(this.sections, [(o) => o.sn]);
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
