<template>
  <div class="m5">
    <ul class="nav pb-4">
      <li>
        <a href="/">Home ></a>
      </li>
      <li>
        <a href="/admin/stakeholder">Stakeholder Inventory ></a>
      </li>
      <li>
        <a href="/admin/research">Research And Knowledge ></a>
      </li>
      <li>
        <a href="/admin/stakeholder-lists" class="active">World Health Organisation</a>
      </li>
    </ul>
    <h3 style="color: #30ab4c">WORLD HEALTH ORGANISATION</h3>
    <div class="dropdown-wrap d-flex aliign-items-center justify-content-between my-3">
      <div class="dropdown">
        <button
          class="btn dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          All Branch
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" href="#">Action</a></li>
          <li><a class="dropdown-item" href="#">Another action</a></li>
          <li><a class="dropdown-item" href="#">Something else here</a></li>
        </ul>
      </div>
      <div class="dropdown">
        <button
          class="btn dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          All categories
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" href="#">Action</a></li>
          <li><a class="dropdown-item" href="#">Another action</a></li>
          <li><a class="dropdown-item" href="#">Something else here</a></li>
        </ul>
      </div>
    </div>
    <stakeholderList :infos="infos" />
  </div>
</template>

<script>
import stakeholderList from '../../components/stakeholderList.vue';

export default {
  name: 'StakeholderLists',
  components: {
    stakeholderList,
  },

  data() {
    return {
      infos: [
        {
          id: 1,
          title: 'Serve as a secretariat to the National Health Research Ethics Committee (NHREC)',
          organisation: 'World Health Organisation',
          branch: 'Health Research Ethics',
          category: 'Resource persons',
          role: 'Provided financial resources to the branch to set-up and facilitate quarterly meetings for the NHREC',
          date: '28/12/2018',
        },
        {
          id: 2,
          title: 'Serve as a secretariat to the National Health Research Ethics Committee (NHREC)',
          organisation: 'World Health Organisation',
          branch: 'Health Research Ethics',
          category: 'Resource persons',
          role: 'Provided financial resources to the branch to set-up and facilitate quarterly meetings for the NHREC',
          date: '28/12/2018',
        },
        {
          id: 3,
          title: 'Serve as a secretariat to the National Health Research Ethics Committee (NHREC)',
          organisation: 'World Health Organisation',
          branch: 'Health Research Ethics',
          category: 'Resource persons',
          role: 'Provided financial resources to the branch to set-up and facilitate quarterly meetings for the NHREC',
          date: '28/12/2018',
        },
        {
          id: 4,
          title: 'Serve as a secretariat to the National Health Research Ethics Committee (NHREC)',
          organisation: 'World Health Organisation',
          branch: 'Health Research Ethics',
          category: 'Resource persons',
          role: 'Provided financial resources to the branch to set-up and facilitate quarterly meetings for the NHREC',
          date: '28/12/2018',
        },
        {
          id: 5,
          title: 'Serve as a secretariat to the National Health Research Ethics Committee (NHREC)',
          organisation: 'World Health Organisation',
          branch: 'Health Research Ethics',
          category: 'Resource persons',
          role: 'Provided financial resources to the branch to set-up and facilitate quarterly meetings for the NHREC',
          date: '28/12/2018',
        },
        {
          id: 6,
          title: 'Serve as a secretariat to the National Health Research Ethics Committee (NHREC)',
          organisation: 'World Health Organisation',
          branch: 'Health Research Ethics',
          category: 'Resource persons',
          role: 'Provided financial resources to the branch to set-up and facilitate quarterly meetings for the NHREC',
          date: '28/12/2018',
        },
        {
          id: 7,
          title: 'Serve as a secretariat to the National Health Research Ethics Committee (NHREC)',
          organisation: 'World Health Organisation',
          branch: 'Health Research Ethics',
          category: 'Resource persons',
          role: 'Provided financial resources to the branch to set-up and facilitate quarterly meetings for the NHREC',
          date: '28/12/2018',
        },
      ],
    };
  },
};
</script>

<style scoped>
.dropdown-wrap {
  width: 40vw;
}
.btn {
  border-radius: 0px;
  padding: 10px;
  border: 1px solid #eee;
}
</style>
