<template>
  <div id="ngo-forum" class="m-4">
    <ul class="nav pb-4">
      <li>
        <a href="/">Home ></a>
      </li>
      <li>
        <a href="/admin/stakeholder" class="active">Stakeholder Inventory</a>
      </li>
    </ul>
    <h2 style="color: #30ab4c">STAKEHOLDER INVENTORY</h2>
    <div class="wrapper">
      <router-link to="/admin/research"
        ><b-card
          title="RESEARCH AND KNOWLEDGE"
          img-alt="Image"
          tag="article"
          style="max-width: 210px; box-shadow: 0px 15px 10px #00000029; height: 225px; margin: 20px"
          class="mb-2"
        >
          <b-card-text> Lorem ipsum dolor sit amet, consetetur </b-card-text>
        </b-card></router-link
      >
      <router-link to="/admin/management-evaluation"
        ><b-card
          title="MANAGEMENT AND EVALUATION"
          img-alt="Image"
          tag="article"
          style="max-width: 210px; box-shadow: 0px 15px 10px #00000029; height: 225px; margin: 20px"
          class="mb-2"
        >
          <b-card-text> Lorem ipsum dolor sit amet, consetetur </b-card-text>
        </b-card></router-link
      >
      <router-link to="/admin/health-system"
        ><b-card
          title="HEALTH SYSTEM STRENGTHENING"
          img-alt="Image"
          tag="article"
          style="max-width: 210px; box-shadow: 0px 15px 10px #00000029; height: 225px; margin: 20px"
          class="mb-2"
        >
          <b-card-text> Lorem ipsum dolor sit amet, consetetur </b-card-text>
        </b-card></router-link
      >
      <router-link to="/admin/policy-planning"
        ><b-card
          title="POLICY AND PLANNING"
          img-alt="Image"
          tag="article"
          style="max-width: 210px; box-shadow: 0px 15px 10px #00000029; height: 225px; margin: 20px"
          class="mb-2"
        >
          <b-card-text> Lorem ipsum dolor sit amet, consetetur </b-card-text>
        </b-card></router-link
      >
      <router-link to="/admin/international-coperation"
        ><b-card
          title="INTERNATIONAL COOPERATION"
          img-alt="Image"
          tag="article"
          style="max-width: 210px; box-shadow: 0px 15px 10px #00000029; height: 225px; margin: 20px"
          class="mb-2"
        >
          <b-card-text> Lorem ipsum dolor sit amet, consetetur </b-card-text>
        </b-card></router-link
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 900px;
  margin: auto;
  flex-wrap: wrap;
  transition: 2s all ease-out;
}

.card {
  border: none;
}
.card:hover {
  transform: scale(1.05);
}
.card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.card-title {
  font-size: 18px;
  text-align: center;
  letter-spacing: 1px;
  color: #30ab4c;
  text-transform: uppercase;
  opacity: 1;
}
.card-text {
  font-size: 15px;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
</style>
