// import store from '@/store';
// import router from '@/router';
import axios from 'axios';

import Vue from 'vue';

// Set config defaults when creating the instance

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

instance.interceptors.request.use(
  (request) => {
    const AUTH_TOKEN = localStorage.getItem('access_token');
    if (AUTH_TOKEN) {
      // eslint-disable-next-line no-param-reassign
      request.headers.Authorization = `Bearer ${AUTH_TOKEN}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

Vue.prototype.$axios = instance;

export default instance;
