<template>
  <div class="container">
    <template v-if="loading">
      <b-overlay :show="loading">
        <div class="vh-100"></div>
      </b-overlay>
    </template>
    <template v-else>
      <div class="row my-3">
        <div class="col">
          <h2 class="text-primary font-weight-normal">
            Health partners and their projects in Nigeria
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4" v-for="(org, index) in organization" :key="index">
          <Card :organizationObject="org" />
        </div>
      </div>
      <div class="row mx-auto text-center">
        <div class="col">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            align="center"
            first-number
            last-number
          ></b-pagination>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Card from '../components/Card.vue';
import { getOrganization } from '../services';

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      rows: 10,
      organization: [],
      loading: true,
    };
  },
  components: {
    Card,
  },
  watch: {
    currentPage: {
      async handler(newValue) {
        this.loading = true;
        const { data } = await getOrganization(newValue);
        console.log(data);
        this.organization = data;
        this.loading = false;
      },
    },
  },
  async mounted() {
    this.loading = true;
    const { data } = await getOrganization();
    this.rows = data.count;
    this.organization = data.results;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped></style>
