<template>
  <div id="ngo-registration-steps">
    <section class="container my-2">
      <h4 class="my-3 text-primary font-weight-normal">Registration Guide</h4>
      <p class="my-4 text-justify">
        <span class="custom-text">
          To work with the Federal Ministry of Health, all health NGO's must be registered with the
          Department Health Planning Research and Statistics.
        </span>
        <span class="font-weight-bold">
          Follow the steps below to register your Non-Governmental Organization with the Department
          of Health Planning Research and Statistics Health Systems Strengthening (HSS)
        </span>
      </p>

      <div class="row my-5">
        <div class="col-md-2 align-self-center text-center">
          <h5 class="text-capitalize font-weight-bold">step 1</h5>
        </div>
        <div class="col-md-10">
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold">
                Write a letter addressed to the Honourable Minister of Health (attention the
                Director of the Department of Health Planning Research and Statistics) requesting to
                be registered.
              </p>
              <p class="custom-color-style">
                This letter should introduce the NGO, state its areas of focus in health, and
                mention some of its services/activities. Submit this letter to the registry at the
                Honourable Minister's office.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-md-2 align-self-center text-center">
          <h5 class="text-capitalize font-weight-bold">step 2</h5>
        </div>
        <div class="col-md-10">
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold">
                On approval of the letter, obtain an NGO registration form from the NGO Branch and
                carefully answer ALL applicable fields.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-md-2 align-self-center text-center">
          <h5 class="text-capitalize font-weight-bold">step 3</h5>
        </div>
        <div class="col-md-10">
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold">Prepare all relevant documents i.e.</p>
              <p class="custom-color-style">
                I. Photocopy of CAC registration certificate; <br />
                II. CV of relevant contact persons/regular resource persons to the organization;
                <br />
                III. Documentation of the NGO's activities as well as past publications/reports of
                your organization
                <br />
              </p>
              <span class="font-weight-bold">
                Here, is a sample of the FMOH NGO registration form that shows all the documents
                required.
                <a href="/FMOH.pdf" download="FMOH_NGO_REGISTRATION_FORM">Click here to download</a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-md-2 align-self-center text-center">
          <h5 class="text-capitalize font-weight-bold">step 4</h5>
        </div>
        <div class="col-md-10">
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold">
                Submit the documents from Step 3 to the NGO Branch . Subsequently, you will be
                visited by a member of the NGO Branch for verification.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-md-2 align-self-center text-center">
          <h5 class="text-capitalize font-weight-bold">step 5</h5>
        </div>
        <div class="col-md-10">
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold">
                On completing the above steps, verified NGOs will be sent a notification with
                one-time login details
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ngo-registration-steps',
};
</script>
<style lang="scss" scoped>
* {
  font-family: 'Open Sans';
}
.custom-color-style {
  color: grey;
  font-weight: 600;
}
.custom-text {
  color: grey;
  font-weight: 600;
  letter-spacing: 0px;
  word-spacing: 0px;
}
p {
  color: #090909;
  font-size: 0.938rem;
}

.card {
  border: none;
  box-shadow: 3px 5px 7px #00000029;
}
</style>
