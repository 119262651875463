<!-- eslint-disable no-plusplus -->
<!-- eslint-disable no-plusplus -->
<!-- eslint-disable prettier/prettier -->
<template>
  <div class="project">
    <!-- projects pangination -->
    <div class="admin-nav">
      <ul class="nav">
        <li>
          <a href="#">Home ></a>
        </li>
        <li>
          <a href="#">Project Inventory ></a>
        </li>
        <li>
          <a class="active" href="#">All Projects</a>
        </li>
      </ul>
      <div class="tab-btn">
        <!-- <button @click="changeAll()" :class="[isAll ? 'btn-active' : '']">All Projects</button> -->
        <button @click="changePend()" :class="[isPending ? 'btn-active' : '']" id="approved">
          Pending Projects
        </button>
        <button @click="changeApprov()" :class="[isApproved ? 'btn-active' : '']" id="pending">
          Approved Projects
        </button>
      </div>
    </div>
    <div></div>
    <br />
    <br />
    <div>
      <b-row class="p-0" no-gutters align-h="between">
        <b-col cols="12" sm="9">
          <div v-if="loading" class="text-center loading-icon">
            <b-spinner v-if="loading" x-large />
          </div>

          <!-- Project list section -->
          <div v-else>
            <ProjectCard
              v-for="(project, index) in ComputedProjects"
              :key="index"

              :seemorelink="false"
              :date="project.created_at"
              :id="project.id"
              :status="project.review_status.value"
            >
              <template slot="body">
                <b-card-title class="text-left font-weight-bold text-black-50 h5">
                  {{ project.name }}
                </b-card-title>
                <b-card-text class="text-left text-black-50">
                  Capacity building for Local Governments and States' Ministries,
                  Departments and Agencies (MDAs) responsible for rural water supply and
                  sanitation intervention.
                </b-card-text>

                <div class="text-left mt-4">
                  <span class="font-weight-bolder text-black-50"
                    >Period of Implementation:</span
                  >
                  <span class="ml-1 text-black-50"
                    >{{ getYear(project.start_date) }} -
                    {{ getYear(project.end_date) }}</span
                  >
                </div>

                <div class="text-left mt-4">
                  <span
                    v-for="(program, index) in project.program_area"
                    :key="index"
                    class="ml-1 text-primary text-decoration-none"
                    ><span v-if="index !== project.program_area.length - 1"
                      >{{ getProgramAreaName(program) }},</span
                    >
                    <span v-else>{{ program }}</span>
                  </span>
                </div>
              </template>
            </ProjectCard>

            <div v-if="projects.length === 0" class="no-projects">
              <div class="no -projects-text">No projects available</div>
              <img src="../../../../assets/no-data.svg" alt="" width="500px" />
            </div>

            <div class="d-flex mt-4 paginationbox">
              <div class="container">
                <div class="row">
                  <div class="col-sm-6">
                    <p class="mt-1 mx-2">Row per page</p>
              <select id="zone" v-model="perPage" style="width: 50px; height: 40px">
                <option value="3">3</option>
                <option value="5">5</option>
                <option value="10">10</option>
              </select>
                  </div>
                  <div class="col-sm-6">
                    <p class="mx-3 mx-4 h5">
                <!-- {{ currentPage * perPage }} -->
                <span>Page </span>{{ currentPage }} of
                {{ Math.ceil(computedData.length / perPage) }}
              </p>
              <b-pagination
                v-model="currentPage"
                :total-rows="computedData.length"
                :per-page="perPage"
                aria-controls="my-table"
              />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </b-col>
        <b-col cols="3" class="pl-5 d-none d-sm-block">
          <FilterSideBar @filterObjs="fetchDataBasedOnSelectedRequirements" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.project {
  padding: 30px 0px 0px 20px;
}

.no-projects {
  display: flex;
  flex-direction: column;
}

.no-projects-text {
  text-align: center;
  margin: 0 auto;
  margin: 10px;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-nav {
  background: #fff;
  top: 0;
  left: 0;
  z-index: 999;
  position: sticky;
  width: 100%;
}
h4 {
  color: #707070;
}
.paragraph {
  color: #707070;
  padding-top: 20px;
}
.card-section {
  padding-top: 20px;
}
.card {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  width: 1000px;
  height: 300px;
  padding: 20px 30px 0px 20px;
  margin: 0 auto;
}
.card-section {
  margin-bottom: 25px;
}
.card-bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}
button {
  border-radius: 10px 12px 1px 0px;
  border: 1px solid#2DA047;
  background: white;
  color: #2da047;
  width: 300px;
  font-size: 15px;
  // background: #2da047 0% 0% no-repeat padding-box;
}
.tab-btn {
  display: flex;
  padding-top: 25px;
  z-index: -3;
}
.btn-active {
  background: #2da047 0% 0% no-repeat padding-box;
  color: #fff;
}
.overflow-auto {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .tab-btn {
    width: auto;
  }

  #pending,
  #approved {
    width: 50%;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import ProjectCard from '../../components/Shared/ProjectCard.vue';
// import Pagination from '../components/Shared/Pagination.vue';
import FilterSideBar from '../../components/Shared/FilterSideBar.vue';

export default {
  data() {
    return {
      isAll: false,
      isApproved: false,
      isPending: true,
      currentPage: 1,
      total: 0,
      perPage: 5,
      loading: true,
      projects: [],
      tempProjects: '',
      timer: 1,
      search: '',
    };
  },

  components: {
    ProjectCard,
    FilterSideBar,
  },

  methods: {
    ...mapActions('DATASET_STORE', ['SETPROJECT']),

    async changeAll() {
      this.loading = true;
      this.isAll = true;
      this.isApproved = false;
      this.isPending = false;

      const projectData = await this.$dataset.getAllProjects();
      this.projects = projectData?.data?.results;
      this.projects = this.projects.filter(
        (project) => project?.review_status === 'Pending' || 'Approved'
      );

      this.loading = false;
    },

    async changeApprov() {
      this.loading = true;
      this.isAll = false;
      this.isApproved = true;
      this.isPending = false;

      const projectData = await this.$dataset.getAllProjects();
      this.projects = projectData?.data?.results;

      this.projects = this.projects.filter((project) => {
        return project?.review_status?.value !== 'Pending';
      });
      this.loading = false;
    },

    async changePend() {
      this.loading = true;
      this.isAll = false;
      this.isApproved = false;
      this.isPending = true;

      const projectData = await this.$dataset.getAllProjects();
      this.projects = projectData?.data?.results;
      this.projects = this.projects.filter((project) => {
        return project?.review_status?.value === 'Pending';
      });
      this.loading = false;
    },

    async getProjectById(id) {
      console.log('id', id);
      const singleProject = this.projects.filter((project) => project.id === id)[0];
      console.log('singleProject', singleProject);

      this.SETPROJECT(singleProject);

      this.$router.push('/admin/project/view-project');

      console.log('getting', await this.getProject);
    },

    async fetchData() {
      this.loading = true;
      try {
        const projects = await this.$dataset.getAllProjects();
        this.projects = projects?.data?.results;
        this.tempProjects = this.projects;
        this.total = projects?.data?.count;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    // async mounted(){
    //   await this.fetchData()
    // },
    async getProgramAreaName(programAreaId) {
      const programArea = await this.$dataset.getSpecificProgramArea(programAreaId);
      return programArea.data.name;
    },
    /**
     *fetchDataBasedOnSelectedRequirements
     * * returns filtered projects based on sidebase requirements.
     * @param Obejcts sidebar emited object.
     */
    async fetchDataBasedOnSelectedRequirements(data) {
      this.currentPage = 1;
      this.loading = true;
      try {
        const filtered = await this.$dataset.filteredData(data);
        console.log(filtered);
        this.projects = filtered.data;
        this.total = filtered.data.length;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    getYear(date) {
      return moment(date).format('YYYY');
    },
  },

  async mounted() {
    if (this.user.admin_type === 'Data Admin') {
      this.$router.push('/admin/data');
    }

    // const projectData = await this.$dataset.getAllProjects();
    // this.projects = projectData.data.results;

    console.log('projects', this.projects);

    await this.fetchData();
  },
  computed: {
    ...mapGetters('DATASET_STORE', ['getProject', 'getFilterData']),
    ...mapGetters('auth', ['user']),

    computedData() {
      // console.log(this.projects, 'computed')
      return this.projects.filter((project) => {
        return project.name.toLowerCase().match(this.search.toLowerCase());
      });
    },

    /**
     * ComputedProjects
     * * returns this amount of projects to display per page
     */
    ComputedProjects() {
      return this.computedData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },

  watch: {
    getFilterData(val) {
      this.loading = true;

      // status
      val.status.forEach((pro) => {
        this.projects = this.tempProjects.filter((proj) => {
          return proj.status.value === pro;
        });
      });
      this.loading = false;
      console.log(this.projects);
      console.log(this.tempProjects);
    },
  },
};
</script>
