// eslint-disable-next-line import/no-cycle
import Vue from 'vue';
import store from '@/store';
import endpoints from '../services/endpoints';
import DatasetRequests from '../services/dataset.service';
import AxiosInstance from '../../../plugin/axios';

const dataset = new DatasetRequests(AxiosInstance, endpoints, store);

Vue.prototype.$dataset = dataset;
