import axios from '@/plugin/axios';
import Endpoints from './routes';

// getting constants
export const constants  = () => axios.get(Endpoints.constants);

// get requests
// export const getFeedType = () => constants.FeedBackTypes;
// export const getEventType = () => constants.EventTypes;

// post requests
export const postFeed = (payload) => {
  const content = payload;
  return axios.post(Endpoints.feed, content);
};

export const postEnq = (payload) => {
  const content = payload;
  return axios.post(Endpoints.enquiry, content);
};
