<template>
  <div>
    <b-link class="text-decoration-none">
      <b-card
      @click="getDatasetById()"
        shadow
        footer-bg-variant="white"
        style="max-width: auto"
        footer-class="text-left pt-3 pb-3 text-black-50"
        class="shadow mb-5 bg-white border-0 text-decoration-none"
      >
        <main>
          <slot name="body"></slot>
        </main>

        <template #footer>
          <b-row>
            <b-col cols="6" sm="4" class="text-right" v-if="seemorelink">
              <!-- <b-link class="ml-1 text-primary text-decoration-none" :to="to"
                >See details...</b-link
              > -->

              <div class="flex-row">
                <div>
                  <span v-if="status === 'Approved'" class="green">Approved</span>
                  <span v-if="status === 'Pending'" class="red">Pending</span>
                </div>

                <button @click="getDatasetById()" class="p-2 custom-button mt-4" :disabled="loader">
                  <div class="spinner-border" role="status" v-if="loader">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span v-else>See details >>></span>
                </button>
              </div>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-link>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    to: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      default: '',
    },
    seemorelink: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      loader: false,
    };
  },

  methods: {
    ...mapActions('DATASET_STORE', ['SETDATASET']),

    async getDatasetById() {
      this.loader = true;
      const data = await this.$dataset.getDatasets();

      const datasetData = data.data.results;

      const singleDataset = datasetData.filter((dataset) => dataset.id === this.id)[0];
      // console.log('singleProject', singleProject);

      this.SETDATASET(singleDataset);

      this.$router.push('/admin/data/sub-data');
    },
  },
};
</script>

<style scoped>
.green {
  color: green;
}

.red {
  color: red;
}
.card-footer {
  display: none;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>
