<template>
  <div id="ngo-portal-view-project">
    <div class="p-4">
      <div class="d-flex justify-content-between my-4 align-items-center">
        <h4 class="text-primary mt-4">ANNUAL REPORTS</h4>
        <base-input
          prepend="fa fa-search"
          prependclass="mt-1"
          :filled="true"
          placeholder="Search"
          width="30%"
          v-model="search"
        />
      </div>
      <div
        class="d-flex justify-content-center text-weight-uppercase"
        v-if="data.length === 0 && loader === false"
      >
        <div class="alert alert-success">No report found</div>
      </div>
      <div class="text-center  text-primary " v-if="loader">
        Please wait loading content...
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div
        class="card shadow border-0 my-3"
        v-for="(data, index) in filteredList"
        :key="index"
        @click="view(data.id)"
      >
        <div class="card-body">
          <h6 class="font-weight-bold">{{ data.name }}</h6>
          <p>
            {{ data.details !== null ? data.details.summary : 'Unknown' }}
          </p>
          <p class="my-0">
            <span class="font-weight-bold">Name of organization: </span>
            {{ data.organization !== null ? data.organization.name : 'Unknown' }}
          </p>
        </div>
        <div class="card-footer d-flex justify-content-between bg-transparent">
          <p class="my-0">Uploaded {{ data.updated_at | commentDateFormat }}</p>
          <p>Comments</p>
        </div>
      </div>
      <div></div>
      <div class="d-flex justify-content-end align-items-center mt-4">
        <p class="mt-1 mx-2">Row per page</p>
        <select id="zone" style="width: 50px; height: 40px;" v-model="perPage">
          <option value="5"> 5 </option>
          <option value="10"> 10 </option>
        </select>
        <p class="mx-2 mt-2">{{ currentPage }} - {{ perPage }} of {{ total }}</p>
        <b-pagination
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getOrganizationProjects as GETPROJECTS } from '../../config/api-modules/api.projects';

export default {
  name: 'ngo-portal-view-project',
  data() {
    return {
      currentPage: 1,
      data: [],
      loader: false,
      search: '',
      total: 0,
      perPage: 2,
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),

    filteredList() {
      return this.data
        .filter((project) => {
          return project.name.toLowerCase().match(this.search.toLowerCase());
        })
        .slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
  },
  methods: {
    get_data() {
      this.loader = true;
      GETPROJECTS(this.user.id)
        .then((data) => {
          this.data = data.data.results;
          this.total = this.data.length;
        })
        .catch(() => {
          this.data = [];
        })
        .finally(() => {
          this.loader = false;
        });
    },

    view(id) {
      this.$router.push({ path: `project/${id}` });
    },
  },

  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss" scoped>
.card {
  cursor: pointer;
}
* {
  font-family: 'Roboto';
}

p {
  font-size: 0.938rem;
}
h5 {
  color: #707070 !important;
}
</style>
