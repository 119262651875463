import Index from './index.vue';
import Content from './content.vue';

export default [
  {
    path: '/program-areas',
    component: Index,
    children: [
      {
        path: '',
        component: Content,
        name: 'Program Areas',
      },
    ],
  },
];
