<template>
  <b-container>
    <div>
      <b-row class="mb-4 ml-1">
        <h4 class="text-left text-primary ml-n1">Request Datasets for your Project</h4>
      </b-row>
      <div class="mb-4 ml-1">
        <p class="text-left font-weight-bold text-black-50 ml-n1">
          Project Title
        </p>
        <p class="text-left text-black ml-n1 mt-n2">
          Childbirth record in Nigeria
        </p>
      </div>
      <div class="mb-4 ml-1">
        <p class="text-left font-weight-bold text-black-50 ml-n1">
          Requested Country Datasets
        </p>
      </div>
      <div class="mt-n2 mt-sm-n5 text-left text-sm-right">
        <button class="sharp ml-n1" @click="$router.go(-1)">Revise Request</button>
      </div>
    </div>

    <div class="mt-3"></div>
    <b-table
      :bordered="false"
      thead-class="bg-secondary text-dark text-left borderless"
      :fields="fields"
      :items="items"
      tbody-class="text-left"
      responsive="sm"
    >
      <!-- add numbering to table -->
      <template #cell(SURVEYS)="">
        <i class="fas fa-check ml-4"></i>
      </template>
    </b-table>
    <div class="ml-2 mt-n3">
      <base-checkbox
        link="/terms"
        checkboxItem="You have accepted the conditions of use for The PDM Program datasets"
      />
    </div>
    <b-row class="ml-1 mt-4">
      <button type="submit" class="sharp ml-n1" @click="showDataRequestModal = true">Submit</button>
      <button type="button" class="save ml-0 ml-sm-n1">Cancel</button>
    </b-row>
    <DataRequestConfirmationModal :showDataRequestModal="showDataRequestModal" />
  </b-container>
</template>

<script>
// eslint-disable-next-line import/extensions
import DataRequestConfirmationModal from '../../../components/ProjectDetails/DataRequestConfirmationModal.vue';

export default {
  components: { DataRequestConfirmationModal },
  data() {
    return {
      fields: ['STATES', 'SURVEYS', 'SPA'],
      items: [
        {
          code: 'AB',
          STATES: 'Abia',
          surveys: true,
        },
        {
          code: 'AD',
          STATES: 'Adamawa',
        },
      ],

      showDataRequestModal: false,
      descriptionOfStudy: '',
      collaboratingPartner: [{ cPartner: '' }],
    };
  },
  computed: {
    descriptionOfStudyComputedLength() {
      return this.descriptionOfStudy.length;
    },
  },
  methods: {
    addNewCollaboratingPartner() {
      this.collaboratingPartner.push({ cPartner: '' });
    },
    addNewAreaOfDataCollection() {
      this.areaOfDataCollection.push({ areaofdata: '' });
    },
    addNewFunder() {
      this.funders.push({ funder: '' });
    },
    addpdObjective() {
      this.pdObjectives.push({ objective: '' });
    },
    addExpectedOutcomes() {
      this.outcomes.push({ outcome: '' });
    },
    addAchievement() {
      this.achievements.push({ achievement: '' });
    },
    addChallenges() {
      this.challenges.push({ challenge: '' });
    },
  },
};
</script>

<style scoped>
button {
  display: inline-block;
  padding: 10px 29px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 10px 16px;
  outline: none;
}

.sharp {
  width: 12em;
  color: #fff;
  background-color: #30ab4c;
  border-color: #0b7022;
}

.save {
  background-color: #fff;
  color: #30ab4c;
  border-color: #0b7022;
  border-radius: 0;
}

.save:hover,
.save:focus,
.save:active,
.save.active,
.open .dropdown-toggle.save {
  background-color: #ffffff;
  color: #30ab4c;
  outline: none;
  border-color: #30ab4c;
}

.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open .dropdown-toggle.sharp {
  border-radius: 0;
  color: #fff;
  background-color: #30ab4c;
  outline: none;
  border-color: #30ab4c;
}

/***********************
  SHARP BUTTONS
************************/
.sharp {
  outline: none;
  border-radius: 0;
}

/***********************
  CUSTON BTN VALUES
************************/

button {
  padding: 14px 24px;
  outline: none;
  border: 0 none;
  /* font-weight: 700; */
  letter-spacing: 1px;
}
button:focus,
button:active:focus,
button.active:focus {
  outline: 0 none;
}

.sharp {
  background: #30ab4c;
  color: #ffffff;
}
.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open > .dropdown-toggle.sharp {
  background: #30ab4c;
}
.sharp:active,
.sharp.active {
  background: #45cc64;
  box-shadow: none;
}
.save:active,
.save.active {
  background: #d8f7e0;
  color: #30ab4c;
  box-shadow: none;
}
</style>
