<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import axios from '@/plugin/axios';

export default {
  created() {
    axios.interceptors.response.use(
      (response) => Promise.resolve(response),
      // Do something with response error
      (error) => {
        const { message, response } = error;
        // debugger;
        // the switch checkout unauthorized  status code and redirects it
        if (response) {
          switch (response.status) {
            case 401:
              if (this.$route.path === '/auth/login') {
                this.$toast.show({
                  type: 'ERROR',
                  message: 'Unauthorized',
                });
              } else {
                this.$store.dispatch('auth/LOGOUT');
                this.$router.push('/auth');
                this.$toast.show({
                  type: 'ERROR',
                  message: 'Unauthorized',
                });
              }

              break;
            default:
              this.$toast.show({
                type: 'ERROR',
                message,
              });
              break;
          }
        } else {
          this.$toast.show({
            type: 'INFO',
            message,
          });
        }
        return Promise.reject(error);
      }
    );
    this.initRoles();
  },

  computed: {
    ...mapGetters('auth', ['roles']),
  },
  methods: {
    ...mapActions('DATASET_STORE', [
      'SETPROGRAMAREAS',
      'SETSYSTEMAREA',
      'SETSTAKEHOLDERSBRANCHES',
      'SETSTAKEHOLDERCATEGORIES',
      'SETGEOCOVERAGE',
    ]),
    ...mapMutations('DATASET_STORE', [
      'setProgramsArea',
      'setHealthSystemArea',
      'setStakeholderBranches',
      'setStakeholderCategories',
      'setGeoCoverage',
    ]),
    initRoles() {
      /**
       * So it check the roles  and set it when entering the  app
       */
      if (this.roles.length > 0) {
        this.$gates.setRoles(this.roles);
      }
    },
  },
  async mounted() {
    const { data } = await this.$dataset.getAllProgramAreas();
    const healthAreas = await this.$dataset.getAllHealthAreas();
    const categories = await this.$dataset.getAllStakeholdersCategory();
    const branches = await this.$dataset.getAllStakeholdersBranch();
    const geoCoverage = await this.$dataset.getAllGeoCoverage();

    // await this.SETPROGRAMAREAS(data);
    this.setProgramsArea(data.results);
    // await this.SETSYSTEMAREA(healthAreas.data);
    this.setHealthSystemArea(healthAreas);
    // await this.SETSTAKEHOLDERSBRANCHES(branches.data);
    this.setStakeholderBranches(branches.data.results);
    // await this.SETSTAKEHOLDERCATEGORIES(categories.data);
    this.setStakeholderCategories(categories);
    // Action isnt setting the data, switched to mutation to set the data in the state
    // await this.SETGEOCOVERAGE(geoCoverage.data);
    this.setGeoCoverage(geoCoverage.data.results);
  },
};
</script>

<style lang="scss">
.app {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;
}
#card-section {
  height: 100%;
}

#card-section .card {
  cursor: pointer;
  height: 100%;
  text-decoration-color: none;
  transition: 1s transform;
}
#card-section p {
  line-height: normal;
  color: black;
}

#card-section .card:hover {
  transform: translateY(-20px);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
