// import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    programsArea: [],
    healthSystemArea: [],
    stakeholderBranches: [],
    stakeholderCategories: [],
    geoCoverage: [],
    stateGeoCoverage: [0, 1, 2, 3],
    populating: true,
    project: {},
    dataset: {},
    constants: {},
    filterData: {},
  },
  mutations: {
    setProgramsArea(state, payload) {
      state.programsArea = payload;
    },
    setHealthSystemArea(state, payload) {
      state.healthSystemArea = payload;
    },
    setStakeholderBranches(state, payload) {
      state.stakeholderBranches = payload;
    },
    setStakeholderCategories(state, payload) {
      state.stakeholderCategories = payload;
    },
    setGeoCoverage(state, payload) {
      state.geoCoverage = payload;
    },
    setStateGeoCoverage(state, payload) {
      state.stateGeoCoverage = payload;
    },
    setPopulating(state) {
      state.populating = !state.populating;
    },
    setProject(state, payload) {
      state.project = payload;
    },
    setConstants(state, payload) {
      state.contants = payload;
    },
    setFilterData(state, payload) {
      state.filterData = payload;
    },
  },
  actions: {
    async SETSYSTEMAREA({ commit }, payload) {
      commit('setHealthSystemArea', payload);
    },
    async SETPROGRAMAREAS({ commit }, payload) {
      commit('setProgramsArea', payload);
    },
    async SETSTAKEHOLDERSBRANCHES({ commit }, payload) {
      commit('setStakeholderBranches', payload);
    },
    async SETSTAKEHOLDERCATEGORIES({ commit }, payload) {
      commit('setStakeholderCategories', payload);
    },
    async SETGEOCOVERAGE({ commit }, payload) {
      commit('setGeoCoverage', payload);
    },
    async SETSTATEGEOCOVERAGE({ commit }, payload) {
      commit('setStateGeoCoverage', payload);
    },
    async SETPOPULATING({ commit }) {
      commit('setPopulating');
    },
    async SETPROJECT({ commit }, payload) {
      commit('setProject', payload);
    },
    async SETDATASET({ commit }, payload) {
      commit('setDataset', payload);
    },
    async SETCONSTANTS({ commit }, payload) {
      commit('setConstants', payload);
    },
    async SETFILTERDATA({ commit }, payload) {
      commit('setFilterData', payload);
    },
  },

  getters: {
    getStakeholderBranches: (state) => state.stakeholderBranches,
    getStakeholderCategories: (state) => state.stakeholderCategories,
    getProgramsArea: (state) => state.programsArea,
    getHealthSystemArea: (state) => state.healthSystemArea,
    getGeoCoverage: (state) => state.geoCoverage,
    getGeoCoverageFederalLevel: (state) =>
      state.geoCoverage.filter((item) => {
        return item.level === 'Fedral';
      }),
    getGeoCoverageStateLevel: (state) =>
      state.geoCoverage.filter((item) => {
        return item.level === 'State';
      }),
    getGeoCoverageLgaLevel: (state) => (parentId) =>
      state.geoCoverage.filter((item) => {
        return item.parent === parentId && item.level === 'Lga';
      }),
    getStateGeoCoverage: (state) => state.stateGeoCoverage,
    getPopulating: (state) => state.populating,

    getProject: (state) => state.project,
    getDataset: (state) => state.dataset,
    getConstants: (state) => state.constants,
    getFilterData: (state) => state.filterData,
  },
};
