<template>
  <ValidationProvider
    tag="div"
    :rules="rules"
    :name="name"
    :vid="vid"
    v-slot="{ errors, ariaInput, ariaMsg }"
  >
    <b-form-group
      :id="name"
      :label="label"
      :label-for="name"
      class="text-left font-weight-bold text-black-50 mb-1 mt-4"
    >
      <p class="text-black-50 font-weight-lighter mb-2 mt-n1">{{ description }}</p>
      <input type="file" :id="name" v-bind="ariaInput" value="" />
    </b-form-group>
    <p class="text-danger text-left mt-n1 font-weight-light" v-bind="ariaMsg" v-if="errors[0]">
      <small> {{ errors[0] }} </small>
    </p>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'BaseFileInput',

  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    vid: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  data: () => ({}),
  // watch: {
  //   /**
  //    * allows us to use v-model on our input
  //    * @param {*} val
  //    */
  //   currentValue(val) {
  //     this.$emit('input', val);
  //   },
  // },
  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style scoped>
input[type='file'],
select.form-control {
  background: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

input[type='file']:focus,
select.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
