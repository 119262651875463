import axios from '@/plugin/axios';

const urlNewAdmin = '/admins/';

const RegisterAdmin = (details) =>
  new Promise((resolve, reject) => {
    // alert(LoginDetails.type)
    // console.log(user);
    axios
      .post(urlNewAdmin, details)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

const getAdmin = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`/admins/`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

const updateAdmin = (updateData) =>
  new Promise((resolve, reject) => {
    const { id, data } = updateData;
    axios
      .patch(`/admins/${id}/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

const getAdminById = (id) =>
  new Promise((resolve, reject) => {
    // alert(LoginDetails.type)
    // console.log(user);
    axios
      .get(`/admins/${id}/`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

const approveDataset = (id) =>
  new Promise((resolve, reject) => {
    // alert(LoginDetails.type)
    // console.log(user);
    axios
      .get(`/datasets/${id}/approve/`)
      .then((response) => {
        resolve(response);
        this.$swal('Hello word!');
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

const rejectDataset = (id) =>
  new Promise((resolve, reject) => {
    // alert(LoginDetails.type)
    // console.log(user);
    axios
      .get(`/datasets/${id}/reject/`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

const approveProject = (id) =>
  new Promise((resolve, reject) => {
    // alert(LoginDetails.type)
    // console.log(user);
    axios
      .get(`/projects/${id}/approve/`)
      .then((response) => {
        resolve(response);
        this.$swal('Hello word!');
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

const rejectProject = (id) =>
  new Promise((resolve, reject) => {
    // alert(LoginDetails.type)
    // console.log(user);
    axios
      .get(`/projects/${id}/reject/`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

const approveNgo = (id) =>
  new Promise((resolve, reject) => {
    // alert(LoginDetails.type)
    // console.log(user);
    axios
      .get(`/organizations/${id}/approve/`)
      .then((response) => {
        resolve(response);
        this.$swal('Hello word!');
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

const rejectNgo = (id) =>
  new Promise((resolve, reject) => {
    // alert(LoginDetails.type)
    // console.log(user);
    axios
      .get(`/organizations/${id}/reject/`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

const updateNgo = (updateData) =>
  new Promise((resolve, reject) => {
    const { id, data } = updateData;
    axios
      .patch(`/organizations/${id}/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

//= ========================Get DataSets==================================//
const getDatasets = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`/datasets/`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

//= ========================Get Activities==================================//
const getActivities = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`/activities/`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

//= ========================Get DataSet by Id==================================//
const getDatasetById = (id) =>
  new Promise((resolve, reject) => {
    // alert(LoginDetails.type)
    // console.log(user);
    axios
      .get(`/datasets/${id}/`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

//= ========================Update DataSet==================================//
const updateDataset = (updateData) =>
  new Promise((resolve, reject) => {
    const { id, data } = updateData;
    axios
      .patch(`/datasets/${id}/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

//= ========================Post DataSets==================================//
const postDatasets = (details) =>
  new Promise((resolve, reject) => {
    // alert(LoginDetails.type)
    // console.log(user);
    axios
      .post('/datasets/', details)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

//= ========================Get DataRequest==================================//
const getDatarequests = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`/datarequests/`)
      .then((response) => {
        console.log(response, 'respose');
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

//= ========================Get Datarequestby Id==================================//
const getDatarequestById = (id) =>
  new Promise((resolve, reject) => {
    // alert(LoginDetails.type)
    // console.log(user);
    axios
      .get(`/datarequests/${id}/`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

//= ========================Update Datarequest==================================//
const updateDatarequests = (updateData) =>
  new Promise((resolve, reject) => {
    const { id, data } = updateData;
    axios
      .patch(`/datarequests/${id}/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

//= ========================Post Datarequest==================================//
const postDatarequest = (details) =>
  new Promise((resolve, reject) => {
    // alert(LoginDetails.type)
    // console.log(user);
    axios
      .post('/datarequests/', details)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });
export default {
  RegisterAdmin,
  getAdmin,
  updateAdmin,
  approveDataset,
  rejectDataset,
  getAdminById,
  approveProject,
  rejectProject,
  approveNgo,
  rejectNgo,
  updateNgo,
  getDatasets,
  getDatasetById,
  updateDataset,
  postDatasets,
  getDatarequestById,
  getDatarequests,

  updateDatarequests,
  postDatarequest,
  getActivities,
};
