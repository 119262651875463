<template>
  <div>
    <div class="sticky-top">
      <BaseLogoBar />
      <BaseNavbar />
      <!-- <div v-if="user === null"> -->
      <!-- <BreadCrumb /> -->
      <!-- </div> -->
    </div>
    <div class="d-flex" id="wrapper">
      <!-- <div id="page-content-wrapper" class=""> -->
      <router-view></router-view>
    </div>
    <!-- </div> -->
    <BaseFooter />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
