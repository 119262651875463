<template>
  <b-navbar
    toggleable="md"
    type="light"
    variant="white"
    class="justify-content-between logonavbar"
    :style="{ marginLeft: marginLeft, marginRight: marginRight }"
  >
    <b-navbar-brand to="/" class="logo"
      ><img src="@/assets/ministryOfHealthLogo.png" style="height: 3rem" />
    </b-navbar-brand>
    <router-link to="/">
      <div class="logotext">
        <p class="text-primary h4 healthpartner">
          Health Partners' Projects & Data Mapping Platform
        </p>
        <p class="text-black mt-n2 small text-lef mb-1 healthpartner">
          Mapping Health Partners' Projects and Data-related activities in Nigeria
        </p>
      </div>
    </router-link>

    <div class="link">
      <ul class="d-inline-flex list-unstyled">
        <li
          v-for="(nav, index) in navLinks"
          :key="index"
          class="border-dark list-unstyled px-3 text-decoration-none"
          :class="[navLinks.length - 1 != index ? 'border-right' : '']"
        >
          <router-link :to="nav.link" class="text-dark"> {{ nav.title }}</router-link>
        </li>
      </ul>
    </div>
  </b-navbar>
  <!-- <b-navbar
    variant="white"
    type="light"
    class="justify-content-between"
    style="margin-left: 48px; margin-right: 48px"
  >
    <b-navbar-brand to="/" class="d-flex"
      ><img src="@/assets/ministryOfHealthLogo.png" style="height: 3rem" />
      <div class="ml-3">
        <p class="text-primary text-left h4">Health Partners' Projects & Data Mapping Platform</p>
        <p class="text-black mt-n2 small text-lef mb-1">
          Mapping Health Partners' Projects and Data-related activities in Nigeria
        </p>
      </div>
    </b-navbar-brand>

    <div class="mt-4">
      <ul class="d-inline-flex list-unstyled">
        <li
          v-for="(nav, index) in navLinks"
          :key="index"
          class="border-dark list-unstyled px-3 text-decoration-none"
          :class="[navLinks.length - 1 != index ? 'border-right' : '']"
        >
          <router-link :to="nav.link" class="text-dark"> {{ nav.title }}</router-link>
        </li>
      </ul>
    </div>
  </b-navbar> -->
</template>

<script>
export default {
  data() {
    return {
      navLinks: [
        {
          title: 'Home',
          link: '/',
        },
        {
          title: 'About',
          link: '/ngo/about',
        },
        {
          title: 'FAQs',
          link: '/ngo/faq',
        },
        {
          title: 'Contact Us',
          link: '/ngo/contact-us',
        },
      ],
    };
  },

  // To change navbar for admin to be responsive
  computed: {
    marginLeft() {
      if (this.$route.path === '/admin/project') {
        return '0px';
      }
      return '48px';
    },

    marginRight() {
      if (this.$route.path === '/admin/project') {
        return '0px';
      }
      return '48px';
    },
    navheight() {
      if (this.$route.path === '/admin/project') {
        return 'auto';
      }
      return 'auto';
    },
  },
};
</script>

<style scoped>
.lognav {
  /* padding-bottom: 3rem; */
}

@media screen and (max-width: 768px) {
  .link {
    display: none;
  }
  .top-navbar {
    margin-left: 0%;
    margin-right: 0%;
  }
  .healthpartner {
    margin-top: 1rem !important;
  }
  .logotext {
    text-align: center;
  }
  .logo {
    margin-left: auto;
    margin-right: auto;
  }

  .logonavbar {
    height: auto !important;
  }
}
</style>
