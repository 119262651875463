const Roles = {
  User: 'user',
  Organization: 'organization',
  Admin: 'admin',
  ngo: 'ngo',
};

const allRoles = Object.values(Roles);

export default Roles;
export { Roles, allRoles };
