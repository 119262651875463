export default {
  data() {
    return {
      successMessage: false,
      errorMessage: false,
      noFields: false,
    };
  },
  methods: {
    toast() {
      this.$toast.show({
        type: 'SUCCESS',
        message: `Your form has been submitted`,
      });
    },
  },
};
