<template>
  <div class="m-5">
    <b-overlay id="overlay-background" :show="Loading"> </b-overlay>
  </div>
</template>

<script>
import AuthServices from '../UserService';

export default {
  data() {
    return {
      Loading: true,
    };
  },
  async beforeRouteEnter(to, from, next) {
    if (to.params.token) {
      try {
        const { data } = await AuthServices.ActivateUser(to.params.token);
        next('/auth/login');
        console.log(data);
      } catch (error) {
        // Navigate to fallback error occurred page
        next('/fallback');
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
