<template>
  <div class="container">
    <template v-if="loading">
      <b-overlay :show="loading">
        <div class="vh-100"></div>
      </b-overlay>
    </template>
    <template v-else>
      <div class="row align-items-center my-2">
        <div class="col-6">
          <h3 class="text-primary font-weight-light mb-0">
            {{ organization.name }}
          </h3>
        </div>
        <div class="col-6">
          <base-input v-model="searchInput"></base-input>
        </div>
      </div>
      <div class="row" v-if="projects">
        <template v-for="(project, index) in filteredOrganizations">
          <div class="col-12" :key="index">
            <router-link
              :key="index"
              :to="{
                name: 'view_project',
                params: { id: project.id },
              }"
            >
              <ProjectCard :projectObject="project" :key="index"></ProjectCard>
            </router-link>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import ProjectCard from '../components/ProjectCard.vue';
import { getOrganizationProject, getAnOrganization } from '../services';

export default {
  data() {
    return {
      projects: [],
      loading: false,
      organization: {},
      searchInput: '',
    };
  },
  components: {
    ProjectCard,
  },
  computed: {
    filteredOrganizations() {
      return this.projects.filter((project) =>
        project.name.toLowerCase().includes(this.searchInput.toLowerCase())
      );
    },
  },
  async mounted() {
    this.loading = true;
    const { data: organization } = await getAnOrganization(this.$route.params.id);
    const { data } = await getOrganizationProject(this.$route.params.id);

    this.projects = data.results;

    this.organization = organization;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped></style>
