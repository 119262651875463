<template>
  <div class="addUser">
    <h1>Edit USER</h1>
    <form>
      <div class="form-group">
        <label>Organization/Owner</label>
        <input type="text" class="form-control" v-model="owner" />
      </div>
      <div class="form-group">
        <label>Position</label>
        <input type="text" class="form-control" v-model="position" />
      </div>
      <div class="form-group">
        <label>Username</label>
        <input type="text" class="form-control" v-model="username" />
      </div>
      <div class="form-group">
        <label>Email</label>
        <input type="text" class="form-control" v-model="email" />
      </div>
      <div class="form-group">
        <label>Password</label>
        <input type="text" class="form-control" v-model="password" />
      </div>
      <div class="form-group">
        <select class="form-control" style="width: 242px" v-model="role">
          <option value="" selected>{{ role }}</option>
          <!-- <option value="" selected>Choose Admin</option> -->
          <option value="Super Admin">Root Admin</option>
          <option value="International Cooperation Admin">International Cooperation Admin</option>
          <option value="Monitoring and Evaluation Admin">Management and Evaluation Admin</option>
          <option value="Non-Governmental Organization Admin">
            Non-Governmental Organisation Admin
          </option>
          <option value="Organization Admin">Organisation Admin</option>
          <option value="Super Admin">Super Admin</option>
        </select>
      </div>
      <label>Permissions to:</label>
      <p>Read from:</p>
      <p>Write to:</p>
      <div class="user-link" style="padding-bottom: 20px">
        <button
          type="button"
          class="btn btn-primary"
          style="width: 200px; height: 50px"
          @click="update()"
        >
          Update User
        </button>
        <router-link to="/admin/user" style="padding-left: 50px">Back</router-link>
      </div>
    </form>
  </div>
</template>
<style lang="scss" scoped>
.addUser {
  margin: 30px 0px 0px 20px;
}
h1 {
  color: #30ab4c;
}
label {
  text-align: left;
  font: normal normal bold 16px Roboto;
  letter-spacing: 0px;
  color: #090909;
  opacity: 1;
}
form {
  width: 900px;
}
.form-group {
  padding: 20px 0px;
}
.check {
  display: flex;
  justify-content: space-around;
  left: 0;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      owner: '',
      position: '',
      username: '',
      email: '',
      role: '',
    };
  },

  methods: {
    ...mapActions('admin', [
      'NEWADMIN',
      'UPDATE_ADMIN', // -> this.LOGIN()
    ]),

    submit() {
      console.log('checking the login');
      const data = {
        organization: this.owner,
        username: this.username,
        email: this.email,
        authorization: this.role,
        position: this.position,
        password: this.password,
      };

      this.NEWADMIN(data)
        .then(() => {
          this.$swal('User Added');
          this.$router.go(-1);
        })
        .catch(() => {
          this.$swal('User not added');
        });
    },

    async update() {
      const user1 = {
        username: this.username,
        email: this.email,
      };
      const form = {
        organization: this.owner,
        user1,
        authorization: this.role,
        position: this.position,
      };
      await this.UPDATE_ADMIN({ id: this.getAdminById.data.id, data: form })
        .then(() => {
          this.$swal('Admin updated!');
          this.$router.go(-1);
        })
        .catch(() => {
          this.$swal('Admin not updated');
        });
    },
  },

  async mounted() {
    // this.NEWADMIN();
    // pre-filling the form inputs

    this.owner = this.getAdminById.data.organization;
    this.position = this.getAdminById.data.position;
    this.username = this.getAdminById.data.user.username;
    this.email = this.getAdminById.data.user.email;
    this.role = this.getAdminById.data.authorization;
    console.log(this.getAdminById.data.authorization, 'role');
  },

  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('admin', ['getAllAdmins', 'getAdminById']),
  },
};
</script>
