<template>
  <div class="bg-light sidebar">
    <div class="mt-2 p-3">
      <div class="">
        <i class="fas fa-user-circle" />
        <p class="my-0 font-weight-bold">
          {{ user.full_name }}
        </p>
        <p class="font-weight-normal">
          {{ user.email }}
        </p>
      </div>

      <div class="mt-5">
        <p class="font-weight-bold">DATASETS</p>

        <b-link class="text-decoration-none mb-5" :to="`/inventory/my-dataset`">
          View your datasets
        </b-link>
        <div class="mt-2" />
        <!-- <b-link class="text-decoration-none pt-2 text-dark" to="/inventory/submit-dataset"
          >Submit your datasets</b-link
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', ['user']),
  },
};
</script>

<style scoped>
.sidebar {
  height: 100vh;
  min-height: 100vh;
}
.fa-user-circle {
  font-size: 7rem;
  color: gray;
}
</style>
