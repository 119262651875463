import Index from './views/index.vue';
import about from './views/about.vue';
import Home from './views/Home.vue';
// import faq from './views/faq.vue';

export default [
  {
    path: '/inventory-dashboard',
    component: Index,
    children: [
      {
        path: '',
        component: Home,
      },
      {
        path: 'about',
        component: about,
      },
    ],
  },
];
