<template>
  <b-modal id="modal-center" centered hide-footer hide-header size="lg" ref="modal">
    <div class="container">
      <div class="d-flex justify-content-between my-3">
        <h3 class="font-weight-bold text-primary">{{ data.name }}</h3>
        <h6 class="text-primary" type="button" @click="closeModal()">close</h6>
      </div>
      <div class="row row-cols-2">
        <div class="col-md-6">
          <h6 class="font-weight-bold">NAME OF NGO</h6>
          <p>{{ data.name }}</p>
          <h6 class="font-weight-bold mt-4">YEAR OF REGISTRATION</h6>
          <p>{{ data.details.year }}</p>
          <h6 class="font-weight-bold mt-4">NAME OF CONTACT PERSON</h6>
          <p>first name: {{ data.details.contact_person_first_name }}</p>
          <p>last name: {{ data.details.contact_person_last_name }}</p>
        </div>
        <div class="col-md-6">
          <h6 class="font-weight-bold">PHONE ON. OF CONTACT PERSON</h6>
          <p>{{ data.details.contact_person_phone }}</p>
          <h6 class="font-weight-bold">EMAIL</h6>
          <p>{{ data.details.email }}</p>
          <h6 class="font-weight-bold">STATE</h6>
          <p>{{ data.details.state }}</p>
          <h6 class="font-weight-bold">GEOGRAPHICAL ZONE</h6>
          <p>{{ data.details.geo_zone }}</p>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    closeModal() {
      this.$refs.modal.hide();
    },
  },
};
</script>

<style scoped>
* {
  font-family: 'Open Sans';
}

p {
  color: #424141;
}
h6 {
  margin-top: 5px;
  color: black;
}
</style>
