<template>
  <div id="single-project-details" class="container mt-5 mb-4">
    <div class="text-center text-primary" v-if="loader">
      Please wait loading content...
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-else>
      <button class="btn btn-sm rounded-0 mb-4 btn-primary" @click="$router.go(-1)">BACK</button>
      <h4 class="text-primary text-capitalize">{{ data.name }}</h4>
      <div class="text-right text-primary d-inline">
        <p v-b-modal.modal-center @click="preview(data)">contact info</p>
      </div>

      <section class="bg-light project-background">
        <div class="container p-4">
          <h6 class="font-weight-bold">Report title</h6>
          <p>{{ data.name }}</p>
          <h6 class="font-weight-bold">Name of organization</h6>
          <p>{{ data.organization.name }}</p>
          <h6 class="font-weight-bold">Reporting period</h6>
          <p>{{ data.start_date | monthsFormat }} - {{ data.end_date | monthsYearFormat }}</p>
          <h6 class="font-weight-bold">Achievements</h6>
          <p>{{ data.details.achievements }}</p>
          <h6 class="font-weight-bold">Challenges</h6>
          <p>{{ data.details.challenge }}</p>
          <p class="font-weight-bold">
            Download:
            <a :href="data.document" class="text-primary" download target="_blank"
              >{{ data.name }}
            </a>
          </p>

          <h4 class="text-primary">{{ comments.length }} Comments</h4>
        </div>
      </section>

      <section id="chat" class="mt-5 pl-4">
        <div class="alart text-primary text-uppercase text-center" v-if="comments.length === 0">
          No comments found
        </div>

        <div class="d-flex flex-column" v-for="(comments, index) in comments" :key="index" v-else>
          <div class="d-flex">
            <i class="fa fa-user-circle h1"></i>
            <div class="d-flex flex-column ml-2">
              <p class="my-0">{{ comments.sender.first_name }} {{ comments.sender.last_name }}</p>
              <p>{{ comments.created_at | commentDateFormat }}</p>
            </div>
          </div>
          <div class="chat-content">
            <p class="ml-5">
              {{ comments.content }}
            </p>
          </div>
        </div>
        <form @submit.prevent="submitComments(form)" class="mt-5">
          <textarea
            class="form-control input-fields"
            name="comment"
            v-model="form.content"
            required
          />
          <div class="d-flex justify-content-end my-4">
            <button
              class="btn btn-sm btn-primary px-5 py-2 rounded-0"
              type="submit"
              :disabled="add_comment_loader"
            >
              <div class="spinner-border" role="status" v-if="add_comment_loader">
                <span class="sr-only">Loading...</span>
              </div>
              <span v-else>
                Submit
              </span>
            </button>
          </div>
        </form>
      </section>
    </div>
    <InfoModal v-if="info != null" :data="info" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoModal from '../modals/ngo-info-modal.vue';
import { getSinglePorject as GETPROJECT } from '../../config/api-modules/api.projects';
import {
  addComment as ADDCOMMENTS,
  getComments as GETCOMMENTS,
  markAsRead as MARKASREAD,
} from '../../config/api-modules/api.comments';

export default {
  name: 'single-project-details',
  components: {
    InfoModal,
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  data() {
    return {
      form: {},
      data: null,
      info: null,
      loader: false,
      add_comment_loader: false,
      comment_loader: false,
      error: null,
      comments: [],
    };
  },
  methods: {
    async getproject() {
      this.loader = true;
      GETPROJECT(this.$route.params.id)
        .then((data) => {
          this.data = data.data;
        })
        .catch(() => {
          this.data = null;
        })
        .finally(() => {
          this.loader = false;
        });
    },

    fetchComments() {
      this.comment_loader = true;
      const payload = {};
      payload.mapping = this.$route.params.id;
      GETCOMMENTS(payload)
        .then((data) => {
          this.comments = data.data.results;
          this.comment_loader = false;
          this.$toast.show({
            type: 'SUCCESS',
            message: 'Fetched Comment',
          });
        })
        .catch(() => {
          this.$toast.show({
            type: 'ERROR',
            message: 'Unable to fetch comments',
          });
          this.comment_loader = false;
        });
    },

    submitComments(data) {
      this.add_comment_loader = true;
      const payload = {};
      payload.mapping_ui_page_url = this.$route.path;
      payload.content = data.content;
      payload.sender = this.user.id;
      payload.mapping = this.$route.params.id;
      ADDCOMMENTS(payload)
        .then(() => {
          this.fetchComments();
          this.add_comment_loader = false;
          this.$toast.show({
            type: 'SUCCESS',
            message: 'Comment added',
          });
        })
        .catch(() => {
          this.add_comment_loader = false;
          this.$toast.show({
            type: 'ERROR',
            message: 'Unable to Added comment',
          });
        });
    },

    filterUnreadComment() {
      if (this.comments.length !== 0) {
        const unread = this.comments
          .filter((items) => {
            return items.read === false;
          })
          .map((comment) => {
            return comment.id;
          });
        // mark all unread comments as read: true
        unread.forEach((id) => {
          MARKASREAD(id)
            .then(() => {
              return 0;
            })
            .catch(() => {
              return 0;
            });
        });
      }
    },

    preview(data) {
      this.info = data;
    },
  },
  created() {
    this.getproject();
    this.fetchComments();
  },
  watch: {
    $route() {
      this.getproject();
      this.fetchComments();
    },
    comments() {
      this.filterUnreadComment();
    },
  },
};
</script>

<style lang="scss">
$text-color: #707070;

* {
  font-family: 'Roboto';
}
h6 {
  font-weight: bold;
  color: $text-color;
}

p {
  color: $text-color;
}
</style>
