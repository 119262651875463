<template>
  <div class="w-100 desc p-4">
    <p class="text-left font-weight-bold text-black-50">Implementing organization</p>
    <p class="text-left mt-n2">{{ projectDetails.organization.name }}</p>

    <p class="text-left font-weight-bold text-black-50">Collaborating organization</p>
    <p v-if="projectDetails.collaborating_partners.length < 1">No organization available</p>
    <p
      v-else
      v-for="(org, index) in projectDetails.collaborating_partners"
      :key="index"
      class="text-left mt-n2"
    >
      {{ org.organization__name }}
    </p>

    <p class="text-left font-weight-bold text-black-50">Funding agency</p>
    <p v-if="projectDetails.funders.length < 1">No organization available</p>
    <p v-else v-for="(org, index) in projectDetails.funders" :key="index" class="text-left mt-n2">
      {{ org.organization__name }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.desc {
  background-color: #f7f7f7;
}
</style>
