<template>
  <div :style="`width: ${width}`">
    <ValidationProvider
      :mode="rules || rules.includes('required') ? 'aggressive' : 'passive'"
      tag="div"
      :rules="`${currentValue === selected ? `select:${selected}` : `${rules}`}`"
      :name="name"
      :vid="vid"
      v-slot="{ errors, ariaInput, ariaMsg }"
    >
      <!-- :rules="rules" -->
      <b-form-group
        :id="name"
        :label="label"
        :label-for="name"
        class="text-left font-weight-bold text-dark mb-1 mt-4"
      >
        <p class="text-black-50 font-weight-lighter mb-1 mt-n1">{{ description }}</p>
        <select :style="`height: ${height}`" :id="name" v-model="currentValue" v-bind="ariaInput">
          <option :value="selected" :disabled="disabled" selected>{{ selected }}</option>
          <option v-for="(item, index) in items" :key="index" :value="item">
            <span v-if="track === ''">{{ item }}</span>
            <span v-else>{{ item[track] }}</span>
          </option>
        </select>
      </b-form-group>
      <p
        class="text-danger text-left mt-n1 pb-0 mb-0 font-weight-light"
        v-bind="ariaMsg"
        v-if="errors[0]"
      >
        <small> {{ errors[0] }} </small>
      </p>
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate';

// manually check if the select value is === placeholder (selected/disabled) option
extend('select', {
  validate(value, args) {
    return value === args;
  },
  // eslint-disable-next-line quotes
  message: `This field is required`,
});

export default {
  name: 'BaseSelect',
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: [String, Object, Array, Number],
      default: '',
    },
    track: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => ['item1', 'item2', 'item3', 'item4'],
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '98%',
    },
    height: {
      type: String,
      default: '2.5rem',
    },
    selected: {
      type: String,
      default: 'Select',
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    vid: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  data: () => ({}),
  // watch: {
  //   /**
  //    * allows us to use v-model on our input
  //    * @param {*} val
  //    */
  //   currentValue(val) {
  //     this.$emit('input', val);
  //   },
  // },
  mounted() {
    this.currentValue = this.selected;
  },
  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style scoped>
select,
select.form-control {
  background: transparent;
  border: none;
  border: 1px solid #707070;
  -webkit-box-shadow: none;
  outline: none;
  box-shadow: none;
  border-radius: 0;
}

select {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0;
  font-family: inherit;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  color: #707070;
  outline: none;
}

select:focus {
  background: transparent;
  border: none;
  border: 1px solid #707070;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  outline: none;
}

select:focus,
select.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
