<template>
  <b-card overlay :img-src="image" img-alt="Card Image" text-variant="white">
    <div class="card-img-overlay text-center pt-5" style="background-color: rgba(0, 0, 0, 0.3)">
      <h1 class="text-white">
        {{ heading }}
      </h1>
      <b-row class="pt-5 justify-content-center">
        <b-col cols="6">
          <base-button
            :text="buttonOneText"
            width="100%"
            height="3.5rem"
            :to="buttonOneLink"
            radius="0.15rem"
            class="exploredatasets"
          />
        </b-col>
        <b-col cols="6">
          <base-button
            :text="buttonTwoText"
            width="100%"
            height="3.5rem"
            :to="buttonTwoLink"
            radius="0.15rem"
            class="submitdatasets"
          />
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    buttonOneLink: {
      type: String,
      required: true,
    },
    buttonOneText: {
      type: String,
      required: true,
    },
    buttonTwoText: {
      type: String,
      required: true,
    },
    buttonTwoLink: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-img-overlay {
  background-color: rgba(#000, 0.5);
}
.exploredatasets {
  width: auto !important;
  font-size: x-small !important;
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: inherit;
  }
}
</style>
