<template>
  <b-link :to="to">
    <div id="card-section">
      <div class="card shadow">
        <div class="card-body">
          <slot name="image"></slot>
          <h6 class="font-weight-bold my-4" style="color: black">
            <slot name="paragraph"></slot>
          </h6>
          <p class=" my-4">
            <slot name="subtitle"></slot>
          </p>
          <p class="text-primary my-4">
            <slot name="subtitle2"></slot>
          </p>
        </div>
      </div>
    </div>
  </b-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
    },
  },
  name: 'cards',
};
</script>
<style scoped>
* {
  color: #242424;
  letter-spacing: 0px;
  font-family: 'Roboto';
}
.card {
  height: 250px !important;
}
</style>
