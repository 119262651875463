import axios from '@/plugin/axios';
import Routes from './routes';

export const getOrganizations = () => {
  return axios.get(Routes.organization, {
    params: {
      page: 1,
      size: 1000,
    },
  });
};

export const getSingleOrganizations = (id) => axios.get(`${Routes.organization}${id}`);

export const getProgramArea = () =>
  axios.get(Routes.program_areas, {
    params: {
      page: 1,
      size: 1000,
    },
  });

export const addOrganization = (payload) => {
  const data = payload;

  if (payload.status === 'active') {
    data.status = true;
  } else data.status = false;
  return axios.post(Routes.organization, data);
};

export const updateOrganization = (id, payload) => axios.put(Routes.organization + id, payload);

export const patchOrganization = (id, payload) => axios.patch(Routes.organization + id, payload);

export const news = () => axios.get(Routes.news);
