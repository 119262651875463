<template>
  <b-container>
    <b-col cols="12" md="9" lg="6" class="mx-auto">
      <b-card
        footer-bg-variant="white"
        style="max-width: auto; border: 0.5px solid rgba(0, 0, 0, 0.125)"
        class="mb-3 bg-white text-decoration-none"
      >
        <b-alert
          :show="displaymodal"
          variant="warning"
          class="warningprompt"
          @dismissed="dismissAlert"
          fade
          >Please Login in order to access submit data set feature</b-alert
        >
        <div class="px-0 px-md-4">
          <b-card-title class="text-center font-weight-bold h5 pt-4"> Login </b-card-title>
          <ValidationObserver v-slot="{ passes }">
            <form @submit.prevent="passes(LoginButton)">
              <base-select
                v-model="type"
                height="3rem"
                placeholder="Type"
                width="100%"
                :items="user_type"
                rules="required"
                name="Type"
              />
              <base-input
                v-model="email"
                height="3rem"
                placeholder="Email address"
                width="100%"
                rules="required|email"
                name="Email address"
              />
              <base-input
                v-model="password"
                height="3rem"
                placeholder="Password"
                width="100%"
                :type="password ? 'password' : 'text'"
                rules="required"
                name="Password"
                prependclass="mt-1"
              />
              <div class="d-flex justify-content-between pt-2 pb-2">
                <div>
                  <label>
                    <input v-model="currentValue" type="checkbox" value="" />
                    Stay Logged in
                  </label>
                </div>
                <div>
                  <b-link class="text-right text-decoration-none" to="/auth/reset-password">
                    Forgot Password?
                  </b-link>
                </div>
              </div>
              <div>
                <!-- <b-link class="text-right text-decoration-none" to="/inventory/forgot-password">
                  Forgot Password?</b-link
                > -->
              </div>
              <button type="submit" class="sharp" :disabled="loading">
                <b-spinner v-show="loading" small />
                Login
              </button>
            </form>
          </ValidationObserver>
          <!-- <div class="mt-2">
            <span>Don't have an account?</span> <b-link to="/auth/register">Register</b-link>
          </div> -->
        </div>
      </b-card>
    </b-col>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import CustomToast from '../components/CustomToast.vue';

export default {
  data() {
    return {
      type: '',
      password: true,
      email: '',
      loading: false,
      currentValue: '',
      user_type: ['user', 'organization', 'admin'],
      user: null,
      displaymodal: false,
      submitdatasetroute: null,
    };
  },

  mounted() {
    // Get user from local storage to know if they're logged in
    const authenticated = localStorage.getItem('vuex');
    const submitdataset = localStorage.getItem('submitdataroute');
    this.user = JSON.parse(authenticated);
    this.submitdatasetroute = submitdataset;

    // If a user tries to access the submit dataset page when not authenticated
    // this throws a prompt to the user that they need to be logged in to access this page.

    if (this.user.auth.user === null && this.submitdatasetroute === '/inventory/submit-dataset') {
      this.displaymodal = true;
    }

    // set the 5 seconds for the prompt to be dismissed
    setTimeout(() => {
      this.displaymodal = false;
    }, 20000);
  },

  computed: {
    ...mapGetters('auth', ['role']),

    // to display prompt if user is logged in

    // displayprompt() {
    //   if (this.user.auth.user=== null) {
    //     return true;
    //   }

    //   return false;
    // },
  },
  methods: {
    ...mapActions('auth', [
      'LOGIN',
      'REGISTRATION',
      'SET_ROLE', // -> this.LOGIN()
    ]),

    dismissAlert() {
      this.displaymodal = false;
    },

    async LoginButton() {
      this.loading = true;
      await this.SET_ROLE('ngo');
      await this.$router.push('/ngo');

      try {
        const data = await this.LOGIN({
          password: this.password,
          username: this.email,
          type: this.type,
        });
        console.log('data', data);

        if (data.status === 200) {
          /**
           * set the roles in the Vue gates plugin
           */
          console.log('type', 'organize');
          await this.SET_ROLE('ngo');
          await this.$router.push('/dataset');

          if (
            this.type === 'organization'
            //  &&
            // data.data.organization_type === 'Non Governmental Organization'
          ) {
            this.$router.push('/ngo');
            this.SET_ROLE('ngo');
          } else {
            this.SET_ROLE(this.type);
          }

          this.$gates.setRoles([this.role]);

          if (data.data.user_type === 'admin') {
            console.log('to push to the admin');
            this.$router.push('/admin/project');
          }
          if (data.data.user_type === 'ngo') {
            console.log('to push to the ngo');
            this.$router.push('/organizations');
          }

          // If a user is coming from this path the user will be redirected back to it on succesful login
          if (
            data.data.user_type === 'ngo' &&
            this.submitdatasetroute === '/inventory/submit-dataset'
          ) {
            this.$router.push('/inventory/submit-dataset');
          }
        } else {
          this.$router.push('/');
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.loginshadow {
  box-shadow: 4px 4px 2px -2px rgb(192, 190, 190);
  border: 1px solid #fcfafa;
}

b-modal {
  text-align: center !important;
}
button {
  display: inline-block;
  /* padding: 10px 29px; */
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  width: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  /* padding: 10px 16px; */
  outline: none;
}

.sharp {
  /* width: 12em; */
  color: #fff;
  background-color: #30ab4c;
  border-color: #0b7022;
}

.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open .dropdown-toggle.sharp {
  /* border-radius: 0; */
  color: #fff;
  background-color: #30ab4c;
  outline: none;
  border-color: #30ab4c;
}

/***********************
  SHARP BUTTONS
************************/
.sharp {
  outline: none;
  border-radius: 0;
}

/***********************
  CUSTON BTN VALUES
************************/

button {
  padding: 0.7rem;
  outline: none;
  border: 0 none;
  /* font-weight: 700; */
  letter-spacing: 1px;
}
button:focus,
button:active:focus,
button.active:focus {
  outline: 0 none;
}

.sharp {
  background: #30ab4c;
  color: #ffffff;
}
.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open > .dropdown-toggle.sharp {
  background: #30ab4c;
}
.sharp:active,
.sharp.active {
  background: #45cc64;
  box-shadow: none;
}
.save:active,
.save.active {
  background: #d8f7e0;
  color: #30ab4c;
  box-shadow: none;
}

.close {
  width: fit-content;
}
.warningprompt {
  text-align: center;
}
</style>
