import Home from './views/home.vue';
import organizationProject from './views/organizationProject.vue';
import index from './index.vue';

export default [
  {
    path: '/health_partner',
    component: index,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: '',
        component: Home,
      },
      {
        path: ':id',
        name: 'health_partner',
        component: organizationProject,
      },
    ],
  },
];
