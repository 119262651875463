<template>
  <div class="index">
    <BaseLogoBar
      style="
        position: fixed;
        top: 0;
        z-index: 3500;
        background-color: #fff;
        width: 100%;
        height: 100px;
      "
    />

    <BaseNavbar
      style="position: fixed; top: 100px; z-index: 3000"
      :isToggle="isToggle"
      @handleToggle="handleToggle"
    />

    <div class="d-flex content">
      <transition name="slide">
        <aside class="sidebar" v-if="isToggle">
          <div class="main-left">
            <Span style="font-size: x-large" class="close" @click="handleToggle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-x-square"
                viewBox="0 0 16 16"
              >
                <path
                  d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                />
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </Span>
            <div class="user">
              <img src="@/assets/user-icon.svg" /><br />
              <h5>
                <b>{{ user.username }}</b>
              </h5>
              <p style="color: #707070">{{ user.email }}</p>
            </div>
            <span v-if="!isDataAdmin && !isNGOAdmin">
              <router-link to="/admin/project">PROJECT INVENTORY</router-link></span
            >
            <span v-if="!isNGOAdmin"
              ><router-link to="/admin/data">DATA INVENTORY</router-link></span
            >
            <span v-if="!isDataAdmin && isNGOAdmin"
              ><router-link to="/admin/ngo">NGO INVENTORY</router-link></span
            >
            <span v-if="!isDataAdmin && !isNGOAdmin"
              ><router-link to="/admin/stakeholder">STAKEHOLDER INVENTORY</router-link></span
            >
            <span v-if="!isNGOAdmin"
              ><router-link to="/admin/datarequest">DATA REQUEST INVENTORY</router-link></span
            >
            <!-- <span><router-link to="/admin/feedback">FEEDBACK</router-link></span>
        <span><router-link to="/admin/enquiries">ENQUIRES</router-link></span> -->
            <span><router-link to="/admin/faq">FAQ</router-link></span>
            <span><router-link to="/admin/activities">ACTIVITIES</router-link></span>
            <span v-if="!isDataAdmin && !isNGOAdmin"
              ><router-link to="/admin/user">USER MANAGEMENT</router-link></span
            >
            <span @click="logoutButton()" class="logout"><div>SIGN OUT</div></span>
          </div>
        </aside>
      </transition>

      <div class="main-content">
        <div class="container projectscontainer" :style="{ marginLeft: projectscontainer }">
          <span v-if="showtaskbar" @click="handleToggle" style="margin-left: 6%">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-forward-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="m9.77 12.11 4.012-2.953a.647.647 0 0 0 0-1.114L9.771 5.09a.644.644 0 0 0-.971.557V6.65H2v3.9h6.8v1.003c0 .505.545.808.97.557z"
              />
            </svg>
          </span>
          <router-view class="main-right-view" />
          <BaseFooter style="width: 100%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      isToggle: true,
      isDataAdmin: false,
      isNGOAdmin: false,
      projectscontainer: '0%',
      tabBtnLeft: '4rem',
      showtaskbar: false,
      tabBtnRight: '0px',
    };
  },

  methods: {
    ...mapActions('auth', ['LOGOUT']),

    async logoutButton() {
      await this.LOGOUT();
      this.$router.reload();
      this.$gates.setRoles([]);
      this.$forceUpdate();
      this.$router.push('/');
    },
    handleToggle() {
      this.isToggle = !this.isToggle;
      this.showtaskbar = !this.showtaskbar;
    },
    showTaskbar() {
      this.isToggle = !this.isToggle;
      this.showtaskbar = !this.showtaskbar;
    },
  },

  async mounted() {
    console.log('Admin user', this.user.admin_type);
    // check the type of Admin to conditionally render some pages
    if (this.user.admin_type === 'Data Admin') {
      this.isDataAdmin = true;
      this.$router.push('/admin/data');
      this.category = 'DATA';
    }

    if (this.user.admin_type === 'Non-Governmental Organization Admin') {
      this.isNGOAdmin = true;
      this.$router.push('/admin/ngo');
    }
  },

  // making the admin dashboard only accessible to admins
  beforeMount() {
    if (this.user.user_type !== 'admin') {
      this.$router.push('/');
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
    mainContent() {
      return this.marginLeft;
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;
}
.main {
  // display: grid;
  // width: 400px;
  // grid-template-columns: 300px auto;
  display: flex;
  height: 100%;
}
.slide-enter-active {
  transition: transform 0.3s ease-out;
}

.slide-enter {
  transform: translateX(-100%);
}

.slide-leave-active {
  transition: transform 0.3s ease-in;
}

.slide-leave-to {
  transform: translateX(-100%);
}
.large {
  padding: 20px;
  background: transparent
    linear-gradient(22deg, #30ab4c52 0%, #2fa94b51 0%, #2ea74a50 6%, #1856261c 100%) 0% 0% no-repeat
    padding-box;
  height: 100vh;
  width: 25.4%;
  // flex:1;
  opacity: 1;
  padding: 50px 20px 20px 20px;
  position: fixed;
  top: 135px;
  left: 0;
  // overflow: auto;
}
.main-left {
  display: flex;
  // margin-top:200px;
  flex-direction: column;
  padding: 120px 0px 0px 10px;
  justify-content: flex-start;
}
.main-left span {
  font: normal normal bold 14px Roboto;
  margin: 20px 0px 0px 60px;
}
.main-left a {
  color: #0a0a0a;
  margin: 0px;
  padding: 0px;
}

.main-right-view {
  padding: 20px;
  width: 100%;
}
.main-right {
  // flex:3;
  width: 75%;
  margin-left: 25%;
  margin-top: 150px;
}
a:hover {
  border-bottom: 4px solid #2da047;
}
a.router-link-active {
  border-bottom: 4px solid #2da047;
}
.user {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-self: flex-start;
}

.logout {
  cursor: pointer;
}
.logout:hover {
  border-bottom: 4px solid #2da047;
  width: 100px;
}
.toggle {
  display: none;
}
.responsive {
  display: none;
}
// .base {
//   display: none;
// }
@media (max-width: 900px) {
  .toggle {
    display: inline-block;
  }
  .responsive {
    display: flex;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // .responsive .main-left {
  //   position: fixed;
  //   flex-direction: column;
  //   width: 70%;
  //   height: auto;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   background-color: #d0e0d4;
  //   z-index: 1090;
  // }
  .main-right-view {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-right {
    // display: flex;
    width: 100%;
    margin-left: 0;
    margin-top: 150px;
  }
  .main-left {
    height: 100%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 10px;
    justify-content: center;
    margin: 100px 0 0 auto;
    top: 0px;
    bottom: 0px;
    overflow-x: hidden;
    z-index: 1000;
  }

  .tag {
    margin: 5px;
  }
  .main {
    position: relative;
    display: flex;
    /* flex-direction: column; */
    transition: 2s all ease-in;
  }
  .large {
    display: none;
    padding: 20px;
    background: transparent
      linear-gradient(22deg, #30ab4c52 0%, #2fa94b51 0%, #2ea74a50 6%, #1856261c 100%) 0% 0%
      no-repeat padding-box;
    height: 100vh;
    width: 25.4%;
    // flex:1;
    opacity: 1;
    padding: 50px 20px 20px 20px;
    position: fixed;
    top: 135px;
    left: 0;
    // overflow: auto;
    z-index: 1000;
  }
}

.sidebar {
  position: fixed;
  height: 100%;
  padding-top: 10%;
  background: linear-gradient(22deg, #30ab4c 0%, #2fa94b51 0%, #2ea74a50 6%) 0% 0% no-repeat
    padding-box;
  width: 25.4%;
  // flex:1;
  opacity: 1;
  padding: 50px 20px 20px 20px;
  // overflow: auto;
  z-index: 1000;
  overflow-y: auto;
  transition: transform 0.3s ease-out;
}
.projectscontainer {
  margin-left: 20.5%;
}
.main-content {
  flex: 1;
  padding-top: 10%;
  margin-left: 25%;
}

.close {
  display: none;
}
.container {
  padding: 0%;
}
@media screen and (max-width: 600px) {
  .main-left {
    padding-top: 8rem;
  }
  .projectscontainer {
    margin-left: 16.4%;
    padding-top: 20%;
  }
  .sidebar {
    background: aliceblue;
  }
  .main-content {
    padding-top: 50%;
    margin-left: 0%;
  }
  .main-left span {
    font-size: xx-small;
    margin-top: 20px;
    margin-left: 0px;
    width: fit-content;
  }
  .user,
  h5 {
    font-size: x-small;
  }
  .sidebar {
    padding-left: 10px;
    width: 35%;
  }
  .close {
    display: block;
  }
}
</style>
