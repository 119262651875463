<template>
  <b-navbar toggleable="sm" variant="primary" type="light" :style="{ marginTop: navMarginTop }">
    <!-- Commented toggler to hide sidebar when logged in as admin -->

    <!-- <span class="toggle" v-if="isLoggedIn && isAdmin" @click="$emit('handleToggle')">
      <i class="fa-solid fa-bars"></i>
    </span> -->
    <b-navbar-toggle target="nav-collapse"> </b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav
        class="justify-content-between w-100"
        style="margin-left: 48px; margin-right: 70px"
      >
        <b-nav-item
          v-for="(nav, index) in navBarLinks"
          :key="index"
          :to="nav.link"
          style="margin-right: 30px"
          >{{ nav.title }}</b-nav-item
        >
        <!-- {{ isLoggedIn }}
        {{ isAdmin }} -->
        <template v-if="!isLoggedIn && !isAdmin">
          <b-nav-item to="/auth/login" class="mx-2" id="login">LOGIN</b-nav-item>
        </template>
        <template v-if="isLoggedIn && isAdmin">
          <b-nav-item to="/admin/project" class="mx-2" id="login">ADMIN</b-nav-item>
        </template>
        <template v-if="isLoggedIn && !isAdmin">
          <b-nav-item to="/organizations" v-role:any="'organization|ngo'" class="mx-2"
            >DASHBOARD</b-nav-item
          >
          <!-- <b-nav-item to="/ngo/admin" v-role="'admin'" class="mx-2">DASHBOARD </b-nav-item> -->
          <b-nav-item to="/organizations" v-role="'user'" class="mx-2">DASHBOARD</b-nav-item>
        </template>
        <b-nav-item v-if="isLoggedIn">
          <!-- <b-icon icon="bell-fill" id="my-button"></b-icon>
          <span class="badge-notify">{{ comments.length }}</span> -->
          <b-popover placement="bottomleft" target="my-button">
            <div class="container">
              <p class="h5 font-weight-bold">NOTIFICATION {{ comments.length }}</p>
              <div v-for="(comment, index) in comments.slice(0, 4)" :key="index">
                <router-link :to="'/ngo/project/' + comment.mapping.id">
                  <div class="d-flex w-100">
                    <div class="d-flex mr-4">
                      <i class="fa fa-user-circle ml-0 h1 mr-2"></i>
                      <div>
                        <p class="my-0 text-truncate">{{ comment.content }}</p>
                        <p class="font-weight-bold ml-0">
                          {{ comment.mapping.name }}
                        </p>
                      </div>
                    </div>
                    <div class="">
                      <p class="my-0">
                        {{ comment.mapping.created_at | dayFormat }}
                      </p>
                      <p>{{ comment.mapping.created_at | timeFormat }}</p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </b-popover>
        </b-nav-item>
        <b-nav-item v-if="isLoggedIn">
          <div class="drop-down">
            <i class="fa fa-user-circle mx-2" aria-hidden="true"></i>
            <span class="font-weight-normal">
              {{ getName }}
            </span>

            <i class="fa fa-caret-down"></i>
            <div class="content text-center">
              <a @click="logoutButton()">Logout</a>
              <!-- <a>Settings</a> -->
              <!-- <a>Help</a> -->
            </div>
          </div>
        </b-nav-item>

        <!-- <b-nav-item>
          <b-dropdown size="md" variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content
              ><i class="fa fa-user-circle text-white h5 mx-2"></i>
              <span class="text-white" v-if="user.details !== undefined">
                {{ user.details.contact_person_first_name }}
              </span>
              <span class="text-white" v-if="user.full_name !== undefined">
                {{ user.details.contact_person_first_name }}
              </span>
              <i class="fa fa-caret-down text-white"></i>
            </template>
            <b-dropdown-item href="#" class="text-center">Settings</b-dropdown-item>
            <b-dropdown-item href="#" class="text-center">Help</b-dropdown-item>
            <b-dropdown-item @click="LOGOUT()" class="text-center">Logout</b-dropdown-item>
          </b-dropdown>
        </b-nav-item> -->
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { notification as NOTIFICATION } from '../../modules/ngo/config/api-modules/api.comments';

export default {
  data() {
    return {
      options: {
        content: null,
        html: true,
      },
      comments: [],
      navBarLinks: [
        {
          title: 'HEALTH PARTNERS',
          link: '/health_partner',
        },
        {
          title: 'PROGRAM AREAS',
          link: '/program-areas',
        },
        // {
        //   title: 'GAP ANALYSIS',
        //   link: '/',
        // },
        {
          title: 'INVENTORY DASHBOARD',
          link: '/inventory-dashboard',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['user', 'isLoggedIn', 'isAdmin']),

    getName() {
      if (this.user.username) {
        return this.user.username;
      }
      return this.user.name;
    },
    // To adjust the css property for the baselogo bar when the logged in as admin and on mobile screen
    navMarginTop() {
      return window.innerWidth <= 600 ? '25%' : '0';
    },
  },
  methods: {
    ...mapActions('auth', ['LOGOUT']),
    async logoutButton() {
      await this.LOGOUT();
      this.$gates.setRoles([]);
      this.$forceUpdate();
      this.$router.push('/');
    },
  },
  mounted() {
    if (this.user) {
      NOTIFICATION(this.user.id)
        .then((res) => {
          const unreadComments = res.data.results.filter((data) => {
            return data.read === false;
          });
          this.comments = unreadComments;
        })
        .catch(() => {
          this.comments = [];
        });
    }
  },
  props: {
    isToggle: Boolean,
  },
};
</script>

<style lang="css" scoped>
.navbar {
  width: 100vw;
}
.badge-notify {
  background: red;
  color: white;
  position: relative;
  top: -10px;
  border-radius: 10px;
  left: -5px;
}
.hover:hover {
  background-color: gray;
}
.navbar-light .navbar-nav .nav-link {
  color: white !important;
}
.fixed-top-2 {
  margin-top: 5rem;
}
.badge-notify {
  background: red;
  position: relative;
  top: -10px;
  border-radius: 20px;
  left: -5px;
}
.hover:hover {
  background-color: gray;
}
.navbar-light .navbar-nav .nav-link {
  color: white !important;
}
.fixed-top-2 {
  margin-top: 5rem;
}
.content {
  display: none;
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.content a {
  display: flex;
  justify-content: center;
  direction: horizontal;
  color: black;
  margin: 10px;
}
.content a:hover {
  color: black;
}

.drop-down {
  z-index: 1000;
}

.drop-down:hover .content {
  display: block;
}

.big-content {
  font-family: 'Roboto';
  font-size: 0.886rem;
  display: none;
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  min-width: 600px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: black;
  padding: 10px;
  right: 200px;
  top: 38px;
}
.drop-down:hover .big-content {
  display: block;
  color: black;
}

p {
  color: #707070;
}
.toggle {
  display: none;
}

.navbar-light .navbar-toggler {
  width: auto;
}
@media (max-width: 900px) {
  .toggle {
    display: flex;
  }
  .fa-solid {
    color: #ffffff;
    font-size: 30px;
  }
}
</style>
