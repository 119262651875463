<template>
  <div class="my-3">
    <div v-for="info in infos" :key="info.id">
      <b-card>
        <b-card-text>
          {{ info.title }}
        </b-card-text>

        <b-card-text> <span>Name of organisation: </span> {{ info.organisation }} </b-card-text>

        <b-card-text> <span>Branch: </span> {{ info.branch }} </b-card-text>

        <b-card-text> <span>Category: </span> {{ info.category }} </b-card-text>

        <b-card-text> <span>Role: </span> {{ info.role }} </b-card-text>

        <div class="w-100 d-flex align-items-center justify-content-between">
          <a href="#" class="card-link">Submitted {{ info.date }}</a>
          <b-link href="/admin/stakeholder-details" class="card-link">See Details >></b-link>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'stakeholderList',
  props: {
    infos: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.card {
  margin-bottom: 30px;
  width: 95%;
  height: auto;
  border: none;
  box-shadow: 0px 12px 10px #3e3d3d1a;
}
.card-link {
  color: #2da047;
}
.card-text {
  margin: 20px !important;
}
.card-text span {
  font-weight: 600 !important;
}
.card-text:nth-child(1) {
  font-weight: 800 !important;
}
.sub-title {
  margin-left: 20px !important;
}

.card-link:nth-child(1) {
  margin-left: 20px;
}
</style>
