import Vue from 'vue';
import moment from 'moment';

Vue.filter('dayFormat', (value) => {
  return moment(String(value)).format('dddd');
});

Vue.filter('formatDate', (value) => {
  return moment(String(value)).format('MMMM Do YYYY, h:mm a');
});

Vue.filter('monthsFormat', (value) => {
  return moment(String(value)).format('MMMM');
});

Vue.filter('monthsYearFormat', (value) => {
  return moment(String(value)).format('MMMM [] YYYY');
});

Vue.filter('timeFormat', (value) => {
  return moment(String(value)).format('hh:mm a');
});

Vue.filter('commentDateFormat', (value) => {
  return moment(String(value)).format('l');
});
