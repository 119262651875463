<template>
  <div class="project">
    <!-- projects pangination -->
    <div class="admin-nav">
      <ul class="nav">
        <li>
          <a href="#">Home ></a>
        </li>
        <li>
          <a href="#">NGO Inventory ></a>
        </li>
        <!-- <li>
          <a class="active" href="#">All Projects</a>
        </li> -->
      </ul>
      <div class="tab-btn">
        <!-- <button @click="changeAll()" :class="[isAll ? 'btn-active' : '']">All NGO's</button> -->
        <button @click="changePend()" :class="[isPending ? 'btn-active' : '']">
          Pending NGO's
        </button>
        <button @click="changeApprov()" :class="[isApproved ? 'btn-active' : '']">
          Approved NGO's
        </button>
      </div>
      <b-col md="4">
        <base-input v-model="search" label="Search NGO" />
      </b-col>
    </div>

    <!-- <input type="text" v-model="search" placeholder="Search title.."/> -->
    <!-- class="float-right"  -->

    <br />

    <div class="container">
      <div class="row">
        <div class="col-md-9">
          <div>
            <b-row class="p-0" no-gutters align-h="between">
              <b-col cols="12" sm="9">
                <div v-if="loading" class="text-center loading-icon">
                  <b-spinner v-if="loading" x-large></b-spinner>
                </div>
                <div v-else>
                  <div>
                    <div v-for="(ngo, index) in ComputedNgos" :key="index">
                      <NgoCard :id="ngo.id">
                        <template slot="body">
                          <b-card-title class="text-left font-weight-bold text-black-50 h5">
                            {{ ngo.name }}
                          </b-card-title>
                          <b-card-text class="text-left text-black-50">
                            Contact email: {{ ngo.contact_email }}
                            <br />
                            Contact name: {{ ngo.contact_name }}
                            <!-- Capacity building for Local Governments and States’ Ministries, Departments
                      and Agencies (MDAs) responsible for rural water supply and sanitation
                      intervention. -->
                          </b-card-text>
                        </template>
                      </NgoCard>
                    </div>
                  </div>
                  <div v-if="ngos.length === 0">
                    No NGO's available
                    <img src="../../../../assets/no-data.svg" alt="" width="500px" />
                  </div>

                  <!-- DataRequest card -->

                  <div v-if="isDatarequest">
                    <DataRequestCard
                      v-for="(datarequest, index) in datarequests"
                      :key="index"
                      :to="`/inventory/dataset-view/${datarequest.id}`"
                      :seemorelink="true"
                      :date="datarequest.created_at"
                      :id="datarequest.id"
                    >
                      <template slot="body">
                        <b-card-title class="text-left font-weight-bold text-black-50 h5">
                          {{ datarequest.name }}
                        </b-card-title>
                        <b-card-text class="text-left text-black-50">
                          Capacity building for Local Governments and States’ Ministries,
                          Departments and Agencies (MDAs) responsible for rural water supply and
                          sanitation intervention.
                        </b-card-text>

                        <div class="text-left mt-4">
                          <span class="font-weight-bolder text-black-50"
                            >Period of Implementation:</span
                          >
                          <span class="ml-1 text-black-50"
                            >{{ getYear(datarequest.start_date) }} -
                            {{ getYear(datarequest.end_date) }}</span
                          >
                        </div>

                        <div class="text-left mt-4">
                          <span class="font-weight-bolder text-black-50">Programs Area:</span>
                          <span
                            v-for="(program, index) in datarequest.program_area"
                            :key="index"
                            class="ml-1 text-primary text-decoration-none"
                            ><span v-if="index !== datarequest.program_area.length - 1"
                              >{{ program }},</span
                            >
                            <span v-else>{{ program }}</span></span
                          >
                        </div>
                      </template>
                    </DataRequestCard>
                  </div>
                </div>
              </b-col>
              <!-- <b-col cols="3" class="pl-5 d-none d-sm-block">
          <FilterSideBar />
        </b-col> -->
            </b-row>
            <!-- <div class="mx-auto text-center"> -->
            <div class="d-flex justify-content-center align-items-center mt-4">
              <p class="mt-1 mx-2">Row per page</p>
              <select id="zone" v-model="perPage" style="width: 50px; height: 40px">
                <option value="3">3</option>
                <option value="5">5</option>
                <option value="10">10</option>
              </select>
              <p class="mx-3 mx-4 h5">
                <!-- {{ currentPage * perPage }} -->
                <span>Page </span>{{ currentPage }} of
                {{ Math.ceil(computedData.length / perPage) }}
              </p>
              <b-pagination
                v-model="currentPage"
                :total-rows="computedData.length"
                :per-page="perPage"
                aria-controls="my-table"
              />
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <FilterSideBar @filterObjs="fetchDataBasedOnSelectedRequirements" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.project {
  padding: 30px 0px 0px 20px;
}
.admin-nav {
  background: #fff;
  top: 0;
  left: 0;
  z-index: 1030;
  position: sticky;
  width: 100%;
}
h4 {
  color: #707070;
}
.paragraph {
  color: #707070;
  padding-top: 20px;
}
.card-section {
  padding-top: 20px;
}
.card {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  width: 1000px;
  height: 300px;
  padding: 20px 30px 0px 20px;
  margin: 0 auto;
}
.card-section {
  margin-bottom: 25px;
}
.card-bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}
button {
  border-radius: 10px 12px 1px 0px;
  border: 1px solid#2DA047;
  background: white;
  color: #2da047;
  width: 230px;
  font-size: 15px;
  // background: #2da047 0% 0% no-repeat padding-box;
}
.tab-btn {
  display: flex;
  padding-top: 25px;
}
.btn-active {
  background: #2da047 0% 0% no-repeat padding-box;
  color: #fff;
}
.overflow-auto {
  display: flex;
  justify-content: center;
}
</style>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import NgoCard from '../../components/Shared/NgoCard.vue';
import DataRequestCard from '../../components/Shared/DataRequestCard.vue';
// import Pagination from '../../components/Shared/Pagination.vue';
import FilterSideBar from '../../components/Shared/NgoFilterSidebar.vue';
import { getOrganizations as ORGANIZATIONS } from '../../../ngo/config/api-modules/api.organization';
// import { isValidES3Identifier } from '@babel/types';

export default {
  components: {
    // Pagination,
    // FilterSideBar,
    DataRequestCard,
    NgoCard,
    FilterSideBar,
  },
  data() {
    return {
      search: '',
      isAll: false,
      isApproved: false,
      isPending: true,
      currentPage: 1,
      loading: false,
      datasets: [],
      perPage: 3,
      datarequests: [],
      isDataset: true,
      isDatarequest: false,
      items: [],
      ngos: [],
    };
  },

  computed: {
    ...mapGetters('DATASET_STORE', ['getProject', 'getFilterData']),
    ...mapGetters('auth', ['user']),
    filteredData() {
      console.log('Filtering the data now');

      const filtered = this.items.filter((item) => {
        return item.type === 'Non governmental Organization';
      });

      console.log('NGO filter', filtered);
      // const filtered = this.items.filter((item) => {
      //   return (
      //     item.state.toLowerCase().indexOf(this.ByState.toLowerCase()) > -1 &&
      //     item.geo_zone.toLowerCase().indexOf(this.ByZone.toLowerCase()) > -1 &&
      //     item.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 &&
      //     item.year.toString().indexOf(this.ByYear.toString()) > -1
      //   );
      // });
      // console.trace(filtered);
      return filtered;
    },

    // filteredList() {
    //   return this.ngos.filter((ngo) => {
    //     return ngo.name.toLowerCase().includes(this.search.toLowerCase());
    //   });
    // },

    ComputedNgos() {
      return this.computedData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    computedData() {
      // console.log(this.projects, 'computed')
      return this.ngos.filter((items) => {
        return items.name.toLowerCase().match(this.search.toLowerCase());
      });
    },
  },

  watch: {
    ngos(val) {
      this.loading = false;
      if (val.length !== 0) {
        this.loading = false;
      }
    },
    getFilterData(val) {
      this.loading = true;

      console.log(val);
      // cactype
      val.cactype.forEach((pro) => {
        this.ngos = this.ngos.filter((proj) => {
          return proj.cac_type === pro;
        });
      });
      this.loading = false;
      this.ngos.forEach((items) => {
        console.log(items);
      });
    },
  },

  methods: {
    async get_data() {
      this.loading = true;
      this.isBusy = true;
      await ORGANIZATIONS()
        .then((data) => {
          this.items = data?.data?.results;
          this.ngos = this.items.filter((item) => {
            return item.type === 'Non governmental Organization';
          });
          // this.ngos = this.items;
          this.total = data?.data?.results.length;
        })
        .catch(() => {
          this.items = [];
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    // async changeAll() {
    //   this.loading = true;
    //   this.isAll = true;
    //   this.isApproved = false;
    //   this.isPending = false;
    //   this.ngos = await this.items;
    //   this.loading = false;
    // },

    async changeApprov() {
      this.loading = true;
      this.isAll = false;
      this.isApproved = true;
      this.isPending = false;
      this.ngos = await this.items.filter((item) => item.approved === true);
      this.loading = false;
    },
    async fetchDataBasedOnSelectedRequirements(data) {
      this.currentPage = 1;
      this.loading = true;
      try {
        const filtered = await this.$dataset.filteredData(data);
        console.log(filtered);
        this.ngos = filtered.data;
        this.total = filtered.data.length;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async changePend() {
      this.loading = true;
      this.isAll = false;
      this.isApproved = false;
      this.isPending = true;
      this.ngos = await this.items.filter((item) => {
        return item.approved !== true;
      });
      this.loading = false;
    },

    async changePage(e) {
      this.loading = true;
      try {
        const datasets = await this.$dataset?.getDatasets(e);
        this.datasets = datasets.data;
        this.currentPage = e;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    getYear(date) {
      return moment(date).format('YYYY');
    },
  },
  async mounted() {
    this.loading = true;
    await this.get_data();
    await this.changePend();
    this.loading = false;
  },
};
</script>
