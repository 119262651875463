<template>
  <div class="container">
    <div class="align-items-center search justify-content-between mx-auto d-flex ms-5">
      <span
        class="add-user d-flex align-items-center justify-content-center my-4"
        style="color: #30ab4c; font-weight: 500; font-size: 22px"
      >
        ANNUAL REPORTS
      </span>
      <form>
        <div class="input-group input-wrap">
          <span class="input-group-append">
            <button class="btn border-o ms-n5" type="button">
              <i class="fa fa-search"></i>
            </button>
          </span>
          <input class="input" type="search" placeholder="Search ..." v-model="searchQuery" />
        </div>
      </form>
    </div>
    <div>
      <AnnualReportsList :infos="infos" :resultQuery="resultQuery" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from '@/plugin/axios';
import AnnualReportsList from '../components/AnnualReportsList.vue';

export default {
  name: 'AnnualReports',
  components: {
    AnnualReportsList,
  },
  data() {
    return {
      infos: [],
      searchQuery: null,
    };
  },
  computed: {
    ...mapGetters('organisation', ['getAllReports']),
    ...mapGetters('auth', ['user']),
    resultQuery() {
      if (this.searchQuery) {
        return this.infos.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every((v) => item.title.toLowerCase().includes(v) || item.year.includes(v));
        });
      }
      return this.infos;
    },
  },
  methods: {
    ...mapActions('organisation', ['GET_REPORTS']),

    async getReports() {
      try {
        const res = await this.GET_REPORTS(this.user.id);
        Promise.all(
          res.data.map(async (el) => {
            const orgData = await axios.get(`/organizations/${el.organization}`);
            this.infos.push({
              ...el,
              orgName: orgData?.data?.name,
              orgInfo: orgData?.data?.user,
            });
          })
        );
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getReports();
  },
};
</script>

<style scoped>
.input-wrap {
  width: 343px;
  height: 48px;
  background: #c2c7c3b5 0% 0% no-repeat padding-box;
  border: 2px solid rgb(134, 129, 129);
  opacity: 0.31;
  outline: none;
}
.input {
  background: transparent;
  border: none;
  width: 85%;
  outline: none;
}
.search {
  width: 85%;
}
@media (max-width: 900px) {
  .search {
    flex-direction: column;
  }
  .container {
    flex-direction: column;
  }
}
</style>
