<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="card w-100 shadow" style="min-height: 230px">
      <div class="card-body d-flex flex-column justify-content-between">
        <div class="card-title text-wrap d-flex align-items-center">
          <img :src="require('../assets/picture.png')" width="80px" alt="" srcset="" class="mx-1" />
          <h6 class="text-primary">{{ organizationObject.name }}</h6>
        </div>
        <ul v-if="projectForOrganizations.length > 0">
          <li v-for="(projOrg, index) in projectForOrganizations" :key="index">
            {{ projOrg.name }}
          </li>
        </ul>
        <div class="d-flex justify-content-end" v-if="projectForOrganizations.length > 0">
          <router-link
            :to="{
              name: 'health_partner',
              params: { id: this.organizationObject.id },
            }"
            ><p class="text-primary">view more</p></router-link
          >
          <!-- <router-link><p class="text-primary">view more</p></router-link> -->
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { getOrganizationProject } from '../services';

export default {
  data() {
    return {
      projectForOrganizations: [],
      loading: false,
    };
  },
  props: {
    organizationObject: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    this.loading = true;
    const { data } = await getOrganizationProject(this.organizationObject.id);
    this.projectForOrganizations = data.results.slice(0, 3); // only get three item
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped></style>
