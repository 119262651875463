<template>
  <div :class="[showsideborder ? 'border-left' : '', 'text-left p-3 h-100']">
    <div>
      <div class="filter-button mt-n2" :style="`position: relative;`">
        <button @click="applyFilter" class="p-2 custom-button mt-4" style="background: #30ab4c">
          <div class="spinner-border" role="status" v-if="loading" style="color: white">
            <span class="sr-only">Loading...</span>
          </div>
          <span v-else style="color: white">Apply Filter</span>
        </button>
      </div>
      <div class="d-flex" style="margin-top: 10%">
        <p class="font-weight-bolder h6 text-black-50 mb-4 mt-n2">FILTERS</p>
        <v-spacer />
      </div>
    </div>
    <!--
    <div>
      <p class="font-weight-bolder regular mb-n4 mt-4 pb-2 h6 text-black-50">STATES</p>
      <base-input
        @input="searchStates"
        v-model="stateToSearch"
        prepend="fa fa-search"
        height="1.8rem"
        prependclass="mt-n1"
        :filled="true"
        class="pb-2"
        placeholder="Search"
        :width="statesSearchWidth ? statesSearchWidth : '90%'"
      />
      <div
        v-if="statesToShowComputed.length > 0"
        :class="statesToShowComputed.length > 4 ? 'states' : ''"
      >
        <base-checkbox
          v-for="(state, index) in statesToShowComputed"
          :key="index"
          class="pl-2 mt-n5"
          :checkboxItem="state.name"
          :item="state"
          v-model="stateSelected"
        />
      </div>
      <p
        v-else
        style="
        font-size: 15px; color: #888888"
      >
        No State found for your search "{{ stateToSearch }}"
      </p>
      <b-link
        v-if="statesToShowComputed.length < 4 && !statesToShowComputed.length < 1"
        class="text-decoration-none"
        @click="showMoreLessStates(true)"
        >See {{ states.length - 4 }} more</b-link
      >
      <b-link
        v-if="statesToShowComputed.length > 4 && !statesToShowComputed.length < 1"
        class="text-decoration-none"
        @click="showMoreLessStates(false)"
        >See less</b-link
      >
    </div> -->

    <div class="w-100">
      <p class="font-weight-bolder mt-4 pb-2 h6 text-black-50">CAC TYPE</p>
      <base-checkbox
        v-for="(cac_type, index) in ['For Profit', 'Non profit']"
        :key="index"
        class="pl-2 mt-n5"
        :checkboxItem="cac_type"
        :item="cac_type"
        v-model="cacTypeSelected"
      />
    </div>
  </div>
</template>

<script type="js">
import { mapGetters, mapActions, mapMutations } from 'vuex';
// import baseButton from '../../../../components/FormInputs/_base-button.vue';

export default {
  name: 'ProjectFilters',
  // components: { baseButton },
  props: {
    showsideborder: {
      type: Boolean,
      default: true,
    },
    statesSearchWidth: {
      type: String,
      default: null,
    },
    filterButtonAlign: {
      type: String,
      default: '7rem',
    },
    accessLevel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      allProjData: [],
      statesProj: [],
      newStatesProj: [],
      loading: false,
      stateToSearch: '',
      statesToShow: [],
      progamAreasSelected: [],
      programsAreaSelectedToSend: [],
      stateSelected: [],
      stateToSend: [],
      accessLevelSelected: [],
      accessLevelToSend: [],
      cacTypeSelected: [],
      cacTypeToSend: [],
      healthSystemAreasSelected: [],
      healthSystemAreasToSend: [],
      sensitivity: [],
      states: [],
      healthSystemAreaToShow: [],
      healthSystemAreaFormatted: [],
      programsAreaToShow: [],
      programsAreaFormatted: [],
    };
  },
  methods: {
    ...mapActions('DATASET_STORE', ['SETPROGRAMAREAS', 'SETSYSTEMAREA', 'SETSTATEGEOCOVERAGE', 'SETPOPULATING', 'SETFILTERDATA']),
    ...mapMutations('DATASET_STORE', [
      'setProgramsArea',
      'setHealthSystemArea',
      'setStakeholderBranches',
      'setStakeholderCategories',
      'setGeoCoverage',
    ]),

    async applyFilter() {
        this.loading = true;
        this.statesProj = this.getGeoCoverageStateLevel;
        this.allProjData = await this.$dataset.getAllProjects();

        const data = {
        cactype: await this.cacTypeToSend,
        // program_areas: await this.programsAreaSelectedToSend,
        // health_system: await this.healthSystemAreasToSend
       }

       console.log('data', data)
       this.loading = false
       this.SETFILTERDATA(data);
      },

    searchStates() {
      this.statesToShow = this.states.filter((item) => {
        return item.name.toLowerCase().includes(this.stateToSearch.toLowerCase());
      });
    },

    showMoreLessHealthSysytem(show) {
      if (show) {
        this.healthSystemAreaToShow = this.getHealthSystemArea;
        return;
      }
      this.healthSystemAreaToShow = this.healthSystemAreaFormatted.slice(0, 3);
    },

    showMoreLessProgramArea(show) {
      if (show) {
        this.programsAreaToShow = this.getProgramsArea;
        return;
      }

      this.programsAreaToShow = this.programsAreaFormatted.slice(0, 3);
    },

    showMoreLessStates(show) {
      if (show) {
        this.statesToShow = this.states;
        return;
      }
      this.statesToShow = this.states.slice(0, 3);
    },

  },
  watch: {

    getHealthSystemArea(val) {
      this.healthSystemAreaFormatted = val;
      this.healthSystemAreaToShow = val.slice(0, 3);
    },

    getProgramsArea(val) {
      this.programsAreaFormatted = val;
      this.programsAreaToShow = val.slice(0, 3);
    },

    progamAreasSelected(val) {
      const indexOfItem = this.programsAreaSelectedToSend.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.programsAreaSelectedToSend.splice(indexOfItem, 1);
        return;
      }
      this.programsAreaSelectedToSend.push((val.item.id));

      console.log(this.programsAreaSelectedToSend);
    },
    stateSelected(val) {
      const indexOfItem = this.stateToSend.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.stateToSend.splice(indexOfItem, 1);
        return;
      }
      this.stateToSend.push(val.item.id);
    },
    accessLevelSelected(val) {
      const indexOfItem = this.accessLevelToSend.indexOf(val.item.id);
      if (indexOfItem >= 0) {
        this.accessLevelToSend.splice(indexOfItem, 1);
        return;
      }
      this.accessLevelToSend.push(val.item.id);
    },
    cacTypeSelected(val) {
      const indexOfItem = this.cacTypeToSend.indexOf(val.item);
      if (indexOfItem >= 0) {
        this.cacTypeToSend.splice(indexOfItem, 1);
        return;
      }
      this.cacTypeToSend.push(val.item);
      console.log(this.cacTypeToSend)
    },
    healthSystemAreasSelected(val) {
      const indexOfItem = this.healthSystemAreasToSend.indexOf(val.item);
      if (indexOfItem >= 0) {
        this.healthSystemAreasToSend.splice(indexOfItem, 1);
        return;
      }
      this.healthSystemAreasToSend.push(val.item);
      console.log(this.healthSystemAreasToSend)
    },
  },
  computed: {

    ...mapGetters('DATASET_STORE', [
      'getProgramsArea',
      'getHealthSystemArea',
      'getStateGeoCoverage',
      'getGeoCoverageStateLevel',
      'getGeoCoverage',
      'getPopulating'
    ]),
    statesToShowComputed() {
      return this.statesToShow;
    },
    healthSystemAreaToShowComputed() {
      return this.healthSystemAreaToShow;
    },
    programsAreaToShowComputed() {
      return this.programsAreaToShow;
    },

  },

  async mounted() {

    // make changes to the state
    this.SETPOPULATING(true);
    const geoCoverage = await this.$dataset.getAllGeoCoverage();

    this.setGeoCoverage(geoCoverage.data.results);

    this.statesProj = this.getGeoCoverageStateLevel;

    const projects = await this.$dataset.getAllProjects();

    // get the number of projects for each state
    this.statesProj.forEach((state, index) => {
      const num = projects.data.results.filter((value) => value.geocoverages.some(el => el === state.id)).length;

      this.statesProj[index].numProj = num;

    });

    // setting the geo coverage
    await this.SETSTATEGEOCOVERAGE(this.statesProj);

    // if there are no program areas and health system areas fetch them
    if (this.getProgramsArea.length < 1 || this.getHealthSystemArea.length < 1) {
      const programsAreaData = await this.$dataset.getAllProgramAreas();
      const healthAreas = await this.$dataset.getAllHealthAreas();

      await this.SETPROGRAMAREAS(programsAreaData.data);
      await this.SETSYSTEMAREA(healthAreas);
    }

    // make final changes to state data
    const statesData = await this.$dataset.getStates();
    const sensitivityData = await this.$dataset.getAllSensitivity();
    this.sensitivity = sensitivityData;
    this.states = statesData.data;
    this.statesToShow = this.states.slice(0, 3);
    this.healthSystemAreaFormatted = this.getHealthSystemArea;
    this.healthSystemAreaToShow = this.healthSystemAreaFormatted.slice(0, 3);
    this.programsAreaFormatted = this.getProgramsArea;
    this.programsAreaToShow = this.programsAreaFormatted.slice(0, 3);



    // new implementation
    // for Anambra
   const anambraId =  this.geoCoverage.data.results.filter(geo => geo.name === 'Anambra');
   // filtering the projects
   const anambraProjects = this.allProjData.data.results.filter(proj => proj.geocoverages.includes(anambraId));

   console.log('anambraProjects', anambraProjects)

    this.SETPOPULATING(false);
  },
};
</script>

<style scoped>
.states {
  height: 10rem;
  overflow: auto;
}

.base-button:hover {
  opacity: 0.9;
}
</style>
