export default {
  // for constants
  getConstants: `/constants/`,
  // for program areas
  getAllProgramAreas: `/program_areas/`,
  // for geocoverages
  getAllGeoCoverage: `/geocoverages/`,
  // for organizations
  getAllOrganization: `/organizations/`,
  // for stakeholder branches
  getAllStakeholdersBranch: `/stakeholder_branches/`,
  // for datasets
  getAllDataset: `/datasets/`,

  getDatasetDetails: '/datasets/',
  // for datasets
  getAllDataRequests: `/datarequests/`,
  // for stakeholders
  getAllStakeholders: `/stakeholders/`,
  // for project
  getAllProject: `/projects/`,
  // for stakeholder activities
  getAllStakholderActivities: `/stakeholder_activities/`,
};
