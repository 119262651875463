<template>
  <div class="form_div">
    <h3 class="form__header">Feedback,Enquires and Events</h3>
    <div class="buttons">
      <button
        id="feedback"
        @click="component = 'FEEDBACK'"
        class="headers active border-bottom-0 border-secondary mr-1 rounded-top"
      >
        Feedback
      </button>
      <button
        id="enquiry"
        @click="component = 'ENQUIRY'"
        class="headers border-bottom-0 border-secondary mr-1 rounded-top"
      >
        Enquiry
      </button>
      <button
        id="events"
        @click="component = 'EVENTS'"
        class="headers border-bottom-0 border-secondary rounded-top"
      >
        Publish Event
      </button>
    </div>

    <ValidationObserver v-slot="{ passes }">
      <form
        slot="form"
        class=".bg-light w-60 text-left border border-secondary m-auto"
        @submit.prevent="passes()"
      >
        <keep-alive>
          <component v-bind:is="component"> </component>
        </keep-alive>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import FEEDBACK from './components/feedback.vue';
import ENQUIRY from './components/enquiry.vue';
import EVENTS from './components/events.vue';

export default {
  components: {
    FEEDBACK,
    ENQUIRY,
    EVENTS,
  },
  data() {
    return {
      component: 'FEEDBACK',
    };
  },
  methods: {},
  mounted() {
    console.log('mounted');
  },

  watch: {
    component(val) {
      const feedback = document.querySelector('#feedback');
      const enq = document.querySelector('#enquiry');
      const event = document.querySelector('#events');

      if (val === 'FEEDBACK') {
        feedback.classList.add('active');
        enq.classList.remove('active');
        event.classList.remove('active');
      } else if (val === 'ENQUIRY') {
        feedback.classList.remove('active');
        enq.classList.add('active');
        event.classList.remove('active');
      } else if (val === 'EVENTS') {
        feedback.classList.remove('active');
        enq.classList.remove('active');
        event.classList.add('active');
      }
    },
  },
};
</script>

<style src="./components/feedback.css" scoped></style>
