<template>
  <b-row align-h="center">
    <!-- <span class="text-black-50 d-none d-sm-block">Rows per page</span>
    <div class="mt-n0 ml-1 mr-n3 d-none d-sm-block">
      <b-dropdown
        size="md"
        variant="link"
        class="perpageselect"
        toggle-class="text-decoration-none text-black-50 d-none d-sm-block"
      >
        <template #button-content>
          <span>4</span>
        </template>
        <b-dropdown-item v-for="n in 10" :key="n" class="bg-white">{{ n }}</b-dropdown-item>
      </b-dropdown>
    </div> -->
    <span class="text-black-50 ml-4 d-none d-sm-block"
      >{{ currentPageComputed === 0 ? 1 : currentPageComputed }} - {{ itemCount * currentPage }}
      <span class="mx-2">of</span> {{ totalPages }}</span
    >
    <span
      class="text-black-50 ml-4 nextprev mt-n1 ml-n2 ml-sm-5 align-middle"
      @click="$emit('change-page', Number(currentPage - 1))"
      ><i class="fas fa-chevron-left"></i
    ></span>
    <span
      v-for="n in range()"
      :key="n"
      :class="[n === currentPage ? 'ml-3 text-primary pages' : 'ml-3 text-gray pages']"
      @click="changePageFunction(n)"
      >{{ n }}</span
    >
    <span
      class="text-black-50 ml-3 nextprev mt-n1 align-middle"
      @click="$emit('change-page', Number(currentPage + 1))"
      ><i class="fas fa-chevron-right align-middle"></i
    ></span>
  </b-row>
</template>

<script>
export default {
  props: {
    totalPages: {
      type: Number,
    },
    itemCount: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    changePageFunction(n) {
      if (this.currentPage === n) {
        return false;
      }
      return this.$emit('change-page', n);
    },
    range() {
      const start = this.currentPage === 1 ? this.currentPage : this.currentPage - 1;
      const end = this.currentPage === this.totalPages ? this.totalPages : this.currentPage + 3;
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
  },
  computed: {
    currentPageComputed() {
      return this.itemCount * this.currentPage - this.itemCount;
    },
  },
};
</script>

<style scoped>
.perpageselect {
  margin-top: -0.4rem;
}

.nextprev {
  border: 1px solid rgb(114, 111, 111);
  border-radius: 3px;
  height: 1.7rem;
  margin: 0;
  padding: 0px;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  cursor: pointer;
}

.pages {
  cursor: pointer;
}

i {
  font-size: 0.7rem;
}
</style>
