<template>
  <div>
    <div class="card bg-dark text-white container rounded-0">
      <div class="card-body custom-background">
        <div class="my-3 text-center">
          <h1 class="font-weight-light">Non-Governmental Organisations</h1>
          <p class="font-italic my-4 font-weight-light">
            The NGO Inventory provides a guideline on how to register an NGO,
            <br />
            a list of all registered NGO in Nigeria, allows NGO to submit their yearly report
            <br />and a link that redirects to the forum page
          </p>
          <router-link to="/auth/login"
            ><button class="btn w-auto btn-primary my-1 rounded-0">
              Login to your NGO account
            </button></router-link
          >
        </div>
      </div>
    </div>

    <section class="text-center my-5 container" id="card-section">
      <div class="row justify-content-center">
        <div class="col-md-4 mb-4">
          <router-link to="registration-form"
            ><div class="card shadow text-dark">
              <div class="card-body">
                <img
                  src="../../../../assets/ngoimgs/surface1.png"
                  class="img-fluid my-3"
                  width="40"
                />
                <h6 class="font-weight-bold mb-3">Register your NGO?</h6>
                <!-- <h6 class="font-weight-bold mb-3">Want to register your NGO?</h6>
                <p class="font-weight-normal">
                  Find out step by step to <br />
                  register an NGO
                </p> -->
              </div>
            </div></router-link
          >
        </div>
        <div class="col-md-4 mb-4">
          <router-link to="registered"
            ><div class="card shadow text-dark">
              <div class="card-body">
                <img
                  src="../../../../assets/ngoimgs/surface1.png"
                  class="img-fluid my-3"
                  width="40"
                />
                <h6 class="font-weight-bold text-decoration-none mb-3">View all Registered NGOs</h6>
                <p class="text-decoration-none">
                  This shows a list of NGOs in Nigeria that have been duly registered
                </p>
              </div>
            </div></router-link
          >
        </div>
        <div class="col-md-4 mb-4">
          <router-link to="forum">
            <div class="card shadow text-dark">
              <div class="card-body">
                <img
                  src="../../../../assets/ngoimgs/Group 1123.png"
                  class="img-fluid my-3"
                  width="50"
                />
                <h6 class="font-weight-bold mb-4">Forum</h6>
                <p>This redirect to the forum platform.</p>
              </div>
            </div></router-link
          >
        </div>
      </div>
    </section>

    <section class="container my-4" id="info-section">
      <div class="text-center">
        <h2>Non-Governmental Organisations</h2>
      </div>
      <p class="text-dark text-justify">
        Non-Governmental Organisations (NGOs) branch of the Health System Strengthening division of
        the Department of Health Planning, Research and Statistics was created by the Federal
        Ministry of Health to coordinate the activities of health sector NGOs nationwide. This
        resource will serve as a database of all registered health NGOs across the country and would
        also provide the Ministry and stakeholders with necessary information on NGOs and their
        focus areas, to aid the proper coordination of the activities of NGOs in the country.
      </p>
      <div v-if="extended">
        <p class="text-dark">The objectives are:</p>
        <ul class="text-justify ml-5">
          <li>To recognize all NGOs actively carrying out interventions in the health space.</li>
          <li>
            To provide the Ministry and stakeholders with information about all NGOs working in the
            health sector for better coordination of health interventions in the country.
          </li>
          <li>
            To disseminate information on priorities of the health sector thus providing needed
            guidance to NGOs on health programs/interventions.
          </li>
        </ul>
      </div>
      <p style="cursor: pointer">
        <span class="text-primary" @click="extended = !extended">
          <i class="fa fa-angle-down mx-1"></i>
          show <span v-if="extended">less</span>
          <span v-else>more</span>
        </span>
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'main-landing',
  data() {
    return {
      extended: false,
    };
  },
};
</script>
<style scoped>
.custom-background {
  background-image: url('../../../../assets/ngoimgs/annie-spratt-cVEOh_JJmEE-unsplash.png');
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.5);
  background-position: center;
  background-size: inherit;
}

.btn {
  border-radius: 5px;
}
h1 {
  font-family: 'Roboto';
  font-size: 45px;
}

button {
  color: #ebebeb;
}

p {
  color: #ffffff;
  font-weight: 400;
}

.card h5 {
  font-size: 24px;
  font-family: 'Roboto';
}
h6 {
  color: #242424;
  font-size: 1.063rem;
}
</style>
