import T from './Toast';

export { T as toast };
export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$toast = T;
    console.log(Vue.prototype.$toast);
  },
};
