const routes = {
  organization: '/organizations/',
  mapping: '/main/crud/mapping/',
  comments: '/project_comments/',
  geocoverage: '/geo_coverage/',
  news: 'news/crud/news/',
  program_areas: '/program_areas/',
  projects: '/project/organization/',
  ngo_routes: {
    create: '/organizations/',
    update: '/organizations/',
  },
  auth: {
    reset_password: '/account/forgot_password',
    verify_token: '/account/reset_password',
  },
};

export default routes;
