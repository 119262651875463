<template>
  <b-container>
    <div>
      <b-row class="mb-5 ml-0" align-h="between">
        <b-col cols="12" sm="4" class="mt-4 ml-n3">
          <h4 class="text-left text-primary">Explore Datasets</h4>
        </b-col>
        <b-col
          cols="12"
          sm="3"
          class="text-right mt-n1 mr-n2 ml-n3 ml-sm-auto pl-4 justify-content-end d-flex"
        >
          <input
            prepend="fa fa-search"
            prependclass="mt-1"
            :filled="true"
            @keyup.enter="onSearch"
            placeholder="Search"
            width="100%"
            v-model="search"
            class="search"
          />
        </b-col>
      </b-row>
      <b-row class="p-0" no-gutters align-h="between">
        <b-col cols="12" sm="9">
          <div v-if="loading" class="text-center loading-icon">
            <b-spinner v-if="loading" x-large></b-spinner>
          </div>
          <div v-else>
            <ProjectCard
              v-for="(dataset, index) in ComputedDatasets"
              :key="index"
              :to="`/inventory/dataset-view/${dataset.id}`"
              :seemorelink="false"
              :date="dataset.created_at"
            >
              <template slot="body">
                <b-card-title class="text-left font-weight-bold text-black-50 h5">
                  {{ dataset.name }}
                </b-card-title>
                <b-card-text class="text-left text-black-50">
                  Capacity building for Local Governments and States’ Ministries, Departments and
                  Agencies (MDAs) responsible for rural water supply and sanitation intervention.
                </b-card-text>

                <div class="text-left mt-4">
                  <span class="font-weight-bolder text-black-50">Period of Implementation:</span>
                  <span class="ml-1 text-black-50"
                    >{{ getYear(dataset.start_date) }} - {{ getYear(dataset.end_date) }}</span
                  >
                </div>

                <div class="text-left mt-4">
                  <span class="font-weight-bolder text-black-50">Programs Area:</span>
                  <span
                    v-for="(program, index) in dataset.program_area"
                    :key="index"
                    class="ml-1 text-primary text-decoration-none"
                    ><span v-if="index !== dataset.program_area.length - 1">{{ program }},</span>
                    <span v-else>{{ program }}</span></span
                  >
                </div>
              </template>
            </ProjectCard>
            <div v-if="displayNoData">
              <div class="no -projects-text">No Data Available</div>
              <img src="../../../../assets/no-data.svg" alt="" width="500px" />
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <p class="mt-1 mx-2">Row per page</p>
              <select id="zone" v-model="perPage" style="width: 50px; height: 40px">
                <option value="3">3</option>
                <option value="5">5</option>
                <option value="10">10</option>
              </select>
              <p class="mx-3 mx-4 h5">
                <!-- {{ currentPage * perPage }} -->
                <span>Page </span>{{ currentPage }} of
                {{ Math.ceil(computedData.length / perPage) }}
              </p>
              <b-pagination
                v-model="currentPage"
                :total-rows="computedData.length"
                :per-page="perPage"
                aria-controls="my-table"
              />
            </div>
            <!-- <div class="mx-auto text-center">
              <Pagination
                :totalPages="datasets.count"
                :itemCount="datasets.length"
                @change-page="changePage($event)"
                :currentPage="currentPage"
              />
            </div> -->
          </div>
        </b-col>
        <b-col cols="3" class="pl-5 d-none d-sm-block">
          <FilterSideBar @filterObjs="fetchDataBasedOnSelectedRequirements" />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import ProjectCard from '../../components/Shared/ProjectCard.vue';
// import Pagination from '../../components/Shared/Pagination.vue';
import FilterSideBar from '../../components/Shared/FilterSideBar.vue';

export default {
  components: {
    ProjectCard,
    // Pagination,
    FilterSideBar,
  },
  data() {
    return {
      currentPage: 1,
      loading: false,
      perPage: 3,
      datasets: [],
      tempDatasets: '',
      search: '',
      displayNoData: false,
      completed: '',
      ongoing: '',
      completedDatasets: '',
      ongoingDatasets: '',
    };
  },
  async mounted() {
    this.loading = true;
    try {
      const datasets = await this.$dataset.getDatasets();
      this.datasets = datasets.data.results;
      this.tempDatasets = this.datasets;
      console.log(this.tempDatasets);

      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
  methods: {
    async changePage(e) {
      this.loading = true;
      try {
        const datasets = await this.$dataset.getDatasets(e);
        this.datasets = datasets.data.results;
        this.currentPage = e;
        this.loading = false;

        // if(this.search !== ''){

        // }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    /**
     *fetchDataBasedOnSelectedRequirements
     * * returns filtered projects based on sidebase requirements.
     * @param Obejcts sidebar emited object.
     */
    async fetchDataBasedOnSelectedRequirements(data) {
      this.currentPage = 1;
      this.loading = true;
      try {
        const filtered = await this.$dataset.filteredData(data);
        console.log(filtered);
        this.projects = filtered.data;
        this.total = filtered.data.length;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    getYear(date) {
      return moment(date).format('YYYY');
    },

    async onSearch() {
      const datasets = await this.$dataset.getDatasetsSearch(this.search);
      //  console.log('new datasets', await datasets)
      this.datasets = datasets.data.results;
    },
  },
  computed: {
    ...mapGetters('DATASET_STORE', ['getFilterData']),
    ...mapGetters('auth', ['user']),
    computedData() {
      return this.datasets.filter((project) => {
        return project.name.toLowerCase().match(this.search.toLowerCase());
      });
    },
    ComputedDatasets() {
      return this.computedData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  watch: {
    getFilterData(val) {
      // status
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= 1; i++) {
        if (val.status[i] === 'Completed') {
          this.completed = 'Approved';
          console.log(this.completed);
          this.completedDatasets = this.tempDatasets.filter((proj) => {
            return proj.status.display === this.completed;
          });
          console.log(this.datasets);
          console.log(this.tempDatasets);
        }
        if (val.status[i] === 'Ongoing') {
          this.ongoing = 'Pending';
          console.log(this.ongoing);
          this.ongoingDatasets = this.tempDatasets.filter((proj) => {
            return proj.status.display === this.ongoing;
          });

          console.log(this.datasets);
          console.log(this.tempDatasets);
        }
      }

      // Combine both datasets incase user selects to filter based on ongoing and completed
      this.datasets = [...this.completedDatasets, ...this.ongoingDatasets];

      if (this.datasets.length === 0) {
        this.displayNoData = true;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.search {
  padding: 20px;
}
</style>
