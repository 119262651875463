<template>
  <div class="m5">
    <ul class="nav pb-4">
      <li>
        <a href="/">Home ></a>
      </li>
      <li>
        <a href="/admin/stakeholder">Stakeholder Inventory ></a>
      </li>
      <li>
        <a href="/admin/policy-planning" class="active">Policy and Planning</a>
      </li>
    </ul>
    <h3 style="color: #30ab4c">POLICY AND PLANNING</h3>
    <div class="align-items-center justify-content-between d-flex">
      <span
        class="add-user d-flex align-items-center justify-content-center my-4"
        style="color: #707070; padding: 15px; background: #30ab4c1a 0% 0% no-repeat padding-box"
      >
        + ADD NEW USER
      </span>
      <form>
        <div class="input-group input-wrap">
          <span class="input-group-append">
            <button class="btn border-o ms-n5" type="button">
              <i class="fa fa-search"></i>
            </button>
          </span>
          <input class="input" type="search" value="search" id="example-search-input" />
        </div>
      </form>
    </div>
    <div class="">
      <h5 style="color: #000000">ALL USERS</h5>
      <stakholderRows :infos="infos" />
    </div>
  </div>
</template>

<script>
import stakholderRows from '../../components/stakholderRows.vue';

export default {
  name: 'StakeholderPolicy',
  components: {
    stakholderRows,
  },
  data() {
    return {
      infos: [
        {
          id: 1,
          title: 'Federal Ministry of Health Administrator',
        },
        {
          id: 2,
          title: 'National Centre for Disease Control Administrator',
        },
        {
          id: 3,
          title: 'Lorem Ipsum dolor et al cognif Administrator',
        },
        {
          id: 4,
          title: 'Lorem Ipsum dolor et al cognif Administrator',
        },
        {
          id: 5,
          title: 'Lorem Ipsum dolor et al cognif Administrator',
        },
        {
          id: 6,
          title: 'Lorem Ipsum dolor et al cognif Administrator',
        },
        {
          id: 7,
          title: 'Lorem Ipsum dolor et al cognif Administrator',
        },
      ],
    };
  },
};
</script>

<style scoped>
.input-wrap {
  width: 343px;
  height: 48px;
  background: #30ab4cb5 0% 0% no-repeat padding-box;
  opacity: 0.31;
}
.input {
  background: transparent;
  border: none;
  width: 85%;
}
</style>
