<template>
  <div>
    <b-link class="text-decoration-none">
      <b-card
        shadow
        footer-bg-variant="white"
        style="max-width: auto;"
        footer-class="text-left pt-3 pb-3 text-black-50"
        class="shadow mb-5 bg-white border-0 text-decoration-none"
      >
        <main>
          <slot name="body"></slot>
        </main>

        <template #footer>
          <b-row align-h="between">
            <!-- <b-col cols="6" sm="4">
              <span class="d-none d-sm-block">
                Uploaded {{ $dataset.formatDate(date, 'DD/MM/YYYY') }}</span
              >
              <span class="text-truncate d-sm-none">
                Uploaded {{ $dataset.formatDate(date, 'DD/MM/YYYY') }}</span
              >
            </b-col> -->
            <!-- <b-col cols="6" sm="4" class="text-right" v-if="seemorelink">
            <b-link class="ml-1 text-primary text-decoration-none" :to="to"
                >See details...</b-link
              > -->
            <!-- 
              <div>
                <span v-if="status === 'Approved'">Approved</span>
                <span v-if="status === 'Pending'">Pending</span>
              </div>

            </b-col> -->

            <button
              @click="getNgoById()"
              class="text-white bg-primary p-2 custom-button mt-4"
              :disabled="loader"
            >
              <div class="spinner-border" role="status" v-if="loader">
                <span class="sr-only">Loading...</span>
              </div>
              <span v-else>View NGO</span>
            </button>
          </b-row>
        </template>
      </b-card>
    </b-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getSingleOrganizations as ORGANIZATION } from '../../../ngo/config/api-modules/api.organization';

export default {
  data() {
    return {
      loader: false,
    };
  },

  props: {
    id: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters('admin', ['getSingleNgo']),
  },

  methods: {
    ...mapActions('admin', ['SET_NGO']),

    async getNgoById() {
      this.loader = true;
      console.log('id', this.id);

      ORGANIZATION(this.id)
        .then((data) => {
          // setting the single NGP
          this.SET_NGO(data.data);
          this.$router.push('/admin/ngo/sub-ngo');
          // console.log('sinlgeMNgo', this.getSingleNgo)
        })
        .catch(() => {})
        .finally(() => {});
    },
  },
};
</script>
