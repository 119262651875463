const data = {
  state: [
    { name: 'Niger', zone_id: 1 },
    { name: 'Kogi', zone_id: 1 },
    { name: 'Benue', zone_id: 1 },
    { name: 'Plateau', zone_id: 1 },
    { name: 'Nasarawa', zone_id: 1 },
    { name: 'Kwara', zone_id: 1 },
    { name: 'FCT', zone_id: 1 },

    { name: 'Bauchi', zone_id: 2 },
    { name: 'Borno', zone_id: 2 },
    { name: 'Taraba', zone_id: 2 },
    { name: 'Adamawa', zone_id: 2 },
    { name: 'Gombe', zone_id: 2 },
    { name: 'Yobe', zone_id: 2 },

    { name: 'Zamfara', zone_id: 3 },
    { name: 'Sokoto', zone_id: 3 },
    { name: 'Kaduna', zone_id: 3 },
    { name: 'Kebbi', zone_id: 3 },
    { name: 'Katsina', zone_id: 3 },
    { name: 'Kano', zone_id: 3 },
    { name: 'Jigawa', zone_id: 3 },

    { name: 'Enugu', zone_id: 4 },
    { name: 'Imo', zone_id: 4 },
    { name: 'Ebonyi', zone_id: 4 },
    { name: 'Abia', zone_id: 4 },
    { name: 'Anambra', zone_id: 4 },

    { name: 'Bayelsa', zone_id: 5 },
    { name: 'Akwa Ibom', zone_id: 5 },
    { name: 'Edo', zone_id: 5 },
    { name: 'Rivers', zone_id: 5 },
    { name: 'Cross River', zone_id: 5 },
    { name: 'Delta', zone_id: 5 },

    { name: 'Oyo', zone_id: 6 },
    { name: 'Ekiti', zone_id: 6 },
    { name: 'Osun', zone_id: 6 },
    { name: 'Ondo', zone_id: 6 },
    { name: 'Lagos', zone_id: 6 },
    { name: 'Ogun', zone_id: 6 },
  ],

  zones: [
    { name: 'North Central', zone_id: 1 },
    { name: 'North East', zone_id: 2 },
    { name: 'North West', zone_id: 3 },
    { name: 'South East', zone_id: 4 },
    { name: 'South South', zone_id: 5 },
    { name: 'South West', zone_id: 6 },
  ],
};

module.exports = data;
