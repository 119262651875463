<template>
  <div class="manage-user">
    <ul class="nav">
      <li>
        <a href="#">Home ></a>
      </li>
      <li>
        <a class="active" href="#">User Management</a>
      </li>
    </ul>
    <h1>USER MANAGEMENT</h1>
    <div v-if="loading" class="text-center loading-icon">
      <b-spinner v-if="loading" x-large></b-spinner>
    </div>
    <div class="btn-group addFaq" role="group">
      <router-link to="/admin/add-user"
        ><button type="button" class="btn btn-primary rounded-0">
          + ADD NEW ADMIN
        </button></router-link
      >
    </div>
    <h5>ALL USER ADMINS</h5>
    <div class="users">
      <p v-for="(item, index) in getAllAdmins.data.results" :key="index" :value="item">
        {{ index + 1 }} {{ item.organization }}
        <!-- <i
          class="fa fa-trash"
          aria-hidden="true"
          v-b-modal.del-modal
          @click="setAdmin(item.id)"
        ></i> -->
        <i class="fa fa-edit" @click="setAdminEdit(item.id)"></i>
      </p>

      <!-- <div to="/admin/edit-permission" @click="setAdminEdit(item.id)">
        <i class="fa fa-edit"></i>
      </div> -->

      <b-modal id="del-modal" title="Delete Admin" hide-backdrop hide-footer hide-header>
        <p class="my-4">Are you sure you want to delete this user</p>
        <button type="button" class="btn btn-primary rounded-0">Yes</button>
        <button type="button" class="btn btn-primary rounded-0">No</button>
      </b-modal>
      <p></p>
      <hr />
    </div>
    <div class="overflow-auto">
      <b-pagination-nav :link-gen="linkGen" :number-of-pages="4" use-router></b-pagination-nav>
    </div>
  </div>
</template>
<style scoped>
.manage-user {
  margin: 30px 0px 0px 20px;
}
.users {
  width: 900px;
}
h1 {
  color: #30ab4c;
}
h5 {
  padding: 30px 0px;
}
p {
  color: #000000;
}
i {
  float: right;
}
.fa {
  padding: 0px 20px 0px 0px;
  cursor: pointer;
}
.fa-edit {
  color: #2da047;
}
.fa-trash {
  color: red;
}
.overflow-auto {
  display: flex;
  justify-content: center;
  padding: 50px 0px;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      loading: true,
    };
  },

  methods: {
    ...mapActions('admin', ['GET_ADMINS', 'GET_ADMIN_SINGLE']),

    async setAdminDel(id) {
      console.log('setting the first');
      this.GET_ADMIN_SINGLE(id);

      console.log('singleAdmin', this.getAdminById);
    },

    async setAdminEdit(id) {
      console.log('setting the first');
      await this.GET_ADMIN_SINGLE(id);
      console.log('singleAdmin', await this.getAdminById);
      this.$router.push('/admin/edit-user');
    },
  },

  async mounted() {
    console.log('checking admin');
    this.GET_ADMINS();

    console.log('admins', await this.getAllAdmins);

    console.log('allAdmins', await this.getAllAdmins);

    this.loading = false;
  },

  computed: {
    ...mapGetters('admin', ['getAllAdmins', 'getAdminById']),
  },
};
</script>
