import OrganisationService from './OrganisationService.js';

export default {
  namespaced: true,
  state: {
    allReports: {},
    singleReport: {},
  },
  mutations: {
    setReports(state, payload) {
      state.allReports = payload;
    },
    setReport(state, payload) {
      state.singleReport = payload;
    },
  },
  actions: {
    // //= =================CREATE NEW RECORD ==================//
    async NEW_REPORT(context, payload) {
      const response = await OrganisationService.submitReport(payload);
      return Promise.resolve(response);
    },

    //= =================GET ALL RECORDS ========================//
    async GET_REPORTS(context, payload) {
      const data = await OrganisationService.getReports(payload);
      // console.log(data);
      return Promise.resolve(data);
    },
  },
  getters: {
    getSingleReport: (state) => state.singleReport,
    getAllReports: (state) => state.allReports,
  },
};
