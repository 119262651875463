<template>
  <b-card
    shadow
    footer-bg-variant="white"
    style="max-width: auto"
    footer-class="text-left pt-3 pb-3 text-black-50"
    class="shadow mb-5 bg-white border-0 text-decoration-none"
  >
    <main>
      <h4 class="card-title text-left font-weight-bold text-black-50 h5">
        {{ projectObject.name }}
      </h4>

      <p class="card-text text-left text-black-50 my-3">
        <span class="font-weight-bold"> Funding</span>
        <ul>
          <li
            v-for="(funder,index) in projectObject.funders" 
            :key="index"
          >
            {{ funder.organization__name }}
          </li>
        </ul>
      </p>
      <p class="card-text text-left text-black-50 my-3">
        <span class="font-weight-bold"> Implementing Partner  </span>
        <ul>
          <li
            v-for="(implementation,index) in projectObject.implementing_partners" 
            :key="index"
          >
            {{ implementation.organization__name }}
          </li>
        </ul>
      </p>
      
      <div class="text-left mt-4">
        <span
          class="font-weight-bolder text-black-50"
        >Name of organization:</span><span class="ml-1 text-black-50">{{
          projectObject.organization.name
        }}</span>
      </div>
    </main>
  </b-card>
</template>

<script>
export default {
  props: {
    projectObject: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
