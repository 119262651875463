<template>
  <div class="w-100 desc p-4">
    <p class="text-left font-weight-bold text-black-50">Name of data collection activity</p>
    <p class="text-left mt-n2">{{ projectDetails.name }}</p>

    <p class="text-left font-weight-bold text-black-50 mt-4">Year(s) of Implementation</p>
    <p class="text-left mt-n2">{{ getDate(projectDetails.end_date) }}</p>

    <div v-if="projectDetails.status">
      <p class="text-left font-weight-bold text-black-50 mt-4">Status</p>
      <p class="text-left mt-n2">{{ projectDetails.status }}</p>
    </div>

    <p v-if="projectDetails.period" class="text-left font-weight-bold text-black-50 mt-4">
      Frequency of Implementation
    </p>
    <p v-if="projectDetails.period" class="text-left mt-n2">{{ projectDetails.period }}</p>

    <p class="text-left font-weight-bold text-black-50 mt-4">Geographical Coverage</p>
    <p>
      {{ projectDetails.geo_coverage[0].level.toUpperCase() }}
    </p>
    <div v-if="projectDetails.geo_coverage[0].level.toLowerCase() === 'state'">
      <p class="text-left font-weight-bold text-black-50 mt-4">States Implementation</p>
      <p class="ml-n1">
        <span
          v-for="(coverage, index) in projectDetails.geo_coverage"
          :key="index"
          class="text-left mt-n2 ml-1"
        >
          <span v-if="index !== projectDetails.geo_coverage.length - 1">{{ coverage.name }},</span>
          <span v-else>{{ coverage.name }}</span>
        </span>
      </p>
    </div>

    <div v-if="projectDetails.geo_coverage[0].level.toLowerCase() === 'lga'">
      <p class="text-left font-weight-bold text-black-50 mt-4">LGA</p>
      <p
        v-for="(coverage, index) in projectDetails.geo_coverage"
        :key="index"
        class="text-left mt-n2"
      >
        {{ coverage.name }}
      </p>
    </div>

    <p class="text-left font-weight-bold text-black-50 mt-4">Program Area</p>
    <p v-for="(program, index) in projectDetails.program_area" :key="index" class="text-left mt-n2">
      {{ program.name }}
    </p>

    <p class="text-left font-weight-bold text-black-50 mt-4">Target group/Population of Interest</p>
    <p class="text-left mt-n2">
      {{ projectDetails.target_group || projectDetails.population_target }}
    </p>
    <div v-if="projectDetails.population_unit">
      <p class="text-left font-weight-bold text-black-50 mt-4">Population Unit</p>
      <p
        v-for="(population, index) in projectDetails.population_unit"
        :key="index"
        class="text-left mt-n2"
      >
        {{ population.name }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ProjectDescription',
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getDate(date) {
      return moment(date).format('MMMM YYYY');
    },
  },
};
</script>

<style scoped>
.desc {
  background-color: #f7f7f7;
}
</style>
