<template>
  <div id="faq" class="container mb-5 mt-4">
    <h4 class="text-primary font-weight-normal">FAQs</h4>
    <div class="my-4 container">
      <div class="row">
        <div class="col">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-primary rounded-0" @click="faq = 'NgoFaq'">
              NGO FAQs
            </button>
            <button type="button" class="btn btn-primary rounded-0" @click="faq = 'DataFaq'">
              DATA FAQs
            </button>
            <button type="button" class="btn btn-primary rounded-0" @click="faq = 'ProjectFaq'">
              PROJECT FAQs
            </button>
          </div>
        </div>
      </div>
    </div>
    <component :is="faq"></component>
  </div>
</template>

<script>
import ProjectFaq from './faqs/project-inventory-faq.vue';
import DataFaq from './faqs/data-inventory-faq.vue';
import NgoFaq from './faqs/ngo-faq.vue';

export default {
  name: 'faq',
  components: {
    ProjectFaq,
    DataFaq,
    NgoFaq,
  },
  data() {
    return {
      faq: 'NgoFaq',
    };
  },
};
</script>

<style>
.accordion {
  font-family: 'Roboto';
}
.accordion .card {
  border: none !important;
}
.accordion .card button {
  border: none !important;
  background-color: #e8f5eb2e;
}
.accordion > .card > .card-header {
  border-radius: 0 !important;
  margin-bottom: 0px !important;
  background-color: #e8f5eb2e;
  border: none !important;
}
.accordion > .card {
  background-color: #e8f5eb2e;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  color: red;
}
</style>
