<template>
  <div>
    <BaseLogoBar />
    <BaseNavbar />

    <router-view />

    <BaseFooter />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
