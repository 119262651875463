import axios from '@/plugin/axios';

const submitReport = ({ id, data }) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/organizations/${id}/submit_yearly_report/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // processError(error);
        reject(error);
      });
  });

const getReports = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/organizations/${id}/view_yearly_report/`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export default {
  submitReport,
  // RegisterOrgainisation,
  getReports,
};
