<template lang="">
  <div style="margin-top: -30px; margin-bottom: 30px">
    <router-link
      :to="item.link"
      v-for="(item, index) in breadcrumblinks"
      :key="index"
      :class="{ active: index === activeItemIndex }"
      >{{ item.label }}/&nbsp;</router-link
    >
  </div>
</template>
<script>
export default {
  props: {
    breadcrumbData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      breadcrumblinks: this.breadcrumbData,
      activeItemIndex: '',
    };
  },

  mounted() {
    let i = 0;

    // eslint-disable-next-line no-plusplus
    for (i; i <= this.breadcrumblinks.length; i++) {
      if (this.breadcrumblinks[i].link === '/inventory') {
        this.breadcrumblinks[i].label = 'Home';
        this.breadcrumblinks[i].link = '/';
      }

      if (this.breadcrumblinks[i].link === '/inventory/project') {
        this.breadcrumblinks[i].label = 'Inventory Project';
      }

      if (this.breadcrumblinks[i].link === '/inventory/dataset') {
        this.breadcrumblinks[i].label = 'Inventory Dataset';
      }

      if (this.breadcrumblinks[i].link === this.$route.path) {
        this.activeItemIndex = i;
      }
    }

    console.log(this.breadcrumblinks);
    console.log(this.breadCrumbColor);
  },
};
</script>

<style scoped>
.active {
  color: grey;
}
</style>
