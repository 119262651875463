<template>
  <b-link :to="`/inventory/stakeholder/${section.name}`" class="hover text-decoration-none">
    <b-card shadow class="shadow mb-5 bg-white border-0" style="height: 15rem; width: 13rem;">
      <b-card-title class="text-center font-weight-bold text-black mt-1 h6" style="height: 3rem">
        {{ section.name }}
      </b-card-title>
      <b-card-text
        class="text-center text-black-50 mt-0"
        style="font-size: 0.95rem; color: #242424"
      >
        {{ section.description ? section.description : 'No description available' }}
      </b-card-text>
    </b-card>
  </b-link>
</template>

<script>
export default {
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.text-black {
  color: black;
}

.hover {
  text-decoration-color: none;
  transition: 1s transform;
}
.hover:hover {
  transform: translateY(-20px);
}
</style>
