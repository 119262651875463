<template>
  <ValidationProvider
    tag="div"
    :rules="rules"
    :name="name"
    :vid="vid"
    v-slot="{ errors, ariaInput, ariaMsg }"
  >
    <b-form-group
      :id="name"
      :label="label"
      :label-for="name"
      class="text-left font-weight-bold text-black-50 mt-4 mb-2"
    >
      <p class="text-black-50 font-weight-lighter mb-2 mt-2">
        {{ description }}
      </p>
      <label class="customstuff font-weight-normal text-black-50 mt-4 mb-2 ml-n2">
        <input
          type="checkbox"
          :id="name"
          v-model="currentValue"
          v-bind="ariaInput"
          :value="currentValue"
          @change="$emit('change', currentValue)"
        />
        <span class="checkmark"></span>
        <span v-if="link === 'no'" class="pt-n5"> {{ checkboxItem }} </span>
        <b-link v-else :to="link" class="ml-1"> {{ checkboxItem }}</b-link>
      </label>
    </b-form-group>
    <p class="text-danger text-left mt-n1 font-weight-light" v-bind="ariaMsg" v-if="errors[0]">
      <small> {{ errors[0] }} </small>
    </p>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    value: {
      type: [String, Number, Object, Boolean, Array],
    },
    item: {
      type: [String, Number, Object, Boolean, Array],
    },
    label: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: 'no',
    },
    checkboxItem: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    vid: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  data: () => ({
    currentValue: '',
  }),
  watch: {
    /**
     * allows us to use v-model on our input
     * @param {*} val
     */
    currentValue(val) {
      this.$emit('input', { selected: val, item: this.item });
    },
  },
  // computed: {
  //   currentValue: {
  //     get() {
  //       return this.value;
  //     },
  //     set(val) {
  //       this.$emit('input', { selected: val, item: this.item });
  //     },
  //   },
};
</script>

<style scoped>
/* The customstuff */
.customstuff {
  display: block;
  position: relative;
  padding-left: 22px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.customstuff input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #707070;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.customstuff:hover input ~ .checkmark {
  background-color: #f9f9f9;
}

/* When the checkbox is checked, add a blue background */
.customstuff input:checked ~ .checkmark {
  background-color: #30ab4c;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.customstuff input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.customstuff .checkmark:after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
