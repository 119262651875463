var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inputBox",style:(("width: " + _vm.width))},[_c('ValidationProvider',{attrs:{"tag":"div","rules":_vm.rules,"name":_vm.name,"vid":_vm.vid},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
return [_c('b-form-group',{staticClass:"text-left font-weight-bold text-dark mb-1 mt-4",attrs:{"id":_vm.name,"label":_vm.label,"label-for":_vm.name}},[_c('p',{staticClass:"text-black-50 font-weight-lighter mb-1 mt-n1"},[_vm._v(_vm._s(_vm.description))]),_c('i',{class:(_vm.prepend + " prepend " + _vm.prependclass),staticStyle:{"color":"#c5c1c1"},attrs:{"aria-hidden":"true"}}),_c('b-form-input',_vm._b({class:[
          _vm.prepend ? 'withprepend' : '',
          _vm.append ? 'withappend' : '',
          _vm.filled ? 'filled' : '',
          'inputField' ],style:(("height: " + _vm.height)),attrs:{"id":_vm.name,"type":_vm.type,"placeholder":_vm.placeholder},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'b-form-input',ariaInput,false)),_c('i',{class:(_vm.append + " append position-absolute\n      " + _vm.appendclass),staticStyle:{"color":"#c5c1c1"},attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.$emit('append')}}})],1),(errors[0])?_c('p',_vm._b({staticClass:"text-danger text-left mt-n1 pb-0 mb-0 font-weight-light"},'p',ariaMsg,false),[_c('small',[_vm._v(" "+_vm._s(errors[0])+" ")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }