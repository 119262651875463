<template>
  <div id="ngo-admin">
    <div class="container">
      <h4 class="text-primary my-2 font-weight-normal">NGO BRANCH</h4>
      <div class="d-flex justify-content-between align-items-center">
        <router-link to="add-new-ngo"
          ><button class="btn bg-primary text-white p-2 btn-sm rounded-0 mt-2">
            <i class="fa fa-plus mx-2"></i>ADD NEW NGO
          </button></router-link
        >
        <base-input
          prepend="fa fa-search"
          prependclass="mt-1"
          :filled="true"
          v-model="filter"
          placeholder="Search"
          width="30%"
        />
      </div>
    </div>

    <section class="container my-4">
      <b-table
        thead-class="bg-success text-dark"
        tbody-tr-class="danger"
        :hover="true"
        :bordered="false"
        :filter="filter"
        responsive="true"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        primary-key="id"
        selectable
        @row-selected="viewProjects"
        :busy="isBusy"
        show-empty
      >
        <template #empty="scope">
          <h4 class="text-primary text-center">{{ scope.emptyText }}</h4>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #head()="data">
          <span class="table-header-style">
            {{ data.label }}
          </span>
        </template>

        <template #head(id)="data">
          <span class="table-header-style">{{ data.label }}</span>
        </template>

        <template #head(status)="data">
          <span class="table-header-style">{{ data.label }}</span>
        </template>

        <template #head(action)>
          <span></span>
        </template>

        <template #cell(action)="row">
          <b-button
            v-b-modal.modal-center
            class="bg-primary text-white rounded-0 btn-sm"
            @click="preview(row.item)"
            ><i class="fa fa-eye mr-2"></i>view
          </b-button>
        </template>

        <template #cell(created_at)="row">
          <span>{{ row.item.created_at | commentDateFormat }}</span>
        </template>

        <template #cell(id)="row">
          <span>{{ row.index + 1 }}</span>
        </template>

        <template #cell(status)="row">
          <span class="text-primary" v-if="row.item.details.status">Active</span>
          <span class="text-danger" v-else>Suspended</span>
        </template>

        <template #cell(updated_at)="row">
          <span>{{ row.item.updated_at | commentDateFormat }}</span>
        </template>
      </b-table>
      <div class="d-flex justify-content-end container">
        <p class="mt-1 mx-2">Row per page</p>
        <select id="zone" style="width: 50px; height: 40px;" v-model="perPage">
          <option value="5"> 5 </option>
          <option value="10"> 10 </option>
        </select>
        <p class="mx-2 mt-2">{{ currentPage }} - {{ perPage }} of {{ total }}</p>
        <b-pagination
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </section>

    <NgoAdminModal :data="previewData" v-if="previewData && previewData != null" />
  </div>
</template>

<script>
import NgoAdminModal from '../modals/ng-admin-modal.vue';
import { table4 } from '../../config/config.table';
import { getOrganizations as ORGANIZATIONS } from '../../config/api-modules/api.organization';

export default {
  name: 'ngo-admin',
  components: {
    NgoAdminModal,
  },
  data() {
    return {
      filter: null,
      total: 0,
      perPage: 10,
      currentPage: 1,
      isBusy: table4.isBusy,
      fields: table4.fields,
      items: table4.items,
      previewData: null,
      search: null,
    };
  },

  methods: {
    viewProjects(data) {
      this.$router.push({ path: `project-list/${data[0].id}/${data[0].name}` });
    },

    preview(data) {
      this.previewData = data;
    },
    get_data() {
      this.isBusy = true;
      ORGANIZATIONS()
        .then((data) => {
          this.items = data.data.results;
          this.total = data.data.results.length;
        })
        .catch(() => {
          this.items = [];
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
<style lang="scss" scoped>
.table-header-style {
  color: gray;
}
.nextprev {
  border: 1px solid rgb(114, 111, 111);
  border-radius: 3px;
  height: 1.7rem;
  margin: 0;
  padding: 0px;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  cursor: pointer;
}

.pages {
  cursor: pointer;
}

i {
  font-size: 0.7rem;
}
* {
  font-family: 'Open Sans';
}
.custom {
  width: 50px;
  height: 30px;
}

h2 {
  font-weight: 400;
}

.input-group-text {
  border: none !important;
  background-color: #efe7e74d !important;
}

.form-control {
  background-color: #efe7e74d !important;
  border-left-width: none !important;
  outline: none i !important;
  border-left: none !important;
}

.input-group {
  border: 1px solid #707070;
  width: 350px !important;
}
</style>
