// import store from '@/store';
import index from './views/index.vue';
import AnnualReports from './views/AnnualReports.vue';
import SubmitReports from './views/SubmitReports.vue';

export default [
  {
    path: '/organizations',
    component: index,
    // meta: {
    //   requiresAuth: false,
    // },
    children: [
      {
        path: '',
        component: AnnualReports,
      },
      {
        path: '/submit_yearly_report',
        component: SubmitReports,
      },
    ],
  },
];
