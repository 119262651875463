/* eslint-disable no-alert */ /* eslint-disable no-alert */
<template>
  <div id="ngo-portal-submit-report">
    <div class="container my-2">
      <h4 class="text-primary my-4">SUBMIT ANNUAL REPORT</h4>
      <p>
        Please complete the following questions by selecting the answer(s) of your choice or writing
        in the space provided.
      </p>
      <ValidationObserver ref="observer">
        <form @submit.prevent="submit(form)">
          <div class="my-3">
            <base-input
              height="3rem"
              placeholder="Report title"
              width="100%"
              rules="required"
              id="name"
              name="Report title"
              v-model="form.name"
              label=" Report title "
            />
          </div>
          <div class="my-3">
            <base-input
              height="3rem"
              placeholder="Name of organization"
              width="100%"
              id="organization"
              rules="required"
              v-model="form.organization"
              name="Name of organization"
              label=" Name of organization "
            />
          </div>
          <div class="my-4">
            <h5 class="font-weight-bold my-4">Reporting period</h5>
            <div class="form-row">
              <div class="col-4">
                <base-select
                  height="3rem"
                  name="year"
                  id="year"
                  rule="required"
                  :items="generateYear()"
                  placeholder="Year"
                  width="100%"
                  v-model="form.year"
                  label="Year"
                />
              </div>
              <div class="col-4">
                <base-select
                  id="start_name"
                  height="3rem"
                  :items="months"
                  track="name"
                  placeholder="start date"
                  width="100%"
                  rule="required"
                  name="Start date"
                  v-model="form.start_date"
                  label="Start month"
                />
              </div>
              <div class="col-4">
                <base-select
                  height="3rem"
                  :items="months"
                  track="name"
                  id="month"
                  name="months"
                  placeholder="End Date"
                  width="100%"
                  rule="required"
                  v-model="form.end_date"
                  label="End month"
                />
              </div>
            </div>
          </div>
          <div class="my-4">
            <h5 class="font-weight-bold my-4">Submission was authorized by</h5>
            <div class="form-row">
              <div class="col-4">
                <base-input
                  height="3rem"
                  label="First Name"
                  placeholder="First Name"
                  width="100%"
                  type="text"
                  rules="required"
                  id="first_name"
                  name="first name"
                  v-model="form.first_name"
                />
              </div>
              <div class="col-4">
                <base-input
                  height="3rem"
                  label="Last Name"
                  placeholder="Last Name"
                  width="100%"
                  type="text"
                  id="last_name"
                  name="last Name"
                  rules="required"
                  v-model="form.last_name"
                />
              </div>
            </div>
          </div>
          <div class="form-row my-3">
            <div class="col-4">
              <base-input
                height="3rem"
                label="Position"
                placeholder="position"
                width="100%"
                type="text"
                name="position"
                id="position"
                rules="required"
                v-model="form.position"
              />
            </div>
          </div>
          <div class="form-row my-3">
            <div class="col-6">
              <base-input
                height="3rem"
                label="Email"
                placeholder="Email"
                width="100%"
                name="Email"
                rules="required|email"
                type="email"
                id="email"
                v-model="form.email"
              />
            </div>
          </div>
          <div class="form-row my-3">
            <div class="col-6">
              <base-input
                height="3rem"
                label="Phone"
                name="Phone"
                placeholder="Phone"
                width="100%"
                type="text"
                id="phone"
                rules="required"
                v-model="form.phone"
              />
            </div>
          </div>
          <div class="form-row my-3">
            <div class="col-12">
              <base-textarea
                v-model="form.achievements"
                label="Achievements (for the reporting period)"
                name="achievements"
                rules="required"
                id="achievements"
              />
            </div>
          </div>
          <div class="form-row my-3">
            <div class="col-12">
              <base-textarea
                v-model="form.challenge"
                label="Challenges"
                name="challenges"
                rules="required"
                id="challenge"
              />
            </div>
          </div>
          <div class="row my-3">
            <div class="col-12">
              <base-textarea
                v-model="form.summary"
                label="Summary (compulsory)"
                name="summary"
                rules="required"
                id="summary"
              />
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-12">
              <label for="uploads" class="form-label font-weight-bold"
                >Upload Report [select file pdf. docx.]</label
              >
              <input
                class="select-class"
                type="file"
                id="uploads"
                rules="required"
                placeholder="upload file"
                @change="file_handler($event)"
              />
            </div>
          </div>
          <div class="w-20 my-5">
            <button
              class="btn text-white p-2 bg-primary custom-button rounded-0"
              type="submit"
              :disabled="loader"
            >
              <div class="spinner-border" role="status" v-if="loader">
                <span class="sr-only">Loading...</span>
              </div>
              <span v-else> Submit </span>
            </button>
            <span class="mx-4 text-primary">Save and Continue Later</span>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { addReport as ADD_REPORT } from '../../config/api-modules/api.projects';

export default {
  name: 'ngo-portal-submit-report',
  computed: {
    ...mapGetters('auth', ['user']),
  },
  components: {},
  data() {
    return {
      startDate: null,
      form: {
        start_date: null,
        end_date: null,
      },
      file: null,
      loader: false,
      error: undefined,
      months: [
        { name: 'January', no: 1 },
        { name: 'February', no: 2 },
        { name: 'March', no: 3 },
        { name: 'April', no: 4 },
        { name: 'May', no: 5 },
        { name: 'June', no: 6 },
        { name: 'July', no: 7 },
        { name: 'August', no: 8 },
        { name: 'September', no: 9 },
        { name: 'October', no: 10 },
        { name: 'November', no: 11 },
        { name: 'December ', no: 12 },
      ],
    };
  },
  methods: {
    generateYear() {
      let startYear = 1940;
      const currentYear = new Date().getFullYear();
      const years = [];
      startYear = startYear || 1980;
      while (startYear <= currentYear) {
        // eslint-disable-next-line no-plusplus
        years.push(startYear++);
      }
      return years;
    },
    async submit(form) {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }
      this.loader = true;

      const formData = form;
      formData.file = this.file;
      formData.start_date = `${formData.year}-${formData.start_date.no}-01`;
      formData.end_date = `${formData.year}-${formData.end_date.no}-01`;
      formData.organization = this.user.id;
      ADD_REPORT(formData)
        .then(() => {
          this.$router.push('/ngo/portal');
          this.$toast.show({
            type: 'SUCCESS',
            message: `${formData.name} has been added`,
          });
        })
        .catch(() => {
          this.$toast.show({
            type: 'ERROR',
            message: `Unable to add ${formData.name}`,
          });
        })
        .finally(() => {
          this.loader = false;
        });
    },

    file_handler(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0];
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: 'Open Sans';
}
.custom-button {
  width: 150px;
}

label {
  font-size: 0.875rem;
}
</style>
