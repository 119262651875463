/* eslint-disable no-sequences */
<template>
  <div>
    <div class="text-center  text-primary " v-if="loader">
      Please wait loading content...
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <section class="container my-5" v-else>
      <h2 class="text-primary text-uppercase font-weight-normal my-4">{{ details.name }}</h2>
      <p>
        Please complete the following questions by selecting the answers(s) of your choice or
        writing in the space provided
      </p>

      <form @submit.prevent="update()">
        <div style="width: 50%" class="inputBox">
          <p class="text-left font-weight-bold text-dark mb-1 mt-4">Name</p>
          <b-form-input
            style="height: 50%"
            class="inputField"
            v-model="details.name"
          ></b-form-input>
        </div>
        <p class="text-left font-weight-bold text-dark mb-1 mt-4">Program areas</p>
        <div class="row">
          <div class="text-center ml-4" v-if="program_area_loader">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="col-md-3" v-for="(input, index) in programs" :key="index" v-else>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input m-1"
                type="checkbox"
                id="inlineCheckbox1"
                :value="input.id"
                v-model="details.program_area"
              />
              <label class="form-check-label" for="inlineCheckbox1">{{ input.name }}</label>
            </div>
          </div>
        </div>
        <div style="width: 30%">
          <p class="text-left font-weight-bold text-dark mb-1 mt-4">Year of registration</p>
          <select style="height: 50%" id="year" v-model="details.year">
            <option v-for="(item, index) in generateYear()" :key="index" :value="item">{{
              item
            }}</option>
          </select>
        </div>
        <div class="form-group mt-4">
          <p class="font-weight-bold">Name of contact person</p>
          <div class="form-row">
            <div class="col-md-4">
              <div style="width: 100%" class="inputBox">
                <p class="text-left font-weight-bold text-dark mb-1 mt-0">First name</p>
                <b-form-input
                  style="height: 100%"
                  class="inputField"
                  v-model="details.contact_person_first_name"
                ></b-form-input>
              </div>
            </div>
            <div class="col-md-4">
              <div style="width: 100%" class="inputBox">
                <p class="text-left font-weight-bold text-dark mb-1 mt-0">Last name</p>
                <b-form-input
                  style="height: 100%"
                  class="inputField"
                  v-model="details.contact_person_last_name"
                ></b-form-input>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 50%" class="inputBox my-4">
          <p class="text-left font-weight-bold text-dark mb-1 mt-0">Email</p>
          <b-form-input
            style="height: 100%"
            class="inputField"
            v-model="details.email"
            disabled
          ></b-form-input>
        </div>

        <div style="width: 50%" class="inputBox">
          <p class="text-left font-weight-bold text-dark mb-1 mt-0">Address</p>
          <b-form-input
            style="height: 100%"
            class="inputField"
            v-model="details.address"
          ></b-form-input>
        </div>
        <div style="width: 30%">
          <p class="text-left font-weight-bold text-dark mb-1 mt-4">Status</p>
          <select style="height: 50%" v-model="details.status">
            <option value="true">Active</option>
            <option value="false">Suspend</option>
          </select>
        </div>
        <div style="width: 30%">
          <p class="text-left font-weight-bold text-dark mb-1 mt-4">State</p>
          <select style="height: 50%" v-model="details.state">
            <option v-for="(item, index) in states" :key="index" :value="item">{{ item }}</option>
          </select>
        </div>
        <div style="width: 30%">
          <p class="text-left font-weight-bold text-dark mb-1 mt-4">Geopolitical zone</p>
          <select style="height: 50%" v-model="details.geo_zone">
            <option v-for="(item, index) in zones" :key="index" :value="item">{{ item }}</option>
          </select>
        </div>
        <div class="d-flex  align-items-center my-5">
          <button class="text-white bg-primary px-3 btn-lg rounded-0" :disabled="button_loader">
            <div class="spinner-border" role="status" v-if="button_loader">
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else>Save Changes</span>
          </button>
          <p class="mt-3 text-primary ml-4">Discard Changes</p>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import {
  updateOrganization as UPDATE_ORGANIZATION,
  getProgramArea as PROGRAM_AREAS,
} from '../../config/api-modules/api.organization';
import axios from '../../../../plugin/axios';
// import zones from '../../config/states_and_zones';

export default {
  data() {
    return {
      param: this.$route.params.id,
      details: {},
      programs: [],
      zones: [
        'North Central',
        'North East',
        'North West',
        'South East',
        'South South',
        'South West',
      ],
      states: [
        'Niger',
        'Kogi',
        'Benue',
        'Plateau',
        'Nasarawa',
        'Kwara',
        'FCT',
        'Bauchi',
        'Borno',
        'Taraba',
        'Adamawa',
        'Gombe',
        'Yobe',
        'Zamfara',
        'Sokoto',
        'Kaduna',
        'Kebbi',
        'Katsina',
        'Kano',
        'Jigawa',
        'Enugu',
        'Imo',
        'Ebonyi',
        'Abia',
        'Anambra',
        'Bayelsa',
        'Akwa Ibom',
        'Edo',
        'Rivers',
        'Cross River',
        'Delta',
        'Oyo',
        'Ekiti',
        'Osun',
        'Ondo',
        'Lagos',
        'Ogun',
      ],
      loader: false,
      button_loader: false,
      selected_state: null,
      program_area_loader: false,
      currentValue: null,
    };
  },
  methods: {
    generateYear() {
      let startYear = 1940;
      const currentYear = new Date().getFullYear();
      const years = [];
      startYear = startYear || 1980;
      while (startYear <= currentYear) {
        // eslint-disable-next-line no-plusplus
        years.push(startYear++);
      }
      return years;
    },
    update() {
      this.button_loader = true;
      UPDATE_ORGANIZATION(this.param, this.details)
        .then(() => {
          this.$router.go(-1);
          this.$toast.show({
            type: 'SUCCESS',
            message: `${this.details.name} has been updated`,
          });
        })
        .catch(() => {
          this.$toast.show({
            type: 'ERROR',
            message: `unable to update ${this.details.name}`,
          });
        })
        .finally(() => {
          this.button_loader = false;
        });
    },
    getPrograms() {
      this.program_area_loader = true;
      PROGRAM_AREAS()
        .then((data) => {
          this.programs = data.data.results;
        })
        .finally(() => {
          this.program_area_loader = false;
        });
    },
    get_ngo() {
      // this.loader = true;
      // GET_ORGANIZATION_BY_ID(this.param)
      //   .then((data) => {
      //     this.details = data.data.results;
      //   })
      //   .finally(() => {
      //     this.loader = false;
      //   });
      this.loader = true;
      axios.get(`/main/crud/organization/${this.param}`).then((response) => {
        this.details = response.data.details;
        this.loader = false;
      });
    },
  },

  mounted() {
    this.get_ngo();
    this.getPrograms();
  },
};
</script>
<style scoped>
select,
select.form-control {
  background: transparent;
  border: none;
  border: 1px solid #707070;
  -webkit-box-shadow: none;
  outline: none;
  box-shadow: none;
  border-radius: 0;
}

select {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0;
  font-family: inherit;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  color: #707070;
  outline: none;
}

select:focus {
  background: transparent;
  border: none;
  border: 1px solid #707070;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  outline: none;
}

select:focus,
select.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
label {
  font-size: 0.813rem !important;
}

.inputField,
select.form-control {
  background: #ffffff;
  border: none;
  border: 1px solid #707070;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.inputField:focus,
select.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #707070;
}

.withappend {
  padding-right: 3rem;
}

.withprepend {
  padding-left: 3rem;
}

.inputBox i {
  position: absolute;
}

.prepend {
  padding: 10px;
  color: green;
  min-width: 50px;
  text-align: center;
}

.append {
  color: green;
  min-width: 1rem;
  text-align: right;
  top: 10.6rem;
  right: 3.4rem;
}

.filled {
  background: #efe7e74d;
  border: 1px solid #707070;
}

.filled:focus {
  background: #f3f3f3;
  border: 1px solid #c5c1c1;
}

.filled::placeholder {
  color: 1px solid #c5c1c1;
}
</style>
