const routes = {
  // `${main}/health_system_area/`

  feedback_types: `/feedback_type/`,
  event_types: `/event_type/`,
  feedback: `/feedback/`,
  enquiry: `/enquiry/`,
  event: `/events/`,
  constants: '/constants/'
};

export default routes;
