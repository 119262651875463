<template>
  <div>
    <BaseLogoBar />
    <BaseNavbar />
    <div class="d-flex" id="wrapper">
      <!-- <Sidebar /> -->
      <div id="page-content-wrapper" class="mb-4">
        <div class="mb-4">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <BaseFooter />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#wrapper {
  overflow-x: hidden;
}

#page-content-wrapper {
  min-width: 100vw;
  margin-top: 5rem;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
</style>
