<template>
  <div>
    <b-modal
      body-class="border-0"
      centered
      class="border-0"
      hide-footer
      hide-header
      @hidden="modalShow = false"
      size="lg"
      ref="micro-modal"
    >
      <div class="m-4">
        <h4 class="my-4 text-left text-primary mb-4">Get Micro-data</h4>
        <p class="my-4 text-left">
          To download this Micro-data, you need to create a project. Make sure the project you
          create contains the description on how you plan to use the Micro-data, including the
          analysis you propose to perform with the Micro-data you wish to download
        </p>
        <br />
        <span
          >If you are registered user please click this link to
          <b-link to="/auth/login">Login</b-link></span
        >
        <br />
        <!-- <b-link to="/auth/login">Login</b-link></span
        > -->
        <span
          >If you are not registered user please click this link to
          <b-link to="/auth/register">Register a new account</b-link></span
        >
      </div>
      <br />
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalShow: false,
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openModal() {
      this.$refs['micro-modal'].show();
    },
    closeModal() {
      this.$refs['micro-modal'].hide();
    },
  },

  watch: {
    showModal(newVal) {
      if (newVal) {
        this.openModal();
      }
    },
  },
  // mounted() {
  //   this.$refs['micro-modal'].show();
  // },
};
</script>
