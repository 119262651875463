<template>
  <div>
    <section id="footer-section" style="background-color: #e8f5eb">
      <div class="container py-4">
        <div class="row w-100 text-left footermenu">
          <div class="col-md-3">
            <ul class="nav flex-column">
              <li class="">
                <a class="nav-link text-black font-weight-bold">QUICK LINKS</a>
              </li>
              <li class="">
                <a class="nav-link text-primary">HOME</a>
              </li>
              <li class="">
                <a class="nav-link text-primary">PROJECT INVENTORY</a>
              </li>
              <li class="text-primary ml-3">
                <b-link class="text-decoration-none" to="/inventory/project-inventory">
                  Explore Projects
                </b-link>
              </li>
              <li class="text-primary ml-3">
                <b-link class="text-decoration-none" to="/inventory/submit-project">
                  Submit Project
                </b-link>
              </li>

              <li class="">
                <a class="nav-link">Data Inventory</a>
              </li>
              <li class="text-primary ml-3">
                <b-link class="text-decoration-none" to="/inventory/dataset-inventory">
                  Explore Datasets
                </b-link>
              </li>
              <li class="text-primary ml-3">
                <b-link class="text-decoration-none" to="/inventory/submit-dataset">
                  Submit Datasets
                </b-link>
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <ul class="nav flex-column">
              <li class="">
                <a class="nav-link text-black font-weight-bold">INVENTORY PARTNERS </a>
              </li>
              <li class="">
                <a class="nav-link text-primary" href="https://ehealth4everyone.com/"
                  >eHealth4everyone</a
                >
              </li>
              <li class="">
                <a class="nav-link text-primary" href="https://www.gatesfoundation.org/"
                  >Bill and Melinda Gates Foundation</a
                >
              </li>
              <li class="">
                <a
                  class="nav-link text-primary"
                  href="https://www.health.gov.ng/index.php/department/information-communication-technology"
                  >FMOH-ICT Department</a
                >
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <ul class="nav flex-column">
              <li class="">
                <a class="nav-link text-black font-weight-bold">OTHER LINKS</a>
              </li>
              <li class="">
                <a
                  class="nav-link text-primary"
                  href="https://dhis2nigeria.org.ng/dhis/dhis-web-commons/security/login.action"
                >
                  DHIS2 Nigeria</a
                >
              </li>
              <li class="">
                <a class="nav-link text-primary" href="https://www.health.gov.ng/"
                  >Federal Ministry Of Health</a
                >
              </li>
              <li class="">
                <a
                  class="nav-link text-primary"
                  href="https://nigerianstat.gov.ng/nada/index.php/catalog"
                >
                  National Bureau of Statistics</a
                >
              </li>
              <li class="">
                <a class="nav-link text-primary" href="http://www.opendata.edostate.gov.ng/">
                  Edo State Open Data</a
                >
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <ul class="nav flex-column">
              <li class="">
                <a class="nav-link text-black font-weight-bold">CONTACT US</a>
              </li>
              <li class="">
                <a class="nav-link text-black">
                  <address>
                    Department of Health Planning, Research and Statistics, Federal Ministry of
                    Health, 11th floor New Federal Secretariat, Complex, Phase III, Central Business
                    District, FCT Abuja, Nigeria.
                  </address>
                </a>
              </li>
              <li class="">
                <a class="nav-link text-black">
                  <!-- <div class="mt-n5 d-flex mb-4">
                    <i class="fas fa-envelope mr-2"></i>
                    <span class="text-primary mt-n1">adeleke.balogun@fedcs.gov.ng </span>
                  </div> -->
                  <div class="d-flex flex-row mt-n2">
                    <div class="mt-n2 pr-0">
                      <i class="fas fa-phone-alt" />
                    </div>
                    <div>
                      <div class="mt-n3">
                        <p class="font-weight-normal d-flex">
                          <span>Mr.Otuama</span>
                          <span class="text-primary">(+2347031992732)</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li class="mt-n3">
                <a class="nav-link text-black font-weight-bold"> JOIN US ON SOCIAL MEDIA </a>
                <div class="ml-3">
                  <a class="mr-1" href="https://twitter.com/fmohnigeria">
                    <img src="@/assets/icons/social/twitter.svg" style="height: 2rem" />
                  </a>
                  <a class="mr-1">
                    <img src="@/assets/icons/social/facebook.svg" style="height: 2rem" />
                  </a>
                  <a class="mr-1">
                    <img src="@/assets/icons/social/pinterest.svg" style="height: 2rem" />
                  </a>
                  <a class="mr-1">
                    <img src="@/assets/icons/social/linkedin.svg" style="height: 2rem" />
                  </a>
                  <a class="mr-1">
                    <img src="@/assets/icons/social/instagram.svg" style="height: 2rem" />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="!$route.path.includes('feedback')">
        <router-link
          class="btn btn-outline-primary border-0 rounded-0 rotate feedback"
          to="/feedback"
        >
          Feedback
        </router-link>
      </div>
    </section>

    <section class="m-0">
      <div class="d-flex justify-content-end bg-primary">
        <p class="text-white pb-0 pt-3 mx-4">
          © 2019 Federal Ministry of Health. All Rights Reserved.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'FooterSection',
};
</script>
<style scoped>
@media screen and (max-width: 600px) {
  .footermenu {
    margin-left: auto;
    margin-right: auto;
  }

  .nav {
    text-align: center;
  }
}
* {
  font-family: 'Roboto';
}
.fab {
  margin-right: 2px;
  margin-left: 2px;
}
.text-black {
  color: black;
}

.feedback {
  background-color: #d22d2d;
  width: 6rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.9rem;
  opacity: 0.9;
  color: white;
  position: fixed;
  left: 95vw;
  bottom: 15%;
  cursor: pointer;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.feedback:hover {
  opacity: 1;
}
</style>
