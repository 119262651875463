<template>
  <div class="m5 px-5">
    <ul class="nav pb-4">
      <li>
        <a href="/">Home ></a>
      </li>
      <li>
        <a href="/admin/stakeholder">Stakeholder Inventory ></a>
      </li>
      <li>
        <a href="/admin/research">Research And Knowledge ></a>
      </li>
      <li>
        <a href="/admin/stakeholder-lists" class="active">World Health Organisation ></a>
      </li>
      <li>
        <a href="/admin/stakeholder-details" class="active">NHREC</a>
      </li>
    </ul>
    <h4 style="color: #30ab4c; width: 60%; margin-bottom: 20px">
      Serve as a secretariat to the National Health Research Ethics Committee (NHREC)
    </h4>
    <div class="text-wrap">
      <div class="texts"><span>Stakeholder </span>World Health Organisation</div>
      <div class="texts"><span>Division </span>Research Knowledge and Management</div>
      <div class="texts"><span>Branch</span>Health Research Ethics.</div>
      <div class="texts"><span>Category </span>Resource persons</div>
      <div class="texts">
        <span>Activity </span>Serve as a secretariat to the National Health Research Ethics
        Committee (NHREC)
      </div>
    </div>
    <router-link to=""><button class="btn">Back</button></router-link>
  </div>
</template>

<script>
export default {
  name: 'stakeholderDetails',
};
</script>

<style scoped>
.text-wrap {
  padding: 20px;
  margin-bottom: 30px;
  border: none;
  background: #c6c3c336;
  width: 80%;
  height: auto;
}

.texts {
  margin: 40px;
  display: flex;
  flex-direction: column;
  color: black;
}
.texts span {
  font-weight: 800;
  margin-bottom: 10px;
  color: black;
}
.btn {
  background-color: #30ab4c;
  padding: 10px;
  width: 20%;
  border-radius: 0%;
  color: #f4f4f4;
}
</style>
