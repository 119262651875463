<template>
  <div>
    <b-modal id="modal-center" size="lg" hide-header hide-footer centered ref="modal">
      <div class="p-2">
        <div class="d-flex justify-content-between my-2">
          <h3 class="text-primary">SUBMISSION AUTHORISED BY</h3>
          <p class="text-primary" @click="closeModal()">close</p>
        </div>
        <div class="row row-cols-2 my-4">
          <div class="col">
            <h6 class="font-weight-bold">NAME OF CONTACT PERSON</h6>
            <p>First Name: {{ data.details.first_name }}</p>
            <p>Last Name: {{ data.details.last_name }}</p>
          </div>
          <div class="col">
            <h6 class="font-weight-bold">POSITION</h6>
            <p>{{ data.details.position }}</p>
          </div>
          <div class="col">
            <h6 class="font-weight-bold">PHONE NO. OF CONTACT PERSON</h6>
            <p>{{ data.details.phone }}</p>
          </div>
          <div class="col">
            <h6 class="font-weight-bold">EMAIL</h6>
            <p>{{ data.details.email }}</p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    closeModal() {
      this.$refs.modal.hide();
    },
  },
};
</script>
