import axios from '@/plugin/axios';
import Routes from './routes';

export const getOrganizations = () => {
  return axios.get(Routes.organization, {
    params: {
      org_type: 1,
      page_size: 200000,
    },
  });
};

export const getSingleOrganizations = (id) => axios.get(Routes.organization`${id}`);
export const getProgramArea = () =>
  axios.get(Routes.program_areas, {
    params: {
      page_size: 100000,
    },
  });
export const addOrganization = (payload) => {
  const data = payload;

  if (payload.status === 'active') {
    data.status = true;
  } else data.status = false;
  return axios.post(Routes.ngo_routes.create, data);
};
export const updateOrganization = (id, payload) =>
  axios.patch(Routes.ngo_routes.update + id, payload);

export const patchOrganization = (id, payload) => axios.patch(Routes.organization + id, payload);

export const news = () => axios.get(Routes.news);
