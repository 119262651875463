// import vuex from 'vuex';
import moment from 'moment';

export default class DatasetRequests {
  /**
   *
   * @param {import("axios").AxiosInstance} http
   * @param {Object} services
   * @param {*} store
   */
  constructor(http, services, store) {
    this.http = http;
    this.services = services;
    this.store = store;
    this.moment = moment;
  }

  // Error Handling for all
  processError(error) {
    let errmsg;

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { data, status } = error.response;
      // set errmsg based on error.response.status code
      switch (status) {
        case 400:
          errmsg = data.message || 'Invalid credentials';
          break;
        case 401:
          errmsg = data.message || data.error || 'Unauthorised request';
          if (data.error.includes('jwt') && data.error.includes('expired')) {
            this.store.dispatch('user/logOut');
          }
          break;
        case 404:
          errmsg = data.message || 'Requested params not found';
          break;
        case 422:
          errmsg = data.error[0].message || 'Invalid credentials';
          break;
        case 500:
          errmsg = data.error || 'Network error, try again';
          break;
        case 501:
          errmsg = data.error || 'Network error, try again';
          break;
        case 502:
          errmsg = data.error || 'Network error, try again';
          break;
        case 503:
          errmsg = data.error || 'Service unavailable, try again later';
          break;
        default:
          // eslint-disable-next-line operator-linebreak
          errmsg =
            // eslint-disable-next-line operator-linebreak
            data.message ||
            // eslint-disable-next-line operator-linebreak
            data.error.message ||
            // eslint-disable-next-line operator-linebreak
            data.error[0].message ||
            'An error occurred, try again';
          break;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      // and an instance of http.ClientRequest in node.js
      errmsg = 'Internet connection error';
    } else {
      // Something happened in setting up the request that triggered an Error
      errmsg = error.message || 'Unknown error, try again';
    }

    // Display error message in a snackbar
    this.store.commit('snackbar/show', { icon: 'error', text: errmsg });
  }

  // format the dates comming from the api
  formatDate(date, format) {
    return this.moment(date).format(format);
  }

  async getAllGeoCoverage(page = 1, pagesize = 1000) {
    try {
      const data = await this.http.get(
        `${this.services.getAllGeoCoverage}?page=${page}&size=${pagesize}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllHealthAreas() {
    try {
      const response = await this.http.get(`${this.services.getConstants}`);
      return response.data.HealthSystemArea;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllStakeholdersCategory() {
    try {
      const response = await this.http.get(`${this.services.getConstants}`);
      return response.data.StakeholderCategory;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllStakeholdersDivision() {
    try {
      const response = await this.http.get(this.services.getConstants);
      return response.data.StakeholderDivision;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllSensitivity() {
    try {
      const response = await this.http.get(`${this.services.getConstants}`);
      return response.data.ProjectSensitivity;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getOwnerType() {
    try {
      const response = await this.http.get(`${this.services.getConstants}`);
      return response.data.ProjectOwnership;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getPopulationUnit() {
    try {
      const response = await this.http.get(`${this.services.getConstants}`);
      return response.data.PopulationUnit;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllProgramAreas(page = 1, pagesize = 100) {
    try {
      const data = await this.http.get(
        `${this.services.getAllProgramAreas}?page=${page}&size=${pagesize}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getSpecificProgramArea(programAreaId) {
    try {
      const data = await this.http.get(`${this.services.getAllProgramAreas}${programAreaId}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllOrganization(page = 1, pagesize = 1000) {
    try {
      const data = await this.http.get(
        `${this.services.getAllOrganization}?page=${page}&size=${pagesize}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getSpecificOrganization(orgId) {
    try {
      const data = await this.http.get(`${this.services.getAllOrganization}${orgId}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllNGOs(page = 1, pagesize = 1000) {
    try {
      const data = await this.http.get(
        `${this.services.getAllOrganization}?filter=Non%20Governmental%20Organization&page=${page}&size=${pagesize}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllInternationalPartners(page = 1, pagesize = 1000) {
    try {
      const data = await this.http.get(
        `${this.services.getAllOrganization}?filter=International%20Partner&page=${page}&size=${pagesize}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllOrgainzationWithUnspecifiedTypes(page = 1, pagesize = 1000) {
    try {
      const data = await this.http.get(
        `${this.services.getAllOrganization}?filter=?filter=Unspecified%20Type&page=${page}&size=${pagesize}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async createDataset(datasetData) {
    try {
      const data = await this.http.post(`/datasets/`, datasetData);
      // const data = await this.http.post(this.services.getAllDataset, datasetData);
      return data;
    } catch (error) {
      console.log(error);
      // eslint-disable-next-line no-restricted-syntax
      for (const value of datasetData.values()) {
        console.log(value);
      }

      return Promise.reject(error);
    }
  }

  async createProjectForDatasetRequest(dataentry) {
    try {
      const result = await this.http.post(`/user_projects/`, dataentry);
      return result;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getProjectForSpecificUser() {
    try {
      const result = await this.http.get(`/user_projects/`);
      return result;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async addOrganizationDatasetRole(datasetId, organizationId, role) {
    try {
      const inputData = {
        organization: organizationId,
        role,
      };
      const data = await this.http.post(`/datasets/${datasetId}/organization_roles`, inputData);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllOrganizationDatasetRoles(datasetId) {
    try {
      const data = await this.http.get(`/datasets/${datasetId}/organization_roles`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getDatasets(page = 1, pagesize = 1000) {
    try {
      const data = await this.http.get(
        `${this.services.getAllDataset}?page=${page}&size=${pagesize}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async postDataset() {
    try {
      const data = await this.http.post(
        `${this.services.getAllDataset}?page=${page}&size=${pagesize}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getDatasetsSearch(search) {
    try {
      const data = await this.http.get(
        `${this.services.getAllDataset}?page=${1}&size=${10}&search=${search}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async requestDataset(requestInfo) {
    try {
      const data = await this.http.post(this.services.getAllDataRequests, requestInfo);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getStates() {
    try {
      const data = await this.http.get(
        `${this.services.getAllGeoCoverage}?level=State&page=1&size=100`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async editDataset(id, datasetData) {
    try {
      const data = await this.http.put(`${this.services.getAllDataset}${id}/`, datasetData);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllStakeholdersBranch(page = 1, pagesize = 1000) {
    try {
      const data = await this.http.get(
        `${this.services.getAllStakeholdersBranch}?page=${page}&size=${pagesize}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getOrganizationDatasets(organizationId, page = 1, pagesize = 1000) {
    try {
      const data = await this.http.get(
        `${this.services.getAllDataset}?page=${page}&size=${pagesize}&organization=[${organizationId}]`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  // eslint-disable-next-line consistent-return, no-unused-vars
  async getUserCreatedProjects(id) {
    try {
      const data = await this.http.get();
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }
  // async getUserProjects(id, pageSize = 1000) {
  //   try {
  //     const data = await this.http.get(
  //       `${this.services.getUserCreatedProjects}${id}&size=${pageSize}`
  //     );
  //     return data;
  //   } catch (error) {
  //     console.log(error);
  //     return Promise.reject(error);
  //   }
  // }

  async submitProject(projectData) {
    try {
      const data = await this.http.post(this.services.getAllProject, projectData);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async editProject(id, projectData) {
    try {
      const data = await this.http.patch(`${this.services.getAllProject}${id}/`, projectData);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async filterDatasets(states = [], programAreas = []) {
    try {
      console.lot(states, programAreas);
      const data = await this.http.post(`${this.services.filterRoute}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async filterProjects() {
    // states = [],
    // programAreas = [],
    // healthSystemAreas = [],
    // projectSensitivity = [],
    // status = []
    try {
      const data = await this.http.post(`${this.services.filterRoute}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllProjects(page = 1, pagesize = 1000) {
    try {
      const data = await this.http.get(
        `${this.services.getAllProject}?page=${page}&size=${pagesize}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getProjectDetails(projectId) {
    try {
      const data = await this.http.get(`${this.services.getAllProject}${projectId}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllStakeholders(page = 1, pagesize = 1000) {
    try {
      const data = await this.http.get(
        `${this.services.getAllStakeholders}?page=${page}&size=${pagesize}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getStakeholderById(id) {
    try {
      const data = await this.http.get(`${this.services.getAllStakeholders}${id}`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async addOrganizationProjectRole(projectId, organizationId, role) {
    try {
      const inputData = {
        organization: organizationId,
        role,
      };
      const data = await this.http.post(`/projects/${projectId}/organization_roles`, inputData);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getOrganizationProjectRole(projectId) {
    try {
      const data = await this.http.get(`/projects/${projectId}/organization_roles`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async createUserProject(userId, createdata) {
    try {
      const data = await this.http.post(`users/${userId}/projects/`, createdata);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getDatasetDetails(id) {
    try {
      const data = await this.http.get(`${this.services.getAllDataset}${id}/`);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getAllStakeholderActivitiesById(id) {
    try {
      const data = await this.http.get(
        `${this.services.getAllStakholderActivities}?stakeholder=${id}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  //   async getAllStakeholderActivityDetailsById(id) {
  //     try {
  //       const data = await this.http.get(
  //         `${this.services.getAllStakeholderActivityDetailsById}/${id}`
  //       );
  //       return data;
  //     } catch (error) {
  //       console.log(error);
  //       return Promise.reject(error);
  //     }
  //   }

  //
  // async getUserProjects(userId) {
  //   try {
  //     const data = await this.http.get(`users/${userId}/projects/`);
  //     return data;
  //   } catch (error) {
  //     console.log(error);
  //     return Promise.reject(error);
  //   }
  // }

  //   async getAllStakeholdersDivisionActivityById(url) {
  //     try {
  //       const data = await this.http.get(
  //         `${this.services.getAllStakeholdersDivisionActivityById}${url}`
  //       );
  //       return data;
  //     } catch (error) {
  //       console.log(error);
  //       return Promise.reject(error);
  //     }
  //   }
}
