const table2 = {
  selected: null,
  isBusy: false,

  fields: [
    {
      key: 'no',
      label: 'S/N',
    },
    {
      key: 'stakeholder',
      label: 'STAKEHOLDER',
      sortable: true,
    },
    {
      key: 'branch',
      label: 'BRANCH',
    },
    {
      key: 'category',
      label: 'CATEGORY',
    },
    {
      key: 'action',
    },
  ],
  items: [
    {
      no: 2,
      stakeholder: 'e-xapl',
      branch: 'Wuse',
      category: 'ngo',
    },
  ],
};

const table = {
  selected: null,
  isBusy: false,

  fields: [
    {
      key: 'id',
      label: 'S/N',
    },
    {
      key: 'name',
      label: 'NAME OF NGO',
      sortable: true,
    },
    {
      key: 'details.year',
      label: 'YEAR OF REGISTRATION',
      sortable: true,
    },
    {
      key: 'details.state',
      label: 'STATE',
      sortable: true,
    },
    {
      key: 'details.geo_zone',
      label: 'GEOGRAPHICAL ZONE',
      sortable: true,
    },
    {
      key: 'details.status',
      label: 'STATUS',
      sortable: true,
    },
    {
      key: 'action',
      label: 'ACTION',
    },
  ],
  items: [
    {
      no: 1,
      name: 'Ehelp',
      program_area: 'Wuse',
      year: '2020',
      state: 'Abuja',
      zone: 'FCT',
      phone: '098765423',
      firstname: 'makr',
      lastname: 'e-health',
      email: 'e4e@help',
    },
    {
      no: 2,
      name: 'Ehelp',
      program_area: 'Wuse',
      year: '2020',
      state: 'Lagos',
      zone: 'FCT',
    },
    {
      no: 3,
      name: 'Ehelp',
      program_area: 'Wuse',
      year: '2020',
      state: 'Abia',
      zone: 'FCT',
    },
    {
      no: 4,
      name: 'Ehelp',
      program_area: 'Wuse',
      year: '2020',
      state: 'Aba',
      zone: 'FCT',
    },
    {
      no: 5,
      name: 'Ehelp',
      program_area: 'Wuse',
      year: '2020',
      state: 'loc',
      zone: 'FCT',
    },
    {
      no: 6,
      name: 'Ehelp',
      program_area: 'Wuse',
      year: '2020',
      state: 'Abuja',
      zone: 'FCT',
    },
    {
      no: 7,
      name: 'Ehelp',
      program_area: 'Wuse',
      year: '2020',
      state: 'Abuja',
      zone: 'FCT',
    },
    {
      no: 8,
      name: 'Ehelp',
      program_area: 'Wuse',
      year: '2020',
      state: 'Abuja',
      zone: 'FCT',
    },
    {
      no: 9,
      name: 'Ehelp',
      program_area: 'Wuse',
      year: '2020',
      state: 'Abuja',
      zone: 'FCT',
    },
    {
      no: 10,
      name: 'Ehelp',
      program_area: 'Wuse',
      year: '2020',
      state: 'Abuja',
      zone: 'FCT',
    },
  ],
};

const table3 = {
  selected: null,
  isBusy: false,

  fields: [
    {
      key: 'no',
      label: 'S/N',
    },
    {
      key: 'name',
      label: 'NAME OF NGO',
      sortable: true,
      _rowVariant: 'danger',
    },
    {
      key: 'action',
    },
  ],
  items: [{ no: 10, name: 'Ehelp' }],
};

const table4 = {
  selected: null,
  isBusy: false,

  fields: [
    {
      key: 'id',
      label: 'S/N',
    },
    {
      key: 'name',
      label: 'NAME OF NGO',
      sortable: true,
    },
    {
      key: 'created_at',
      label: 'DATE ADDED',
      sortable: true,
    },
    {
      key: 'updated_at',
      label: 'LAST EDITED',
      sortable: true,
    },
    {
      key: 'status',
      label: 'STATUS',
      sortable: true,
    },
    {
      key: 'action',
    },
  ],
  items: [
    {
      no: 1,
      name: 'Ehelp',
      date: '20/20/10',
      last_edited: '20/20/10',
      status: 'active',
    },
    {
      no: 2,
      name: 'Ehelp',
      date: '20/20/10',
      last_edited: '20/20/10',
      status: 'active',
    },
    {
      no: 3,
      name: 'Ehelp',
      date: '20/20/10',
      last_edited: '20/20/10',
      status: 'suspended',
    },
  ],
};

export { table2, table, table3, table4 };
