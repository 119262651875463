import index from './views/index.vue';
import projectHome from './views/projectHome.vue';
import Project from './views/project/Project.vue';
import Data from './views/dataset/Dataset.vue';
import Datarequest from './views/Datarequest.vue';
import dataHome from './views/dataHome.vue';
import datarequestHome from './views/datarequestHome.vue';
import NGO from './views/ngo/NGO.vue';
import ngoHome from './views/ngoHome.vue';
import subNgo from './views/subpages/subNgo.vue';
import subProject from './views/subpages/subProject.vue';
import ngoForm from './views/subpages/ngoForm.vue';
import subDataset from './views/subpages/subDataset.vue';
import subDatarequest from './views/subpages/subDatarequest.vue';
import Faq from './views/faq.vue';
import addFaq from './views/subpages/addQuestion.vue';
import User from './views/user.vue';
import addUser from './views/subpages/addUser.vue';
import editUser from './views/subpages/editUser.vue';
import editFaq from './views/subpages/editFaq.vue';
import editPermission from './views/subpages/editPermission.vue';
import Stakeholder from './views/Stakeholder.vue';
import StakeholderResearch from './views/subpages/stakeholderResearch.vue';
import StakeholderHealth from './views/subpages/stakeholderHealth.vue';
import StakeholderInternational from './views/subpages/stakeholderInternational.vue';
import StakeholderManagement from './views/subpages/stakeholderManagement.vue';
import StakeholderPolicy from './views/subpages/stakeholderPolicy.vue';
import StakeholderLists from './views/subpages/stakeholderLists.vue';
import StakeholderDetails from './views/subpages/stakeholderDetails.vue';
import EditStakeholder from './views/subpages/editStakeholder.vue';
import AddStakeholder from './views/subpages/addStakeholder';
import Feedback from './views/Feedback.vue';
import Enquiries from './views/Enquiries.vue';
import Activities from './views/Activities.vue';

// activate new admin added
import AccountActivation from './views/AccountActivation.vue';

export default [
  {
    path: '/admin',
    component: index,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: 'project',
        meta: {
          requiresAuth: false,
        },
        component: projectHome,
        children: [
          {
            path: '',
            component: Project,
            meta: {
              requiresAuth: false,
            },
          },
          {
            path: 'sub-project',
            component: subProject,
            meta: {
              requiresAuth: false,
            },
          },
        ],
      },
      {
        path: 'sub-project',
        component: subProject,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'data',
        component: dataHome,
        meta: {
          requiresAuth: false,
        },
        children: [
          {
            path: '',
            component: Data,
            meta: {
              requiresAuth: false,
            },
          },
          {
            path: 'sub-data',
            component: subDataset,
            meta: {
              requiresAuth: false,
            },
          },
        ],
      },
      {
        path: 'datarequest',
        component: datarequestHome,
        meta: {
          requiresAuth: false,
        },
        children: [
          {
            path: '',
            component: Datarequest,
            meta: {
              requiresAuth: false,
            },
          },
          {
            path: 'sub-datarequest',
            component: subDatarequest,
            meta: {
              requiresAuth: false,
            },
          },
        ],
      },
      {
        path: 'stakeholder',
        meta: {
          requiresAuth: false,
        },
        component: Stakeholder,
      },
      {
        path: 'research',
        component: StakeholderResearch,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'edit-stakeholder',
        component: EditStakeholder,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'add-new-stakeholder',
        component: AddStakeholder,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'management-evaluation',
        component: StakeholderManagement,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'health-system',
        component: StakeholderHealth,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'policy-planning',
        component: StakeholderPolicy,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'international-coperation',
        component: StakeholderInternational,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'stakeholder-lists',
        component: StakeholderLists,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'stakeholder-details',
        component: StakeholderDetails,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'enquiries',
        component: Enquiries,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'sub-dataset',
        component: subDataset,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'ngo',
        component: ngoHome,
        meta: {
          requiresAuth: false,
        },
        children: [
          {
            path: '',
            component: NGO,
            meta: {
              requiresAuth: false,
            },
          },
          {
            path: 'sub-ngo',
            component: subNgo,
            meta: {
              requiresAuth: false,
            },
          },
        ],
      },
      {
        path: 'sub-ngo',
        component: subNgo,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'ngo-form',
        component: ngoForm,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'faq',
        component: Faq,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'add-faq',
        component: addFaq,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'user',
        component: User,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'add-user',
        component: addUser,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'edit-user',
        component: editUser,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'edit-faq',
        component: editFaq,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'edit-permission',
        component: editPermission,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'activities',
        meta: {
          requiresAuth: false,
        },
        component: Activities,
      },
      // {
      //   path: '/activate/:token',
      //   component: VerifyResetPassword,
      // },
    ],
  },
];
