<template>
  <div class="main">
    <h4>
      {{ getDataset.name }}
    </h4>

    <div class="grid">
      <div>Start date : {{ getDataset.start_date }}</div>
      <div>End date : {{ getDataset.end_date }}</div>
    </div>

    <div class="grid">
      <div>Population unit : {{ getDataset.population_unit }}</div>
      <div>Status : {{ getDataset.status }}</div>
    </div>

    <div class="grid">
      <div><button @click="approve()" class="approve_btn">Approve</button></div>
      <div><button @click="reject()" class="reject_btn">Reject</button></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('DATASET_STORE', ['getDataset']),
  },

  methods: {
    ...mapActions('admin', [
      'APPROVE_DATASET',
      'REJECT_DATASET', // -> this.LOGIN()
    ]),
    approve() {
      console.log('to approve');

      this.APPROVE_DATASET(this.getDataset.id)
        .then(() => {
          this.$swal('Dataset approved!');
          this.$router.go(-1);
        })
        .catch(() => {
          this.$swal('Dataset not approved');
        });
    },
    reject() {
      this.REJECT_DATASET(this.getDataset.id)
        .then(() => {
          this.$swal('Dataset rejected!');
          this.$router.go(-1);
        })
        .catch(() => {
          this.$swal('Dataset not rejected');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  justify-content: center;
  align-items: center;
}
.grid {
  display: grid;
  grid-template-columns: 50% 50%;
}

.approve_btn {
  background-color: green;
  margin: 20px;
  width: 150px;
  color: white;
  border-radius: 5px;
  opacity: 1;
}

.approve_btn:hover {
  background-color: green;
  opacity: 0.8;
}

.reject_btn {
  background-color: green;
  margin: 20px;
  width: 150px;
  color: white;
  border-radius: 5px;
}

.reject_btn:hover {
  background-color: green;
  opacity: 0.8;
}
</style>
