import Routes from './routes';
import axios from '../../../../plugin/axios';

export const getComments = (params) => axios.get(Routes.comments, { params });
export const docComments = () => axios.get(Routes.comments);
export const addComment = (data) => axios.post(Routes.comments, data);
export const notification = (sender) =>
  axios.get(Routes.comments, {
    params: {
      sender,
    },
  });
export const markAsRead = (id) =>
  axios.patch(`${Routes.comments}${id} /`, {
    read: true,
  });
