<template>
  <div>
    <div class="m-5">
      <span class="my-4" style="color: #30ab4c; font-weight: 500; font-size: 22px">
        SUBMIT ANNUAL REPORTS
      </span>
      <p class="mt-4">
        Please complete the following questions by selecting the answer(s) of your choice or writing
        in the space provided.
      </p>
      <form @submit.prevent="submit()">
        <div class="my-5 wrap">
          <span class="title">Report title</span>
          <input type="text" v-model="title" class="form-control value p-4" placeholder="" />
        </div>
        <div class="my-5 wrap">
          <span class="title">Name of organization</span>
          <input type="text" v-model="description" class="form-control value p-4" placeholder="" />
        </div>

        <div class="my-5 wrap">
          <span class="title fw-bold mb-4">Reporting Period</span>
          <div class="d-flex align-items-center justify-content-between mt-4">
            <div class="">
              <label class="option">Year</label>
              <select class="form-control select" v-model="year">
                <option v-for="y in years" :key="y">{{ y }}</option>
              </select>
            </div>
            <div class="">
              <label class="option">Start Month</label>
              <select class="form-control select" v-model="start_month">
                <option v-for="el in months" :key="el">{{ el }}</option>
              </select>
            </div>
            <div class="">
              <label class="option">End Month</label>
              <select class="form-control select" v-model="end_month">
                <option v-for="el in months" :key="el">{{ el }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="my-5 wrap">
          <span class="title fw-bold mb-4">Submission was authorized by</span>
          <div class="d-flex mt-4">
            <div class="option1">
              <label>First name</label>
              <input
                type="text"
                v-model="first_name"
                class="form-control value p-4"
                placeholder=""
              />
            </div>
            <div class="option1">
              <label>Last name</label>
              <input
                type="text"
                v-model="last_name"
                class="form-control value p-4"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="my-5 wrap">
          <span class="title">Position</span>
          <input type="text" v-model="position" class="form-control value2 p-4" placeholder="" />
        </div>
        <div class="my-5 wrap">
          <span class="title">Email</span>
          <input type="email" class="form-control value2 p-4" v-model="email" placeholder="" />
        </div>
        <div class="my-5 wrap">
          <span class="title">Achievements (for the reporting period)</span>
          <textarea
            type="text"
            v-model="achievements"
            class="form-control value p-4"
            placeholder=""
          />
        </div>
        <div class="my-5 wrap">
          <span class="title">Challenges</span>
          <textarea
            type="text"
            v-model="challenges"
            class="form-control value p-4"
            placeholder=""
          />
        </div>
        <div class="my-5 wrap">
          <span class="title">Summary (compulsory)</span>
          <textarea type="text" v-model="summary" class="form-control value p-4" placeholder="" />
        </div>
        <div class="my-5 wrap d-flex flex-column">
          <span class="title">Upload report [select file pdf. docx.]</span>
          <input type="file" @change="setFile" class="p-4" />
        </div>
        <div class="my-5 w-60 d-flex">
          <button type="submit" class="btn">Submit</button>
          <button class="btn">Save and Continue Later</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SubmitReports',
  data() {
    return {
      year: '',
      title: '',
      description: '',
      first_name: '',
      last_name: '',
      position: '',
      email: '',
      achievements: '',
      challenges: '',
      summary: '',
      report_file: '',
      start_month: '',
      end_month: '',
      years: [],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  mounted() {
    this.generateArrayOfYears();
  },
  methods: {
    generateArrayOfYears() {
      const max = new Date().getFullYear();
      const min = max - 9;
      for (let i = max; i >= min; i--) {
        this.years.push(i);
      }
      return this.years;
    },
    ...mapActions('organisation', ['NEW_REPORT']),
    setFile(e) {
      // console.log(e.target.files[0], 'chisom');
      // eslint-disable-next-line prefer-destructuring
      this.report_file = e.target.files[0];
    },
    async submit() {
      const formData = new FormData();
      formData.append('year', this.year);
      formData.append('title', this.title);
      formData.append('description', this.description);
      formData.append('first_name', this.first_name);
      formData.append('last_name', this.last_name);
      formData.append('position', this.position);
      formData.append('email', this.email);
      formData.append('achievements', this.achievements);
      formData.append('challenges', this.challenges);
      formData.append('summary', this.summary);
      formData.append('report_file', this.report_file);
      formData.append('start_month', this.start_month);
      formData.append('end_month', this.end_month);
      try {
        const res = await this.NEW_REPORT({ id: this.user.id, data: formData });
        console.log('report', res);
        this.$router.push('/organizations/');
      } catch (err) {
        console.log(err);
      }
      this.year = '';
      this.title = '';
      this.description = '';
      this.first_name = '';
      this.last_name = '';
      this.position = '';
      this.email = '';
      this.achievements = '';
      this.challenges = '';
      this.summary = '';
      this.report_file = '';
      this.start_month = '';
      this.end_month = '';
    },
  },
};
</script>

<style scoped>
.title {
  font-weight: 600;
  margin-bottom: 10px;
}
.btn:first-child {
  background-color: #30ab4c;
  padding: 10px;
  color: white;
}
.btn:last-child {
  color: #30ab4c;
}
.option {
  color: rgb(56, 56, 53);
  padding: 10px;
}
.select {
  width: 250px;
}
.value2 {
  width: 600px;
}
.option1:first-child {
  margin-right: 100px;
}
</style>
