<template>
  <div>
    <b-container>
      <!-- <div> -->
      <b-row class="mb-4 ml-1">
        <h4 class="text-left text-primary ml-n1">
          {{ division }}
        </h4>
      </b-row>
      <b-row class="mb-n2 mt-n4">
        <b-col cols="6" md="2">
          <base-select
            ref="aselect"
            v-model="branchSelected"
            width="110%"
            selected="All Branches"
            :disabled="false"
            :items="getStakeholderBranches"
          />
        </b-col>
        <b-col cols="6" md="2">
          <base-select
            v-model="categorySelected"
            :disabled="false"
            width="115%"
            :items="getStakeholderCategories"
            selected="All Categories"
          />
        </b-col>
        <b-col cols="12" md="4" class="ml-auto">
          <base-input
            v-model="searchInput"
            prepend="fa fa-search"
            prependclass="mt-1 pt-n2"
            width="100%"
            :filled="true"
            placeholder="Search"
            @input="searchItems()"
          />
        </b-col>
      </b-row>
    </b-container>
    <div class="mt-5" />
    <b-container>
      <b-table
        :bordered="false"
        thead-class="bg-success text-dark text-left borderless"
        :fields="fields"
        :items="itemsToWorkWith"
        :busy="isBusy"
        :current-page="currentPage"
        :per-page="perPage"
        tbody-class="text-left"
        responsive="sm"
      >
        <!-- customize the action button header -->
        <template #head(action)="data">
          <span class="d-none">{{ data.label.toUpperCase() }}</span>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2 loading">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <!-- add numbering to table -->
        <template #cell(sn)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(action)="data">
          <base-button
            text="View Roles and Activities"
            width="100%"
            :to="`/inventory/stakeholder/view/${data.item.id}/${data.item.STAKEHOLDER}`"
          />
        </template>
      </b-table>
      <div class="mx-auto text-center mt-5">
        <!-- <p class="mx-2 mt-2">Rows per page - {{ perPage }}</p> -->
        <p class="mx-2 mt-2">
          Page {{ currentPage }} of
          {{ Math.ceil(itemsToWorkWith.length / perPage) }}
        </p>
        <div class="d-flex justify-content-center align-items-center mt-4">
          <p class="mt-1 mx-2">Row per page</p>
          <select id="zone" v-model="perPage" style="width: 50px; height: 40px" class="mb-3 mr-1">
            <option value="5">5</option>
            <option value="10">10</option>
          </select>
          <b-pagination
            v-model="currentPage"
            :total-rows="itemsToWorkWith.length"
            :per-page="perPage"
            align="center"
          />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { uniq } from 'lodash';

export default {
  components: {
    // Pagination,
  },
  data() {
    return {
      searchInput: '',
      perPage: 10,
      currentPage: 1,
      branchSelected: null,
      categorySelected: null,
      isBusy: false,
      fields: ['SN', 'STAKEHOLDER', 'BRANCH', 'CATEGORY', 'action'],
      items: [],
      division: '',
      itemsToWorkWith: [],
      getStakeholderBranches: [],
      getStakeholderCategories: [],
    };
  },
  computed: {
    itemsToShowComputed() {
      const skip = 10 * this.currentPage;
      const currentPage = skip - 10;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.itemsToWorkWith.slice(currentPage, skip);
    },
    rows() {
      return this.itemsToWorkWith.length;
    },
  },
  watch: {
    async branchSelected(val) {
      if (this.categorySelected !== null) {
        this.itemsToWorkWith = this.items.filter((item) => {
          // eslint-disable-next-line prettier/prettier
          return item.BRANCH === val && item.CATEGORY === this.categorySelected.name;
        });
      }
      this.itemsToWorkWith = this.items.filter((item) => {
        return item.BRANCH === val;
      });
      if (val === 'All Branches') {
        this.itemsToWorkWith = this.items;
      }
    },
    async categorySelected(val) {
      if (this.branchSelected !== null) {
        this.itemsToWorkWith = this.items.filter((item) => {
          return item.CATEGORY === val && item.BRANCH === this.branchSelected.name;
        });
      }
      this.itemsToWorkWith = this.items.filter((item) => {
        return item.CATEGORY === val;
      });
      if (val === 'All Categories') {
        this.itemsToWorkWith = this.items;
      }
    },
  },
  async mounted() {
    try {
      this.isBusy = true;
      // const categories = await this.$dataset.getAllStakeholdersCategory();
      // const branches = await this.$dataset.getAllStakeholdersBranch();
      // this.getStakeholderBranches = branches.data.results;
      // this.getStakeholderCategories = categories.data.results;
      const stakeholders = await this.$dataset.getAllStakeholders();
      const filteredStakeholders = stakeholders.data.results.filter((stakeholder) =>
        stakeholder.divisions.includes(this.$route.params.division)
      );

      // eslint-disable-next-line arrow-body-style
      this.items = uniq(
        filteredStakeholders.map((item) => {
          return {
            id: item.id,
            SN: 1,
            STAKEHOLDER: item.name,
            BRANCH: item.branch,
            CATEGORY: item.category,
          };
        })
      );
      this.itemsToWorkWith = this.items;

      // branches and categories are gotten from available data
      const Allbranches = this.itemsToWorkWith.map((item) => item.BRANCH);
      const AllCategories = this.itemsToWorkWith.map((item) => item.CATEGORY);
      this.getStakeholderCategories = uniq(AllCategories);
      this.getStakeholderBranches = uniq(Allbranches);
      this.isBusy = false;
      this.itemsToWorkWith = this.items;
    } catch (error) {
      this.isBusy = false;
    }
    this.aRoutea();
  },
  methods: {
    searchItems() {
      this.itemsToWorkWith = this.items.filter((item) => {
        return item.STAKEHOLDER.toLowerCase().includes(this.searchInput.toLowerCase());
      });
    },
    changePage(event) {
      this.currentPage = event;
    },
    // Display heading for each route

    aRoutea() {
      this.division = this.$route.params.division;
    },
  },
};
</script>

<style scoped>
.loading {
  padding-top: 5rem;
  height: 10rem;
}
</style>
