<template>
  <div class="project">
    <!-- projects pangination -->
    <div class="admin-nav">
      <ul class="nav">
        <li>
          <a href="#">Home ></a>
        </li>
        <li>
          <a href="#">Data Inventory ></a>
        </li>
        <li>
          <a class="active" href="#">All Datasets</a>
        </li>
      </ul>
      <div class="tab-btn">
        <!-- <button @click="changeAll()" :class="[isAll ? 'btn-active' : '']">All Datasets</button> -->
        <button @click="changePend()" :class="[isPending ? 'btn-active' : '']">
          Pending Datasets
        </button>
        <button @click="changeApprov()" :class="[isApproved ? 'btn-active' : '']">
          Approved Datasets
        </button>
      </div>
    </div>

    <br />
    <b-row class="mb-5 ml-0" align-h="between">
      <b-col cols="12" sm="4" class="mt-4 ml-n3">
        <h4 class="text-left text-primary">Explore Datasets</h4>
      </b-col>
      <b-col
        cols="12"
        sm="3"
        class="text-right mt-n1 mr-n2 ml-n3 ml-sm-auto pl-4 justify-content-end d-flex"
      >
        <input
          prepend="fa fa-search"
          prependclass="mt-1"
          :filled="true"
          @keyup.enter="onSearch"
          placeholder="Search"
          width="100%"
          v-model="search"
          v-if="false"
          class="search"
        />
      </b-col>
    </b-row>
    <div>
      <b-row class="p-0" no-gutters align-h="between">
        <b-col cols="12" sm="9">
          <div v-if="loading" class="text-center loading-icon">
            <b-spinner v-if="loading" x-large></b-spinner>
          </div>
          <div v-else>
            <div v-if="isDataset">
              <DatasetCard
                v-for="(dataset, index) in ComputedDatasets"
                :key="index"
                :to="`/inventory/dataset-view/${dataset.id}`"
                :seemorelink="false"
                :date="dataset.created_at"
                :id="dataset.id"
                :status="dataset.status.value"
              >
                <template slot="body">
                  <b-card-title class="text-left font-weight-bold text-black-50 h5">
                    {{ dataset.name }}
                  </b-card-title>
                  <b-card-text class="text-left text-black-50">
                    Capacity building for Local Governments and States’ Ministries, Departments and
                    Agencies (MDAs) responsible for rural water supply and sanitation intervention.
                  </b-card-text>

                  <div class="text-left mt-4">
                    <span class="font-weight-bolder text-black-50">Period of Implementation:</span>
                    <span class="ml-1 text-black-50"
                      >{{ getYear(dataset.start_date) }} - {{ getYear(dataset.end_date) }}</span
                    >
                  </div>

                  <div class="text-left mt-4">
                    <span
                      v-for="(program, index) in dataset.program_area"
                      :key="index"
                      class="ml-1 text-primary text-decoration-none"
                      ><span v-if="index !== dataset.program_area.length - 1">{{ program }},</span>
                      <span v-else>{{ program }}</span></span
                    >
                  </div>
                </template>
              </DatasetCard>

              <div v-if="datasets.length === 0">
                No datasets available
                <img src="../../../../assets/no-data.svg" alt="" width="500px" />
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <p class="mt-1 mx-2">Row per page</p>
              <select id="zone" v-model="perPage" style="width: 50px; height: 40px">
                <option value="3">3</option>
                <option value="5">5</option>
                <option value="10">10</option>
              </select>
              <p class="mx-3 mx-4 h5">
                <!-- {{ currentPage * perPage }} -->
                <span>Page </span>{{ currentPage }} of
                {{ Math.ceil(computedData.length / perPage) }}
              </p>
              <b-pagination
                v-model="currentPage"
                :total-rows="computedData.length"
                :per-page="perPage"
                aria-controls="my-table"
              />
            </div>
          </div>
        </b-col>
        <b-col cols="3" class="pl-5 d-none d-sm-block">
          <FilterSideBar @filterObjs="fetchDataBasedOnSelectedRequirements" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.project {
  padding: 30px 0px 0px 20px;
}
.admin-nav {
  background: #fff;
  top: 0;
  left: 0;
  z-index: 1030;
  position: sticky;
  width: 100%;
}
h4 {
  color: #707070;
}
.paragraph {
  color: #707070;
  padding-top: 20px;
}
.card-section {
  padding-top: 20px;
}
.card {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  width: 1000px;
  height: 300px;
  padding: 20px 30px 0px 20px;
  margin: 0 auto;
}
.card-section {
  margin-bottom: 25px;
}
.card-bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}
button {
  border-radius: 10px 12px 1px 0px;
  border: 1px solid#2DA047;
  background: white;
  color: #2da047;
  width: 230px;
  font-size: 15px;
  // background: #2da047 0% 0% no-repeat padding-box;
}
.tab-btn {
  display: flex;
  padding-top: 25px;
}
.btn-active {
  background: #2da047 0% 0% no-repeat padding-box;
  color: #fff;
}
.overflow-auto {
  display: flex;
  justify-content: center;
}
</style>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import DatasetCard from '../../components/Shared/DatasetCard.vue';
// import Pagination from '../../components/Shared/Pagination.vue';
import FilterSideBar from '../../components/Shared/FilterSideBar.vue';

export default {
  components: {
    DatasetCard,
    // Pagination,
    FilterSideBar,
  },
  data() {
    return {
      search: '',
      isAll: false,
      isApproved: false,
      isPending: true,
      currentPage: 1,
      loading: false,
      perPage: 5,
      datasets: [],
      tempDatasets: '',
      isDataset: true,
      completed: '',
      ongoing: '',
      completedDatasets: '',
      ongoingDatasets: '',
    };
  },

  methods: {
    // async changeAll() {
    //   this.isDataset = true;
    //   this.loading = true;
    //   this.isAll = true;
    //   this.isApproved = false;
    //   this.isPending = false;
    //   const datasets = await this.$dataset.getDatasets();
    //   this.datasets = datasets.data.results;
    //   this.loading = false;

    //   console.log(this.datasets);
    // },

    async changeApprov() {
      this.isDataset = true;
      this.loading = true;
      this.isAll = false;
      this.isApproved = true;
      this.isPending = false;
      const datasets = await this.$dataset.getDatasets();
      this.datasets = datasets.data.results;
      this.datasets = this.datasets.filter((dataset) => dataset.status.value === 'Approved');
      this.loading = false;
    },
    async fetchDataBasedOnSelectedRequirements(data) {
      this.currentPage = 1;
      this.loading = true;
      try {
        const filtered = await this.$dataset.filteredData(data);
        console.log(filtered);
        this.datasets = filtered.data;
        this.total = filtered.data.length;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async changePend() {
      this.isDataset = true;
      this.loading = true;
      this.isAll = false;
      this.isApproved = false;
      this.isPending = true;
      const datasets = await this.$dataset.getDatasets();
      this.datasets = datasets.data.results;
      this.datasets = this.datasets.filter((dataset) => {
        return dataset.status.value === 'Pending';
      });
      this.loading = false;
    },

    async changePage(e) {
      this.loading = true;
      try {
        this.currentPage = e;
        const datasets = await this.$dataset.getDatasets(this.currentPage);
        this.datasets = datasets?.data?.results;
        if (this.isPending) {
          this.datasets = this.datasets.filter((dataset) => {
            return dataset?.status?.value === 'Pending';
          });
          if (this.datasets.length === 0) {
            const newSet = await this.$dataset.getDatasets(this.currentPage + 1);
            this.datasets = newSet.data.results;
          }
        }

        if (this.isApproved) {
          this.datasets = this.datasets.filter((dataset) => {
            return dataset?.status?.value === 'Approved';
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    getYear(date) {
      return moment(date).format('YYYY');
    },

    // searching
    async onSearch() {
      const datasets = await this.$dataset.getDatasetsSearch(this.search);
      this.datasets = datasets.data.results;
      if (this.isPending) {
        this.datasets = this.datasets.filter((dataset) => {
          return dataset.status.value === 'Pending';
        });
      }

      if (this.isApproved) {
        this.datasets = this.datasets.filter((dataset) => {
          return dataset.status.value === 'Approved';
        });
      }
    },
  },
  async mounted() {
    this.loading = true;
    try {
      // getting the datasets
      const datasets = await this.$dataset.getDatasets();
      this.datasets = datasets.data.results;
      this.datasets = this.datasets.filter((dataset) => {
        return dataset.status.value === 'Pending';
      });
      this.tempDatasets = this.datasets;
      this.loading = false;
      console.log(this.datasets);
    } catch (error) {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters('DATASET_STORE', ['getProject', 'getFilterData']),
    ...mapGetters('auth', ['user']),

    computedData() {
      // console.log(this.projects, 'computed')
      return this.datasets.filter((project) => {
        return project.name.toLowerCase().match(this.search.toLowerCase());
      });
    },

    ComputedDatasets() {
      return this.computedData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },

  watch: {
    getFilterData(val) {
      // this.loading = true;
      console.log(this.datasets);
      console.log(val.status[0]);
      console.log(val.status[1]);

      // status
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= 1; i++) {
        if (val.status[i] === 'Completed') {
          this.completed = true;
          console.log(this.completed);
          this.completedDatasets = this.tempDatasets.filter((proj) => {
            return proj.completed === this.completed;
          });
        }
        if (val.status[i] === 'Ongoing') {
          this.ongoing = false;
          this.ongoingDatasets = this.tempDatasets.filter((proj) => {
            return proj.completed === this.ongoing;
          });
        }
      }

      console.log(this.datasets);
      console.log(this.tempDatasets);
      // Combine both datasets incase user selects to filter based on ongoing and completed
      this.datasets = [...this.completedDatasets, ...this.ongoingDatasets];

      this.loading = false;
    },
  },
};
</script>
