import AdminServices from './AdminService';

export default {
  namespaced: true,
  state: {
    adminType: '',
    allAdmins: {},
    singleAdmin: {},
    allDatasets: {},
    singleDataset: {},
    singleNgo: {},
    allDatarequests: {},
    singleDatarequests: {},
    activities: {},
  },
  mutations: {
    setAdminType(state, payload) {
      state.adminType = payload;
    },
    setAdmins(state, payload) {
      state.allAdmins = payload;
    },
    setAdmin(state, payload) {
      state.singleAdmin = payload;
    },
    setNgo(state, payload) {
      state.singleNgo = payload;
    },
    setDatarequests(state, payload) {
      state.allDatarequests = payload;
    },
    setDatasets(state, payload) {
      state.allDatasets = payload;
    },
    setDataset(state, payload) {
      state.singleDataset = payload;
    },
    setActivities(state, payload) {
      state.activities = payload;
    },
  },
  actions: {
    async SETADMINTYPE({ commit }, payload) {
      commit('setAdminType', payload);
    },

    async NEWADMIN(context, payload) {
      const response = await AdminServices.RegisterAdmin(payload);
      const { status } = response;
      if (status === 200) {
        // if (data.entity) {
        //   commit('setUser', data);
        // }
      }
      return Promise.resolve(response);
    },

    async GET_ADMINS({ commit }) {
      const data = await AdminServices.getAdmin();
      // console.log(data);
      commit('setAdmins', data);
      return Promise.resolve(data);
    },

    async UPDATE_ADMIN(context, payload) {
      const response = await AdminServices.updateAdmin(payload);
      // console.log('update =>', payload, response);
      const { status } = response;
      if (status === 200) {
        // if (data.entity) {
        //   commit('setUser', data);
        // }
      }
      return Promise.resolve(response);
    },

    async GET_ADMIN_SINGLE({ commit }, payload) {
      const data = await AdminServices.getAdminById(payload);
      // console.log(data);
      commit('setAdmin', data);
      return Promise.resolve(data);
    },

    async APPROVE_DATASET(context, payload) {
      console.log('to approve');
      const response = await AdminServices.approveDataset(payload);
      const { status } = response;
      if (status === 200) {
        // if (data.entity) {
        //   commit('setUser', data);
        // }
      }
      return Promise.resolve(response);
    },

    async REJECT_DATASET(context, payload) {
      // console.log('to reject');
      const response = await AdminServices.rejectDataset(payload);
      const { status } = response;
      if (status === 200) {
        // if (data.entity) {
        //   commit('setUser', data);
        // }
      }
      return Promise.resolve(response);
    },

    async APPROVE_PROJECT(context, payload) {
      // console.log('to approve');
      const response = await AdminServices.approveProject(payload);
      const { status } = response;
      if (status === 200) {
        // if (data.entity) {
        //   commit('setUser', data);
        // }
      }
      return Promise.resolve(response);
    },

    async REJECT_PROJECT(context, payload) {
      // console.log('to reject');
      const response = await AdminServices.rejectProject(payload);
      const { status } = response;
      if (status === 200) {
        // if (data.entity) {
        //   commit('setUser', data);
        // }
      }
      return Promise.resolve(response);
    },

    async APPROVE_NGO(context, payload) {
      // console.log('to approve');
      const response = await AdminServices.approveNgo(payload);
      const { status } = response;
      if (status === 200) {
        // if (data.entity) {
        //   commit('setUser', data);
        // }
      }
      return Promise.resolve(response);
    },

    async REJECT_NGO(context, payload) {
      // console.log('to reject');
      const response = await AdminServices.rejectNgo(payload);
      const { status } = response;
      if (status === 200) {
        // if (data.entity) {
        //   commit('setUser', data);
        // }
      }
      return Promise.resolve(response);
    },

    async UPDATE_NGO(context, payload) {
      const response = await AdminServices.updateNgo(payload);
      // console.log('update =>', payload, response);
      const { status } = response;
      if (status === 200) {
        // if (data.entity) {
        //   commit('setUser', data);
        // }
      }
      return Promise.resolve(response);
    },
    // setting single NGO to be edited in the Admin section

    async SET_NGO({ commit }, payload) {
      commit('setNgo', payload);
    },

    //= =================CREATE NEW DATASETS ==================//
    async NEW_DATASET(context, payload) {
      const response = await AdminServices.postDatasets(payload);
      const { status } = response;
      if (status === 200) {
        // if (data.entity) {
        //   commit('setUser', data);
        // }
      }
      return Promise.resolve(response);
    },
    //= =================GET ALL DATAREQUEST ========================//
    async GET_DATAREQUEST() {
      const data = await AdminServices.getDatarequest();
      // console.log(data);
      // commit('setDatasets', data);
      return Promise.resolve(data);
    },

    //= =================GET ALL DATASETS ========================//
    async GET_DATASETS({ commit }) {
      const data = await AdminServices.getDatasets();
      // console.log(data);
      commit('setDatasets', data);
      return Promise.resolve(data);
    },

    //= =================GET SINGLE DATASET ========================//
    async GET_SINGLE_DATASET({ commit }, payload) {
      const data = await AdminServices.getDatasetById(payload);
      // console.log(data);
      commit('setDataset', data);
      return Promise.resolve(data);
    },

    //= =================uPDATE DATASET ========================//

    async UPDATE_DATASET(context, payload) {
      const response = await AdminServices.updateDataset(payload);
      // console.log('update =>', payload, response);
      const { status } = response;
      if (status === 200) {
        // if (data.entity) {
        //   commit('setUser', data);
        // }
      }
      return Promise.resolve(response);
    },

    // gsfhdjfhd

    //= =================CREATE NEW DATAREQUESTS ==================//
    async NEW_DATAREQUEST(context, payload) {
      const response = await AdminServices.postDatarequest(payload);
      const { status } = response;
      if (status === 200) {
        // if (data.entity) {
        //   commit('setUser', data);
        // }
      }
      return Promise.resolve(response);
    },

    //= =================GET ALL DATASETS ========================//

    async GET_DATAREQUEST({ commit }) {
      const data = await AdminServices.getDatarequests();
      // console.log(data);
      commit('setDatasets', data);
      return Promise.resolve(data);
    },

    //= =================GET SINGLE DATASET ========================//
    async GET_SINGLE_DATAREQUEST({ commit }, payload) {
      const data = await AdminServices.getDatarequestById(payload);
      // console.log(data);
      commit('setDataset', data);
      return Promise.resolve(data);
    },

    //= =================uPDATE DATASET ========================//

    async UPDATE_DATAREQUEST(context, payload) {
      const response = await AdminServices.updateDatarequests(payload);
      // console.log('update =>', payload, response);
      const { status } = response;
      if (status === 200) {
        // if (data.entity) {
        //   commit('setUser', data);
        // }
      }
      return Promise.resolve(response);
    },

    //= =================GET ACTIVITIES ========================//
    async GET_ACTIVITIES({ commit }, payload) {
      const data = await AdminServices.getActivities(payload);
      console.log(data);
      commit('setActivities', data);
      return Promise.resolve(data);
    },
  },

  getters: {
    getAdminType: (state) => state.adminType,
    getAllAdmins: (state) => state.allAdmins,
    getAdminById: (state) => state.singleAdmin,
    getSingleNgo: (state) => state.singleNgo,
    getDatasets: (state) => state.allDatasets,
    getSingleDataset: (state) => state.singleDataset,
    getDatarequests: (state) => state.allDatarequests,
    getSingleDatarequest: (state) => state.singleDatarequests,
    getActivities: (state) => state.activities,
  },
};
