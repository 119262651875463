import Routes from './routes';
import axios from '../../../../plugin/axios';

/**
 * ! reset password function
 */
const RESET_PASSWORD = (PAYLOAD) => axios.post(Routes.auth.reset_password, PAYLOAD);

const VERIFY_RESET_PASSWORD_TOKEN = (PAYLOAD) => axios.post(Routes.auth.verify_token, PAYLOAD);

export { RESET_PASSWORD, VERIFY_RESET_PASSWORD_TOKEN };
