<template>
  <div id="ngo-admin-add">
    <section class="container mt-2 mb-5">
      <router-link to="/admin/ngo" class="back-icon">
        <b-icon icon="arrow-left"></b-icon>
      </router-link>
      <h4 class="text-primary text-uppercase font-weight-normal">
        Edit NGO - {{ getSingleNgo.name }}
      </h4>
      <p>
        <!-- Please complete the following questions by selecting the answers(s) of your choice or
        writing in the space provided -->
      </p>
      <vue-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="Ngo registered form"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="800px"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
      >
        <section slot="pdf-content" class="pdf-sec">
          <ValidationObserver v-slot="{ passes }">
            <form @submit.prevent="passes(submit(form))">
              <div class="form-row">
                <div class="col-md-4">
                  <base-input
                    height="3rem"
                    placeholder="Name of Organization"
                    width="100%"
                    v-model="form.name"
                    label="Name of Organization"
                  />
                </div>
                <div class="col-md-4">
                  <base-input
                    height="3rem"
                    placeholder="Year of Establishment"
                    width="100%"
                    v-model="form.establishment_year"
                    label="Year of Establishment"
                    maxlength="4"
                    type="number"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-4">
                  <base-select
                    height="3rem"
                    placeholder="Is it an umbrella organization"
                    width="100%"
                    :items="polar"
                    v-model="form.is_umbrella_organization"
                    label="Is it an umbrella organization?"
                  />
                </div>
                <!-- <div class="col-md-4 col-mrg mt-8">
                  <p>
           If Yes, kindly furnish the records
          </p>
          <input
            type="file"
            class="ml-1 mt-0"
            accept="image/*"
            @change="fileSelected3($event.target.files)"
          />
            </div> -->
              </div>

              <br /><br />
              <div class="sub-head">CAC</div>

              <div class="form-row">
                <div class="col-md-4">
                  <base-input
                    height="3rem"
                    placeholder="Year of registration"
                    width="100%"
                    v-model="form.cac_registration_year"
                    label="CAC Year of registration"
                    type="number"
                  />
                </div>
                <div class="col-md-4">
                  <base-input
                    height="3rem"
                    placeholder="Registration no"
                    width="100%"
                    v-model="form.cac_registration_number"
                    label="CAC Registration no"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-4">
                  <base-select
                    height="3rem"
                    placeholder="Type"
                    width="100%"
                    :items="type"
                    v-model="form.cac_type"
                    label="CAC Type"
                  />
                </div>
                <!-- <div class="col-md-4 col-mrg upload-sec">
                  <p>CAC registration certificate</p>

                  <input
                    type="file"
                    accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
                    @change="fileSelected1($event.target.files)"
                  />
                </div> -->
              </div>

              <div class="col-md-8">
                <base-input
                  height="3rem"
                  placeholder="Postal Address"
                  width="100%"
                  v-model="form.cac_postal_address"
                  label="Postal Address"
                />
              </div>

              <br />
              <div class="sub-head">Contact Person</div>

              <div class="form-row">
                <div class="col-md-4">
                  <base-input
                    height="3rem"
                    placeholder="Name of contact person"
                    width="100%"
                    v-model="form.contact_name"
                    label="Name of contact person"
                  />
                </div>
                <div class="col-md-4">
                  <base-input
                    height="3rem"
                    placeholder="Full address"
                    width="100%"
                    v-model="form.contact_address"
                    label="Full address"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-4">
                  <base-input
                    height="3rem"
                    placeholder="Position"
                    width="100%"
                    v-model="form.contact_position"
                    label="Position"
                  />
                </div>
                <div class="col-md-4">
                  <base-input
                    height="3rem"
                    placeholder="Email"
                    width="100%"
                    v-model="form.contact_email"
                    label="Email"
                    type="email"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-4">
                  <base-input
                    height="3rem"
                    placeholder="Telephone"
                    width="100%"
                    v-model="form.contact_phone"
                    label="Telephone"
                  />
                </div>

                <!-- <div class="col-md-4 col-mrg pxt-8 upload-sec">
                  <p>CV of regular contact person</p>
                  <input
                    type="file"
                    class="ml-1 mt-0"
                    accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
                    @change="fileSelected2($event.target.files)"
                  />
                </div> -->
              </div>

              <br />
              <br />
              <div class="sub-head">Organization</div>

              <base-textarea
                height="3rem"
                placeholder="Focus of Organization programmes in health"
                width="100%"
                v-model="form.organization_focus"
                label="Focus of Organization programmes in health"
                name="Focus of Organization programmes in health"
              />

              <div class="form-row">
                <div class="col-md-4">
                  <base-input
                    height="3rem"
                    placeholder="No of Staff"
                    width="100%"
                    v-model="form.number_of_staffs"
                    label="No of Staff"
                  />
                </div>
                <div class="col-md-4">
                  <base-textarea
                    height="3rem"
                    placeholder="Physical Facilites"
                    width="100%"
                    v-model="form.physical_facilities"
                    label="Physical Facilites with no (e.g Offices x1, ..)"
                  />
                </div>
              </div>

              <base-input
                height="3rem"
                placeholder="Bankers"
                width="100%"
                v-model="form.bankers"
                label="Bankers and their full addresses"
                name="Bankers and their full addresses"
              />

              <base-textarea
                height="3rem"
                placeholder="Other assets"
                width="100%"
                v-model="form.other_assets"
                label="Other assets"
                name="Other assets"
              />

              <base-input
                height="3rem"
                placeholder="Sources"
                width="100%"
                v-model="form.source_of_funding"
                label="Sources of funding"
                name="Sources of funding"
              />

              <base-textarea
                height="3rem"
                placeholder="Scope of Operation"
                width="100%"
                v-model="form.scope"
                label="Scope of Operation"
                name="Scope of Operation"
              />

              <base-textarea
                height="3rem"
                placeholder="Local Affiliation"
                width="100%"
                v-model="form.local_affiliation"
                label="Local Affiliation"
                name="Local Affiliation"
              />

              <base-textarea
                height="3rem"
                placeholder="international Affiliation"
                width="100%"
                v-model="form.international_affiliation"
                label="International Affiliation"
                name="International Affiliation"
              />

              <base-textarea
                height="3rem"
                placeholder="Past projects in the last 5 years"
                width="100%"
                v-model="form.past_projects"
                label="Past projects in the last 5 years "
                name="Past projects in the last 5 years"
              />

              <base-select
                height="3rem"
                placeholder="Is your organization working with others"
                width="100%"
                v-model="form.is_organization_working_others"
                :items="polar"
                label="Is your organization working with others"
                name="Is your organization working with others"
              />

              <base-textarea
                height="3rem"
                placeholder="List of organizations"
                width="100%"
                v-model="form.list_of_organizations"
                label="List of Organizations"
                name="List of Organizations"
              />

              <div class="form-row">
                <div class="col-md-6">
                  <base-textarea
                    height="3rem"
                    placeholder="List of past publications/ reports of your organization"
                    width="100%"
                    v-model="form.list_past_publications"
                    label="List of past publications/ reports of your organization"
                  />
                </div>
                <!-- 
                <div class="col-md-4 col-mrg pxt-8 upload-sec">
                  <p>Upload Sample</p>
                  <input
                    type="file"
                    class="ml-1 mt-0"
                    accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
                    @change="fileSelected3($event.target.files)"
                  />
                </div> -->
              </div>

              <base-textarea
                height="3rem"
                placeholder="Regular resource persons"
                width="100%"
                v-model="form.resource_persons"
                label="Regular resource persons"
                name="Regular resource persons"
              />

              <br /><br /><br />
              <div class="grid">
                <button
                  class="text-white bg-primary p-2 custom-button mt-4"
                  @click.prevent="update"
                >
                  Save Edits
                </button>

                <button
                  class="text-white bg-primary p-2 custom-button mt-4"
                  @click="generateReport()"
                >
                  Print
                </button>
              </div>
            </form>
          </ValidationObserver>
        </section>
      </vue-html2pdf>
    </section>

    <div class="grid">
      <div v-if="getSingleNgo.approved === false">
        <button @click="approve()" class="approve_btn">Approve</button>
      </div>
      <div v-if="getSingleNgo.approved === true">
        <button @click="reject()" class="reject_btn">Suspend</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import _baseSelect from '../../../../components/FormInputs/_base-select.vue';
// import _baseTextarea from '../../../../components/FormInputs/_base-textarea.vue';
import VueHtml2pdf from 'vue-html2pdf';
import {
  getProgramArea as GETPROGRAMS,
  addOrganization as ADD_ORGANIZATION,
  // patchOrganization as UPDATAE_NGO,
} from '../../config/api-modules/api.organization';
import zones from '../../config/states_and_zones';

export default {
  // components: { _baseSelect, _baseTextarea },
  name: 'ngo-admin-add',
  data() {
    return {
      form: {
        name: '',
        type: 'Non governmental Organization',
        program_area: null,
        email: '',
        address: '',
        establishment_year: '',
        state: '',
        geo_zone: '',
        name_of_organization: '',
        is_umbrella_organization: '',
        cac_registration_year: '',
        cac_registration_number: '',
        cac_type: '',
        cac_postal_address: '',
        contact_address: '',
        contact_position: '',
        contact_email: '',
        contact_name: '',
        contact_phone: '',
        // focus_organisation_programmes: '',
        organization_focus: '',
        number_of_staffs: '',
        source_of_funding: null,
        scope: '',
        bankers: null,
        physical_facilities: '',
        other_assets: '',
        local_affiliation: '',
        international_affiliation: '',
        past_projects: '',
        list_past_publications: '',
        list_of_organizations: '',
        is_organization_working_others: '',
        contact_person_first_name: '',
        contact_person_last_name: '',
        contact_person_phone: '',
        contact_person_email: '',
        contact_person_position: '',
        umbrella_org: '',
        cac_registration_certificate: null,
        cv_of_regular_contact_person: null,
        activities_documentation: null,
        resource_persons: '',
        status: false,
        password: '',
      },
      polar: ['Yes', 'No'],
      type: ['For Profit', 'Non Profit'],
      zones: [
        'North Central',
        'North East',
        'North West',
        'South East',
        'South South',
        'South West',
      ],
      states: [
        'Niger',
        'Kogi',
        'Benue',
        'Plateau',
        'Nasarawa',
        'Kwara',
        'FCT',
        'Bauchi',
        'Borno',
        'Taraba',
        'Adamawa',
        'Gombe',
        'Yobe',
        'Zamfara',
        'Sokoto',
        'Kaduna',
        'Kebbi',
        'Katsina',
        'Kano',
        'Jigawa',
        'Enugu',
        'Imo',
        'Ebonyi',
        'Abia',
        'Anambra',
        'Bayelsa',
        'Akwa Ibom',
        'Edo',
        'Rivers',
        'Cross River',
        'Delta',
        'Oyo',
        'Ekiti',
        'Osun',
        'Ondo',
        'Lagos',
        'Ogun',
      ],
      source1: '',
      source2: '',
      source3: '',
      banker1: '',
      banker2: '',
      banker3: '',
      cac_registration_certificate: null,
      cv_of_regular_contact_person: null,
      activities_documentation: null,
      loader: false,
      programs: [],
      zone: zones,
      program_area_loader: false,
      total: null,
    };
  },

  computed: {
    ...mapGetters('admin', ['getSingleNgo']),
  },
  components: {
    VueHtml2pdf,
  },

  methods: {
    ...mapActions('admin', [
      'APPROVE_NGO',
      'REJECT_NGO', // -> this.LOGIN()
      'UPDATE_NGO',
    ]),
    async approve() {
      await this.APPROVE_NGO(this.getSingleNgo.id)
        .then(() => {
          console.log('id', this.getSingleNgo.id);
          this.$swal('Ngo approved!');
          this.$router.go(-1);
        })
        .catch(() => {
          console.log('id', this.getSingleNgo.id);
          this.$swal('Ngo not approved');
        });
    },
    async reject() {
      await this.REJECT_NGO(this.getSingleNgo.id)
        .then(() => {
          this.$swal('Ngo rejected!');
          this.$router.go(-1);
        })
        .catch(() => {
          this.$swal('Ngo not rejected');
        });
    },
    async update() {
      await this.UPDATE_NGO({ id: this.getSingleNgo.id, data: this.form })
        .then(() => {
          this.$swal('Ngo updated!');
          this.$router.go(-1);
        })
        .catch(() => {
          this.$swal('Ngo not updated');
        });
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    fileSelected1(file) {
      // eslint-disable-next-line prefer-destructuring
      this.cac_registration_certificate = file[0];
    },

    fileSelected2(file) {
      // eslint-disable-next-line prefer-destructuring
      this.cv_of_regular_contact_person = file[0];
    },

    generateYear() {
      let startYear = 1940;
      const currentYear = new Date().getFullYear();
      const years = [];
      startYear = startYear || 1980;
      while (startYear <= currentYear) {
        // eslint-disable-next-line no-plusplus
        years.push(startYear++);
      }
      return years;
    },
    async submit(payload) {
      // resolving the form data:
      this.form.cac_registration_certificate = new FormData();
      this.form.cac_registration_certificate.append('cac', this.cac_registration_certificate);
      this.form.umbrella_org = 'None';
      this.form.establishment_year = this.form.year;
      this.form.year_of_establishment = this.form.year;
      this.form.cv_of_regular_contact_person = new FormData();
      this.form.cv_of_regular_contact_person.append('cv', this.cv_of_regular_contact_person);
      this.form.contact_email = this.form.contact_person_email;
      this.form.contact_name = this.form.contact_person_first_name;
      this.form.activities_documentation = new FormData();
      this.form.activities_documentation.append('activities', this.activities_documentation);

      // source of funding
      this.form.source_of_funding = '';
      this.form.source_of_funding += this.source1;
      if (this.source2) {
        this.form.source_of_funding = this.source1.concat(this.source2);
      }

      if (this.source3) {
        this.form.source_of_funding = this.source1.concat(this.source2, this.source3);
      }

      // bankers
      this.form.bankers = '';
      this.form.bankers += this.banker1;
      if (this.banker2) {
        this.form.bankers = this.banker1.concat(this.banker2);
      }

      if (this.banker3) {
        this.form.bankers = this.banker1.concat(this.banker2, this.banker3);
      }

      this.form.email = this.form.contact_person_email;
      this.form.name_of_organization = this.form.name;

      // debugger;
      this.loader = true;
      console.log('payload', payload);
      ADD_ORGANIZATION(payload)
        .then((e) => {
          console.log(e);
          this.$toast.show({
            type: 'SUCCESS',
            message: `${payload.name} has been added`,
          });
          // this.$router.push('/ngo/admin');
          this.$router.push('/ngo/landing');
          /**
           *
           */
          // const newJec = Object.assign(e.data.details, { roles: [3] });
          // console.log(newJec);
          // debugger;
          // UPDATAE_NGO(e.data.id, { roles: [3] })
          //   .then((et) => {
          //     console.log(et);
          //     this.$toast.show({
          //       type: 'SUCCESS',
          //       message: `${payload.name} has been added`,
          //     });
          //     this.$router.push('/ngo/admin');
          //   })
          //   .catch((el) => {
          //     console.log(el);
          //   });
          // this.$toast.show({
          //   type: 'SUCCESS',
          //   message: `${payload.name} has been added`,
          // });
        })
        .catch(() => {
          this.$toast.show({
            type: 'ERROR',
            message: `Unable to add ${payload.name}`,
          });
        })
        .finally(() => {
          this.loader = false;
        });
    },

    getPrograms(count) {
      this.program_area_loader = true;
      GETPROGRAMS(count)
        .then((data) => {
          this.total = data.data.count;
          this.programs = data.data.results;
        })
        .catch(() => {
          this.programs = [];
        })
        .finally(() => {
          this.program_area_loader = false;
        });
    },
  },
  mounted() {
    this.getPrograms();

    // setting the inital data of the database
    this.form.name = this.getSingleNgo.name;
    this.form.email = this.getSingleNgo.email;
    this.form.address = this.getSingleNgo.address;
    this.form.year = this.getSingleNgo.establishment_year;
    this.form.state = this.getSingleNgo.state;
    this.form.geo_zone = this.getSingleNgo.geo_zone;
    this.form.name_of_organization = this.getSingleNgo.name_of_organization;
    this.form.year_of_establishment = this.getSingleNgo.year_of_establishment;
    this.form.is_umbrella_organization = this.getSingleNgo.is_umbrella_organization;
    this.form.cac_registration_year = this.getSingleNgo.cac_registration_year;
    this.form.cac_registration_number = this.getSingleNgo.cac_registration_number;
    this.form.cac_type = this.getSingleNgo.cac_type;
    this.form.cac_postal_address = this.getSingleNgo.cac_postal_address;
    this.form.contact_address = this.getSingleNgo.contact_address;
    this.form.contact_position = this.getSingleNgo.contact_position;
    this.form.contact_email = this.getSingleNgo.contact_email;
    this.form.contact_name = this.getSingleNgo.contact_name;
    this.form.focus_organisation_programmes = this.getSingleNgo.focus_organisation_programmes;
    this.form.number_of_staffs = this.getSingleNgo.number_of_staffs;
    this.form.source_of_funding = this.getSingleNgo.source_of_funding;
    this.form.scope = this.getSingleNgo.scope;
    this.form.bankers = this.getSingleNgo.bankers;
    this.form.physical_facilities = this.getSingleNgo.physical_facilities;
    this.form.other_assets = this.getSingleNgo.other_assets;
    this.form.local_affiliation = this.getSingleNgo.local_affiliation;
    this.form.international_affiliation = this.getSingleNgo.international_affiliation;
    this.form.past_projects = this.getSingleNgo.past_projects;
    this.form.list_past_publications = this.getSingleNgo.list_past_publications;
    this.form.list_of_organizations = this.getSingleNgo.list_of_organizations;
    this.form.is_organization_working_others = this.getSingleNgo.is_organization_working_others;
    this.form.contact_person_first_name = this.getSingleNgo.contact_person_first_name;
    this.form.contact_person_last_name = this.getSingleNgo.contact_person_last_name;
    this.form.contact_phone = this.getSingleNgo.contact_phone;
    this.form.contact_person_email = this.getSingleNgo.contact_person_email;
    this.form.contact_person_position = this.getSingleNgo.contact_person_position;
    this.form.establishment_year = this.getSingleNgo.establishment_year;
    this.form.umbrella_org = this.getSingleNgo.umbrella_org;
    this.form.resource_persons = this.getSingleNgo.resource_persons;
  },
};
</script>

<style lang="scss" scoped>
.back-icon {
  font-size: 30px;
  font-weight: bolder;
}
.sub-head {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.custom-button {
  width: 150px;
}
label {
  font-size: 0.9375rem;
}

* {
  font-family: 'Open Sans';
}
label {
  font-size: 0.813rem !important;
}
.form-check-input {
  font-family: 'Open Sans' !important;
}

.col-mrg {
  margin-left: 40px;
}

.upload-sec {
  margin-top: 35px;
}

.pdf-sec {
  padding: 20px;
}

.grid {
  display: grid;
  grid-template-columns: 50% 50%;
}

.approve_btn {
  background-color: green;
  margin: 20px;
  width: 150px;
  color: white;
  border-radius: 5px;
  opacity: 1;
}

.approve_btn:hover {
  background-color: green;
  opacity: 0.8;
}

.reject_btn {
  background-color: green;
  margin: 20px;
  width: 150px;
  color: white;
  border-radius: 5px;
}

.reject_btn:hover {
  background-color: green;
  opacity: 0.8;
}
</style>
