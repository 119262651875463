import index from './views/index.vue';
import Feedback from './views/feedback-page.vue';

export default [
  {
    path: '/feedback',
    component: index,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: '',
        component: Feedback,
      },
      {
        path: 'feedback',
        component: Feedback,
      },
    ],
  },
];
