<template>
  <b-container>
    <div v-if="loadingData" class="text-center loading-icon">
      <b-spinner v-if="loadingData" x-large />
    </div>
    <div v-else>
      <b-row class="mb-5 ml-0" align-h="between">
        <h4 class="text-left text-primary">Get Micro-data</h4>
      </b-row>
      <b-row class="p-0" no-gutters align-h="start">
        <b-col cols="12" md="5" lg="3" class="mr-0">
          <p class="text-left font-weight-bold text-black-50">Name of data collection activity:</p>
        </b-col>
        <b-col cols="12" md="7" lg="9" class="ml-0">
          <p class="text-left">
            {{ projectDetails.name }}
          </p>
        </b-col>
      </b-row>
      <b-row class="p-0" no-gutters align-h="start">
        <b-col cols="12" md="5" lg="3" class="mr-0">
          <p class="text-left font-weight-bold text-black-50">Year(s) of Implementation</p>
        </b-col>
        <b-col cols="12" md="7" lg="9" class="ml-0">
          <p class="text-left">
            {{ $dataset.formatDate(projectDetails.end_date, 'MMMM YYYY') }}
          </p>
        </b-col>
      </b-row>
      <b-row class="p-0" no-gutters align-h="start">
        <b-col cols="12" md="5" lg="3" class="mr-0">
          <p class="text-left font-weight-bold text-black-50">States Implemented</p>
        </b-col>
        <b-col cols="12" md="7" lg="9" class="ml-0">
          <p class="text-left">Kaduna</p>
        </b-col>
      </b-row>
      <b-row class="p-0" no-gutters align-h="start">
        <b-col cols="12" md="5" lg="3" class="mr-0">
          <p class="text-left font-weight-bold text-black-50">Status</p>
        </b-col>
        <b-col cols="12" md="7" lg="9" class="ml-0">
          <p class="text-left">Completed</p>
        </b-col>
      </b-row>
    </div>
    <div class="mt-5" />
    <ValidationObserver v-slot="{ passes }">
      <form @submit.prevent="passes(getDataProject)">
        <div>
          <p class="text-left mt-n3">
            To download this Micro-data, select your project below. Make sure you select the project
            that contains the description on how you plan to use the Micro-data, including the
            analysis you propose to perform with the Micro-data you wish to download.
          </p>
          <p class="text-left pt-3 mb-5 pb-2">
            <span
              ><span class="font-weight-bold text-black-50 mr-1">Click this link to</span>
              <b-link :to="`/inventory/create-project/${$route.params.id}`"
                >Create new project</b-link
              ></span
            >
          </p>
          <!-- <pre>{{ userProjects }}</pre> -->
          <div class="d-flex flex-column">
            <label> Your Projects</label>
            <select v-model="project" class="w-50 p-3">
              <option value="" selected>Pick a Project</option>
              <option
                v-for="project in userProjects"
                class="p-2"
                :key="project"
                :value="!project ? '' : project.id"
              >
                {{ !project ? 'No projects to display' : project.title }}
              </option>
            </select>
          </div>
          <div class="d-flex flex-column">
            <label>Reason </label>
            <input type="textarea" v-model="reason" class="w-50 p-3" />
          </div>
        </div>
        <b-row class="mt-5 ml-2">
          <button type="submit" :disabled="loading" class="sharp ml-n1">
            <b-spinner v-if="loading" small />
            <span v-else @click="postDataRequest">Proceed</span>
          </button>
        </b-row>
      </form>
    </ValidationObserver>
    <DataRequestConfirmationModal
      :show-data-request-modal="showDataRequestModal"
      :projectselected="projectSelected"
    />
  </b-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { toast } from '@/plugin/customToast';
import { mapGetters, mapActions } from 'vuex';
import DataRequestConfirmationModal from '../../../components/ProjectDetails/DataRequestConfirmationModal.vue';

export default {
  components: {
    ValidationObserver,
    DataRequestConfirmationModal,
  },
  data() {
    return {
      loading: false,
      projectSelected: '',
      showDataRequestModal: false,
      userProjects: [],
      projectDetails: [],
      project: '',
      reason: '',
      dataset: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  async mounted() {
    try {
      // this.loadingData = true;
      const { data } = await this.$dataset.getProjectForSpecificUser();
      // console.log(this.user.id,'chidata')
      this.userProjects = data.results.filter((el) => el.owner === this.user.id);
      // const datasetDetails = await this.$dataset.getDatasetDetails(this.$route.params.id);
      // this.projectDetails = datasetDetails.data;
      // console.log(this.projectDetails);
      // console.log('userProjects', this.userProjects);
      this.loadingData = false;
    } catch (error) {
      this.loadingData = false;
    }
  },
  methods: {
    ...mapActions('admin', ['NEW_DATAREQUEST']),
    async getDataProject() {
      this.loading = true;
      try {
        const dataToRequest = {
          // details: null,
          approved: false,
          user: this.user.id,
          project: this.projectSelected.id,
          dataset: this.projectDetails.id,
          reason: this.reason,
        };
        const { data } = await this.$dataset.requestDataset(dataToRequest);
        console.log('data', data);
        if (data) {
          toast.show({
            type: 'SUCCESS',
            message: 'datarequest sent Sucessfully!',
          });
        }
        this.showDataRequestModal = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    // async postDataRequest() {
    //   this.loading = true;
    //   // const datasetid = this.$route.params.id;
    //   const data = {
    //     reason: this.reason,
    //     status: 'Pending',
    //     user: this.user.id,
    //     dataset: this.projectDetails.id,
    //     project: this.project,
    //   };
    //   try {
    //     const response = await this.NEW_DATAREQUEST(data);
    //     this.project = '';
    //     this.reason = '';
    //     console.log(response);
    //     this.$swal('Data Request Sent');
    //     this.loading = false;
    //   } catch (err) {
    //     this.loading = false;
    //     console.log(err);
    //   }
    // },
  },
};
</script>

<style scoped>
button {
  display: inline-block;
  padding: 10px 29px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 10px 16px;
  outline: none;
}

.sharp {
  width: 9em;
  color: #fff;
  background-color: #30ab4c;
  border-color: #0b7022;
}

.save {
  background-color: #fff;
  color: #30ab4c;
  border-color: #0b7022;
  border-radius: 0;
}

.save:hover,
.save:focus,
.save:active,
.save.active,
.open .dropdown-toggle.save {
  background-color: #ffffff;
  color: #30ab4c;
  outline: none;
  border-color: #30ab4c;
}

.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open .dropdown-toggle.sharp {
  border-radius: 0;
  color: #fff;
  background-color: #30ab4c;
  outline: none;
  border-color: #30ab4c;
}

/***********************
  SHARP BUTTONS
************************/
.sharp {
  outline: none;
  border-radius: 0;
}

/***********************
  CUSTON BTN VALUES
************************/

button {
  padding: 14px 24px;
  outline: none;
  border: 0 none;
  /* font-weight: 700; */
  letter-spacing: 1px;
}
button:focus,
button:active:focus,
button.active:focus {
  outline: 0 none;
}

.sharp {
  background: #30ab4c;
  color: #ffffff;
}
.sharp:hover,
.sharp:focus,
.sharp:active,
.sharp.active,
.open > .dropdown-toggle.sharp {
  background: #30ab4c;
}
.sharp:active,
.sharp.active {
  background: #45cc64;
  box-shadow: none;
}
.save:active,
.save.active {
  background: #d8f7e0;
  color: #30ab4c;
  box-shadow: none;
}
</style>
