<template>
  <div id="about" class="container">
    <h4 class="text-primary font-weight-normal my-4 custom">About</h4>
    <p class="text-justify">
      This central web-based inventory has been developed by the Department of Health Planning,
      Research and Statistics (DHPRS) of the Federal Ministry of Health (FMoH) to compile all health
      data and intervention projects carried out by international partners & non-governmental
      organisations across all health domains in the country. The platform also captures details of
      Non Governmental Organizations (NGOs) that have been registered with the Federal Ministry of
      Health under the purview of the NGO branch at the Health Systems Strengthening division of the
      DHPRS-FMoH.
    </p>
    <p class="text-justify">
      This information will be used to enhance coordination of all health data activities and
      projects, and increase visibility of these activities and resources to the general public.
      This website contains inventories of all health related data activities, health partner
      projects and in the country.
    </p>
    <p class="text-justify">
      Section 3.2 (Page 9) of the 2014 National HIS Policy states that; “All stakeholders including
      bilateral and multilateral agencies* and other international development agencies/programmes
      working at national and subnational levels shall support HIS activities through existing
      government structures”
    </p>
    <p class="text-justify">
      *A Bilateral organization is a government agency or non-profit organization based in a single
      country that provides aid to people in other countries. <br />
      *A multilateral organization is formed by three or more nations to work on issues that are
      relevant to each of them.
    </p>
    <p class="text-justify">
      “Performance of programmes/projects supported by international development organizations shall
      be assessed using the data obtainable from the approved national data collection system”.
    </p>
    <p class="text-justify">This inventory has been divided into four sections;</p>
    <ul class="ml-5">
      <li class="text-justify">
        <b>The Health Data inventory:</b> An inventory of all health related data activities,
        datasets, and data portfolios in the country.
        <span>
          Visit the
          <router-link to="/inventory/dataset"> “Data Inventory” </router-link>
          page to learn more about this inventory.</span
        >
      </li>
      <li class="text-justify">
        <b>The Health Projects inventory:</b> An inventory of health partners and their projects in
        the country.
        <span>
          Visit the
          <router-link to="/inventory/project"> “Projects Inventory” </router-link>
          page to learn more about this inventory.</span
        >
      </li>
      <li class="text-justify">
        <b>The NGO Section:</b> An inventory of all registered Nigerian NGOs, their contact
        information and their activity reports.
        <span>
          Visit the
          <router-link to="/ngo/landing"> “NGO Inventory” </router-link>
          page to learn more about this inventory.</span
        >
      </li>
      <li class="text-justify">
        <b>The Stakeholder Database:</b> A comprehensive record of all stakeholders’ information and
        activities/areas of support to the DHPRS.
        <span>
          Visit the
          <router-link to="/inventory/stakeholders"> “Stakeholder Inventory” </router-link>
          page to learn more about this inventory.</span
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'about',
};
</script>

<style scoped>
h4 {
  color: black;
}
.custom {
  font-family: 'Open Sans';
}

p {
  font-family: 'Roboto';
  font-size: 1.125rem;
  letter-spacing: 0px;
  word-spacing: 0px;
  font-weight: normal;
  color: black;
}

li {
  font-family: 'Roboto';
  font-size: 1.125rem;
  letter-spacing: 0px;
  word-spacing: 0px;
  font-weight: normal;
  color: black;
}
</style>
